import {DbIdentity, DefaultId, NumberDate} from "./types";

import { observable, action, toJS, computed, makeObservable } from "mobx";
import { aFetch } from "../services/api/fetch";

import { localStorage } from "../utils/localStorage";

import { Gradebook_Sync_5C_Storage_Name } from "../config";
import { sortLevelId } from "./QualityMatrix";

export class Quality {
    constructor(data?:{}) {
        if (data != null) {
            Object.assign(this, data);

            this.originData.levelIds = data.levelIds ?? [];
            this.originData.threadId = data.threadId ?? "";
            this.levelIds = Array.isArray(data.levelIds) ? data.levelIds : [];
        }

        makeObservable(this, {
            levelIds     : observable,
            set_levelIds : action.bound,
            updatedBy    : observable,
            dateUpdated  : observable,
            qualityId    : computed,
            hasLevels    : computed,
        });
    }

    studentId : DbIdentity = DefaultId;
    activityId: DbIdentity = DefaultId;
    /** @description mean qualityMatrix.Id 😢*/
    criteriaId: DbIdentity = DefaultId;
    levelIds : DbIdentity[] = []        ; set_levelIds (v: DbIdentity[]) { this.levelIds = v };
    originData = { levelIds: [], threadId: "" };

    threadId  : string     = ''       ;
    updatedBy?: DbIdentity            ;
    dateUpdated?: NumberDate          ;

    get pk() { return ({ studentId:this.studentId, activityId: this.activityId, criteriaId:this.criteriaId }) }
    get qualityId() { return `${this.studentId}${this.activityId}${this.criteriaId}`; }
    get isDirty() { return JSON.stringify(this.originData.levelIds.slice().sort(sortLevelId)) != JSON.stringify(this.levelIds.slice().sort(sortLevelId)) || this.originData.threadId != this.threadId; }
    get hasLevels() { return (this.levelIds ?? []).length > 0 }

    toJS() { return toJS(this); }
    clone() { return new Quality(this.toJS()); }

    //#region Faculty
    static async saveAllActivityStudentQualities({facultyId, activityId, studentId, qualities}:{facultyId:DbIdentity, activityId:DbIdentity, studentId:DbIdentity, qualities: Quality[]}) {
        const [err, xs] = await aFetch<{}[]>("POST", `/faculty/${facultyId}/activity/${activityId}/student/${studentId}/quality/batch`, qualities);

        // post data to gradebook table
        if(!err) localStorage.setItem(Gradebook_Sync_5C_Storage_Name, JSON.stringify({activityId, studentId, qualities: xs}));

        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    static async saveActivityStudentQualities({facultyId, activityId, studentId, qualities}:{facultyId:DbIdentity, activityId:DbIdentity, studentId:DbIdentity, qualities: Quality[]}) {
        const [err, xs] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/activity/${activityId}/student/${studentId}/qualities`, qualities);

        // post data to gradebook table
        if(!err) localStorage.setItem(Gradebook_Sync_5C_Storage_Name, JSON.stringify({activityId, studentId, qualities: xs}));

        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }

    static async saveActivityStudentQuality({facultyId, activityId, studentId, quality}:{facultyId:DbIdentity, activityId:DbIdentity, studentId:DbIdentity, quality: Quality}) {
        const [err, xs] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/activity/${activityId}/student/${studentId}/quality`, quality);

        // post data to gradebook table
        if(!err) localStorage.setItem(Gradebook_Sync_5C_Storage_Name, JSON.stringify({activityId, studentId, qualities: xs}));

        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }

    static async saveActivityStudentQualityAsCommitteeAssessment({facultyId, activityId, studentId, quality}:{facultyId : DbIdentity, activityId : DbIdentity, studentId : DbIdentity, quality : Quality}) {
        const [err, xs] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/committeeAssessment/activity/${activityId}/student/${studentId}/quality`, quality);
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }

    static async saveMany(
        {facultyId, activityId}:{facultyId:DbIdentity, activityId:DbIdentity},
        studentIds:DbIdentity[],
        qualities: Quality[]) {
        const [err, xs] = await aFetch<{}[]>("POST", `/faculty/${facultyId}/activity/${activityId}/quality/batch`, {studentIds , qualities});
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    /**@deprecated */
    static async fetchStudentQualities({studentId, facultyId, signal}:{studentId:DbIdentity, facultyId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/student/${studentId}/qualities`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    static async fetchActivityAsFaculty({facultyId, activityId, signal}:{facultyId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/activity/${activityId}/quality`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    static async fetchScoreAsFaculty({facultyId, activityId, studentId, signal}:{facultyId:DbIdentity, activityId:DbIdentity, studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/activity/${activityId}/student/${studentId}/quality`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    static async fetchClassAsFaculty({facultyId, classId, signal}:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/class/${classId}/quality`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    /**@deprecated */
    static async fetchStudentQualitiesByClass({facultyId, classId, studentId, signal}:{facultyId:DbIdentity, classId:DbIdentity, studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/class/${classId}/student/${studentId}/qualities`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    //#endregion

    //#region Student
    static async fetchScoreAsStudent({studentId, activityId, signal}:{studentId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/student/${studentId}/activity/${activityId}/quality`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    static async fetchClassAsStudent({studentId, classId, signal}:{studentId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/student/${studentId}/class/${classId}/quality`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    //#endregion

    //#region PreviewStudent
    static async fetchScoreAsPreviewStudent({facultyId, activityId, signal}:{facultyId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/previewStudent/activity/${activityId}/quality`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    static async fetchClassAsPreviewStudent({facultyId, classId, signal}:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/previewStudent/class/${classId}/quality`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    //#endregion

    //#region Parent
    static async fetchScoreAsParent({studentId, parentId, activityId, signal}:{studentId:DbIdentity, parentId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/parent/${parentId}/student/${studentId}/activity/${activityId}/quality`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    static async fetchClassAsParent({studentId, parentId, classId, signal}:{studentId:DbIdentity, parentId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/parent/${parentId}/student/${studentId}/class/${classId}/quality`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    //#endregion

    //#region Committee Assessment
    static async fetchRandomStudentQualityAsCommitteeAssessment({facultyId, activityId, signal}:{facultyId:DbIdentity, activityId: DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}>("GET", `/faculty/${facultyId}/committeeAssessment/activity/${activityId}/randomStudentQuality`, undefined, { signal });
        return [err, (err ? undefined : xs ? new this(xs) : undefined)] as const;
    }

    static async fetchQualityAsCommitteeAssessment({facultyId, activityId, studentId, signal}:{facultyId:DbIdentity, activityId:DbIdentity, studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/committeeAssessment/activity/${activityId}/student/${studentId}/quality`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new this(x)))] as const;
    }
    //#endregion
}