import { observable, action, toJS, makeObservable, computed } from "mobx";

import {DbIdentity, DefaultId, NumberDate} from "./types"
import { aFetch } from "../services/api/fetch";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import i18n from "../i18n";

export enum GradingTermType {
    Normal  = 1,
    Virtual = 2,
}

export class GradingTerm {
    gradingTermId   : DbIdentity      = DefaultId;
    schoolId        : DbIdentity      = DefaultId;
    name            : string          = "";
    gradingTermType : GradingTermType = GradingTermType.Normal

    startDate        : NumberDate = 0;
    endDate          : NumberDate = 0;
    gradingStartTime : NumberDate = 0;
    gradingEndTime   : NumberDate = 0;

    constructor(data?:{}) {
        makeObservable(this, {
            schoolId             : observable,
            name                 : observable,
            gradingTermType      : observable,
            startDate            : observable,
            endDate              : observable,
            set_gradingTermType  : action.bound,
            set_name             : action.bound,
            set_startDate        : action.bound,
            set_endDate          : action.bound,
            gradingStartTime     : observable,
            gradingEndTime       : observable,
            set_gradingStartTime : action.bound,
            set_gradingEndTime   : action.bound,
            isCurrentGradingTerm : computed,
            isFutureGradingTerm  : computed,
            canGrade             : computed,
            isPastGradingTerm    : computed,
        });

        if (data != null) Object.assign(this, data);
    }

    toJS() {
        return toJS(this);
    }

    clone() {
        return new GradingTerm(this.toJS());
    }

    set_gradingTermType (v: GradingTermType ) { this.gradingTermType = v };
    set_name            (v: string          ) { this.name            = v };
    set_startDate       (v: NumberDate      ) { this.startDate       = v };
    set_endDate         (v: NumberDate      ) { this.endDate         = v };

    set_gradingStartTime(v: NumberDate) { this.gradingStartTime = v };
    set_gradingEndTime  (v: NumberDate) { this.gradingEndTime   = v };

    get isCurrentGradingTerm() {
        return (this.startDate <= Date.now() && Date.now() <= this.endDate) ||
            (this.gradingStartTime < Date.now() && Date.now() < this.gradingEndTime); //TES-5485
    }
    get isFutureGradingTerm() {return this.startDate > Date.now();}
    get isPastGradingTerm() {return this.endDate != null && this.endDate < Date.now()}

    get canGrade() { return this.gradingStartTime < Date.now() && Date.now() < this.gradingEndTime }
    get canUpdateClass() { return Date.now() < this.gradingEndTime }

    async save() {
        const { schoolId, gradingTermId } = this;
        if (gradingTermId < 1) {
            const [err, x] = await aFetch<GradingTerm>("POST", `/admin/schools/${schoolId}/grading-terms`, this.toJS());
            return [err, (err ? undefined : new GradingTerm(x))!] as const;
        }

        const [err, x] = await aFetch<GradingTerm>("PUT", `/admin/schools/${schoolId}/grading-terms/${gradingTermId}`, this.toJS());
        return [err, (err ? undefined : new GradingTerm(x))!] as const;
    }

    async remove() {
        const { schoolId, gradingTermId } = this;
        const [err,] = await aFetch<GradingTerm>("DELETE", `/admin/schools/${schoolId}/grading-terms/${gradingTermId}`);
        return [err, this] as const;
    }

    static async fetchOfStudentAsFaculty({faccultyId, studentId}:{faccultyId: DbIdentity, studentId:DbIdentity}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${faccultyId}/student/${studentId}/gradingTerm`);
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchOfStudentAsParent({parentId, studentId}:{parentId: DbIdentity, studentId:DbIdentity}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/parent/${parentId}/student/${studentId}/gradingTerm`);
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static sorter = {
        name     : (a: GradingTerm, b: GradingTerm) => a.name.localeCompare(b.name),
        startDate: (a: { startDate: NumberDate, endDate: NumberDate }, b: { startDate: NumberDate, endDate: NumberDate }) => ((a?.startDate || 0) - (b?.startDate || 0)),
        endDate  : (a: { startDate: NumberDate, endDate: NumberDate }, b: { startDate: NumberDate, endDate: NumberDate }) => ((a?.endDate || 0) - (b?.endDate || 0)),
        startDateDesc: (a: { startDate: NumberDate, endDate: NumberDate }, b: { startDate: NumberDate, endDate: NumberDate }) => ((b?.startDate || 0) - (a?.startDate || 0)),
        endDateDesc  : (a: { startDate: NumberDate, endDate: NumberDate }, b: { startDate: NumberDate, endDate: NumberDate }) => ((b?.endDate || 0) - (a?.endDate || 0)),
    }
}

export class DisplayAsOptionGradingTerm {
    gradingTermId     : DbIdentity      = DefaultId;
    gradingTermName   : string          = "";
    gradingTermType   : GradingTermType = GradingTermType.Normal;
    schoolId          : DbIdentity      = DefaultId;
    schoolName        : string          = "";
    startDate        ?: NumberDate      = undefined;
    endDate          ?: NumberDate      = undefined;
    isCurrent         : boolean         = false;
    oneRosterId      ?: string          = "";
    constructor(data?:{}) {
        makeObservable(this, {
            gradingTermId     : observable,
            gradingTermName   : observable,
            gradingTermType   : observable,
            schoolId          : observable,
            schoolName        : observable,
            startDate         : observable,
            endDate           : observable,
            isCurrent         : observable,
            oneRosterId       : observable,
            displayName       : computed,
            displayTermName   : computed,
        });

        if (data != null) Object.assign(this, data);
    }

    get displayName() { return `${this.schoolName} - ${this.gradingTermName}${(this.isCurrent ? `(${i18n.t("app.classes.gradingterm.current")})` : "")}` };
    get displayTermName() { return `${this.gradingTermName}${(this.isCurrent ? `(${i18n.t("app.classes.gradingterm.current")})` : "")}` };

    static sorter = {
        newestFirst: (a: DisplayAsOptionGradingTerm, b: DisplayAsOptionGradingTerm) => ((b?.endDate || 0) - (a?.endDate || 0)),
        schoolName : (a: DisplayAsOptionGradingTerm, b: DisplayAsOptionGradingTerm) => (a?.schoolName || "").localeCompare(b?.schoolName || "")
    }
}