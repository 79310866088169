import { observable, toJS, makeObservable } from "mobx";

import { DbIdentity, DefaultId, NumberDate } from "./types"

export class CommentSeen {
    threadId: string     = "";
    userId  : DbIdentity = DefaultId;
    lastSeen: NumberDate = -1;

    constructor(data?:{}) {
        makeObservable(this, {
            lastSeen: observable,
        });

        if (data != null) Object.assign(this, data);
    }

    toJS() {
        return toJS(this);
    }
}
