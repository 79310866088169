import { action, computed, makeObservable, observable } from "mobx";

import {DbIdentity, DefaultId} from "./types"
import { PrimaryRoleEnum, User, UserExt } from "./User";
import { aFetch } from "../services/api/fetch";
import { ParentStudent } from "./ParentStudent";
import { AdminStudent } from "../FacultyPortal/stores/AdminStudentListStore";

export class Parent extends User {
    parentId: DbIdentity;
    sendConfirmEmail: boolean = false; set_sendConfirmEmail(v: boolean) { this.sendConfirmEmail = v };

    constructor(data?:{}) {
        super(data);

        makeObservable(this, {
            params: computed,
            sendConfirmEmail: observable,
            set_sendConfirmEmail: action.bound,
        });

        this.parentId = DefaultId;
        this.primaryRole = PrimaryRoleEnum.Parent;

        if (data != null) Object.assign(this, data);
    }

    toJS() {
        return ({
            ...super.toJS(),
            sendConfirmEmail: this.sendConfirmEmail
        });
    }

    get params() { return ({ parentId: String(this.parentId) }) }

    async saveAsAdmin(SisUserIds:string[]) {
        const body = { ...this.toJS(), SisUserIds };
        const [err, x] = await aFetch<{}>("PUT", `/admin/parent/${this.parentId}`, body);
        return [err, (err ? undefined : new Parent(x))!] as const;
    }

    async createAsAdmin({ studentId,  SisUserIds}: { studentId: DbIdentity,  SisUserIds:string[]}) {
        const body = { ...this.toJS(), studentId, SisUserIds };
        const [err, x] = await aFetch<{}>("POST", `/admin/parent`, body);
        return [err, (err ? undefined : new Parent(x))!] as const;
    }

    async saveAsFaculty({ schoolId, facultyId }: { schoolId?: DbIdentity, facultyId: DbIdentity }) {
        const body = { ...this.toJS() };
        const [err, x] = await (
            (schoolId == null || schoolId < 1 )
            ? aFetch<{}>("PUT", `/faculty/${facultyId}/parent/${this.parentId}`, body)
            : aFetch<{}>("PUT", `/faculty/${facultyId}/school/${schoolId}/parent/${this.parentId}`, this.toJS())
        );
        return [err, (err ? undefined : new Parent(x))!] as const;
    }

    async createAsFaculty({ schoolId, facultyId, studentId, SisUserIds }: { schoolId?: DbIdentity, facultyId: DbIdentity, studentId: DbIdentity, SisUserIds:string[] }) {
        const body = { ...this.toJS(), studentId, SisUserIds };
        const [err, x] = await (
            (schoolId == null || schoolId < 1 )
            ? aFetch<{}>("POST", `/faculty/${facultyId}/parent`, body)
            : aFetch<{}>("POST", `/faculty/${facultyId}/school/${schoolId}/parent`, body)
        );
        return [err, (err ? undefined : new Parent(x))!] as const;
    }

    static async fetchParentsAsAdmin({schoolId, paging, signal}: {schoolId: DbIdentity, paging: any, signal:AbortSignal}) {
        const [err, data] = await aFetch<{dto: Parent[], total: number}>("GET", `/admin/school/${schoolId}/parent`, paging, { signal });
        return [err, (err ? undefined : {dto: data.dto.map(rc => new Parent(rc)), total: data.total})!] as const;
    }
    static async fetchParentsFaculty({schoolId, facultyId, signal}: {schoolId: DbIdentity, facultyId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<Parent[]>("GET", `/faculty/${facultyId}/school/${schoolId}/parent`, undefined, { signal });
        return [err, (err ? undefined : data.map(rc => new Parent(rc)))!] as const;
    }

    static async fetchAsAdmin({ parentId, signal }: { parentId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<Parent>("GET", `/admin/parent/${parentId}`, undefined, { signal });
        return [err, (err ? undefined : new Parent(data))!] as const;
    }

    static async fetchStudentsAsFacultyAdmin({ paging, signal }
        : {            
            paging: { schoolId: number, searchText: string, offset: number, page: number, limit: number, orderBy: string, total: number },
            signal?: AbortSignal
        }
    ) {        
        const [err, data] =  await aFetch<{students:AdminStudent[], parents:ParentStudent[], total: number}>("GET", `/admin/parent/students`, paging, { signal });
        return [err, (err ? undefined : {students:data.students ?? [], parentStudents: data.parents ?? [], total: data.total})!] as const;
    }

    static async fetchAsFaculty({ parentId, schoolId, facultyId, signal }: {parentId: DbIdentity, schoolId?: DbIdentity, facultyId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await (
            (schoolId == null || schoolId < 1)
            ? aFetch<Parent>("GET", `/faculty/${facultyId}/parent/${parentId}`, undefined, { signal })
            : aFetch<Parent>("GET", `/faculty/${facultyId}/school/${schoolId}/parent/${parentId}`, undefined, { signal })
        );
        return [err, (err ? undefined : new Parent(data))!] as const;
    }    

    static async updatePassword({ facultyId, schoolId, parentId, password }: {parentId: DbIdentity, password: string, schoolId?: DbIdentity, facultyId: DbIdentity}) {
        const body ={ id: parentId, password };
        const [err, data] = await (
            (schoolId == null || schoolId < 1)
            ? aFetch<boolean>("PUT", `/faculty/${facultyId}/parent/${parentId}/updatePassword`, body)
            : aFetch<boolean>("PUT", `/faculty/${facultyId}/school/${schoolId}/parent/${parentId}/updatePassword`, body)
        );
        return [err, (err ? undefined : data)!] as const;
    }

    static sorter = {
        ...User.sorter,
        parentId   : <T extends Parent>(a?: T, b?: T) => (a ? a.parentId || 0: 0) - (b ? b.parentId || 0: 0),
        fullName   : <T extends Parent>(a?: T, b?: T) => (a?.fullName         ?? "").localeCompare(b?.fullName         ?? ""),
        firstName  : <T extends Parent>(a?: T, b?: T) => (a?.firstName.trim() ?? "").localeCompare(b?.firstName.trim() ?? ""),
        lastName   : <T extends Parent>(a?: T, b?: T) => (a?.lastName.trim()  ?? "").localeCompare(b?.lastName.trim()  ?? ""),
        displayName: <T extends Parent>(a?: T, b?: T) => (a?.lfNameAlias      ?? "").localeCompare(b?.lfNameAlias      ?? ""),
    };
}
