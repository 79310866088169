import { observable, action, computed, toJS, makeObservable, runInAction, makeAutoObservable } from "mobx";
import type { UploadFile } from "antd/lib/upload/interface";

import { DefaultId, DbIdentity, NumberDate, isValidIdentity, UrlString, DeletedStatus, IUserShortInfo } from "./types";
import { Class, StudentGroup } from "./Class";
import type { QualityMatrix } from "./QualityMatrix";
import { ActivityScore } from "./ActivityScore";
import { Discussion, WordCountType } from "./Discussion";
import { StudentActDoc } from "./StudentActDoc";
import { IModule, Module } from "./Module";
import type { IGradingSetting } from "./IGradingSetting";
import type { Student } from "./Student";
import { ActDoc, IActDoc } from "./ActDoc";

import { aFetch, apiHost, uploadFile, uploadFile2, uploadFileChunks, versionPrefix } from "../services/api/fetch";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";

import { map, groupBy, sumBy, now } from "lodash-es";
import moment from "moment";
import { imageUrlToThumbnail, qs } from "../utils/url";
import { isPublishedNow, trimSeconds } from "../utils/time";
import { isGoogleDriveUrl } from "../components/GoogleApis/utils";
import { isBlobFile } from "../utils/file";

import type { EditActivityStore, StudentActivityGroup } from "../FacultyPortal/stores/EditActivityStore";
import type { IActivityPublish } from "../FacultyPortal/stores/ActivityPublishStore";
import { activityTypesCanMarkComplete, activityTypesGradable, activityTypesGradeOnly, activityTypesHasLessonAutoCompleteSection, activityTypesHasScore, activityTypesHaveDoc, activityTypesHiddenInModule, activityTypesIncludeInstruction, activityTypesIncludeLessonPlan, activityTypesNotAllowComment } from "./ActivityType";
import { ActivityType } from "./ActivityType";
import { stripHtml } from "../utils/html";
import { InstructionActDoc } from "./ActDoc/InstructionActDoc";
import { ActivityVideoExtension } from "./Activity/ActivityVideoExtension";
import { IAeriesRecord } from "./Aeries/IAeriesRecord";
import { nanoid } from "nanoid";
import { createError } from "../services/api/AppError";
import { ImportMethod } from "./Import/ImportDoc";
import { SubmissionTypeEnum } from "./SubmissionTypeEnum";
export { ActivityType } from "./ActivityType";

export const ActivityTitleMaxLength       = 180; //<p><br/></p>
export const ActivityDescriptionMaxLength = 500;

export enum ActtachmentContentType{
    Word      = 1,
    Pdf       = 2,
    Excel     = 3,
    Pptx      = 4,
    Csv       = 5,
    Audio     = 6,
    Video     = 7,
    Archive   = 8,
    Picture   = 9,
    Weblink   = 10
}

export enum ActivitySyncPolicy {
    NotSync = 0b00,
    Sync    = 0b11,
}

export enum GradeSyncPolicy {
    NotSync = 0b00,
    Sync    = 0b11,
}

export enum PublishStatusEnum {
    Published = 1,
    Pending,
    Unpublished,
}

export enum StudentReviewOptionEnum {
    Never = 0,
    AfterMarkGradingCompleted = 1,
    AfterFirstSubmission = 2
}

export enum CopyTypeEnum {
    Content     = 1,
    Settings    = 2,
    Both        = 3,
}

export enum ScoreChoiceType {
    Highest = 1,
    Average = 2,
    Lowest = 3,
    MostRecent = 4,
}

export enum CopySectionTypeEnum {
    InsDoc        = 1,
    ActDoc        = 2,
    Both          = 3, // for InsDoc và QuestionDoc only for now
    LessonPlanDoc = 4,
    All           = InsDoc | ActDoc | LessonPlanDoc,
}

export interface PollOption {
    voteId: number;
    voteText: string;
    voteImage: string;
}

const CanGoToScoreReport = [ ActivityType.Assignment, ActivityType.Assessment, ActivityType.Discussion, ActivityType.GradeOnlyActivity ]

export interface PollSummary{
    option: PollOption
    userIds: number[];
}

export enum AwardTypeEnum  {
    credit, badge, cert
}

export class AwardRule {
    id: string = nanoid(16);
    type: AwardTypeEnum = AwardTypeEnum.credit;
    value?: number; set_value(x?: number) { this.value = x == null ? x : Math.max(x, 0)}
    credit?: number; set_credit(x?: number) { this.credit = x == null ? x : Math.max(x, 0)}
    badgeId?: number; set_badgeId(x?: number) { this.badgeId = x}

    constructor (data?: {}){
        Object.assign(makeAutoObservable(this), data ?? {});
    }
}

export const validateAwardRule = (rule: AwardRule) => {
    if (rule.type === AwardTypeEnum.credit) return undefined;
    if (rule.badgeId != null) return undefined;
    return createError(new Error("app.activities.awards.badgeRequired"), 400);
}

export class ActivityGroup {
    groupId        : string          = "";
    groupName      : string          = "";
    studentIds     : DbIdentity   [] = [];
    activityScores?: ActivityScore[] = [];
    isActiveGroup ?: boolean = undefined;

    constructor(data?:any) {
        if (data) {
            const { activityScores, ...pData } = data;
            Object.assign(this, pData);
            if (Array.isArray(activityScores)) this.activityScores = activityScores.map(a => new ActivityScore(a));
        }
    }

    static async fetchGroups({facultyId, activityId, signal}:{facultyId: DbIdentity, activityId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/activity/${activityId}/group`, undefined, { signal });
        return [err, ((err || !Array.isArray(data)) ? [] : data.map(a => new ActivityGroup(a)))] as const;
    }

    static async fetchDiscussionGroups({facultyId, activityId, signal}:{facultyId: DbIdentity, activityId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/activity/${activityId}/discussionGroup`, undefined, { signal });
        return [err, ((err || !Array.isArray(data)) ? [] : data.map(a => new ActivityGroup(a)))] as const;
    }

    static async saveGroups({groups, facultyId, activityId}:{groups: ActivityGroup[], facultyId: DbIdentity, activityId: DbIdentity}) {
        const [err, data] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/activity/${activityId}/group`, toJS(groups));
        return [err, ((err || !Array.isArray(data)) ? [] : data.map(a => new ActivityGroup(a)))] as const;
    }

    static async deleteGroups({ facultyId, activityId }: { facultyId: DbIdentity, activityId: DbIdentity }) {
        const [err, data] = await aFetch<string[]>("PUT", `/faculty/${facultyId}/activity/${activityId}/deleteGroups`);
        return [err, ((err || !Array.isArray(data)) ? [] : data)] as const;
    }
    static sorter = {
        groupId: <T extends ActivityGroup>(a: T, b: T) => ((a?.groupId ?? "").localeCompare(b?.groupId ?? "")),
        groupName: <T extends ActivityGroup>(a?: T, b?: T) => ((a?.groupName ?? "").localeCompare(b?.groupName ?? "")),
    };
}

export class IDuplicateActivity {
    classId        : DbIdentity   = DefaultId        ;
    moduleParentId : string       = ""               ;
    titlePrefix    : string       = ""               ;
    copyType       : CopyTypeEnum = CopyTypeEnum.Both;
    isAddModuleItemToTop ?: boolean = false;
    isCopyFromHomePage   ?: boolean = false;
}

export class ICopyActivitySection {
    classId      : DbIdentity          = DefaultId                 ;
    actItemId    : string              = ""                        ;
    moduleId     : string              = ""                        ;
    copyTypeFrom : CopySectionTypeEnum = CopySectionTypeEnum.InsDoc;
    copyTypeTo   : CopySectionTypeEnum = CopySectionTypeEnum.InsDoc;
}
export class IImportAIContentToExistingActivity  {
    actDoc?         : IActDoc           = undefined;
    instructionDoc? : InstructionActDoc = undefined;
    importMethod    : ImportMethod      = ImportMethod.None;
}

export interface ICategoryConsumer{
    categoryId         ?: DbIdentity   ;
    color               : string       ;
    isGraded            : boolean      ;
    maxScore            : number       ;
    weight              : number        ;
    set_categoryId         (v?: DbIdentity  )      : void;
    set_color              (v : string      )      : void;
    set_isGraded           (v : boolean     )      : void;
    set_maxScore           (v : number      )      : void;
    set_weight             (v : number      )      : void;

}

export class FacultyModuleProgress {
    activityId           = DefaultId;
    isGroup              = false;
    isGraded             = false;
    type                 = ActivityType.Activity;
    isSpecificAssign     = false;
    publishStartDate    ?: NumberDate;
    publishEndDate      ?: NumberDate;
    submittedCount       = 0;
    studentCount         = 0;
    groupCount           = 0;
    commentCount         = 0;
    groupSubmittedCount  = 0;
    excludeCount         = 0;
    ignoreCompleteMark   = true;

    get publishStatus() {
        const now =  Date.now();
        if (this.publishStartDate == null) return PublishStatusEnum.Unpublished;
        if (now < this.publishStartDate) return PublishStatusEnum.Pending;
        if (this.publishEndDate != null && this.publishEndDate < now) return PublishStatusEnum.Unpublished;
        return PublishStatusEnum.Published;
    }

    get isGroupWithoutDiscussion() { return this.isGroup && this.type != ActivityType.Discussion; }

    get isCompleted() { // Done if all students have submitted
        switch(this.type) {
            case ActivityType.Activity  :
            case ActivityType.Attachment:
                return this.publishStatus == PublishStatusEnum.Published;
            case ActivityType.Assignment:
            case ActivityType.Assessment:
                if (this.isSpecificAssign)
                {
                    const studentCount = this.studentCount - this.excludeCount;
                    return !!studentCount && (studentCount == this.submittedCount);
                }

                if (this.isGroup)
                {
                    const studentCount = this.groupCount;
                    return !!studentCount && (studentCount == this.groupSubmittedCount);
                }
                return !!this.studentCount && (this.studentCount == this.submittedCount);
            case ActivityType.Discussion:
                if (this.isGraded) {
                    if(this.isGroup) return !!this.groupCount && (this.groupSubmittedCount == this.groupCount);
                    return !!this.studentCount && (this.submittedCount == this.studentCount);
                }

                // if (this.isGroup) return !!this.groupCount && (this.commentCount == this.groupCount);
                return !!this.studentCount && (this.commentCount == this.studentCount);
            default: return false;
        }
    }

    constructor(data?:{}) {
        makeObservable(this, {
            isGroupWithoutDiscussion : computed,
            publishStatus            : computed,
            isCompleted              : computed,
        });
        Object.assign(this, data);
    }

    static async fetchClassProgress({facultyId, classId, signal}:{facultyId: DbIdentity, classId: DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/class/${classId}/moduleProgress`, undefined , { signal });
        return [err, ((err || !Array.isArray(xs)) ? [] : xs.map(x => new this(x)))] as const;
    }
}

interface IActivity {}
export class Activity implements ICategoryConsumer, IGradingSetting, IAeriesRecord, IActivity {
    activityId               : DbIdentity = DefaultId;
    classId                  : DbIdentity = DefaultId;
    classGradebookId        ?: DbIdentity = undefined;
    createdBy                : DbIdentity = DefaultId;
    type                     = ActivityType.Activity;
    title                    = "";
    description              = "";
    banner                   : UrlString = "";
    isDeleted                = false;
    deletedStatus            = DeletedStatus.NotDeleted;
    isExcludeFromLinkedSection = false; set_isExcludeFromLinkedSection(v: boolean) { this.isExcludeFromLinkedSection = v }
    dateCreated              = Date.now();
    dateUpdated              = Date.now();
    conferenceLogId         ?: DbIdentity;

    // Folder & Tree
    moduleId                 : string = "";
    module                  ?: IModule = { moduleId: "", moduleParentId: "",  moduleIndex: 0};

    categoryId              ?: DbIdentity = undefined;
    color                    = "#ffffff";

    //
    attachmentUrl           ?: UrlString = undefined;
    attachmentContentType   ?: string    = undefined;

    dateDue                ?: NumberDate = undefined;
    dateAssigned           ?: NumberDate = undefined;
    dateCutoff             ?: NumberDate = undefined;
    markCompletedDate      ?: NumberDate = undefined;
    firstSubmitAllowedDate ?: NumberDate = undefined;
    isDirtyDateCutoff       : boolean    = false    ;
    isDirtyFirstSubmitAllowedDate : boolean = false ;

    //Grading settings
    isGraded            : boolean              = true                       ; set_isGraded           (v : boolean             ) { this.isGraded           = v;                                                        }
    maxScore            : number               = 10                         ; set_maxScore           (v : number              ) { this.maxScore           = v;                                                        }
    correctPossible    ?: number                                            ; set_correctPossible    (v?: number              ) { this.correctPossible    = v;                                                        }
    weight              : number               = 1                          ; set_weight             (v : number              ) { this.weight             = v;                                                        }
    isCredit            : boolean              = false                      ; set_isCredit           (v : boolean             ) { this.isCredit           = v; if (v) this.isExcused = false;                         }
    isExcused           : boolean              = false                      ; set_isExcused          (v : boolean             ) { this.isExcused          = v; if (v) this.isCredit  = false;                         }
    rubricScoreGuideId ?: DbIdentity           = undefined                  ; set_rubricScoreGuideId (v?: DbIdentity          ) { this.rubricScoreGuideId = v; if (v != null) this.set_pointScoreGuideId (undefined); }
    pointScoreGuideId  ?: DbIdentity           = undefined                  ; set_pointScoreGuideId  (v?: DbIdentity          ) { this.pointScoreGuideId  = v; if (v != null) this.set_rubricScoreGuideId(undefined); }
    isFormative         : boolean              = false                      ; set_isFormative        (v : boolean             ) { this.isFormative        = v;                                                        }
    daysAssigned       ?: number               = undefined                  ; set_daysAssigned       (v?: number              ) { this.daysAssigned       = v;                                                        }

    isMarkCompletedInProgress? :boolean        = false                      ; set_isMarkCompletedInProgress (v?: boolean      ) { this.isMarkCompletedInProgress      = v;                                            }
    actScoresToKeep :ActivityScore[]           = []                         ; set_actScoresToKeep (v:ActivityScore[]          ) { this.actScoresToKeep    = v;                                                       }
    // in percentage
    minimumScoreForPass ?: number; set_minimumScoreForPass(v ?: number) { this.minimumScoreForPass = v; }
    set_isExclude(v : boolean) { if (v) { this.weight= 0; } else { if (this.weight <= 0) this.weight = 1.; }};

    isGroup                  = false;
    timeLimit               ?: number = undefined;
    timeLimitInSeconds      ?: number = undefined;
    isPublishGrade           = false;
    isSpecificAssign         = false;
    onQuizFocusMode          = false;
    canRevisitPreviousQuiz   = false;
    shouldRandomizeQuiz      = false;
    ignoreCompleteMark       = false;
    alwaysAllowResubmit      = false;

    maxTimesToRetakeExam    ?: number = undefined;
    scoreChoiceType          = ScoreChoiceType.Highest;

    oneRosterId              ?: string = undefined;
    aeriesSyncPolicy          = ActivitySyncPolicy.Sync;
    aeriesGradeSyncPolicy     = GradeSyncPolicy.Sync; set_aeriesGradeSyncPolicy(v:boolean) { this.aeriesGradeSyncPolicy = v ? GradeSyncPolicy.Sync : GradeSyncPolicy.NotSync }

    passwordHash             ?: string = undefined;
    submissionType            = SubmissionTypeEnum.Online;
    submissionAppId          ?: number = undefined;
    minimumNumberOfWords     ?: number = undefined;
    minimumWordsForReply     ?: number = undefined;
    wordCountType             : number = WordCountType.Word    ; set_wordCountType(v: number) { this.wordCountType =  v  }
    mustReplyToXOtherPeople  ?: number = undefined;

    enableComment             : boolean = false;    set_enableComment(v: boolean) { this.enableComment = v }

    addToClassEPortfolio      : boolean = false;    set_addToClassEPortfolio(v: boolean) { this.addToClassEPortfolio = v }
    commiteeAssessed          : boolean = false;    set_commiteeAssessed    (v: boolean) { this.commiteeAssessed = v }
    communityHub              : boolean = false;    set_communityHub        (v: boolean) { this.communityHub = v }
    threadId                  : string = '';

    // UI
    timeLimitHour           ?: number = undefined;
    timeLimitMinute         ?: number = undefined;
    timeLimitSeconds        ?: number = undefined;

    // Computed
    hasSubmitted             = false;

    // Relation
    groups                    : ActivityGroup[] = [];
    class                     = new Class();
    selectedTags              : QualityMatrix[] = [];
    assigneeIds               : DbIdentity   [] = [];
    qualityIds                : DbIdentity   [] = [];
    standardIds               : DbIdentity   [] = [];
    requirePassword           = false;
    isShowAsImage            ?: boolean = false; set_isShowAsImage(v?:boolean){ this.isShowAsImage = v;}
    linkedSectionActivities   : Activity[] = [];
    linkedSectionGroupId     ?: string = undefined;
    studentGroup              : StudentActivityGroup[] = [];

    isDirtyDateAssigned       : boolean = false;
    isDirtyDateDue            : boolean = false;
    canSelfReflect            : boolean = false;
    hideAdditionalResponses   : boolean = false;
    allowStudentToReview      : StudentReviewOptionEnum = StudentReviewOptionEnum.AfterFirstSubmission;
    allowShowCorrectInCorrectMarks      : StudentReviewOptionEnum = StudentReviewOptionEnum.AfterMarkGradingCompleted;
    allowRevealAnswers       : StudentReviewOptionEnum = StudentReviewOptionEnum.AfterMarkGradingCompleted;

    googleCalendarEventId    ?: string;

    interclassActivityCode   ?: string; set_interclassActivityCode(v?:string) { this.interclassActivityCode = v; }
    interclassActivityId     ?: DbIdentity; set_interclassActivityId(v?:DbIdentity) { this.interclassActivityId = v; }

    isInterclassActivityGroup?:boolean = false;

    isLocked                  : boolean = false;
    isCreatingHomepage        : boolean = false;

    isUpdateDoc               : boolean = true;
    thumbnail                ?: string; set_thumbnail(v ?:string) { this.thumbnail = v; }
    activityTemplateId       ?: DbIdentity; set_activityTemplateId(v?: DbIdentity) { this.activityTemplateId = v; }

    activityVideoExtension   ?: ActivityVideoExtension;

    constructor(data?:any) {
        if (data != null) {
            if (data.timeLimitInSeconds) {
                var hours = Math.trunc(data.timeLimitInSeconds / 3600);
                var minutes = Math.trunc((data.timeLimitInSeconds % 3600)/60);
                var seconds = data.timeLimitInSeconds - (hours * 3600) - (minutes * 60);
                this.set_timeLimitHour(hours);
                this.set_timeLimitMinute(minutes);
                this.set_timeLimitSeconds(seconds);
            }
            Object.assign(this, data);
            if (Array.isArray(data.linkedSectionActivities)) this.linkedSectionActivities = data.linkedSectionActivities.map(a => new Activity(a));
            if (data.isShowAsImage && !!data.attachmentUrl) {
                //append t query param to make image refresh
                this.attachmentUrl = qs(data.attachmentUrl!, { random: moment().valueOf() }).toString();
            }
            if (data.activityVideoExtension) {
                this.activityVideoExtension = new ActivityVideoExtension(data.activityVideoExtension);
            }
        }
        if (!this.color) this.color = "#ffffff";
        if (!stripHtml(this.title)) this.title = '';

        makeObservable(this, {
            type                      : observable,
            title                     : observable,
            description               : observable,
            banner                    : observable,
            isDeleted                 : observable,
            isExcludeFromLinkedSection: observable,
            dateCreated               : observable,
            dateUpdated               : observable,
            conferenceLogId           : observable,
            moduleId                  : observable,
            module                    : observable,
            categoryId                : observable,
            color                     : observable,
            isGraded                  : observable,
            attachmentUrl             : observable,
            attachmentContentType     : observable,
            dateDue                   : observable,
            dateAssigned              : observable,
            dateCutoff                : observable,
            firstSubmitAllowedDate    : observable,
            maxScore                  : observable,
            correctPossible           : observable,
            minimumScoreForPass       : observable,
            weight                    : observable,
            isCredit                  : observable,
            isExcused                 : observable,
            isFormative               : observable,
            daysAssigned              : observable,
            isGroup                   : observable,
            rubricScoreGuideId        : observable,
            pointScoreGuideId         : observable,
            timeLimit                 : observable,
            markCompletedDate         : observable,
            isPublishGrade            : observable,
            isSpecificAssign          : observable,
            onQuizFocusMode           : observable,
            canRevisitPreviousQuiz    : observable,
            shouldRandomizeQuiz       : observable,
            ignoreCompleteMark        : observable,
            maxTimesToRetakeExam      : observable,
            scoreChoiceType           : observable,
            alwaysAllowResubmit       : observable,
            timeLimitHour             : observable,
            timeLimitMinute           : observable,
            timeLimitSeconds          : observable,
            hasSubmitted              : observable,
            groups                    : observable.shallow,
            class                     : observable.ref,
            selectedTags              : observable.shallow,
            assigneeIds               : observable.shallow,
            qualityIds                : observable.shallow,
            standardIds               : observable.shallow,
            requirePassword           : observable        ,
            passwordHash              : observable        ,
            submissionType            : observable        ,
            submissionAppId           : observable        ,
            minimumNumberOfWords      : observable        ,
            minimumWordsForReply      : observable        ,
            wordCountType             : observable        , set_wordCountType: action.bound,
            mustReplyToXOtherPeople   : observable        ,
            oneRosterId               : observable        ,
            aeriesSyncPolicy          : observable        ,
            aeriesGradeSyncPolicy     : observable        , set_aeriesGradeSyncPolicy: action.bound,
            enableComment             : observable        , set_enableComment: action.bound,
            addToClassEPortfolio      : observable        , set_addToClassEPortfolio: action.bound,
            commiteeAssessed          : observable        , set_commiteeAssessed: action.bound,
            communityHub              : observable        , set_communityHub: action.bound,
            activityTemplateId        : observable        , set_activityTemplateId : action.bound,
            googleCalendarEventId     : observable        ,
            isLocked                  : observable        ,
            isMarkCompletedInProgress : observable        ,
            actScoresToKeep           : observable        ,
            set_password              : action.bound,
            set_requirePassword       : action.bound,
            set_classId               : action.bound,
            set_activityId            : action.bound,
            set_type                  : action.bound,
            set_title                 : action.bound,
            set_description           : action.bound,
            set_banner                : action.bound,
            set_dateDue               : action.bound,
            set_dateAssigned          : action.bound,
            set_dateCutoff            : action.bound,
            set_firstSubmitAllowedDate: action.bound,
            set_categoryId            : action.bound,
            set_color                 : action.bound,
            set_isGraded              : action.bound,
            set_maxScore              : action.bound,
            set_correctPossible       : action.bound,
            set_minimumScoreForPass   : action.bound,
            set_weight                : action.bound,
            set_isCredit              : action.bound,
            set_isExcused             : action.bound,
            set_isFormative           : action.bound,
            set_daysAssigned          : action.bound,
            set_hasSubmitted          : action.bound,
            set_isGroup               : action.bound,
            set_isDeleted             : action.bound,
            set_rubricScoreGuideId    : action.bound,
            set_pointScoreGuideId     : action.bound,
            set_timeLimit             : action.bound,
            set_timeLimitHour         : action.bound,
            set_timeLimitMinute       : action.bound,
            set_timeLimitSeconds      : action.bound,
            set_selectedTags          : action.bound,
            set_groups                : action,
            set_isExclude             : action.bound,
            set_isSpecificAssign      : action.bound,
            set_onQuizFocusMode       : action.bound,
            set_shouldRandomizeQuiz   : action.bound,
            set_ignoreCompleteMark    : action.bound,
            set_canRevisitPreviousQuiz: action.bound,
            set_maxTimesToRetakeExam  : action.bound,
            set_scoreChoiceType       : action.bound,
            set_alwaysAllowResubmit   : action.bound,
            set_assigneeIds           : action,
            set_submissionType        : action.bound,
            set_submissionAppId      : action.bound,
            getTime                   : action,
            isGroupWithoutDiscussion  : computed,
            banner_thumbnail          : computed,
            isRetakeExam              : computed,
            isExam                    : computed,
            timeLimitHourFormatted    : computed,
            timeLimitMinuteFormatted  : computed,
            timeLimitFormatted        : computed,
            inProgress                : computed,
            isRubricScore             : computed,
            canAddSisNumber           : computed,
            params                    : computed,
            hasScore                  : computed,
            isGradable                : computed,
            isNonGradable             : computed,
            isNotAllowComment         : computed,
            isGradingCompleted        : computed,
            isPublished               : computed,
            isOverdue                 : computed,
            gradeOnly                 : computed,
            showInInternalLink        : computed,
            isSubmittable             : computed,
            isOffline                 : computed,
            isShowAsImage             : observable,
            linkedSectionActivities   : observable.shallow,
            linkedSectionGroupId      : observable,
            disableCollaborativeMode  : computed,
            isCutoff                  : computed,
            firstSubmitAllowed        : computed,
            studentGroup              : observable.shallow,
            set_studentGroup          : action.bound,
            saveGroups                : action,
            generateGroupName         : action,
            isDirtyDateAssigned       : observable,
            isDirtyDateDue            : observable,
            attachmentType            : computed,
            allowRevealAnswers        : observable, set_allowRevealAnswers: action.bound,
            allowShowCorrectInCorrectMarks: observable, set_allowShowCorrectInCorrectMarks: action.bound,
            allowStudentToReview      : observable, set_allowStudentToReview: action.bound,
            checkAllowStudentToSeeAnswers: action.bound,
            checkAllowStudentToSeeCorrectInCorrectAnswers: action.bound,
            set_isExcludeFromLinkedSection: action.bound,
            isDirtyDateCutoff: observable,
            isDirtyFirstSubmitAllowedDate: observable,
            set_isDirtyDateCutoff: action.bound,
            set_isDirtyFirstSubmitAllowedDate: action.bound,
            canSelfReflect    : observable  ,
            set_canSelfReflect: action.bound,
            hideAdditionalResponses    : observable,
            set_hideAdditionalResponses: action.bound,

            thumbnail                  : observable, set_thumbnail: action.bound,
            activityVideoExtension     : observable,

            canFacultyPrintSummary      : computed,
            canStudentPrintSummary      : computed,
            includeInstruction         : computed,
            includeLessonPlan          : computed,
            IsExternalApp: computed,

            hasStudentEffortLog        : computed,

            interclassActivityCode     : observable,
            set_interclassActivityCode : action.bound,
            interclassActivityId       : observable,
            set_interclassActivityId   : action.bound,

            isInterclassSection        : computed,
            canShowScoreReport         : computed,
            hasDoc                     : computed,
            canMarkComplete            : computed,
            isAutoComplete             : computed,
            classGradebookId           : observable,
            set_classGradebookId       : action.bound,
            isInterclassActivityGroup  : observable,

            set_isMarkCompletedInProgress  : action.bound,
            set_actScoresToKeep            : action.bound,
            canCorrectPossible         : computed,
            actualMinimumScoreForPass  : computed,
        });
    }

    set_password          (v : string              ) { this.passwordHash       = v; }
    set_requirePassword   (v : boolean             ) { this.requirePassword    = v  }
    set_classId           (v : number              ) { this.classId            = v; }
    set_classGradebookId  (v?: DbIdentity          ) { this.classGradebookId      = v; }
    set_activityId        (v : number              ) { this.activityId         = v; }
    set_type              (v : ActivityType        ) { this.type               = v; }
    set_title             (v : string              ) { this.title              = v; }
    set_description       (v : string              ) { this.description        = v; }
    set_banner            (v : UrlString           ) { this.banner             = v; }
    set_dateDue           (v?: number              ) { this.dateDue            = v; this.isDirtyDateDue = true;}
    set_dateAssigned      (v?: number              ) { this.dateAssigned       = v; this.isDirtyDateAssigned = true;}
    set_dateCutoff        (v?: number              ) { this.dateCutoff         = v; this.isDirtyDateCutoff = true;}
    set_firstSubmitAllowedDate (v?: number         ) { this.firstSubmitAllowedDate = v; this.isDirtyFirstSubmitAllowedDate = true; }
    set_categoryId        (v?: DbIdentity          ) { this.categoryId         = v; }
    set_color             (v : string              ) { this.color              = v; }

    set_hasSubmitted      (v : boolean             ) { this.hasSubmitted       = v; }
    set_isGroup           (v : boolean             ) { this.isGroup            = v; }
    set_isDeleted         (v : boolean             ) { this.isDeleted          = v; }
    set_timeLimit         (v?: number              ) { this.timeLimit          = v; }
    set_timeLimitHour     (v?: number              ) { this.timeLimitHour      = v; this.getTime();}
    set_timeLimitMinute   (v?: number              ) { this.timeLimitMinute    = v; this.getTime();}
    set_timeLimitSeconds  (v?: number              ) { this.timeLimitSeconds   = v; this.getTime();}
    set_selectedTags      (v : QualityMatrix[]     ) { this.selectedTags       = v }

    set_groups(v : ActivityGroup[]     ) { this.groups     = v; };

    set_isSpecificAssign      (v : boolean         ) { this.isSpecificAssign    = v; };
    set_onQuizFocusMode       (v : boolean         ) { if (v == false) { this.set_canRevisitPreviousQuiz(false) } this.onQuizFocusMode     = v; };
    set_shouldRandomizeQuiz   (v : boolean         ) { this.shouldRandomizeQuiz = v; };
    set_ignoreCompleteMark    (v : boolean         ) { this.ignoreCompleteMark = v; };
    set_canRevisitPreviousQuiz(v : boolean         ) { this.canRevisitPreviousQuiz = v; };
    set_maxTimesToRetakeExam  (v?: number          ) { this.maxTimesToRetakeExam = v; };
    set_scoreChoiceType       (v : ScoreChoiceType ) { this.scoreChoiceType = v; };
    set_alwaysAllowResubmit   (v : boolean         ) { this.alwaysAllowResubmit = v; };
    set_isDirtyDateAssigned   (v : boolean         ) { this.isDirtyDateAssigned = v; };
    set_isDirtyDateDue        (v : boolean         ) { this.isDirtyDateDue = v; };
    set_canSelfReflect        (v : boolean         ) { this.canSelfReflect = v; };
    set_hideAdditionalResponses(v : boolean ) { this.hideAdditionalResponses = v; };
    set_allowRevealAnswers            (v: StudentReviewOptionEnum) { this.allowRevealAnswers             = v; };
    set_allowShowCorrectInCorrectMarks(v: StudentReviewOptionEnum) { this.allowShowCorrectInCorrectMarks = v; };
    set_allowStudentToReview          (v: StudentReviewOptionEnum) { this.allowStudentToReview           = v; };

    set_assigneeIds(v : DbIdentity[]) { this.assigneeIds = v; };
    // TES-5770 #3459 - Teacher - Clear Submission Cutoff Time when saving as Offline submission type
    set_submissionType(v : SubmissionTypeEnum) { this.submissionType= v; if (v == SubmissionTypeEnum.InPerson || v == SubmissionTypeEnum.NoSubmission) this.set_dateCutoff(undefined); };
    set_submissionAppId(v?: number) { this.submissionAppId = v; }
    set_studentGroup(v: StudentActivityGroup[], sEditActivity: EditActivityStore) {
        if (this.studentGroup != v) {
            sEditActivity.studentGroupIsChanged.set(this.classId,true);
            this.studentGroup = v;
        }
    }
    set_isDirtyDateCutoff            (v: boolean) { this.isDirtyDateCutoff             = v; }
    set_isDirtyFirstSubmitAllowedDate(v: boolean) { this.isDirtyFirstSubmitAllowedDate = v; }

    set_isUpdateDoc               (v: boolean) { this.isUpdateDoc = v; }

    getTime() {
        const timeLimit = ((this.timeLimitHour ?? 0) * 3600) + ((this.timeLimitMinute ?? 0) * 60) + (this.timeLimitSeconds ?? 0);
        return this.timeLimitInSeconds = (timeLimit <= 0 ? undefined : timeLimit);
    }

    get IsExternalApp() : boolean {
        return this.submissionType == SubmissionTypeEnum.ExternalApp && this.submissionAppId != null && this.submissionAppId > 0;
    }

    /**
     * Activity actual minimum score for pass: calculate from minimumScoreForPass
     * @see minimumScoreForPass
     */
    get actualMinimumScoreForPass() { return !this.minimumScoreForPass ? null : Number(((this.maxScore*this.minimumScoreForPass)/100).toFixed(2)) }
    get displayGroup() {
        return this.studentGroup
            .map(x => ({groupId: x.groupId, groupName: x.groupName, students: x.students.filter(Boolean).map(y => y.studentId)}))
    }

    get attachmentType(){
        if (isGoogleDriveUrl(this.attachmentUrl ?? "")) return "googleUrl";
        if (this.attachmentUrl && isBlobFile(this.attachmentUrl)) return "file";
        return "url";
    }

    get hasDoc() { return activityTypesHaveDoc.has(this.type); }
    get isInterclassSection() { return !!this.interclassActivityId;}
    get canShowScoreReport() { return (this.isGraded || this.submissionType === SubmissionTypeEnum.Online) && CanGoToScoreReport.includes(this.type) }
    get canMarkComplete() {
        return activityTypesCanMarkComplete.has(this.type)
            && !this.ignoreCompleteMark
            && (activityTypesHasLessonAutoCompleteSection.has(this.type) ? this.activityVideoExtension?.showMarkCompleteButton : true)
        ;
    }
    get isAutoComplete() {
        return !this.ignoreCompleteMark
            && activityTypesHasLessonAutoCompleteSection.has(this.type) && this.activityVideoExtension?.autoCompleteLesson
        ;
    }

    /**
     * exclude Group Discussion in group check.
     * * TES-4357 Teacher - Group Discussion - don't mark entire group as submitted.
     */
    get isGroupWithoutDiscussion() { return this.isGroup && this.type != ActivityType.Discussion; }

    get banner_thumbnail() { return this.banner ? imageUrlToThumbnail(this.banner) : undefined; }
    get isRetakeExam() { return this.maxTimesToRetakeExam != undefined && this.maxTimesToRetakeExam > 1; }
    get isExam() { const t = this.getTime() ?? 0; return t > 0; }
    get timeLimitHourFormatted() { return `${("00" + this.timeLimitHour).slice(-2)}` }
    get timeLimitMinuteFormatted() { return `${("00" + this.timeLimitMinute).slice(-2)}` }
    get timeLimitSecondsFormatted() { return `${("00" + this.timeLimitSeconds).slice(-2)}` }
    get timeLimitFormatted() { return `${this.timeLimitHourFormatted}:${this.timeLimitMinuteFormatted}:${this.timeLimitSecondsFormatted}` }
    get inProgress() { return (!this.ignoreCompleteMark && (this.type != ActivityType.ExternalLink || this.attachmentContentType != 'lti')) }
    get isRubricScore() { return isValidIdentity(this.rubricScoreGuideId); }
    get canAddSisNumber() {
         return [ActivityType.Conference,
            ActivityType.Discussion,
            ActivityType.Assignment,
            ActivityType.Assessment,
            ActivityType.GradeOnlyActivity,
            ActivityType.SCORM
        ].includes(this.type)
         && this.isGraded
         && !this.isExcused;
    }

    get hasStudentEffortLog() {
        if (!isValidIdentity(this.activityId)) return false;
        return [ActivityType.Conference,
            ActivityType.Activity,
            ActivityType.Video,
            ActivityType.Discussion,
            ActivityType.Assignment,
            ActivityType.Assessment,
        ].includes(this.type);
    }

    get isPublished() {
        const [publishStartDate, publishEndDate] = (
            [
                ActivityType.Activity,
                ActivityType.Video,
                ActivityType.ExternalLink,
                ActivityType.Attachment,
            ].includes(this.type) ? [this.dateAssigned, this.dateDue] : (
            [
                ActivityType.Assignment ,
                ActivityType.Assessment ,
                ActivityType.Discussion ,
                ActivityType.Conference ,
                ActivityType.GradeOnlyActivity ,
                ActivityType.SCORM,
            ].includes(this.type) ? [this.dateAssigned, undefined]
            : [undefined, undefined]
        ));

        return isPublishedNow({publishStartDate, publishEndDate});
    }

    get params() { return ({activityId:String(this.activityId), classId:String(this.classId)}) }

    get showInModule() {
        return !activityTypesHiddenInModule.has(this.type) && !this.isDeleted;
    }

    get disableCollaborativeMode() {
        return this.dateAssigned && this.dateAssigned <= now() ? true : false;
    }

    get showInInternalLink() {
        return this.type != ActivityType.Conference &&
            this.type != ActivityType.GradeOnlyActivity &&
            this.type != ActivityType.Attachment;
    }

    get includeLessonPlan() { return activityTypesIncludeLessonPlan.has(this.type); }
    get includeInstruction() { return activityTypesIncludeInstruction.has(this.type); }
    get showInQuickGrader(){
        return this.type != ActivityType.Conference;
    }

    /** The activity has Activity Score records for student */
    get hasScore() { return activityTypesHasScore.has(this.type) }
    get gradeOnly() { return activityTypesGradeOnly.has(this.type); }
    get isGradable() { return activityTypesGradable.has(this.type); }
    get isNonGradable() {
        return  !this.isGraded && (this.type == ActivityType.Assignment
            ||  this.type == ActivityType.Assessment
            ||  this.type == ActivityType.Conference
            ||  this.type == ActivityType.SCORM
            ||  this.type == ActivityType.Discussion
            ||  this.type == ActivityType.GradeOnlyActivity);
    }
    get isNotAllowComment() { return activityTypesNotAllowComment.has(this.type)}
    get isGradingCompleted() { return this.isPublishGrade && this.markCompletedDate != null }
    get isOverdue() { return this.submissionType == SubmissionTypeEnum.Online && this.type != ActivityType.Conference && this.dateDue != null && this.dateDue < Date.now(); }
    get isSubmittable() {
        return this.type == ActivityType.Assignment ||
                this.type == ActivityType.Assessment ||
                this.type == ActivityType.Discussion;
    }
    get isCutoff() { return this.dateCutoff != null && this.dateCutoff < Date.now() }
    get isOffline() { return this.submissionType === SubmissionTypeEnum.InPerson || this.submissionType === SubmissionTypeEnum.NoSubmission }
    get firstSubmitAllowed() { return !this.firstSubmitAllowedDate || (this.firstSubmitAllowedDate < Date.now()); }

    get canFacultyPrintSummary() {
        return this.isGraded &&
            (this.submissionType === SubmissionTypeEnum.Online || this.rubricScoreGuideId != null) &&
            (this.type == ActivityType.Assignment ||  this.type == ActivityType.Assessment ||
                ((this.type == ActivityType.Conference ||
                        this.type == ActivityType.SCORM ||
                        this.type == ActivityType.Discussion ||
                        this.type == ActivityType.GradeOnlyActivity) &&
                    this.rubricScoreGuideId != null)
            )
    }
    get canStudentPrintSummary() {return this.canFacultyPrintSummary && this.isGradingCompleted;}

    get canCorrectPossible() {
        return this.type == ActivityType.Assignment ||
                this.type == ActivityType.Assessment ||
                this.type == ActivityType.GradeOnlyActivity ||
                this.type == ActivityType.Discussion;
    }

    checkAllowStudentToSeeAnswers(isSubmitted?: boolean) {
        return  !!isSubmitted && (this.allowRevealAnswers == StudentReviewOptionEnum.AfterFirstSubmission || (this.allowRevealAnswers == StudentReviewOptionEnum.AfterMarkGradingCompleted && this.isGradingCompleted));
    }
    checkAllowStudentToSeeCorrectInCorrectAnswers(isSubmitted: boolean) {
        return !!isSubmitted && (this.allowShowCorrectInCorrectMarks == StudentReviewOptionEnum.AfterFirstSubmission || (this.allowShowCorrectInCorrectMarks == StudentReviewOptionEnum.AfterMarkGradingCompleted && this.isGradingCompleted));
    }
    toJS() {
        return ({
            classId                : this.classId,
            classGradebookId       : this.classGradebookId,
            activityId             : this.activityId,
            type                   : this.type,
            title                  : stripHtml(this.title),
            description            : this.description,
            banner                 : this.banner,
            dateAssigned           : this.dateAssigned,
            dateDue                : this.dateDue,
            dateCutoff             : this.dateCutoff,
            firstSubmitAllowedDate : this.firstSubmitAllowedDate,
            categoryId             : this.categoryId,
            color                  : this.color,
            isGraded               : this.isGraded,
            isCredit               : this.isCredit,
            isExcused              : this.isExcused,
            isFormative            : this.isFormative,
            daysAssigned           : this.daysAssigned,
            maxScore               : this.maxScore,
            correctPossible        : this.correctPossible ?? null,
            minimumScoreForPass    : this.minimumScoreForPass,
            weight                 : this.weight,
            isGroup                : this.isGroup,
            isDeleted              : this.isDeleted,
            deletedStatus          : this.deletedStatus,
            groups                 : this.groups,
            rubricScoreGuideId     : this.rubricScoreGuideId,
            pointScoreGuideId      : this.pointScoreGuideId,
            timeLimit              : this.timeLimit,
            timeLimitInSeconds     : this.timeLimitInSeconds,
            module                 : this.module,
            isPublishGrade         : this.isPublishGrade,
            qualityIds             : this.selectedTags.map(q => q.id),
            isSpecificAssign       : this.isSpecificAssign,
            onQuizFocusMode        : this.onQuizFocusMode,
            alwaysAllowResubmit    : this.alwaysAllowResubmit,
            shouldRandomizeQuiz    : this.shouldRandomizeQuiz,
            ignoreCompleteMark     : this.ignoreCompleteMark,
            assigneeIds            : this.assigneeIds,
            requirePassword        : this.requirePassword,
            passwordHash           : this.passwordHash,
            submissionType         : this.submissionType,
            submissionAppId        : this.submissionAppId,
            attachmentUrl          : (this.isShowAsImage ? qs(this.attachmentUrl!, { random: undefined }).toString() : this.attachmentUrl),
            attachmentContentType  : this.attachmentContentType,
            thumbnail              : this.thumbnail,
            canRevisitPreviousQuiz : this.canRevisitPreviousQuiz,
            maxTimesToRetakeExam   : this.maxTimesToRetakeExam,
            scoreChoiceType        : this.scoreChoiceType,
            minimumNumberOfWords   : this.minimumNumberOfWords,
            wordCountType          : this.wordCountType,
            aeriesGradeSyncPolicy  : this.aeriesGradeSyncPolicy,
            aeriesSyncPolicy       : this.aeriesSyncPolicy,
            isShowAsImage          : this.isShowAsImage,
            linkedSectionActivities: this.linkedSectionActivities.map(x => x.toJS()),
            enableComment          : this.enableComment,
            threadId               : this.threadId,
            allowRevealAnswers     : this.allowRevealAnswers,
            allowShowCorrectInCorrectMarks: this.allowShowCorrectInCorrectMarks,
            allowStudentToReview   : this.allowStudentToReview,
            isExcludeFromLinkedSection: this.isExcludeFromLinkedSection,
            addToClassEPortfolio   : this.addToClassEPortfolio,
            commiteeAssessed       : this.commiteeAssessed,
            communityHub           : this.communityHub,
            canSelfReflect         : this.canSelfReflect,
            hideAdditionalResponses: this.hideAdditionalResponses,
            interclassActivityCode : this.interclassActivityCode,
            interclassActivityId   : this.interclassActivityId,
            isCreatingHomepage     : this.isCreatingHomepage,
            isUpdateDoc            : this.isUpdateDoc,
            activityVideoExtension : this.activityVideoExtension,
            activityTemplateId     : this.activityTemplateId,
        });
    }
    clone() { return new Activity(this.toJS()) }

    generateGroupName(prefix:string) {
        for (const x of this.studentGroup) {
            if (!x.groupName) x.groupName = StudentGroup.makeGroupName(this.studentGroup, prefix);
        }
    }

    //#region Activity Group
    async saveGroups(activityId: DbIdentity, groupNamePrefix: string, currentUserId: number, students: Student[], isGroupIsChanged: boolean) {
        if (!this.isGroup || activityId < 1 || !isGroupIsChanged) return;
        const noGroupStudents = students.filter(s => !this.studentGroup.some(g => g.students.some(x => x.studentId == s.studentId)));
        if (noGroupStudents.length > 0) {
            this.studentGroup.push({ groupId:"", groupName: "", students:noGroupStudents })
        }
        const groups: ActivityGroup[] = this.studentGroup.map(g => {
            if(g.groupName == null || g.groupName == "") g.groupName = StudentGroup.makeGroupName(this.studentGroup, groupNamePrefix);
            return {
                groupId: isGroupIsChanged ? "" : g.groupId,
                groupName: g.groupName,
                studentIds: g.students.map(s => s.studentId)
            }
        });

        const [aErr, data] = await ActivityGroup.saveGroups({groups, facultyId: currentUserId, activityId: activityId});
        if (!aErr)
        runInAction(() => {
            this.set_groups(data);
        })
        return aErr;
    }
    //#endregion Activity Group

    async save(facultyId:DbIdentity, isUpdateDoc:boolean) {
        const { classId, activityId } = this;

        if (activityId < 1) {
            const [err, data] = await aFetch<{activity: Activity, parentName ?: string, linkedActivities: Activity[]} | undefined>("POST", `/faculty/${facultyId}/class/${classId}/activity`, this.toJS());
            if (err == null && !!data){
                const {activity, parentName, linkedActivities} = data;
                return [
                    err,
                    new Activity(activity),
                    activity.module? new Module(activity.module): undefined,
                    undefined,
                    parentName,
                    linkedActivities?.map(la => new Activity(la)),
                ] as const;
            }

            return [err, undefined!, undefined, undefined, undefined, undefined ] as const;
        }

        this.set_isUpdateDoc(isUpdateDoc);
        const [err,data] = await aFetch<{activity: Activity, dto: GeneralDto, parentName ?: string, linkedActivities: Activity[], isNewestActivityUpdated: boolean, scores: ActivityScore[]} | undefined>("PUT", `/faculty/${facultyId}/activity/${activityId}`, this.toJS());
        if (err == null && !!data) {
            const {activity, dto, parentName, linkedActivities, isNewestActivityUpdated, scores} = data;
            return [
                err,
                new Activity(activity),
                activity.module ? new Module(activity.module) : undefined,
                parseGeneralViewModel(dto),
                parentName,
                linkedActivities?.map(la => new Activity(la)),
                isNewestActivityUpdated,
                scores?.map(x => new ActivityScore(x))
                ] as const;
        }

        return [err, undefined!, undefined, undefined, undefined, undefined] as const;
    }

    static async reNameActvity(facultyId:DbIdentity, activityId:DbIdentity, title: string, type: DbIdentity) {
        const [err,] = await aFetch<{}>("PUT", `/faculty/${facultyId}/activity/${activityId}/renameActivity`, {title, type});
        return err;
    }

    static async updateSimple({ facultyId, activityId, simpleActivity } : { facultyId:DbIdentity, activityId:DbIdentity, simpleActivity: Pick<Activity, 'dateDue'> }) {
        const [err, data] = await aFetch<{}>("PUT", `/faculty/${facultyId}/activity/${activityId}/updateSimple`, simpleActivity);
        return [err, (err ? undefined : new Activity(data))] as const;
    }

    static async editCutoffDate(facultyId:DbIdentity, activityId:DbIdentity, classIds: number[], cutoffDate?: number) {
        const [err,] = await aFetch<{}>("PUT", `/faculty/${facultyId}/activity/${activityId}/setCutoffDate`, {cutoffDate, classIds});
        return err;
    }

    static async removeActvity(facultyId:DbIdentity, activityId:DbIdentity) {
        const [err,] = await aFetch<{}>("PUT", `/faculty/${facultyId}/activity/${activityId}/removeActivity`);
        return err;
    }

    async submitAsFaculty(facultyId:DbIdentity, studentId:DbIdentity, activityId:DbIdentity) {
        const [err, data] = await aFetch<{}>("PUT", `/faculty/${facultyId}/activity/${activityId}/student/${studentId}/submission`);
        return [err, (err ? undefined : new StudentActDoc(data))!] as const;
    }

    static async fetchLinkedSectionActivities(facultyId:DbIdentity, activityId:DbIdentity){
        const [err, data] = await aFetch<{}[]>("GET" , `/faculty/${facultyId}/activity/${activityId}/linkedSectionActivities`);
        return [err, (err ? undefined : data.map(x=> new Activity(x)))] as const;
    }

    static async delete({facultyId, activityId}: {facultyId:DbIdentity, activityId:DbIdentity}) {
        const [err, data] = await aFetch<{currentItem: {}, linkedItems: {}[]}>("DELETE", `/faculty/${facultyId}/activity/${activityId}`);
        return [err, (err ? undefined : new Activity(data.currentItem))!, err ? [] : data.linkedItems.map(a => new Activity(a))] as const;
    }

    static async unDeleted(facultyId:DbIdentity, classId:DbIdentity, activityIds:DbIdentity[]) {
        const [err, data] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/class/${classId}/activity/undeleted`, activityIds);
        return [err, (err ? [] : data.map(a => new Activity(a)))] as const;
    }

    static async fetchActivityAsFaculty({facultyId, activityId, signal }:{facultyId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET" , `/faculty/${facultyId}/activity/${activityId}`, undefined, { signal });
        const vm = err == null ? parseGeneralViewModel(dto) : undefined;
        return [err, vm!] as const;
    }

    static async fetchActivityInfoAsFaculty({facultyId, activityId, signal }:{facultyId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal}) {
        const [studentStatusError, studentStatus] = await aFetch<{hasActiveStudent: boolean, hasInActiveStudent: boolean}>("GET" , `/faculty/${facultyId}/activity-info/${activityId}`, undefined, { signal });
        if (studentStatusError) {
            return [studentStatusError, undefined] as const;
        }
        const [err, dto] = await aFetch<GeneralDto>("GET" , `/faculty/${facultyId}/activity/${activityId}`, undefined, { signal });
        const vm = err == null ? parseGeneralViewModel(dto) : undefined;
        return [err, studentStatus, vm] as const;
    }

    static async fetchDeletedActivityAsFaculty({facultyId, activityId, signal }:{facultyId:DbIdentity, activityId:DbIdentity, signal?:AbortSignal}) {
        const [err, data] = await aFetch<{}>("GET" , `/faculty/${facultyId}/activityDeleted/${activityId}`, undefined, { signal });
        return [err, (err ? undefined : new Activity(data))!] as const;
    }

    static async fetchActivitiesForPublicClass({publicLinkId, signal}:{publicLinkId: string, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<Activity[]>("GET", `/public/publicClass/${publicLinkId}/activity`,undefined, { signal });
        const vm = (err ? [] : dto.map(x=>new Activity(x)));
        return [err, vm] as const;
    }

    static async fetchActivitiesOfClassAsFaculty({facultyId, classId, classGradebookId, ...init }:{facultyId:DbIdentity, classId:DbIdentity, classGradebookId?: DbIdentity, signal?:AbortSignal, cache?:RequestCache}) {
        const [err, data] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/class/${classId}/activity`, { classGradebookId }, init);
        return [err, err ? [] : data.map(a => new Activity(a))] as const;
    }
    static async fetchClassFolderActivitiesAsFaculty({facultyId, classId, folderId, signal }:{facultyId:DbIdentity, classId:DbIdentity, folderId:DbIdentity, signal?: AbortSignal }) {
        folderId = folderId == null || folderId < 1 ? 0 : folderId;
        const [err, data] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/class/${classId}/folder/${folderId}/activity`, undefined, { signal });
        return [err, err ? [] : data.map(a => new Activity(a))] as const;
    }

    static async fetchNextDueActivityOfClass({facultyId, classId, signal }:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal }) {
        const [err, x] = await aFetch<{}>("GET" , `/faculty/${facultyId}/class/${classId}/activity/nextDue`, undefined, { signal });
        return [err, (err || !x ? undefined : new Activity(x))] as const;
    }

    static async getActivityOfFacultyInSchoolAsAdmin({schoolId, facultyId, signal }:{schoolId:DbIdentity, facultyId:DbIdentity, signal?: AbortSignal }) {
        const [err, dto] = await aFetch<GeneralDto>("GET" , `/admin/school/${schoolId}/faculty/${facultyId}/activity`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async setPublish({facultyId, activityId}:{facultyId:DbIdentity, activityId:DbIdentity}, start: number | undefined, end: number| undefined, sectionClassIds ?: DbIdentity[]) {
        const [err, dto] = await aFetch<GeneralDto>("PUT", `/faculty/${facultyId}/activity/${activityId}/publish`, {start, end, sectionClassIds});
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async setPublishLinkedSectionActivities({facultyId, activityId}:{facultyId:DbIdentity, activityId:DbIdentity}, activityList: IActivityPublish[]) {
        const [err, dto] = await aFetch<GeneralDto>("PUT", `/faculty/${facultyId}/activity/${activityId}/publishLinkedActivities`, {activityPublishRequest: activityList});
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchUnPublishedActivityAsFaculty({facultyId, activityId, signal }:{facultyId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal }) {
        const [err, vm] = await aFetch<Activity>("GET" , `/faculty/${facultyId}/unpublishedActivity/${activityId}`, undefined, { signal });
        return [err, vm] as const;
    }

    static async duplicate({facultyId, activityId}:{facultyId:DbIdentity, activityId: DbIdentity}, copyTo:IDuplicateActivity) {
        const [err, data] = await aFetch<{item1:Activity, item2: Discussion | undefined}>("POST", `/faculty/${facultyId}/activity/${activityId}/duplicate`, copyTo);
        if(err) return [err, undefined, undefined] as const;
        const {item1, item2} = data;
        return [err, new Activity(item1), (!!item2 ? new Discussion(item2) : undefined)] as const;
    }

    static async copySection({facultyId, classId, moduleId}:{facultyId:DbIdentity, classId: DbIdentity, moduleId: string}, copyTo:ICopyActivitySection) {
        const [err] = await aFetch<{}>("POST", `/faculty/${facultyId}/class/${classId}/module/${moduleId}/copySection`, copyTo);
        return err;
    }

    static async ImportAIContentToExistingActivity({facultyId, classId, toActivityId}:{facultyId:DbIdentity, classId: DbIdentity, toActivityId: string}, content: IImportAIContentToExistingActivity) {
        const [err] = await aFetch<{}>("POST", `/faculty/${facultyId}/class/${classId}/activity/${toActivityId}/importAIContent`, content);
        return err;
    }

    static async fetchSkillRequestOfActivityAsFaculty({facultyId, activityId, studentId, signal }:{facultyId: DbIdentity, activityId: DbIdentity, studentId: DbIdentity, signal?: AbortSignal }) {
        const [err, xs] = await aFetch<number[]>("GET", `/faculty/${facultyId}/activity/${activityId}/student/${studentId}/skillRequest`, undefined, { signal });
        return [err, err ? [] :xs] as const;
    }

    static async fetchSkillRequestByStudentIds({facultyId, activityId, studentIds, signal}:{facultyId: DbIdentity, activityId: DbIdentity, studentIds: DbIdentity[], signal?: AbortSignal} ) {
        const [err, x] = await aFetch<GroupSkillRequest[]>("POST", `/faculty/${facultyId}/activity/${activityId}/skillRequest`, studentIds, { signal });
        return [err, (err ? [] : x)] as const;
    }

    static async getUpcomingActivities({ facultyId, classIds, signal }: { facultyId:DbIdentity, classIds: DbIdentity[], signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}[]>("POST" , `/faculty/${facultyId}/upcomingActivity`, classIds, { signal });
        return [err, err ? [] : data.map(a => new UpcomingActivity(a))] as const;
    }

    static async fetchUpcomingActivitiesAsParent(parentId:DbIdentity, studentId:DbIdentity, gradingTermIds: DbIdentity[], signal?: AbortSignal) {
        const [err, data] = await aFetch<{}[]>("POST" , `/parent/${parentId}/student/${studentId}/upcomingActivity`, gradingTermIds, { signal });
        return [err, err ? [] : data.map(a => new UpcomingActivity(a))] as const;
    }

    static async fetchActDocAsParent({parentId, studentId, activityId, signal }:{parentId:DbIdentity, studentId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal }) {
        const [err, x] = await aFetch<{}>("GET" , `/parent/${parentId}/student/${studentId}/activity/${activityId}/faculty-doc`, undefined, { signal });
        const d = (err ? undefined : new ActDoc(x))!
        return [err, d] as const;
    }

    static async fetchStudentActivitiesAsParent({ parentId, studentId, classId, signal }:{parentId:DbIdentity, studentId:DbIdentity, classId?:DbIdentity, signal?: AbortSignal }) {
        const [err, dto] = await aFetch<GeneralDto>("GET",
            classId == null
            ? `/parent/${parentId}/student/${studentId}/activity`
            : `/parent/${parentId}/student/${studentId}/class/${classId}/activity`
            , undefined, { signal });
        const vm = (err ? undefined : parseGeneralViewModel(dto))!;
        return [err, vm] as const;
    }

    static async fetchActivityAsParent({ parentId, studentId, activityId, signal }: { parentId:DbIdentity, studentId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal }) {
        const [err, dto] = await aFetch<GeneralDto>("GET" , `/parent/${parentId}/student/${studentId}/activity/${activityId}`, undefined, { signal });
        const vm = err == null ? parseGeneralViewModel(dto) : undefined;
        return [err, vm!] as const;
    }

    /**
     * Create import tracker if fileSize less than 100MB, otherwise return trackerId * -1
     * @param schoolId
     * @param resourceGroupId
     * @param fileSize
     * @returns A positive tracker ID if the uploading file can be handled on air.
     * If the file should be handled
     */
    static async startImport(facultyId: DbIdentity, schoolId:DbIdentity, classId:DbIdentity, fileSize:number|undefined) {
        const [err, id] = await aFetch<DbIdentity>("POST" , `/faculty/${facultyId}/school/${schoolId}/class/${classId}/activity/start-import?size=${fileSize}`);
        const trackerId = Math.abs(id);
        const isBigFile = (id < 0);
        return [err, trackerId, isBigFile] as const;
    }

    static async uploadGdriveFileForExternalImport(gdriveFileId: string, facultyId: DbIdentity, schoolId:DbIdentity, classId:DbIdentity, courseType:string, trackerId:DbIdentity, fileName: string) {
        const params = `gdriveFileId=${gdriveFileId}&courseType=${courseType}&trackerId=${trackerId}&fileName=${encodeURI(fileName)}`
        const [err, data] = await aFetch<{}>("POST" , `/faculty/${facultyId}/school/${schoolId}/class/${classId}/activity/upload-gdrive-file?${params}`);
        return [err, data] as const;
    }

    static async uploadFileForExternalImport(importFile: UploadFile|undefined, facultyId: DbIdentity, schoolId:DbIdentity, classId:DbIdentity, couseType:string, trackerId:DbIdentity, onUploadProgress: (uploadedBytes: number, totalBytes: number) => void) {
        if (!!importFile) {
            const [err] = await uploadFileChunks<{}, any>("POST",
                `${apiHost}${versionPrefix}uploadFile/uploadChunk`,
                `${apiHost}${versionPrefix}faculty/${facultyId}/school/${schoolId}/class/${classId}/activity/uploadFileForExternalImport?type=${couseType}&trackerId=${trackerId}`,
                importFile as any as File, onUploadProgress );
            if (err) return err;
        }
        return null;
    }

    static async import(importFile: UploadFile|undefined, facultyId: DbIdentity, schoolId:DbIdentity, classId:DbIdentity, couseType:string, trackerId:DbIdentity, onUploadProgress: (uploadedBytes: number, totalBytes: number) => void) {
        if (!!importFile) {
            const [err] = await uploadFile2<{}, any>("POST", `${apiHost}${versionPrefix}faculty/${facultyId}/school/${schoolId}/class/${classId}/activity/import?type=${couseType}&trackerId=${trackerId}`, importFile as any as File, undefined,
            { onUploadProgress: (pe) => {
                const { loaded, total } = pe;
                onUploadProgress(loaded, total);
            }} );
            if (err) return err;
        }
        return null;
    }
    static async fetchAwardRules({facultyId, activityId, signal }:{facultyId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<AwardRule[]>("GET" , `/faculty/${facultyId}/activity/${activityId}/award-rules`, undefined, { signal });
        return [err, err ? [] : data.map(a => new AwardRule(a))] as const;
    }

    static async submitAwardRules({facultyId, activityId, signal, rules }:{facultyId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal, rules: AwardRule[] }) {
        const [err, data] = await aFetch<AwardRule[]>("PUT" , `/faculty/${facultyId}/activity/${activityId}/award-rules`, toJS(rules), { signal });
        return [err, err ? [] : data.map(a => new AwardRule(a))] as const;
    }

    static async importSCORM({importFile, facultyId, schoolId, classId, trackerId, activity, doUploadInChunk = false}
        : {importFile: UploadFile|undefined,
            facultyId: DbIdentity,
            schoolId:DbIdentity,
            classId:DbIdentity,
            trackerId:DbIdentity,
            activity ?: Activity,
            doUploadInChunk?: boolean,
        }) {
        if (!importFile) return null;
        const [err] = !doUploadInChunk
            ? await uploadFile<{}>("POST", `/faculty/${facultyId}/school/${schoolId}/class/${classId}/activity/importscorm?trackerId=${trackerId}`, importFile as any as File, activity?.toJS())
            : await uploadFileChunks<{}, any>("POST",
                `${apiHost}${versionPrefix}uploadFile/uploadChunk`,
                `${apiHost}${versionPrefix}faculty/${facultyId}/school/${schoolId}/class/${classId}/activity/importscorm?trackerId=${trackerId}`
                    , importFile as any as File
                    , () => {}
                    , activity?.toJS());
        return err;
    }

    static async cancelImportProgress(facultyId: DbIdentity, classId:DbIdentity, trackerId:DbIdentity) {
        const [err, data] = await aFetch<{}>("GET" , `/faculty/${facultyId}/class/${classId}/activity/import-cancel/${trackerId}`)
        return [err, data] as const;
    }

    static async fetchImportProgress(facultyId: DbIdentity, classId:DbIdentity, trackerId:DbIdentity) {
        const [err, data] = await aFetch<{}>("GET" , `/faculty/${facultyId}/class/${classId}/activity/import-progress/${trackerId}`)
        return [err, data] as const;
    }

    static async getStudentsWithMissingActivities({facultyId, classId, signal}: {facultyId: DbIdentity, classId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}[]>("GET" , `/faculty/${facultyId}/class/${classId}/missingActivities`, undefined, { signal });
        return [err, err ? [] : data.map(a => new StudentMissingActivity(a))] as const;
    }

    static async getStudentsNeedsGrading({facultyId, classId, signal}: {facultyId: DbIdentity, classId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}[]>("GET" , `/faculty/${facultyId}/class/${classId}/needsGrading`, undefined, { signal });
        return [err, err ? [] : data.map(a => new StudentNeedsGrading(a))] as const;
    }

    /**  Get list of voters for each votes. */
    static async getPollQuizVoters({facultyId, activityId, pollQuizId, signal}: {facultyId: DbIdentity, activityId: DbIdentity, pollQuizId: string, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{summary: PollSummary[], users: IUserShortInfo[]}>("GET" , `/faculty/${facultyId}/activity/${activityId}/poll/${pollQuizId}/detail`, undefined, { signal });
        return [err, err ? undefined : data] as const;
    }

    /**  Reset all votes of a poll. */
    static async resetPollQuizVotes({facultyId, activityId, pollQuizId, signal}: {facultyId: DbIdentity, activityId: DbIdentity, pollQuizId: string, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{summary: PollSummary[], users: IUserShortInfo[]}>("DELETE" , `/faculty/${facultyId}/activity/${activityId}/poll/${pollQuizId}/votes`, undefined, { signal });
        return [err, err ? undefined : data] as const;
    }

    static async convertPageTo({ facultyId, activityId, targetType }: { facultyId: DbIdentity, activityId: DbIdentity, targetType: ActivityType}){
        const [err, data] = await aFetch<{ activity: Activity, discussion?: Discussion }>("PUT", `/faculty/${facultyId}/activity/${activityId}/convertPageTo`, targetType);
        return [err, err ? undefined : new Activity(data.activity), !!data.discussion ? new Discussion(data.discussion) : undefined] as const;
    }

    static async getUpcomingActivitiesAsStudent({ studentId, classIds, signal }: { studentId:DbIdentity, classIds: DbIdentity[], signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}[]>("POST" , `/student/${studentId}/upcomingActivity`, classIds, { signal });
        return [err, err ? [] : data.map(a => new UpcomingActivity(a))] as const;
    }

    static async fetchMissingActivitiesInTerm({ studentId, gradingTermId, signal }:{studentId: DbIdentity, gradingTermId?:DbIdentity, signal?: AbortSignal }) {
        const [err, dto] = await aFetch<GeneralDto>("GET" , `/student/${studentId}/gradingTerm/${gradingTermId}/activity/missing`, undefined, { signal });
        const vm = (err ? undefined : parseGeneralViewModel(dto)!);
        return [err, vm] as const;
    }

    static async fetchInterclassActivity({facultyId, classId, activityCode, signal }:{facultyId:DbIdentity, classId: DbIdentity, activityCode: string, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{ activity: Activity, discussion?: Discussion, actDoc: ActDoc, insDoc ?: InstructionActDoc }>("GET" , `/faculty/${facultyId}/class/${classId}/interclass/activityCode/${activityCode}`, undefined, { signal });
        return [err,
                err ? undefined : new Activity(data.activity),
                (err || !data.discussion) ? undefined : new Discussion(data.discussion),
                err ? undefined : new ActDoc(data.actDoc),
                err ? undefined : new InstructionActDoc(data.insDoc),
        ] as const;
    }

    //#region Public Activity
    static async fetchPublicActivity({ publicLinkId, activityId, signal } : { activityId: DbIdentity, publicLinkId: string, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{
                                            activity       : Activity,
                                            discussion    ?: Discussion,
                                            questionDoc    : ActDoc,
                                            studentDoc     : StudentActDoc,
                                            instructionDoc : ActDoc
                                        }>("GET", `public/class/${publicLinkId}/activities/${activityId}`, undefined, { signal });
        return [err,
            err ? undefined : new Activity(data.activity),
            (err || !data.discussion) ? undefined : new Discussion(data.discussion),
            err ? undefined : new ActDoc(data.questionDoc),
            err ? undefined : new ActDoc(data.instructionDoc),
            err ? undefined : new StudentActDoc(data.studentDoc),
        ] as const;
    }
    static async fetchPublicQuestionDocOnly({ publicLinkId, activityId, password, signal } : { activityId: DbIdentity, publicLinkId: string, password ?: string, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{ studentDoc: StudentActDoc }>("GET", `public/class/${publicLinkId}/activities/${activityId}/doc`, { password }, { signal });
        return [err, err ? undefined : new StudentActDoc(data.studentDoc) ] as const;
    }
    //#endregion Public Activity
    static async FetchSubmissionSummary({actIds: activityIds, facultyId, signal}: { actIds: DbIdentity[]; facultyId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<IActivitySummary[]>("POST" , `/faculty/${facultyId}/activity/summaries`, activityIds, { signal });
        return [err, err ? [] : data] as const;
    }

    //#endregion

    static sorter = {
        dateDue         : (a: {dateDue ?: NumberDate}, b: {dateDue ?: NumberDate}) => trimSeconds(a.dateDue) - trimSeconds(b.dateDue),
        dateDueNA       : <T extends Activity>(a: T, b: T) => {
            const da = (a.dateDue == null || a.dateDue <= 0) ? 0 : a.dateDue;
            const db = (b.dateDue == null || b.dateDue <= 0) ? 0 : b.dateDue;
            return (da - db);
        },
        dateAssigned    : (a: Activity, b: Activity) => ((a.dateAssigned || 0) - (b.dateAssigned || 0)),
        dateCreated     : (a: {dateCreated ?: NumberDate}, b: {dateCreated ?: NumberDate}) => ((a.dateCreated || 0) - (b.dateCreated || 0)),
        dateUpdated     : (a: Activity, b: Activity) => ((a.dateUpdated || 0) - (b.dateUpdated || 0)),
        datePublishStart: (a: Activity, b: Activity) => ((a.dateAssigned || 0) - (b.dateAssigned || 0)),
        datePublishEnd  : (a: Activity, b: Activity) => ((a.dateDue || 0) - (b.dateDue || 0)),
        title           : (a: {title: string}, b: {title: string}) => (a.title.localeCompare(b.title)),
        weight          : (a: Activity, b: Activity) => (a.weight    - b.weight   ),

        className       : (a: Activity, b: Activity) => (a.class.className.localeCompare(b.class.className)),
        isGraded        : (a: Activity, b: Activity) => ((a.isGraded ? 1 : 0) - (b.isGraded ? 1 : 0)),
        dateDueThenTitle: <T extends Activity>(a: T, b: T) => {
            const i = Activity.sorter.dateDueNA(a, b);
            return i != 0 ? i : Activity.sorter.title(a, b);
        },
        dateAssignedThenCreated: <T extends Activity>(a: T, b: T) => {
            const i = Activity.sorter.dateAssigned(a, b);
            return i != 0 ? i : Activity.sorter.dateCreated(a, b);
        },
        dateAssignedThenTitle: <T extends Activity>(a: T, b: T) => {
            const i = Activity.sorter.dateAssigned(a, b);
            return i != 0 ? i : Activity.sorter.title(a, b);
        },

        dateDueThenDateCreated: <T extends {dateDue?: NumberDate, dateCreated?: NumberDate}>(a: T, b: T) => {
            const i = Activity.sorter.dateDue(a, b);
            return i != 0 ? i : Activity.sorter.dateCreated(a, b);
        },
        maxScore       : (a: Activity, b: Activity) => ((a.maxScore || 0) - (b.maxScore || 0))
    };

    static filter = {
        canGrade: (a: Activity) => a.isGraded
    }
}


export class UpcomingActivity {
    activityId      : DbIdentity = DefaultId;
    classId         : DbIdentity = DefaultId;
    className       = "";
    type            = ActivityType.Assignment;
    title           = "";
    dateDue        ?: number     = undefined;
    dateAssigned   ?: number     = undefined;
    maxScore       ?: number     = undefined;
    submissionCount?: number     = undefined;
    studentCount   ?: number     = undefined;
    isSubmitted     = false;
    submissionType  = SubmissionTypeEnum.Online;
    schoolId        : DbIdentity = DefaultId;

    get params() { return ({ classId:String(this.classId), activityId:String(this.activityId) }) }

    constructor(data?:any) {
        makeObservable(this, {
            className      : observable,
            type           : observable,
            title          : observable,
            dateDue        : observable,
            dateAssigned   : observable,
            maxScore       : observable,
            submissionCount: observable,
            studentCount   : observable,
            isSubmitted    : observable,
            submissionType : observable,
            schoolId       : observable,
            params         : computed,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }
    static sorter = {
        dateDue: (a:UpcomingActivity, b:UpcomingActivity) => (a.dateDue || -1) - (b.dateDue || -1),
        dateDueNA       : (a: UpcomingActivity, b: UpcomingActivity) => {
            if(a.dateDue == null || a.dateDue <= 0) {
                if (b.dateDue == null || b.dateDue <= 0)
                    return 0;
                 else
                    return 1;
            } else {
                if (b.dateDue == null || b.dateDue <= 0)
                    return -1;
                 else
                    return ((a.dateDue || 0) - (b.dateDue || 0));
            }
        },
        title           : (a: UpcomingActivity, b: UpcomingActivity) => (a.title.localeCompare(b.title)),
        dateDueThenTitle: (a: UpcomingActivity, b: UpcomingActivity) => {
            const i = UpcomingActivity.sorter.dateDueNA(a, b);
            return i !== 0 ? i : UpcomingActivity.sorter.title(a, b);
        },
        className       : (a: UpcomingActivity, b: UpcomingActivity) => (a.className.localeCompare(b.className)),
    }
}

export function flatModules<T extends Activity>(modules: Module[], activities:T[]): T[] {
    const rootModuleId = "";
    const _modules = modules.slice();
    const mModule = new Map(_modules.map(m => [m.moduleId, m]));

    let maxModuleIndex = Math.max(0, ..._modules.filter(x => !x.moduleParentId).map(m => m.moduleIndex));
    for (const x of activities) {
        if (!x.moduleId) continue;
        if (mModule.has(x.moduleId)) continue;
        const m = new Module({
            moduleId      : x.moduleId,
            moduleParentId: null,
            moduleIndex   : ++maxModuleIndex,
        });
        _modules.push(m);
        mModule.set(m.moduleId, m);
    }

    const mModuleId2Activity = new Map(activities.map(a => [a.moduleId, a]))

    const p2c = new Map(map(groupBy(_modules, m => (m.moduleParentId || rootModuleId)), g => [g[0]!.moduleParentId || rootModuleId, g.sort((a,b) => a.moduleIndex - b.moduleIndex)]));
    const processedModules = new Set<string>([rootModuleId]);

    return flatModule(p2c.get(rootModuleId) ?? [], p2c, processedModules)
        .map(m => mModuleId2Activity.get(m.moduleId))
        .filter((a): a is T => a != null && a.type != ActivityType.Attachment);

    function flatModule(xs:Module[], p2c:Map<string, Module[]>, processedModules:Set<string>): Module[] {
        xs = xs.filter(x => processedModules.has(x.moduleId) ? false : (processedModules.add(x.moduleId), true));
        return xs.flatMap(x => [x].concat(flatModule(p2c.get(x.moduleId) ?? [], p2c, processedModules)))
    }
}

export interface GroupSkillRequest {
    studentId      : number  ;
    skillRequestIds: number[];
}

export function CalcFacultyProgress(acts: FacultyModuleProgress[] | undefined) {
    const base = acts?.filter(a => (
                            (a.type === ActivityType.Activity || a.type === ActivityType.Attachment || a.type === ActivityType.Video) && !a.ignoreCompleteMark) ||
                            (a.type === ActivityType.Assignment || a.type === ActivityType.Assessment || a.type === ActivityType.Discussion));

    // calc submissions completed
    const progressCount = sumBy(base?.map(a => !a.isGroupWithoutDiscussion ? a.submittedCount : a.groupSubmittedCount));
    const totalStudents = sumBy(base?.map(a => !a.isGroupWithoutDiscussion ? a.studentCount : a.groupCount));

    return [progressCount, totalStudents, Math.round((progressCount ?? 0) * 100 / (totalStudents ?? 0))] as const;
}

export class StudentMissingActivity {
    studentId : DbIdentity = DefaultId;
    activities: Activity[] = [];
    student?: Student;
    constructor(data?:any) {
        makeObservable(this, {
            studentId : observable,
            activities: observable,
            student: observable
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }
}
export class StudentNeedsGrading extends StudentMissingActivity {
    constructor(data?:any) {
        super(data);
    }
}

export const NavigatableActTypes = [
    0,
    ActivityType.Activity,
    ActivityType.Assignment,
    ActivityType.Assessment,
    ActivityType.Discussion,
    ActivityType.Conference,
    ActivityType.Video
];

export const FilterClassGradebooksMap = [ 'discussionList', 'classAssignments', 'classAssessments' ]
export const MinimumScoreForPassType = [ ActivityType.Assignment, ActivityType.Assessment ];
interface IActivitySummary { activityId: DbIdentity, submissionCount: number}