import {DbIdentity, DefaultId} from "./types"

export class CustomLinkPortal {
    customLinkId: DbIdentity;
    portalId    : DbIdentity;

    constructor(data?:{}) {
        this.customLinkId = DefaultId;
        this.portalId     = DefaultId;
        if (data != null) Object.assign(this, data);
    }
}
