import { computed, action, makeObservable, observable } from "mobx";

import {DbIdentity, DefaultId, NumberDate} from "./types";
import { Activity, ActivitySyncPolicy, GradeSyncPolicy } from "./Activity";
import { BaseDiscussion } from "./BaseDiscussion";
import { Student } from "./Student";
import { Comment, ICommentCounter, IDiscussionThreadStats, IPendingComment } from "./Comment";

import { IErrorData, createError } from "../services/api/AppError";
import { aFetch, bindResult as bindResult } from "../services/api/fetch";
import { uploadMedia} from "../services/api/upload";

import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import { Class } from "./Class";
import { stripHtml } from "../utils/html";
import { uniqBy } from "lodash-es";
import { CommentSeen } from "./CommentSeen";

export enum DiscussionViewType {
    List = 1,
    Grid = 2
}

export enum WordCountType {
    Word       = 0,
    Sentence   = 1,
}

export interface IViewItem {
    studentId: DbIdentity,
    comment  : Comment
}

/** Role of member in discussion. */
export enum DiscussionMemberRole
{
    Unknown = 0,
    Owner,
    User,
    Outsider,
}

/** Config of dicussion that links with activity/assignment */
export interface IActDiscConfig  {
    activityId : number,
    minimumNumberOfWords ?: number,
    minimumWordsForReply ?: number,
    wordCountType : number,
    banner       ?: string;
};

interface IGetPublicDiscussion {
    discussion     :  {};
    classId       ?:  number;
    discussionId   :  number;
    /** Role of current user*/
    userRole       :  DiscussionMemberRole;
    /** ActDoc Description for dicussions that links to an activity*/
    doc?           :  {};
    activityConfig ?: IActDiscConfig
}

export enum DiscussionSortType {
    AssignDateNewest = 0,
    AssignDateOldest = 1,
    DueDateNewest = 2,
    DueDateOldest = 3,
}

export interface IApprovalRequest {
    pendingCommentId: number,
    approved: boolean,
    lastSeen ?: NumberDate,
}

export class Discussion extends BaseDiscussion {
    classId               : DbIdentity         = DefaultId;
    activityId            : DbIdentity         = DefaultId; set_activityId(v: DbIdentity) { this.activityId = v; }
    oneRosterId          ?: string             = undefined;
    aeriesSyncPolicy      : ActivitySyncPolicy = ActivitySyncPolicy.Sync;
    aeriesGradeSyncPolicy : GradeSyncPolicy    = GradeSyncPolicy.Sync; set_aeriesGradeSyncPolicy(v: boolean) { this.aeriesGradeSyncPolicy = v ? GradeSyncPolicy.Sync : GradeSyncPolicy.NotSync }
    class                ?: Class = undefined;
    
    defaultTeacherSort      ?: DiscussionViewType  = undefined;
    defaultTeacherGridView  ?: string          = undefined;
    classGradebookId        ?: DbIdentity      = undefined;

    constructor(data?:{}) {
        super(data);

        makeObservable(this, {
            set_activityId          : action,
            aeriesGradeSyncPolicy   : observable, set_aeriesGradeSyncPolicy: action.bound,
            params                  : computed,
            class                   : observable.ref,
            defaultTeacherSort      : observable,
            defaultTeacherGridView  : observable,
            classGradebookId        : observable.ref,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }

    override clone() { return new Discussion(this.toJS()) }

    get params() { return ({
        classId     : String(this.classId),
        discussionId: String(this.discussionId),
        threadId    : String(this.threadId),
        activityId  : String(this.discussionActivityId),
    }) }

    async save(facultyId:DbIdentity, activity?:{}, linkedDiscussions?: {}[], isTinyMCE ?: boolean) {
        activity = Object.assign(this.toJS(), activity);

        if (activity && activity.title) activity.title = stripHtml(activity.title);
        linkedDiscussions?.forEach((x) => x.title = stripHtml(x.title));

        const { classId } = this;
        if (classId < 1) return [createError(new Error("Invalid classId"), 400), undefined!] as const;

        if (this.discussionId == DefaultId) {
            const [err, dto] = await aFetch<GeneralDto>("POST", `/faculty/${facultyId}/class/${classId}/discussion`, { discussion: {...activity, isTinyMCE: isTinyMCE}, linkedDiscussions });
            const vm = err ? undefined : parseGeneralViewModel(dto);
            return [err, vm!] as const;
        }

        const [err, dtoStr] = await aFetch<string>("PUT", `/faculty/${facultyId}/class/${classId}/discussion/${this.discussionId}`, { discussion:activity, linkedDiscussions });
        const dto : GeneralDto = JSON.parse(dtoStr ?? '');
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    static async setPublic({classId, discussionId, facultyId, isPublic}: {facultyId:DbIdentity, discussionId: DbIdentity, classId: DbIdentity, isPublic: boolean}) {
        const [err, publicId] = await aFetch<string>("PUT", `/faculty/${facultyId}/class/${classId}/discussion/${discussionId}/setPublic`, isPublic);
        return [err, (err ? undefined : publicId)!] as const;
    }

    async delete(facultyId:DbIdentity) {
        const { classId } = this;
        const [err, dto] = await aFetch<GeneralDto>("DELETE", `/faculty/${facultyId}/class/${classId}/discussion/${this.discussionId}`);
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }
    static async importDiscussionFromResource({ facultyId, classId, resourceDiscussionId, activityId }: { facultyId: DbIdentity, classId: DbIdentity, resourceDiscussionId: DbIdentity, activityId: DbIdentity}) {
        const [err, dto] = await aFetch<{discussion: {}, activity: {}}>("POST", `/faculty/${facultyId}/class/${classId}/discussion/importDiscussionFromResource`, { resourceDiscussionId, activityId });
        const vm = err ? undefined : {discussion: new Discussion(dto.discussion), activity: new Activity(dto.activity)};
        return [err, vm!] as const;
    }

    static async unDeleted({facultyId, classId}:{facultyId:DbIdentity, classId: DbIdentity}, discussionActivityIds: DbIdentity[]) {
        const [err, dto] = await aFetch<GeneralDto>("PUT", `/faculty/${facultyId}/class/${classId}/discussion/undeleted`, discussionActivityIds);
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    async updatePreferences(facultyId:DbIdentity) {
        const updateSortSettingsRequest = {
            defaultTeacherSort: this.defaultTeacherSort,
            defaultTeacherGridView: this.defaultTeacherGridView,
        }

        const { classId, discussionId } = this;
        if (classId < 1) return [createError(new Error("Invalid classId"), 400), undefined!] as const;
        if (discussionId < 1) return [createError(new Error("Invalid discussionId"), 400), undefined!] as const;

        const [err, x] = await aFetch<Discussion>("PUT", `/faculty/${facultyId}/class/${classId}/discussion/${this.discussionId}/updatePreferences`, updateSortSettingsRequest);
        return [err, (err ? undefined : new Discussion(x))!] as const;
    }


    //#region API AsFaculty
    static async getDiscussionsOfClassAsFaculty({facultyId, classId, signal }:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/class/${classId}/discussion`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);

        return [err, vm!] as const;
    }

    static async getDeletedDiscussionsOfClassAsFaculty({facultyId, classId, signal }:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/class/${classId}/discussion/deleted`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    static async getDiscussionsOfActivityAsFaculty({facultyId, activityId, signal }:{facultyId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/activity/${activityId}/discussion`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    static async getDiscussionFromDiscussionActivityAsFaculty({facultyId, discussionActivityId, signal }:{facultyId:DbIdentity, discussionActivityId:DbIdentity, signal?: AbortSignal}){
        const [err, x] = await aFetch<Discussion>("GET", `/faculty/${facultyId}/discussionActivity/${discussionActivityId}/discussion`, undefined, { signal });
        return [err, (err ? undefined : new Discussion(x))!] as const;
    }

    static async getDiscussionAsFaculty({ facultyId, classId, discussionId, includeLinked, signal }:{facultyId:DbIdentity, classId:DbIdentity, discussionId:DbIdentity, includeLinked?:boolean, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/class/${classId}/discussion/${discussionId}`, { includeLinked }, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    static async getDiscussionByDiscussionModuleIdAsFaculty({facultyId, classId, discussionModuleId, signal }:{facultyId:DbIdentity, classId:DbIdentity, discussionModuleId:string, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/class/${classId}/discussionModule/${discussionModuleId}`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    //#endregion

    //#region API AsParent
    static async getDiscussionAsParent({parentId, studentId, discussionId, signal }:{parentId:DbIdentity, studentId: DbIdentity, discussionId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/parent/${parentId}/student/${studentId}/discussion/${discussionId}`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }
    //#endregion

    //#region API AsStudent
    static async getDiscussionsOfClassAsStudent({studentId, classId, classGradebookId, signal }:{studentId:DbIdentity, classId:DbIdentity, classGradebookId?: DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/student/${studentId}/class/${classId}/discussion`,  { classGradebookId }, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    static async getDiscussionsOfActivityAsStudent({studentId, activityId, signal }:{studentId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/student/${studentId}/activity/${activityId}/discussion`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    static async getDiscussionAsStudent({studentId, classId, discussionId, signal }:{studentId:DbIdentity, classId:DbIdentity, discussionId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/student/${studentId}/class/${classId}/discussion/${discussionId}`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    static async getDiscussionsOfClassAsParent({parentId, studentId, classId, classGradebookId, signal }:{parentId: DbIdentity, studentId:DbIdentity, classId:DbIdentity, classGradebookId?: DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/parent/${parentId}/student/${studentId}/class/${classId}/discussion`, { classGradebookId }, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    static async getDiscussionByDiscussionModuleIdAsStudent({studentId, classId, discussionModuleId, signal }:{studentId:DbIdentity, classId:DbIdentity, discussionModuleId:string, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/student/${studentId}/class/${classId}/discussionModule/${discussionModuleId}`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    static async getStudentDiscussionAsStudent({studentId, classId, discussionId, signal }:{studentId:DbIdentity, classId:DbIdentity, discussionId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<Student[]>("GET", `/student/${studentId}/class/${classId}/discussion/${discussionId}/studentDiscussion`, undefined, { signal });
        return [err, err ? [] : data.map(x => new Student(x))] as const;
    }

    static async getStudentDiscussionAsParent({studentId, classId, discussionId, signal }:{studentId:DbIdentity, classId:DbIdentity, discussionId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<Student[]>("GET", `/parent/student/${studentId}/class/${classId}/discussion/${discussionId}/studentDiscussion`, undefined, { signal });
        return [err, err ? [] : data.map(x => new Student(x))] as const;
    }

    static async fetchRightDiscussionInLinkedSection({studentId, discussionId, classId, signal}:{studentId:DbIdentity, discussionId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, x] = await aFetch<{}>("GET" , `/parent/student/${studentId}/discussion/${discussionId}/getLinkedSectionDiscussion`, { classId }, { signal });
        return [err, (err ? undefined : new Discussion(x))!] as const;
    }

    static async fetchRightDiscussionInLinkedSectionAsParent({studentId, discussionId, classId, signal}:{studentId:DbIdentity, discussionId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, x] = await aFetch<{}>("GET" , `/student/${studentId}/discussion/${discussionId}/getLinkedSectionDiscussion`, { classId }, { signal });
        return [err, (err ? undefined : new Discussion(x))!] as const;
    }

    static async fetchDiscussionThreadStatsAsStudent({ studentId, threadId, signal }: { studentId: DbIdentity, threadId: string, signal?: AbortSignal }) {
        const [err, x] = await aFetch<IDiscussionThreadStats>("GET", `/student/${studentId}/thread/${threadId}/discussionThreadStats`, undefined, { signal });
        return [err,x] as const;
    }

    static async fetchDiscussionThreadStatsAsParent({ studentId, threadId, signal }: { studentId: DbIdentity, threadId: string, signal?: AbortSignal }) {
        const [err, x] = await aFetch<IDiscussionThreadStats>("GET", `/parent/student/${studentId}/thread/${threadId}/discussionThreadStats`, undefined, { signal });
        return [err,x] as const;
    }

    static async fetchStudentCommentCount({parentId, studentId, studentIds, threadId, signal }: { parentId: DbIdentity, studentId: DbIdentity, studentIds: DbIdentity[], threadId: string, signal?: AbortSignal }) {
        const [err, x] = await aFetch<ICommentCounter[]>("GET", `/parent/${parentId}/student/${studentId}/thread/${threadId}/discussionCommentCount`, {studentIds}, { signal });
        return [err,x] as const;
    }

    static async fetchDiscussionThreadStatsAsPreview({ facultyId, threadId, signal }: { facultyId: DbIdentity, threadId: string, signal?: AbortSignal }) {
        const [err, x] = await aFetch<IDiscussionThreadStats>("GET", `/faculty/${facultyId}/previewStudent/thread/${threadId}/discussionThreadStats`, undefined, { signal });
        return [err,x] as const;
    }
    //#endregion

    //#region API PreviewStudent
    static async getDiscussionsOfClassAsPreviewStudent({facultyId, classId, signal }:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/previewStudent/class/${classId}/discussion`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    static async getDiscussionsOfActivityAsPreviewStudent({facultyId, activityId, signal }:{facultyId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/previewStudent/activity/${activityId}/discussion`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    static async getDiscussionAsPreviewStudent({facultyId, classId, discussionId, signal }:{facultyId:DbIdentity, classId:DbIdentity, discussionId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/previewStudent/class/${classId}/discussion/${discussionId}`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    /** @deprecated Move to Student then remove deprecated */
    static async getStudentDiscussionAsPreviewStudent({facultyId, classId, discussionId, signal }:{facultyId:DbIdentity, classId:DbIdentity, discussionId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/previewStudent/class/${classId}/discussion/${discussionId}/studentDiscussion`, undefined, { signal });
        return [err, err ? [] : data.map(x => new Student(x))] as const;
    }
    //#endregion

    //#region API /public
    static async getDiscussionsByActDocAsPublic({activityId, signal}: {activityId: DbIdentity, signal?: AbortSignal}) {
        const [error, data] = await aFetch<{}[]>("GET", `/public/activity/${activityId}/discussionFromDoc`, undefined, { signal });
        return [error, (error ? [] : data.map(item =>  new Discussion(item)))!] as const;
    }

    static async getPublicDiscussion({discPublicId, signal}: {discPublicId: string, signal?: AbortSignal}) {
        const [err, data] = await aFetch<IGetPublicDiscussion>("GET", `/discussion/${discPublicId}`, undefined, { signal });
        return [err, (!!err ? null : data)] as const;
    }
    //#endregion

    //#region API /approval

    static async loadAllPendingComment({discussionId, facultyId, signal}: { signal: AbortSignal; discussionId: number; facultyId: number; }) {
        const query = aFetch<IPendingComment[]>("GET", `/faculty/${facultyId}/discussion/${discussionId}/pending`, undefined, {signal} );
        return await bindResult(query);
    }

    static async loadUserPendingComment({discussionId, userId, signal}: { signal: AbortSignal; discussionId: number; userId: number; }) {
        const query = aFetch<IPendingComment[]>("GET", `/discussion/${discussionId}/user/${userId}/pending`, undefined, {signal} );
        return await bindResult(query);
    }

    static async approveComments({discussionId, facultyId, lastSeen,  data}: {discussionId: number, facultyId: number, lastSeen ?: NumberDate, data: IApprovalRequest[]}) {
        const body = uniqBy(data, x => x.pendingCommentId);
        let url = `/faculty/${facultyId}/discussion/${discussionId}/approve`;
        if (!!lastSeen) url = `${url}?lastSeen=${lastSeen}`;
        const [err, x] = await aFetch<{comments: {}[], pendingComments: IPendingComment[], commentSeen ?: CommentSeen}>("POST", url, body);
        const comments = err ? [] : x.comments.map(x => new Comment(x));
        const pending = err ? [] : x.pendingComments;
        const commentSeen = (err || x.commentSeen == null) ? undefined : new CommentSeen(x.commentSeen);
        return [err, comments, pending, commentSeen] as const;
    }

    //#endregion
}
