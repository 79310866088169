import { DbIdentity, EUserRole } from "../models/types";

export const FacultyPortalHomeUrl     = `/faculty-portal/`;
export const StudentPortalHomeUrl     = `/student-portal/dashboard`;
export const ParentPortalHomeUrl      = `/parent-portal/`;
export const AdminPortalHomeUrl       = `/admin-portal/`;
export const SchoolAdminPortalHomeUrl = `/admin-portal/`;
export const LoginUrl                 = `/login`;

export function FacultyPortalClassUrl({ classId }: { classId: DbIdentity }) { return `/faculty-portal/classes/${classId}`; }
export function FacultyPortalCaseloadHomepageUrl({ caseloadId }: { caseloadId: DbIdentity }) { return `/faculty-portal/caseload/${caseloadId}/homepage`; }
export function FacultyPortalQuickGraderUrl({ activityId }: { activityId: DbIdentity }) { return `/faculty-portal/quickGrader/students/-1/activities/${activityId}?nextActivityToGrade=true`; }
export function StudentPortalClassDetailUrl({ classId }: { classId: DbIdentity }) { return `/student-portal/classes/${classId}`; }
export function StudentPortalClassHomePage({ classId }: { classId: DbIdentity }) { return `/student-portal/classes/${classId}/homepage` }
export function StudentPortalClassGrade({ classId }: { classId: DbIdentity }) { return `/student-portal/classes/${classId}/grades` }
export function StudentPortalViewActDoc({ activityId }: { activityId: DbIdentity }) { return `/student-portal/activities/${activityId}/doc` }
export function FacultyPortalDiscussionUrl({ classId, discussionId }: { classId: DbIdentity, discussionId: DbIdentity }) { return `/faculty-portal/classes/${classId}/discussion/${discussionId}`; }
export function StudentPortalDiscussionUrl({ classId, discussionId }: { classId: DbIdentity, discussionId: DbIdentity }) { return `/student-portal/classes/${classId}/discussion/${discussionId}`; }
export function PreviewStudentClassModuleUrl({ classId }: { classId: DbIdentity }) { return `/preview-student/classes/${classId}/activities` }
export function PreviewStudentClassLearningPathUrl({ classId }: { classId: DbIdentity }) { return `/preview-student/classes/${classId}/learning-path` }
export function PreviewStudentActivity({ activityId, fromRoutName = 'classActivities' }: { activityId: DbIdentity, fromRoutName ?: string }) { return `/preview-student/activities/${activityId}/doc?fromStateRouteName=${fromRoutName}&refActId=${activityId}` }
export function ParentPortalStudentUrl({ studentId }: { studentId: DbIdentity }) { return `/parent-portal/student/${studentId}/class/-1/profile` }

export function HomeUrl({ role }: { role: "faculty" | "student" | "parent" | EUserRole | undefined}) {
    switch(role) {
        case EUserRole.Faculty:
        case 'faculty': return FacultyPortalHomeUrl;
        case EUserRole.Student:
        case 'student': return StudentPortalHomeUrl;
        case EUserRole.Parent:
        case 'parent': return ParentPortalHomeUrl;
        case EUserRole.Admin: return AdminPortalHomeUrl;
        case EUserRole.SchoolAdmin: return SchoolAdminPortalHomeUrl;
        default: return `/`;
    }
}

