import { makeObservable, observable } from "mobx";
import { DbIdentity, DefaultId } from "./types"

export class ShoulderTapOwner {
    shoulderTapOwnerId  : DbIdentity = DefaultId;
    shoulderTapId       : DbIdentity = DefaultId;
    userId              : DbIdentity = DefaultId;

    constructor(data ?: {}) {
        makeObservable(this, {
            shoulderTapOwnerId : observable,
            shoulderTapId      : observable,
            userId             : observable,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }
}