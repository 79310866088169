import { observable, action, makeObservable } from "mobx";

import { DbIdentity, DefaultId, EUserRole, NumberDate } from "./types"
import type { Activity, ActivityType } from "./Activity";
import type { User } from "./User";
import type { ActivityScore } from "./ActivityScore";
import type { Group } from "./Group";
import type { Discussion } from "./Discussion";

import { aFetch } from "../services/api/fetch";
import type { ApplicationRoleValue } from "./ApplicationRoleValue";

export enum UserNoticeEntityType{
    Activity   = 1,
    Group      = 2,
    Event      = 3,
    Discussion = 4,
    Import     = 5,
    Attendance = 6,
    Gradebook  = 7,
    BadgeCredit = 8
}

export enum ActionType {
    Unknown                  = 0,
    Submitted                = 1,
    StudentUnSubmitted       = 2,
    ReSubmitted              = 3,
    AddToGroup               = 4,
    InviteToGroup            = 5,
    RejectMemberRequest      = 6,
    RemoveMemberGroup        = 7,
    ApproveMemberRequest     = 8,
    SkillRequest             = 9,
    AddToEvent               = 10,
    PublishGrade             = 11,
    PublishActivity          = 12,
    DateDueHasChanged        = 13,
    ActDocHasChanged         = 14,
    ImportingProgressUpdated = 15,
    TeacherUnSubmitted       = 16,
    ActivityScoreHasComment  = 17,
    MessageToStudentsViaGradebook = 23,
    FacultyJoinGroup         = 24,
    AttendanceMark           = 25,
    OverdueAssignment        = 26,
    CalculateCumulatliveGradeComplete = 27,
    TeacherReplyStudentComment = 28,
    GotNewCredits = 29,
    GotNewBadge = 30,
    GotNewCertificate = 31,
}

export const NotShowFromUser = [ActionType.FacultyJoinGroup, ActionType.AttendanceMark, ActionType.OverdueAssignment, ActionType.TeacherReplyStudentComment];

type UserNoticeGetProps = {
    type?: UserNoticeEntityType;
    role: EUserRole;
    unread?: boolean;
    pageSize?: number;
    page?: number;
    signal?: AbortSignal;
    isAdminOnly: boolean;
};

export class UserNotice {
    userNoticeId         : DbIdentity           = DefaultId;
    userId               : DbIdentity           = DefaultId;
    schoolId            ?: DbIdentity           = undefined;
    entityType          ?: UserNoticeEntityType = undefined;
    entityId            ?: DbIdentity           = undefined;
    actionType          ?: ActionType           = undefined;
    importTargetType    ?: ImportTargetType     = undefined;
    content              : string               = "";
    subject              : string               = "";
    dateSeen            ?: NumberDate           = undefined;
    createdBy           ?: DbIdentity           = undefined;
    dateCreated          : NumberDate           = -1;
    activity            ?: Activity             = undefined;
    activityScore       ?: ActivityScore        = undefined;
    group               ?: Group                = undefined;
    discussion          ?: Discussion           = undefined;
    fromUser            ?: User                 = undefined;
    isAdminMessage      ?: boolean              = undefined;
    sendToRole          ?: ApplicationRoleValue = undefined;

    constructor(data?: UserNotice) {
        makeObservable(this, {
            userId              : observable,
            schoolId            : observable,
            entityType          : observable,
            entityId            : observable,
            actionType          : observable,
            importTargetType    : observable,
            content             : observable,
            subject             : observable,
            dateSeen            : observable,
            createdBy           : observable,
            dateCreated         : observable,
            activity            : observable,
            activityScore       : observable,
            group               : observable,
            discussion          : observable,
            fromUser            : observable,
            isAdminMessage      : observable,
            sendToRole          : observable,
            set_entityType      : action.bound,
            set_content         : action.bound,
            set_dateSeen        : action.bound,
            set_sendToRole      : action.bound,
        });

        if (data != null) Object.assign(this, data);
    }

    set_entityType(v: UserNoticeEntityType            ) { this.entityType = v };
    set_content   (v: string                          ) { this.content    = v };
    set_subject   (v: string                          ) { this.subject    = v };
    set_dateSeen  (v: NumberDate | undefined          ) { this.dateSeen   = v };
    set_sendToRole(v: ApplicationRoleValue | undefined) { this.sendToRole = v };

    extends(data:any) {
        Object.assign(this, data);
    }

    toJS() {
        return ({
            userNoticeId        : this.userNoticeId,
            userId              : this.userId      ,
            schoolId            : this.schoolId    ,
            entityType          : this.entityType  ,
            entityId            : this.entityId    ,
            actionType          : this.actionType  ,
            importTargetType    : this.importTargetType  ,
            content             : this.content     ,
            subject             : this.subject     ,
            sendToRole          : this.sendToRole  ,
        });
    }

    static async fetchUserNotices({role, signal, ...p} : UserNoticeGetProps) {
        const [err, data] = await aFetch<{items: IUserNoticeResponse[], count: number}>("GET", `userNotice/${role}`, p, { signal});
        const vm = err ? undefined : data.items;
        const notices:IUserNoticeResponse[] = (vm == null ? [] : vm);
        return [err, notices, err ? undefined : data.count] as const;
    }

    static async setSeen({userNoticeIds, isSeen, role}:{userNoticeIds: DbIdentity[], isSeen: boolean, role: EUserRole}) {
        const [err, dt] = await aFetch<{ notices: IUserNoticeResponse[]}>("PUT", `/userNotice/${role}/${isSeen ? "markAsRead" : "markAsUnread"}`, userNoticeIds );
        return [err, (err ? undefined : dt.notices)] as const;
    }

    static async setSeenAll({role}:{role: EUserRole}) {
        const [err, dt] = await aFetch<{ notices: IUserNoticeResponse[], count: number }>("PUT", `/userNotice/${role}/markAllAsRead` );
        return [err, (err ? undefined : dt.notices)!, ( err ? undefined : dt.count)] as const;
    }

    static async fetchUnreadCount(role: EUserRole) {
        const [err, count] = await aFetch<number>("GET", `userNotice/${role}/unreadCount`);
        return [err, count] as const;
    }

    async createUserNoticeAsAdmin() {
        const [err, data] = await aFetch<{}>("POST", `Admin/userNotice`, this.toJS());
        return [err, data] as const;
    }

    static sorter = {
        dateCreated: (a:UserNotice, b:UserNotice) => a.dateCreated - b.dateCreated,
    }
}

export interface IUserNoticeResponse {
    userNoticeId: DbIdentity;
    userId?: DbIdentity;
    entityType?: UserNoticeEntityType;
    actionType?: ActionType;
    importTargetType?: ImportTargetType;
    activityTitle?: string;
    discussionTitle?: string;
    groupName?: string;
    groupId?: DbIdentity;
    fromUser?: string;
    content?: string;
    subject?: string,
    activityType?: ActivityType;
    dateSeen?: NumberDate;
    entityId?: DbIdentity;
    activityClassId?: DbIdentity;
    discussionClassId?: DbIdentity;
    dateCreated?: NumberDate;
    isGroup?: boolean;
    createdBy?: DbIdentity;
    conferenceLogId?:DbIdentity;
    finalImportStatus?: ModuleImportFileStatus;
    attachmentUrl?: string;
    isPublished: boolean;
    isAdminMessage: boolean;
}

export enum ModuleImportFileStatus
{
    Ready,
    Importing,
    NeedToCancel,
    Fail,
    Success,
    Cancelled
}

export enum ImportTargetType
{
    Class,
    ResourceGroup,
    User
}
