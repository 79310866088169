import { GoogleDocItem, ISimpleGoogleFileInfo } from "../../models/GoogleDrive";
import { GoogleDocExt, googleExtToMime, googleMimeTypeToViewType } from "../../models/types";
import { readAsTextFile } from "../../utils/file";
import { qs } from "../../utils/url";

export const embededQueryStrings = { usp:"sharing", embedded: "true" };
export function googleDriveUrl(url: string) : ISimpleGoogleFileInfo | undefined {
    if (/(https?):\/?\/?(docs|drive)(.google.com)/i.test(url) === false) return undefined;
    /**
     * there are many types of url
     * ex:
     * google doc/sheet/ppt/drawings/forms
     * https://docs.google.com/spreadsheets/d/1Opltpx-U0jNfdSTRMUbNvsS-4TyIwk63tBrm_IsA2xA/edit?usp=sharing
     * https://docs.google.com/document/d/1F1_6E-a6RTwt9UCsH50h7naLZ6t9NhDAiK0xSYxAaE0/edit?usp=sharing
     * 
     * other files/ folders
     * https://drive.google.com/drive/folders/1zxOcD53OqFpXDZL-4cW4a9oljlQoT7tO?usp=sharing
     * https://drive.google.com/file/d/1qm96Ua84YqV9jSfMJZJBhria_EqVNuD_/view?usp=share_link
     */
    const groups = url.match(/(http(s?):\/\/|)((drive|docs).google.com)\/(?<fileType>\w+)\/([\w]{1,})(\/[\w]{1,})?\/(?<fileId>[-\w]{25,})/)?.groups;
    if (groups) return {embedUrl: url, id: groups.fileId ?? "", type: (groups.fileType ?? "")};
    const id = url.match(/[-\w]{25,}/)?.[0];
    return { embedUrl: url, id: id ?? "", type: ""};
}

function isOfflineGoogleDoc(fileNameWithExt: string) {
    const _end = fileNameWithExt.lastIndexOf(".");
    let ext = "";
    if (_end > -1) {
        ext = fileNameWithExt.substring(_end+1);
        if (GoogleDocExt.has(ext)) {
            const name = fileNameWithExt.substring(0,_end);
            const mimeType = googleExtToMime(ext) as string;
            const type = googleMimeTypeToViewType(mimeType);
            return [true, { name, ext, type, mimeType }] as const;
        }
    }
    return [false, undefined] as const;
}

export function isGoogleDriveUrl(url: string) {
    return googleDriveUrl(url) != undefined;
}

export function normalizeGoogleDriveFileEmbed(urlStr: string, replaceMode?: 'view'|'edit'|'preview') {
    const gDUrl = googleDriveUrl(urlStr);
    if (gDUrl == undefined) return urlStr;
    let url = new URL(urlStr);
    let normalizeUrl = urlStr.split(/[?#]/)[0];
    if (replaceMode && normalizeUrl) {
        const modeRegex = /\/(view|edit|preview)$/g
        normalizeUrl = normalizeUrl.replace(modeRegex, `/${replaceMode}`);
    }
    let resourcekey = url.searchParams.get("resourcekey");
    let queryParams: any = embededQueryStrings;
    if(resourcekey) queryParams = { ...embededQueryStrings, resourcekey };
    return normalizeUrl ? qs(normalizeUrl, queryParams).toString() : urlStr;
}


// the google file selected from computer
export async function readGoogleShortCut(file : File) {
    const [isGFile, info] = isOfflineGoogleDoc(file.name);
    let docInfo : GoogleDocItem | undefined = undefined;
    if(isGFile) {
        const data = await readAsTextFile(file);
        const fileContent = JSON.parse(data);
        if (!fileContent) return [false as boolean, undefined] as const;
        docInfo = new GoogleDocItem({
            id: fileContent.doc_id,
            embedUrl: fileContent.url,
            name: info?.name,
            type: info?.type,
            mimeType: info?.mimeType
        });
    }
    return [isGFile as boolean, docInfo] as const;
}
export const GoogleDocRegex = /^(https?):\/?\/?(docs|drive)(.google.com)/i;