import { observable, action, computed, toJS, makeObservable } from "mobx";

import {DbIdentity, DefaultId, NumberDate, HexColorString, isValidIdentity} from "./types";
import type { ICategoryConsumer } from "./Activity";
import type { QualityMatrix } from "./QualityMatrix";

import type { UploadFile } from "antd/lib/upload/interface";
import { PublicDiscussionPath } from "../config";
import { stripHtml } from "../utils/html";
import { WordCountType } from "./Discussion";

export enum DiscussionMediaTypeEnum {
    AdvancedEditor = 1 << 0,
    AudioSubmission= 1 << 1,
    VideoSubmission= 1 << 2,
    ImageSubmission= 1 << 3,
}

export enum EditorType{
    SimpleEditor = 1,
    AdvancedEditor = 2
}

export enum MediaToggleType {
    NotAllowed = 0,
    Allowed = 1,
    Required = 2,
}

export class BaseDiscussion implements ICategoryConsumer {
    discussionId              : DbIdentity    = DefaultId            ;
    title                     : string        = ""                   ;
    threadId                  : string        = ""                   ;
    discussionActivityId      : DbIdentity    = DefaultId            ;
    discussionModuleId        : string        = ""                   ;
    isPostBeforeView          = false                                ;
    isOneToOne                = false                                ;
    allowStudentCanUpdatePost = true                                 ;
    allowAnonymous            = false                                ;
    approvalRequired          = false                                ;

    content               : string           = "";
    startTime            ?: NumberDate       = undefined;
    endTime              ?: NumberDate       = undefined;
    dateCutoff           ?: NumberDate       = undefined;
    createdBy            ?: DbIdentity       = undefined;
    dateCreated          ?: NumberDate       = undefined;
    dateUpdated          ?: NumberDate       = undefined;
    isGraded              : boolean          = false;
    categoryId           ?: DbIdentity       = undefined;
    isDeleted             : boolean          = false;
    color                 : HexColorString   = "#ffffff";
    maxScore              = 10;
    correctPossible      ?: number;
    weight                = 1;
    rubricScoreGuideId   ?: DbIdentity       = undefined;
    pointScoreGuideId    ?: DbIdentity       = undefined;

    qualityIds            : DbIdentity[]     = [];
    standardIds           : DbIdentity[]     = [];
    /** @deprecated  */
    isPublishGrade        = false;

    /** @description Minimum number of words (initial post) */
    minimumNumberOfWords ?: number           = undefined;
    /** @description Minimum number of words (reply to others) */
    minimumWordsForReply    ?: number           = undefined;

    /** @description Count post or reply by words / sentences */
    wordCountType    : number           = WordCountType.Word;

    /** @description If teacher sets the value, the student must post + reply to X number of other people. */
    mustReplyToXOtherPeople    ?: number           = undefined;

    selectedTags    : QualityMatrix[]  = [];

    origLink        : string           = "";
    mediaFile      ?: UploadFile       = undefined;

    /** @description Publid Id of discussion. String if public ID exists. True is public ID is pending for submitting in front-end. Otherwise False */
    publicId        : string = "";

    allowMediaType        = 7; // Default AllowAdvancedEditor, AllowAudioSubmission and AllowVideoSubmission
    requireMediaType      = 0;

    interclassActivityCode   ?: string; set_interclassActivityCode(v?:string) { this.interclassActivityCode = v; }
    interclassActivityId     ?: DbIdentity; set_interclassActivityId(v?:DbIdentity) { this.interclassActivityId = v; }

    constructor(data?:{}) {
        makeObservable(this, {
            title                         : observable        ,
            content                       : observable        ,
            startTime                     : observable        ,
            endTime                       : observable        ,
            dateCutoff                    : observable        ,
            threadId                      : observable        ,
            createdBy                     : observable        ,
            dateCreated                   : observable        ,
            dateUpdated                   : observable        ,
            isGraded                      : observable        ,
            categoryId                    : observable        ,
            isDeleted                     : observable        ,
            discussionActivityId          : observable        ,
            discussionModuleId            : observable        ,
            color                         : observable        ,
            maxScore                      : observable        ,
            correctPossible               : observable        ,
            weight                        : observable        ,
            rubricScoreGuideId            : observable        ,
            pointScoreGuideId             : observable        ,
            qualityIds                    : observable.shallow,
            standardIds                   : observable.shallow,
            isPublishGrade                : observable        ,
            isPostBeforeView              : observable        ,
            isOneToOne                    : observable        ,
            allowStudentCanUpdatePost     : observable        ,
            allowAnonymous                : observable        ,
            approvalRequired              : observable        ,
            minimumNumberOfWords          : observable        ,
            minimumWordsForReply          : observable        ,
            wordCountType                 : observable        ,
            set_mustReplyToXOtherPeople   : action.bound      ,
            mustReplyToXOtherPeople       : observable        ,
            publicId                      : observable        ,
            set_minimumWordsForReply : action.bound,
            requireMediaType              : observable        ,
            selectedTags                  : observable.shallow,
            set_title                     : action.bound      ,
            set_content                   : action.bound      ,
            set_startTime                 : action.bound      ,
            set_endTime                   : action.bound      ,
            set_dateCutoff                : action.bound      ,
            set_color                     : action.bound      ,
            set_isGraded                  : action.bound      ,
            set_isDeleted                 : action.bound      ,
            set_maxScore                  : action.bound      ,
            set_correctPossible           : action.bound      ,
            set_weight                    : action.bound      ,
            set_rubricScoreGuideId        : action.bound      ,
            set_pointScoreGuideId         : action.bound      ,
            set_isPublishGrade            : action.bound      ,
            set_isPostBeforeView          : action.bound      ,
            set_isOneToOne                : action.bound      ,
            set_allowStudentCanUpdatePost : action.bound      ,
            set_allowAnonymous            : action.bound      ,
            set_approvalRequired          : action.bound      ,
            set_categoryId                : action.bound      ,
            set_publicId                  : action.bound      ,
            set_minimumNumberOfWords      : action.bound      ,
            set_wordCountType             : action.bound      ,
            set_selectedTags              : action.bound      ,
            origLink                      : observable        ,
            mediaFile                     : observable.ref    ,
            isPublish                     : computed          ,
            isClosed                      : computed          ,
            canPersist                    : computed          ,
            allowMediaType                : observable        ,
            set_allowMediaType            : action.bound      ,
            set_requireMediaType          : action.bound      ,

            interclassActivityCode        : observable,
            set_interclassActivityCode    : action.bound,
            interclassActivityId          : observable,
            set_interclassActivityId      : action.bound,

            audioType                     : computed          ,
            videoType                     : computed          ,
            imageType                     : computed          ,
        });

        if (data != null) {
            Object.assign(this, data);
        }
        if (this.qualityIds == null) this.qualityIds = [];
    }

    toJS() {
        this.title = stripHtml(this.title);
        const {origLink, mediaFile, selectedTags, ...data} = toJS(this);
        data.qualityIds = selectedTags.map(q => q.id);
        return Object.assign(data, {shouldPublic: isValidIdentity(data.discussionId) && data.publicId !== null});
    }
    clone() { return new BaseDiscussion(this.toJS()) }

    set_title               (v : string              ) { this.title                = v; }
    set_content             (v : string              ) { this.content              = v; }

    set_startTime           (v?: NumberDate          ) { this.startTime            = v; }
    set_endTime             (v?: NumberDate          ) { this.endTime              = v; }

    set_dateCutoff          (v?: NumberDate          ) { this.dateCutoff           = v; }

    set_color                     (v : string              ) { this.color                     = v;                                                     }
    set_isGraded                  (v : boolean             ) { this.isGraded                  = v;                                                     }
    set_isDeleted                 (v : boolean             ) { this.isDeleted                 = v;                                                     }
    set_maxScore                  (v : number              ) { this.maxScore                  = v;                                                     }
    set_correctPossible           (v?: number              ) { this.correctPossible           = v;                                                     }    
    set_weight                    (v : number              ) { this.weight                    = v;                                                     }
    set_rubricScoreGuideId        (v?: DbIdentity          ) { this.rubricScoreGuideId        = v; if (v != null) this.pointScoreGuideId = undefined;  }
    set_pointScoreGuideId         (v?: DbIdentity          ) { this.pointScoreGuideId         = v; if (v != null) this.rubricScoreGuideId = undefined; }
    set_isPublishGrade            (v : boolean             ) { this.isPublishGrade            = v;                                                     }
    set_isPostBeforeView          (v : boolean             ) { this.isPostBeforeView          = v;                                                     }
    set_isOneToOne                (v : boolean             ) { this.isOneToOne                = v;                                                     }
    set_allowStudentCanUpdatePost (v : boolean             ) { this.allowStudentCanUpdatePost = v;                                                     }
    set_allowAnonymous            (v : boolean             ) { this.allowAnonymous            = v;                                                     }
    set_approvalRequired          (v : boolean             ) { this.approvalRequired          = v;                                                     }
    set_categoryId                (v?: DbIdentity          ) { this.categoryId                = v;                                                     }
    set_minimumNumberOfWords      (v?: number              ) { this.minimumNumberOfWords      = v;                                                     }
    set_minimumWordsForReply      (v?: number              ) { this.minimumWordsForReply      = v;                                                     }
    set_wordCountType             (v : number              ) { this.wordCountType             = v;                                                     }
    set_mustReplyToXOtherPeople   (v?: number              ) { this.mustReplyToXOtherPeople   = v;                                                     }
    set_discussionModuleId        (v : string              ) { this.discussionModuleId        = v;                                                     }
    set_allowMediaType            (v : number              ) { this.allowMediaType            = v;                                                     }
    set_requireMediaType          (v : number              ) { this.requireMediaType          = v;                                                     }
    set_publicId                  (v : string              ) { this.publicId                  = v;                                                     }

    set_selectedTags(v:QualityMatrix[]|((v:QualityMatrix[]) => QualityMatrix[])) {
        if (typeof v == "function") this.selectedTags = v(this.selectedTags)
        else this.selectedTags = v;
        this.qualityIds = this.selectedTags.map(q => q.id);
    }

    get isPublish() { return (this.startTime != null && this.startTime < Date.now()) }
    get isClosed() { return (this.dateCutoff != null && this.dateCutoff < Date.now()) }
    get isPublic() { return !!this.publicId }
    get canPersist() { return (this.isDeleted == false && !!this.title?.trim()) }
    get publicUrl() { return (typeof this.publicId === 'string') ? `${PublicDiscussionPath}/${String(this.publicId )}` : undefined;}

    get audioType() {
        const allowed  = (this.allowMediaType   & DiscussionMediaTypeEnum.AudioSubmission) != 0;
        const required = (this.requireMediaType & DiscussionMediaTypeEnum.AudioSubmission) != 0;
        return required ? MediaToggleType.Required : (allowed ? MediaToggleType.Allowed : MediaToggleType.NotAllowed);
    }

    get videoType() {
        const allowed  = (this.allowMediaType   & DiscussionMediaTypeEnum.VideoSubmission) != 0;
        const required = (this.requireMediaType & DiscussionMediaTypeEnum.VideoSubmission) != 0;
        return required ? MediaToggleType.Required : (allowed ? MediaToggleType.Allowed : MediaToggleType.NotAllowed);
    }

    get imageType() {
        const allowed  = (this.allowMediaType   & DiscussionMediaTypeEnum.ImageSubmission) != 0;
        const required = (this.requireMediaType & DiscussionMediaTypeEnum.ImageSubmission) != 0;
        return required ? MediaToggleType.Required : (allowed ? MediaToggleType.Allowed : MediaToggleType.NotAllowed);
    }

    static sorter = {
        title      : <T extends BaseDiscussion>(a:T, b:T) => (a.title.localeCompare(b.title)),
        dateCreated: <T extends BaseDiscussion>(a:T, b:T) => (a.dateCreated || -1) - (b.dateCreated || -1),
        dateUpdated: <T extends BaseDiscussion>(a:T, b:T) => (a.dateUpdated || -1) - (b.dateUpdated || -1),
        isGraded   : <T extends BaseDiscussion>(a:T, b:T) => (a.isGraded? 1: 0) - (b.isGraded? 1: 0),
        startTime: <T extends BaseDiscussion>(a:T, b: T) => {
            if(a.startTime == null || a.startTime <= 0) {
                if (b.startTime == null || b.startTime <= 0)
                    return 0;
                 else
                    return 1;
            } else {
                if (b.startTime == null || b.startTime <= 0)
                    return -1;
                 else
                    return ((a.startTime || 0) - (b.startTime || 0));
            }
        },
        startTimeDesc : <T extends BaseDiscussion>(a:T, b:T) => - (BaseDiscussion.sorter.startTime(a, b)),
        endTime: <T extends BaseDiscussion>(a:T, b: T) => {
            if(a.endTime == null || a.endTime <= 0) {
                if (b.endTime == null || b.endTime <= 0)
                    return 0;
                 else
                    return 1;
            } else {
                if (b.endTime == null || b.endTime <= 0)
                    return -1;
                 else
                    return ((a.endTime || 0) - (b.endTime || 0));
            }
        },
        endTimeDesc : <T extends BaseDiscussion>(a:T, b:T) => - (BaseDiscussion.sorter.endTime(a, b)),
    }
}
