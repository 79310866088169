import { pick } from "lodash-es";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { now } from "moment";
import { aFetch } from "../services/api/fetch";
import { DbIdentity, NumberDate } from "./types";

/** To group setting properties of users, when we have many user models */
export class UserExtension {
    lockoutEnabled  : boolean    = false;
    lockoutEnd     ?: NumberDate;

    constructor(data?: {}) {
        if (data != null) Object.assign(this, pick(data, ['lockoutEnabled', 'lockoutEnd']));
        makeObservable(this, {
            lockoutEnabled           : observable,
            lockoutEnd               : observable,
            lockoutStatus            : computed,
            setDisableLockOutByAdmin : action.bound,
        });
    }

    /** @description true : mean User.LockoutEnabled = true and User.LockoutEnd > now() */
    get lockoutStatus() { return this.lockoutEnabled && this.lockoutEnd != undefined && this.lockoutEnd > now() }

    async setDisableLockOutByAdmin({userId}:{userId: DbIdentity}) {
        const [err] = await aFetch<boolean>("PATCH", `/admin/user/${userId}/resetLockout`);
        if(!err) runInAction(() => { this.lockoutEnd = undefined; });
        return err;
    }
}
