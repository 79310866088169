import { observable, action, computed, makeObservable, runInAction, ObservableMap } from "mobx";
import { DefaultId, DbIdentity, DayOfWeek, UrlString, NumberDate, HtmlString } from "./types";
import moment from "moment";
import tzmoment  from "moment-timezone";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import { aFetch } from "../services/api/fetch";
import type { UploadFile } from "antd/lib/upload/interface";
import type { IErrorData } from "../services/api/AppError";
import { Group, GroupMember } from "./Group";
import type { IModule } from "./Module";
import type { IGradingSetting } from "./IGradingSetting";
import { Activity, ActivitySyncPolicy, GradeSyncPolicy } from "./Activity";
import type { ZoomAuthorization } from "./ZoomAuthorization";
import { Student } from "./Student";
import { ClassFacultyInfo } from "./Faculty";
import { User } from "./User";
import { groupBy, map, uniq } from "lodash-es";
import { Caseload } from "./Caseload";
import { SbgConference } from "./SbgConference";
import { IAeriesRecord } from "./Aeries/IAeriesRecord";

function GetCurrentTimeZone() {
    return {
        //clientTime: new Date().valueOf(),
        timeZone: tzmoment.tz.guess()
    }
}

export interface IMeetingStartRequest{
        actionType: WindowActionTypeEnum,
        conferenceServer: ConferenceServerEnum,
        appId?: DbIdentity ,
        isForceCreateNewConference: boolean
}

export enum WindowActionTypeEnum {
    SameWindow = 1,
    OpenNewWindow = 2,
}

export enum ConferenceListEnum {
    HappeningNow = 1,
    Upcoming = 2,
    Completed = 3,
    Deleted = 4,
}

export enum ConferenceServerEnum{
    BBB = "BBB",
    Zoom = "Zoom",
    Lti = "Lti",
    MicrosoftTeams = "MicrosoftTeams"
}

export enum OccurrenceEnum {
    DoesNotRepeat = 0,
    EveryWeekday = 1,
    WeeklyOn = 2,
    Custom = 3,
}

export enum ConferenceLogProcessStateEnum {
    Unknown    = 0,
    NotStart   = 1,
    Processing = 2,
    Error      = 3,
    Done       = 4,
}

export enum ConferenceDeleteType {
    ThisSchedule              = 1,
    ThisAndFollowingSchedules = 2,
    AllSchedules              = 3,
}

export enum ConferenceRepeatType {
    Day   = 1,
    Week  = 2,
    Month = 3,
}

export enum ConferenceRepeatMonthlyType {
    DayInMonth = 1, //1, 2... in a month.
    DayPositionInMonth = 2, //The third Thursday in a month.
}

export enum ConferenceType
{
    InPerson = 1,
    PhoneCall = 2,
    Video = 3,
}

export enum SharingType
{
    AllStudents = 1,
    Tags = 2,
    IndividualStudents = 3,
}

export enum MeetingWith
{
    Student = 1,
    Parents = 2,
    Teachers = 4,
}

export enum ConferenceSettingType
{
    Class = 1,
    Group = 2,
    Caseload = 3,
}

export enum ConferenceProviderType
{
    Conference = 0, //BBB, Zoom
    MicrosoftTeamConference = 1
}

export interface IConferenceStore {
    startOrJoin(actionType: WindowActionTypeEnum, conferenceLog: ConferenceLog, classId: DbIdentity): Promise<readonly [IErrorData<any> | undefined, string]>;
    groupStartOrJoin(actionType: WindowActionTypeEnum, conferenceLog: ConferenceLog, groupId: DbIdentity): Promise<readonly [IErrorData<any> | undefined, string | undefined, ConferenceLog | undefined]>;
    caseloadStartOrJoin(actionType: WindowActionTypeEnum, conferenceLog: ConferenceLog, caseloadId: DbIdentity): Promise<readonly [IErrorData<any> | undefined, string | undefined, ConferenceLog | undefined]>;
    classCheck(item: Conference, scheduledStartDate: NumberDate, classId: DbIdentity): Promise<readonly [IErrorData<any> | undefined, ConferenceLog | undefined]>;
    groupCheck(item: Conference, scheduledStartDate: NumberDate, groupId: DbIdentity): Promise<readonly [IErrorData<any> | undefined, ConferenceLog | undefined]>;
    caseloadCheck(item: Conference, scheduledStartDate: NumberDate, caseloadId: DbIdentity): Promise<readonly [IErrorData<any> | undefined, ConferenceLog | undefined]>;
}

export class ConferenceProvider {
    conferenceId: DbIdentity = DefaultId;
    serverType: ConferenceServerEnum = ConferenceServerEnum.BBB;
    logoUrl?: string;
    name?: string;
    appId: DbIdentity = DefaultId;

    constructor(data?:any) {
        makeObservable(this, {
            conferenceId: observable,
            serverType: observable,
            logoUrl: observable,
            name: observable,
            appId: observable,
        });
        if (data != null) {
            Object.assign(this, data);
        }
    }
}

export class Conference implements IGradingSetting, IAeriesRecord {
    type                      : ConferenceSettingType = ConferenceSettingType.Class;
    conferenceId              : DbIdentity = DefaultId;
    title                     = "";
    dateCreated               = Date.now();
    dateUpdated               = Date.now();
    startDate                ?: NumberDate = (function(){
        var d = new Date();
        d.setMinutes(d.getMinutes() + 30);
        return d.getTime();
    })();
    endDate                  ?: NumberDate = undefined;
    duration                 ?: number = moment().year(1970).month(0).date(1).hour(1).minute(0).valueOf();
    occurrence                : OccurrenceEnum = OccurrenceEnum.DoesNotRepeat;
    repeatsOn                ?: DayOfWeek[]    = [];
    repeatTimes              ?: number         = 1;
    repeatType               ?: ConferenceRepeatType;
    repeatMonthlyType        ?: ConferenceRepeatMonthlyType;
    endsAfter                ?: number         = undefined;
    presentationUrl          ?: UrlString      = undefined;
    webcamsOnlyForModerator   = true;
    autoStartRecording        = true;

    // TES-5299 #2582 - Teacher - Create Conference - default Students can view recording = on
    // set this flag default from True to False
    recordingOnlyForModerator = false;

    muteOnStart               = true;
    disableStudentWebcams     = true;
    disablePrivateChat        = true;
    disableNote               = true;
    isUpdateOnlyForThis       = false;
    createdBy                ?: DbIdentity   = undefined;
    classIds                 ?: DbIdentity[] = undefined;
    groupIds                 ?: DbIdentity[] = undefined;
    caseloadIds              ?: DbIdentity[] = undefined;
    timeZone                  : string = "";
    isSpecificAssign          = false;
    doNotNotifyStudents       = false;
    assigneeIds               : string[] = []; //student ids
    assignees                 : GroupMember[] = [];
    conferenceStudents       ?: ConferenceStudent[] = [];
    conferenceSharings       ?: ConferenceSharing[] = [];

    sharingType    ?: SharingType    = SharingType.AllStudents; set_sharingType    (v ?: SharingType    ) { this.sharingType    = v; }
    meetingWith     : number         = MeetingWith.Student    ; set_meetingWith    (v  : number         ) { this.meetingWith    = v; }
    conferenceType ?: ConferenceType = ConferenceType.Video   ; set_conferenceType (v ?: ConferenceType ) { this.conferenceType = v; }
    location       ?: string         = ""                     ; set_location       (v ?: string         ) { this.location       = v; }

    sharingStudentIds     : number[] = []; set_sharingStudentIds     (v : number [] ) { this.sharingStudentIds     = v; };
    sharingTagIds         : number[] = []; set_sharingTagIds         (v : number [] ) { this.sharingTagIds         = v; };
    meetingWithFacultyIds : number[] = []; set_meetingWithFacultyIds (v : number [] ) { this.meetingWithFacultyIds = v; };

    /** allow invite guest other class in district */
    inviteOtherClasses       = false       ; set_inviteOtherClasses (v ?: boolean    ) { this.inviteOtherClasses = v ?? false; if(v == false) this.set_otherClassIds();}
    /** invite guest class in district */
    otherClassIds            : DbIdentity[] = []; set_otherClassIds(v ?: DbIdentity[]) { this.otherClassIds = v ?? [] }

    // Grading Settings
    isGraded            : boolean              = false                      ; set_isGraded           (v : boolean             ) { this.isGraded           = v; }
    categoryIds         = observable.map<DbIdentity, DbIdentity|undefined>();
    standardIds        ?: SbgConference[] = [];
    maxScore            : number               = 10                         ; set_maxScore           (v : number              ) { this.maxScore           = v; }
    weight              : number               = 1                          ; set_weight             (v : number              ) { this.weight             = v; }
    isCredit            : boolean              = false                      ; set_isCredit           (v : boolean             ) { this.isCredit           = v; }
    rubricScoreGuideId ?: DbIdentity           = undefined                  ; set_rubricScoreGuideId (v?: DbIdentity          ) { this.rubricScoreGuideId = v; if (v != null) this.set_pointScoreGuideId (undefined); }
    pointScoreGuideId  ?: DbIdentity           = undefined                  ; set_pointScoreGuideId  (v?: DbIdentity          ) { this.pointScoreGuideId  = v; if (v != null) this.set_rubricScoreGuideId(undefined); }
    isFormative         : boolean              = false                      ; set_isFormative        (v : boolean             ) { this.isFormative        = v;                                                        }
    aeriesSyncPolicy                           = ActivitySyncPolicy.Sync    ;
    aeriesGradeSyncPolicy                      = GradeSyncPolicy.Sync       ; set_aeriesGradeSyncPolicy(v:boolean             ) { this.aeriesGradeSyncPolicy = v ? GradeSyncPolicy.Sync : GradeSyncPolicy.NotSync     }

    providerType        : ConferenceProviderType = ConferenceProviderType.Conference; set_providerType(v:ConferenceProviderType) { this.providerType = v }

    set_isExclude(v : boolean) { if (v) { this.weight= 0; } else { if (this.weight <= 0) this.weight = 1.; }};

    selectClassStandard(classId: DbIdentity, classStandardId: DbIdentity, selected: boolean) {
        if (selected) {
            if (!this.standardIds || !this.standardIds.some(x => x.classId == classId && x.classStandardId == classStandardId)) {
                const item = new SbgConference({classId: classId, classStandardId: classStandardId});
                this.standardIds = this.standardIds ? [...this.standardIds, item] : [item];
            }
        }
        else this.standardIds = this.standardIds?.filter(x => !(x.classId == classId && x.classStandardId == classStandardId));
    }

    constructor(data?:any) {
        makeObservable(this, {
            providerType                  : observable,
            type                          : observable,
            title                         : observable,
            dateCreated                   : observable,
            dateUpdated                   : observable,
            startDate                     : observable,
            endDate                       : observable,
            duration                      : observable,
            occurrence                    : observable,
            repeatsOn                     : observable,
            repeatTimes                   : observable,
            repeatType                    : observable,
            repeatMonthlyType             : observable,
            endsAfter                     : observable,
            presentationUrl               : observable,
            webcamsOnlyForModerator       : observable,
            autoStartRecording            : observable,
            recordingOnlyForModerator     : observable,
            muteOnStart                   : observable,
            disableStudentWebcams         : observable,
            disablePrivateChat            : observable,
            disableNote                   : observable,
            isUpdateOnlyForThis           : observable,
            createdBy                     : observable,
            classIds                      : observable.ref,
            groupIds                      : observable.ref,
            caseloadIds                   : observable.ref,
            timeZone                      : observable,
            isGraded                      : observable,
            categoryIds                   : observable.ref,
            standardIds                   : observable,
            maxScore                      : observable,
            weight                        : observable,
            isCredit                      : observable,
            isFormative                   : observable,
            rubricScoreGuideId            : observable,
            pointScoreGuideId             : observable,
            isSpecificAssign              : observable,
            assigneeIds                   : observable,
            assignees                     : observable.shallow,
            sharingStudentIds             : observable,
            sharingTagIds                 : observable,
            meetingWithFacultyIds         : observable,
            conferenceStudents            : observable.shallow,
            conferenceType                : observable,
            sharingType                   : observable,
            meetingWith                   : observable,
            location                      : observable,
            doNotNotifyStudents           : observable,
            aeriesSyncPolicy              : observable,
            aeriesGradeSyncPolicy         : observable, set_aeriesGradeSyncPolicy: action.bound,

            set_type                      : action.bound,
            set_conferenceId              : action.bound,
            set_title                     : action.bound,
            set_startDate                 : action.bound,
            set_endDate                   : action.bound,
            set_duration                  : action.bound,
            set_occurrence                : action.bound,
            set_repeatsOn                 : action.bound,
            set_repeatTimes               : action.bound,
            set_repeatType                : action.bound,
            set_repeatMonthlyType         : action.bound,
            set_endsAfter                 : action.bound,
            set_webcamsOnlyForModerator   : action.bound,
            set_presentationUrl           : action.bound,
            set_autoStartRecording        : action.bound,
            set_recordingOnlyForModerator : action.bound,
            set_muteOnStart               : action.bound,
            set_disableStudentWebcams     : action.bound,
            set_disablePrivateChat        : action.bound,
            set_disableNote               : action.bound,
            set_isUpdateOnlyForThis       : action.bound,
            set_classIds                  : action.bound,
            set_groupIds                  : action.bound,
            set_timeZone                  : action.bound,
            set_isGraded                  : action.bound,
            set_maxScore                  : action.bound,
            set_weight                    : action.bound,
            set_isCredit                  : action.bound,
            set_isFormative               : action.bound,
            set_rubricScoreGuideId        : action.bound,
            set_pointScoreGuideId         : action.bound,
            set_isSpecificAssign          : action.bound,
            set_assigneeIds               : action.bound,
            set_sharingStudentIds         : action.bound,
            set_sharingTagIds             : action.bound,
            set_meetingWithFacultyIds     : action.bound,
            set_conferenceType            : action.bound,
            set_sharingType               : action.bound,
            set_meetingWith               : action.bound,
            set_location                  : action.bound,
            selectClassStandard           : action.bound,
            set_doNotNotifyStudents       : action.bound,
            set_providerType              : action.bound,
            documentFile                  : observable.ref,
            set_documentFile              : action.bound,
            params                        : computed,
            getFileName                   : computed,

            inviteOtherClasses            : observable, set_inviteOtherClasses : action.bound,
            otherClassIds                 : observable.shallow, set_otherClassIds      : action.bound,
            allClassIds                   : computed,
            isGuestClass                  : action.bound,
        });

        if (data != null) {
            const { repeatsOn, categoryIds, standardIds, ...pData } = data;
            Object.assign(this, pData);
            (repeatsOn == null || repeatsOn.length == 0) ? this.set_repeatsOn([]): Array.isArray(repeatsOn) ? this.set_repeatsOn(repeatsOn.map((x) => Number(x))) : this.set_repeatsOn(repeatsOn.split(',').map((x) => Number(x)));

            if(this.type == ConferenceSettingType.Class) {
                (pData.conferenceStudents == null || pData.conferenceStudents.length == 0)
                ? this.set_assigneeIds([])
                : this.set_assigneeIds(pData.conferenceStudents.map(x => `${x.classId}.${x.studentId}`));
            }
            else if (this.type == ConferenceSettingType.Group) {
                (pData.conferenceSharings == null || pData.conferenceSharings.length == 0)
                ? this.set_assigneeIds([])
                : this.set_assigneeIds(pData.conferenceSharings.map(x => String(x.userId)));
            }
            else if (this.type == ConferenceSettingType.Caseload) {
                if(pData.conferenceSharings == null || pData.conferenceSharings.length == 0) {
                    this.set_sharingStudentIds([]);
                    this.set_sharingTagIds([]);
                    this.set_meetingWithFacultyIds([]);
                } else {
                    this.set_sharingStudentIds      (pData.conferenceSharings.filter(x => x.studentId     > 0).map(x => x.studentId));
                    this.set_sharingTagIds          (pData.conferenceSharings.filter(x => x.caseloadTagId > 0).map(x => x.caseloadTagId));
                    this.set_meetingWithFacultyIds  (pData.conferenceSharings.filter(x => x.facultyId     > 0).map(x => x.facultyId));
                }
            }

            this.duration = Conference.getDurationFromEndDate(this.startDate, this.endDate);
            // categoryIds from back-end is dictionary { [classId] : [categoryId} }
            if(this.isGraded && categoryIds) {
                if (categoryIds instanceof ObservableMap) {
                    this.categoryIds = categoryIds;
                } else {
                    this.categoryIds = observable.map(Object.keys(categoryIds).map(k => [+k, categoryIds[k] == null ? undefined : categoryIds[k]]));
                }
            }
            if(this.isGraded && standardIds) this.standardIds = standardIds.map(x => new SbgConference(x));
            if (this.otherClassIds.length > 0) this.set_inviteOtherClasses(true);
        }
    }

    set_type                      (v : ConferenceSettingType       ) { this.type                      =  v;                             };
    set_conferenceId              (v : number                      ) { this.conferenceId              =  v;                             };
    set_title                     (v : string                      ) { this.title                     =  v;                             };
    set_startDate                 (v?: number                      ) { this.startDate                 =  v; this.setDurationToEndDate();};
    set_endDate                   (v?: number                      ) { this.endDate                   =  v;                             };
    set_duration                  (v?: number                      ) { this.duration                  =  v; this.setDurationToEndDate();};
    set_occurrence                (v : OccurrenceEnum              ) { this.occurrence                =  v;                             };
    set_repeatsOn                 (v?: DayOfWeek[]                 ) { this.repeatsOn                 =  v;                             };
    set_repeatTimes               (v?: number                      ) { this.repeatTimes               =  v;                             };
    set_repeatType                (v?: ConferenceRepeatType        ) { this.repeatType                =  v;                             };
    set_repeatMonthlyType         (v?: ConferenceRepeatMonthlyType ) { this.repeatMonthlyType         =  v;                             };
    set_endsAfter                 (v?: number                      ) { this.endsAfter                 =  v;                             };
    set_webcamsOnlyForModerator   (v : boolean                     ) { this.webcamsOnlyForModerator   =  v;                             };
    set_presentationUrl           (v?: UrlString                   ) { this.presentationUrl           =  v;                             };
    set_autoStartRecording        (v : boolean                     ) { this.autoStartRecording        =  v;                             };
    set_recordingOnlyForModerator (v : boolean                     ) { this.recordingOnlyForModerator = !v;                             };
    set_muteOnStart               (v : boolean                     ) { this.muteOnStart               =  v;                             };
    set_disableStudentWebcams     (v : boolean                     ) { this.disableStudentWebcams     =  v;                             };
    set_disablePrivateChat        (v : boolean                     ) { this.disablePrivateChat        =  v;                             };
    set_disableNote               (v : boolean                     ) { this.disableNote               =  v;                             };
    set_isUpdateOnlyForThis       (v : boolean                     ) { this.isUpdateOnlyForThis       =  v;                             };
    set_classIds                  (v : DbIdentity[]                ) { this.classIds                  =  v;                             };
    set_groupIds                  (v : DbIdentity[]                ) { this.groupIds                  =  v;                             };
    set_timeZone                  (v : string                      ) { this.timeZone                  =  v;                             };
    set_isSpecificAssign          (v : boolean                     ) { this.isSpecificAssign          =  v;                             };
    set_assigneeIds               (v : string    []                ) { this.assigneeIds               =  v;                             };
    set_doNotNotifyStudents       (v : boolean                     ) { this.doNotNotifyStudents       =  v;                             };

    static getDurationFromEndDate(startDate ?: number, endDate ?: number) {
        if (startDate == null || endDate == null) return undefined;
        var start=moment(startDate);
        var end=moment(endDate);
        var duration = moment.duration(end.diff(start));
        return moment().year(1970).month(0).date(1).hour(0).minute(duration.asMinutes()).startOf('minute').valueOf();
    }

    setDurationToEndDate() {
        if (this.duration == null || this.startDate == null) {
            this.set_endDate(undefined);
            return;
        }

        const hour = moment(this.duration).hours();
        const minute = moment(this.duration).minutes();
        const minutes = (hour * 60) + minute;
        const endDate = moment(this.startDate).add(minutes,'minutes').toDate();
        this.set_endDate(endDate.valueOf());
    }

    documentFile?:UploadFile = undefined;
    async set_documentFile(v:UploadFile|undefined) {
        if (v != null) {
            this.presentationUrl = undefined;
        }
        this.documentFile = v;
    };

    /** include your class conferences or your class is a guest of other conferences */
    get allClassIds () { return uniq((this.classIds??[]).concat(this.otherClassIds)); }
    isGuestClass(classId ?: DbIdentity) { return classId != undefined && this.otherClassIds.some(c => c == classId); }

    get params() { return ({conferenceId:String(this.conferenceId)}) }

    get getFileName() {
        return (this.documentFile ? this.documentFile.name : (this.presentationUrl ? this.presentationUrl.split('/').pop() : ''))
    }

    get mClassToConferenceStandards() {
        return new Map(map(groupBy(this.standardIds, x => x.classId), x => [x[0]?.classId, x.map(y => y.classStandardId)]))
    }

    toJS() {
        // parse observable map to dictionary
        const categoryIds = this.categoryIds.toJSON().map(([classId, categoryId]) => ({classId, categoryId})).reduce((map, obj) => {  map[obj.classId] = obj.categoryId; return map; }, {})

        return ({
            providerType            :  this.providerType,
            type                    :  this.type,
            conferenceId            :  this.conferenceId,
            title                   :  this.title,
            startDate               :  this.startDate,
            endDate                 :  this.endDate,
            occurrence              :  this.occurrence,
            repeatsOn               : (this.repeatsOn == null || this.repeatsOn.length < 1) ? null : this.repeatsOn.join(','),
            repeatTimes             :  this.repeatTimes,
            repeatType              :  this.repeatType,
            repeatMonthlyType       :  this.repeatMonthlyType,
            endsAfter               :  this.endsAfter,
            webcamsOnlyForModerator :  this.webcamsOnlyForModerator,
            presentationUrl         :  this.presentationUrl,
            autoStartRecording      :  this.autoStartRecording,
            recordingOnlyForModerator: this.recordingOnlyForModerator ?? false,
            // allowOutsideGuests      :  this.allowOutsideGuests,
            muteOnStart             :  this.muteOnStart,
            disableStudentWebcams   :  this.disableStudentWebcams,
            disablePrivateChat      :  this.disablePrivateChat,
            disableNote             :  this.disableNote,
            classIds                :  this.classIds,
            otherClassIds           :  this.otherClassIds,
            groupIds                :  this.groupIds,
            caseloadIds             :  this.caseloadIds,
            timeZone                :  GetCurrentTimeZone().timeZone,
            isGraded                :  this.isGraded,
            categoryIds             :  categoryIds,
            standardIds             :  this.standardIds?.map(x => x.toJS()),
            maxScore                :  this.maxScore,
            weight                  :  this.weight,
            isCredit                :  this.isCredit,
            isFormative             :  this.isFormative,
            rubricScoreGuideId      :  this.rubricScoreGuideId,
            pointScoreGuideId       :  this.pointScoreGuideId,
            isSpecificAssign        :  this.isSpecificAssign,
            assigneeIds             :  (this.assigneeIds == null || this.assigneeIds.length < 1) ? null : this.assigneeIds.join(','),
            doNotNotifyStudents     :  this.doNotNotifyStudents,
            aeriesSyncPolicy        :  this.aeriesSyncPolicy,
            aeriesGradeSyncPolicy   :  this.aeriesGradeSyncPolicy,

            sharingType             :  this.sharingType,
            sharingStudentIds       :  this.sharingType != SharingType.IndividualStudents ? [] : this.sharingStudentIds,
            sharingTagIds           :  this.sharingType != SharingType.Tags? [] :this.sharingTagIds,
            meetingWith             :  this.meetingWith,
            meetingWithFacultyIds   :  (this.meetingWith & MeetingWith.Teachers) > 0 ? this.meetingWithFacultyIds : [],
            conferenceType          :  this.conferenceType,
            location                :  this.conferenceType != ConferenceType.InPerson ? "" : this.location,
        });
    }

    clone() { return new Conference(this.toJS()) }

    static sorter = {
        dateCreatedDes : <T extends Conference>(a: T, b: T) => ((b.dateCreated || 0) -        (a.dateCreated || 0)),
        startDate      : <T extends Conference>(a: T, b: T) => ((a.startDate   || 0) -        (b.startDate   || 0)),
        endDate        : <T extends Conference>(a: T, b: T) => ((a.endDate     || 0) -        (b.endDate     || 0)),
        title          : <T extends Conference>(a: T, b: T) => ( a.title       .localeCompare (b.title           )),
    };

    static async fetchZoomAuthorization(){
        const [err, data] = await aFetch<ZoomAuthorization>("GET", `/conference/zoom/authorization`);
        return [err, (err ? null : data)] as const;
    }

    static async updateZoomAuthorizationCode(authorizedCode:string){
        const [err, data] = await aFetch<ZoomAuthorization>("POST", `/conference/zoom/updateAuthorizationCode/${authorizedCode}`);
        return [err, (err ? null : data)] as const;
    }

    static async ZoomUnAuthorization(){
        const [err, data] = await aFetch<ZoomAuthorization>("POST", `/conference/zoom/UnAuthorization/`);
        return [err, (err ? null : data)] as const;
    }

    //#region Faculty
    async save(facultyId:DbIdentity, classId:DbIdentity) {
        if (this.conferenceId < 1) {
            const [err, data] = await aFetch<GeneralDto>("POST", `/faculty/${facultyId}/class/${classId}/conference`, this.toJS());
            return [err, (err ? undefined : new ConferenceLog(data))!] as const;
        }
        const [err, data] = await aFetch<GeneralDto>("PUT", `/faculty/${facultyId}/class/${classId}/conference/${this.conferenceId}`, this.toJS());
        return [err, (err ? undefined : new ConferenceLog(data))!] as const;
    }

    async deleteAsGuest(facultyId:DbIdentity, classId:DbIdentity) {
        const err = await aFetch<{}>("DELETE", `/faculty/${facultyId}/class/${classId}/guestConference/${this.conferenceId}`);
        return err;
    }

    async delete(facultyId:DbIdentity, classId:DbIdentity) {
        const err = await aFetch<{}>("DELETE", `/faculty/${facultyId}/class/${classId}/conference/${this.conferenceId}`);
        return err;
    }

    async deleteUpcoming(facultyId:DbIdentity, classId:DbIdentity) {
        const err = await aFetch<{}>("DELETE", `/faculty/${facultyId}/class/${classId}/conference/${this.conferenceId}/upcoming`);
        return err;
    }

    async deleteAllUpcoming(facultyId:DbIdentity, classId:DbIdentity) {
        const err = await aFetch<{}>("DELETE", `/faculty/${facultyId}/class/${classId}/conference/${this.conferenceId}/allUpcoming`);
        return err;
    }

    static async fetchConferencesOfClassForModuleAsFaculty({facultyId, classId, signal}:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/class/${classId}/conferenceList`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new Conference(x)))] as const;
    }

    static async fetchConferencesOfClassAsFaculty({facultyId, classId, signal}:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/class/${classId}/conferences`, { timeZone: GetCurrentTimeZone().timeZone }, { signal });
        return [err, (err ? [] : xs.map(x => new ConferenceLog(x)))] as const;
    }

    /**
     * Fetchs conference logs of class as faculty.
     * @param facultyId Requesting user ID
     * @param classId Class ID of conference logs
     * @returns [Error if any | Array of General View Model of conference logs]
     */
    static async fetchConferenceLogsOfClassAsFaculty({facultyId, classId, signal}:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/class/${classId}/conferenceLogs`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchConferenceAsFaculty({facultyId, conferenceId, signal}:{facultyId:DbIdentity, conferenceId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}>("GET" , `/faculty/${facultyId}/conference/${conferenceId}`, undefined, { signal });
        return [err, (err ? undefined : new Conference(data))!] as const;
    }

    static async fetchConferenceForCheckingAsFaculty({facultyId, classId, conferenceId, scheduledStartDate, signal}:{facultyId:DbIdentity, classId:DbIdentity, conferenceId:DbIdentity, scheduledStartDate: Number, signal?: AbortSignal}) {
        const [err, data] = await aFetch<ConferenceLog>("GET" , `/faculty/${facultyId}/class/${classId}/conference/${conferenceId}/check`, { scheduledStartDate, timeZone: GetCurrentTimeZone().timeZone }, { signal });
        return [err, (err ? undefined : new ConferenceLog(data))] as const;
    }
    //#endregion

    //#region groups
    async saveForGroup(groupId:DbIdentity) {
        if (this.conferenceId < 1) {
            const [err, data] = await aFetch<GeneralDto>("POST", `/group/${groupId}/conference`, this.toJS());
            return [err, (err ? undefined : new ConferenceLog(data))!] as const;
        }
        const [err, data] = await aFetch<GeneralDto>("PUT", `/group/${groupId}/conference/${this.conferenceId}`, this.toJS());
        return [err, (err ? undefined : new ConferenceLog(data))!] as const;
    }

    async deleteForGroup(groupId:DbIdentity) {
        const err = await aFetch<{}>("DELETE", `/group/${groupId}/conference/${this.conferenceId}`);
        return err;
    }

    async deleteUpcomingForGroup(groupId:DbIdentity) {
        const err = await aFetch<{}>("DELETE", `/group/${groupId}/conference/${this.conferenceId}/upcoming`);
        return err;
    }

    async deleteAllUpcomingForGroup(groupId:DbIdentity) {
        const err = await aFetch<{}>("DELETE", `/group/${groupId}/conference/${this.conferenceId}/allUpcoming`);
        return err;
    }

    async addGroupReminder({groupId}:{groupId:DbIdentity}) {
        const [err, x] = await aFetch<{}>("POST", `/group/${groupId}/conference/${this.conferenceId}/addReminder`);
        return [err, x] as const;
    }

    async getGroupReminder({groupId}:{groupId:DbIdentity}) {
        const [err, x] = await aFetch<string>("GET", `/group/${groupId}/conference/${this.conferenceId}/getReminder`);
        return [err, x] as const;
    }

    static async fetchGroupConferences({groupId, signal}:{groupId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/group/${groupId}/conferences`, { timeZone: GetCurrentTimeZone().timeZone }, { signal });
        return [err, (err ? [] : xs.map(x => new ConferenceLog(x)))] as const;
    }

    static async fetchGroupConferenceLogs({groupId, signal}:{ groupId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/group/${groupId}/conferenceLogs`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchGroupConference({groupId, conferenceId, signal}:{groupId:DbIdentity, conferenceId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}>("GET" , `/group/${groupId}/conference/${conferenceId}`, undefined, { signal });
        return [err, (err ? undefined : new Conference(data))!] as const;
    }

    async fetchGroupConferenceAssignees({groupId, signal}:{groupId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET" , `/group/${groupId}/conference/${this.conferenceId}/assignees`, undefined, { signal });
        if (!err) runInAction(() => {
            this.assignees = xs.map(x => new GroupMember(x));
            this.assigneeIds = this.assignees.map(x => String(x.userId));
        });
        return err;
    }
    //#endregion

    //#region caseload
    async saveForCaseload({facultyId, caseloadId}:{facultyId:DbIdentity, caseloadId:DbIdentity}) {
        if (this.conferenceId < 1) {
            const [err, data] = await aFetch<GeneralDto>("POST", `/faculty/${facultyId}/caseload/${caseloadId}/conference`, this.toJS());
            return [err, (err ? undefined : new ConferenceLog(data))!] as const;
        }
        const [err, data] = await aFetch<GeneralDto>("PUT", `/faculty/${facultyId}/caseload/${caseloadId}/conference/${this.conferenceId}`, this.toJS());
        return [err, (err ? undefined : new ConferenceLog(data))!] as const;
    }

    async deleteForCaseload({facultyId, caseloadId}:{facultyId:DbIdentity, caseloadId:DbIdentity}) {
        const err = await aFetch<{}>("DELETE", `/faculty/${facultyId}/caseload/${caseloadId}/conference/${this.conferenceId}`);
        return err;
    }

    async deleteUpcomingForCaseload({facultyId, caseloadId}:{facultyId:DbIdentity, caseloadId:DbIdentity}) {
        const err = await aFetch<{}>("DELETE", `/faculty/${facultyId}/caseload/${caseloadId}/conference/${this.conferenceId}/upcoming`);
        return err;
    }

    async deleteAllUpcomingForCaseload({facultyId, caseloadId}:{facultyId:DbIdentity, caseloadId:DbIdentity}) {
        const err = await aFetch<{}>("DELETE", `/faculty/${facultyId}/caseload/${caseloadId}/conference/${this.conferenceId}/allUpcoming`);
        return err;
    }

    async addCaseloadReminder({facultyId, caseloadId}:{facultyId:DbIdentity, caseloadId:DbIdentity}) {
        const [err, x] = await aFetch<{}>("POST", `/faculty/${facultyId}/caseload/${caseloadId}/conference/${this.conferenceId}/addReminder`);
        return [err, x] as const;
    }

    async getCaseloadReminder({studentId, caseloadId}:{studentId:DbIdentity, caseloadId:DbIdentity}) {
        const [err, x] = await aFetch<string>("GET", `/student/${studentId}/caseload/${caseloadId}/conference/${this.conferenceId}/getReminder`);
        return [err, x] as const;
    }

    static async fetchCaseloadConferences({facultyId, caseloadId, signal}:{facultyId:DbIdentity, caseloadId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/caseload/${caseloadId}/conferences`, { timeZone: GetCurrentTimeZone().timeZone }, { signal });
        return [err, (err ? [] : xs.map(x => new ConferenceLog(x)))] as const;
    }

    static async fetchCaseloadConferenceLogs({facultyId, caseloadId, signal}:{ facultyId:DbIdentity, caseloadId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/caseload/${caseloadId}/conferenceLogs`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchCaseloadConference({facultyId, caseloadId, conferenceId, signal}:{facultyId:DbIdentity, caseloadId:DbIdentity, conferenceId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}>("GET" , `/faculty/${facultyId}/caseload/${caseloadId}/conference/${conferenceId}`, undefined, { signal });
        return [err, (err ? undefined : new Conference(data))!] as const;
    }
    //#endregion

    //#region Student API
    async addReminder({studentId, classId}:{studentId:DbIdentity, classId:DbIdentity}) {
        const [err, x] = await aFetch<{}>("POST", `/student/${studentId}/class/${classId}/conference/${this.conferenceId}/addReminder`);
        return [err, x] as const;
    }

    async getReminder(studentId:DbIdentity, classId:DbIdentity) {
        const [err, x] = await aFetch<string>("GET", `/student/${studentId}/class/${classId}/conference/${this.conferenceId}/getReminder`);
        return [err, x] as const;
    }

    static async fetchConferencesOfClassAsStudent({studentId, classId, signal}:{studentId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/student/${studentId}/class/${classId}/conferences`, { timeZone: GetCurrentTimeZone().timeZone }, { signal });
        return [err, (err ? [] : xs.map(x => new ConferenceLog(x)))] as const;
    }
    static async fetchConferencesOfClassAsParent({studentId, classId, signal}:{studentId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/parent/student/${studentId}/class/${classId}/conferences`, { timeZone: GetCurrentTimeZone().timeZone }, { signal });
        return [err, (err ? [] : xs.map(x => new ConferenceLog(x)))] as const;
    }

    static async fetchConferenceLogsOfClassAsStudent({studentId, classId, signal}:{studentId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/student/${studentId}/class/${classId}/conferenceLogs`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchConferenceLogsOfClassAsParent({studentId, classId, signal}:{studentId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/parent/student/${studentId}/class/${classId}/conferenceLogs`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    // calling student/conference/GetAllConferenceLogs api
    static async fetchConferencesAsStudent({ studentId, signal }:{studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<{general :GeneralDto, conferenceLogs:{}[], groups:{}[], caseloads: {}[]}>("GET", `/student/${studentId}/conferences`, {timeZone: GetCurrentTimeZone().timeZone}, { signal });
        return [err,
            (err ? undefined : parseGeneralViewModel(dto.general))!,
            (err ? []        : dto.conferenceLogs.map(x => new ConferenceLog(x))),
            (err ? []        : dto.groups.map(x => new Group(x))),
            (err ? []        : dto.caseloads.map(x => new Caseload(x))),
        ] as const;
    }

    // calling student/conference/GetDashboardConferenceLogs api
    static async fetchDashboardConferencesAsStudent({ studentId, signal }:{studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<{general :GeneralDto, conferenceLogs:{}[], groups:{}[], caseloads: {}[]}>("GET", `/student/${studentId}/conferences/dashboard`, {timeZone: GetCurrentTimeZone().timeZone}, { signal });
        return [err,
            (err ? undefined : parseGeneralViewModel(dto.general))!,
            (err ? []        : dto.conferenceLogs.map(x => new ConferenceLog(x))),
            (err ? []        : dto.groups.map(x => new Group(x))),
            (err ? []        : dto.caseloads.map(x => new Caseload(x))),
        ] as const;
    }

    static async fetchConferencesAsFaculty({ facultyId, startDate, endDate, signal, classIds }:{facultyId:DbIdentity, startDate: NumberDate, endDate: NumberDate, signal?: AbortSignal, classIds:DbIdentity[]}) {
        const [err, dto] = await aFetch<{item1:GeneralDto, item2:{}[], item3:{}[], item4:{}[], item5:{}[]}>("POST", `/faculty/${facultyId}/conferences`, {startDate: startDate, endDate: endDate, timeZone: GetCurrentTimeZone().timeZone, classIds}, { signal });
        return [err,
            (err ? undefined : parseGeneralViewModel(dto.item1))!,
            (err ? []        : dto.item2.map(x => new ConferenceLog(x))),
            (err ? []        : dto.item3.map(x => new Group(x))),
            (err ? []        : dto.item4.map(x => new ClassFacultyInfo(x))),
            (err ? []        : dto.item5.map(x => new Caseload(x))),
        ] as const;
    }

    static async checkConferencesAsFaculty({ facultyId, signal, classIds }:{facultyId:DbIdentity, signal?: AbortSignal, classIds: DbIdentity[]}) {
        const [err, dto] = await aFetch<{item1:GeneralDto, item2:{}[], item3:{}[]}>("POST", `/faculty/${facultyId}/conferences`, {timeZone: GetCurrentTimeZone().timeZone, classIds}, { signal });
        return [err,
            (err ? undefined : parseGeneralViewModel(dto.item1))!,
            (err ? []        : dto.item2.map(x => new ConferenceLog(x))),
            (err ? []        : dto.item3.map(x => new Group(x))),
        ] as const;
    }

    static async fetchHappeningNowConferencesAsStudent({studentId, signal}:{studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<any>("GET", `/student/${studentId}/conferences/happeningNow`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    async fetchConferenceForCheckingAsStudent({studentId, classId, conferenceId, scheduledStartDate, signal}:{studentId:DbIdentity, classId:DbIdentity, conferenceId:DbIdentity, scheduledStartDate: Number, signal?: AbortSignal}) {
        const [err, data] = await aFetch<ConferenceLog>("GET" , `/student/${studentId}/class/${classId}/conference/${conferenceId}/check`, { scheduledStartDate, timeZone: GetCurrentTimeZone().timeZone }, { signal });
        return [err, (err ? undefined : new ConferenceLog(data))] as const;
    }

    async fetchGroupConferenceForChecking({groupId, conferenceId, scheduledStartDate, signal}:{groupId:DbIdentity, conferenceId:DbIdentity, scheduledStartDate: NumberDate, signal?: AbortSignal}) {
        const [err, data] = await aFetch<ConferenceLog>("GET" , `/group/${groupId}/conference/${conferenceId}/check`, { scheduledStartDate, timeZone: GetCurrentTimeZone().timeZone }, { signal });
        return [err, (err ? undefined : new ConferenceLog(data))] as const;
    }

    async fetchCaseloadConferenceForChecking({caseloadId, conferenceId, scheduledStartDate, signal}:{caseloadId:DbIdentity, conferenceId:DbIdentity, scheduledStartDate: NumberDate, signal?: AbortSignal}) {
        const [err, data] = await aFetch<ConferenceLog>("GET" , `/caseload/${caseloadId}/conference/${conferenceId}/check`, { scheduledStartDate, timeZone: GetCurrentTimeZone().timeZone }, { signal });
        return [err, (err ? undefined : new ConferenceLog(data))] as const;
    }

    async fetchCaseloadConferenceForCheckingAsStudent({studentId, caseloadId, conferenceId, scheduledStartDate, signal}:{studentId: DbIdentity, caseloadId:DbIdentity, conferenceId:DbIdentity, scheduledStartDate: NumberDate, signal?: AbortSignal}) {
        const [err, data] = await aFetch<ConferenceLog>("GET" , `/student/${studentId}/caseload/${caseloadId}/conference/${conferenceId}/check`, { scheduledStartDate, timeZone: GetCurrentTimeZone().timeZone }, { signal });
        return [err, (err ? undefined : new ConferenceLog(data))] as const;
    }

    static async fetchCaseloadConferencesAsStudent({studentId, caseloadId, signal}:{studentId: DbIdentity, caseloadId: DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/student/${studentId}/caseload/${caseloadId}/conferences`, { timeZone: GetCurrentTimeZone().timeZone }, { signal });
        return [err, (err ? [] : xs.map(x => new ConferenceLog(x)))] as const;
    }

    static async fetchCaseloadConferenceLogsAsStudent({studentId, caseloadId, signal}:{ studentId: DbIdentity, caseloadId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/student/${studentId}/caseload/${caseloadId}/conferenceLogs`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }
    //#endregion

    //#region PreviewStudent API
    static async fetchConferencesOfClassAsPreviewStudent({facultyId, classId, signal}:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/previewStudent/class/${classId}/conferences`, { timeZone: GetCurrentTimeZone().timeZone }, { signal });
        return [err, (err ? [] : xs.map(x => new ConferenceLog(x)))] as const;
    }

    static async fetchConferenceLogsOfClassAsPreviewStudent({facultyId, classId, signal}:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/previewStudent/class/${classId}/conferenceLogs`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchConferencesAsPreviewStudent({facultyId, signal}:{facultyId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<any>("GET", `/faculty/${facultyId}/previewStudent/conferences`, { timeZone: GetCurrentTimeZone().timeZone }, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto.item1))!, (err ? [] : dto.item2.map(x => new ConferenceLog(x)))] as const;
    }
    //#endregion

    //#region public API
    static async fetchPublicConference(publicMeetingId:string, signal?: AbortSignal) {
        const [err, data] = await aFetch<{}>("GET" , `/public/conference/${publicMeetingId}`, { timeZone: GetCurrentTimeZone().timeZone }, { signal })
        return [err, (err ? undefined : new PublicConferenceResponse(data))!] as const;
    }
    //#endregion

    //#region external users

    static async fetchExternalUsersByClass({facultyId, classId, signal}:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET" , `/faculty/${facultyId}/class/${classId}/externalUsers`, undefined, { signal });
        return [err, (err ? undefined : xs.map(x => new ConferenceExternalUser(x)))] as const;
    }

    static async fetchExternalUsersByGroup({groupId, signal}:{groupId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET" , `/group/${groupId}/externalUsers`, undefined, { signal });
        return [err, (err ? undefined : xs.map(x => new ConferenceExternalUser(x)))] as const;
    }

    static async fetchExternalUsersByCaseload({facultyId, caseloadId, signal}:{facultyId:DbIdentity, caseloadId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET" , `/faculty/${facultyId}/caseload/${caseloadId}/externalUsers`, undefined, { signal });
        return [err, (err ? undefined : xs.map(x => new ConferenceExternalUser(x)))] as const;
    }

    static async fetchConferenceExternalUsers({facultyId, classId, conferenceId, signal}:{facultyId:DbIdentity, classId:DbIdentity, conferenceId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET" , `/faculty/${facultyId}/class/${classId}/conference/${conferenceId}/externalUsers`, undefined, { signal });
        return [err, (err ? undefined : xs.map(x => new ConferenceExternalUser(x)))] as const;
    }

    static async fetchGroupConferenceExternalUsers({groupId, conferenceId, signal}:{groupId:DbIdentity, conferenceId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET" , `/group/${groupId}/conference/${conferenceId}/externalUsers`, undefined, { signal });
        return [err, (err ? undefined : xs.map(x => new ConferenceExternalUser(x)))] as const;
    }

    static async fetchCaseloadConferenceExternalUsers({facultyId, caseloadId, conferenceId, signal}:{facultyId:DbIdentity, caseloadId:DbIdentity, conferenceId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET" , `/faculty/${facultyId}/caseload/${caseloadId}/conference/${conferenceId}/externalUsers`, undefined, { signal });
        return [err, (err ? undefined : xs.map(x => new ConferenceExternalUser(x)))] as const;
    }

    static async saveExternalUsers({facultyId, classId, conferenceId, externalList}:{facultyId:DbIdentity, classId:DbIdentity, conferenceId:DbIdentity, externalList: ConferenceExternalUser[]}) {
        const [err, xs] = await aFetch<{}[]>("PUT" , `/faculty/${facultyId}/class/${classId}/conference/${conferenceId}/externalUsers`, externalList.map(x => x.toJS()));
        return [err, (err ? undefined : xs.map(x => new ConferenceExternalUser(x)))] as const;
    }

    static async saveGroupExternalUsers({groupId, conferenceId, externalList}:{groupId:DbIdentity, conferenceId:DbIdentity, externalList: ConferenceExternalUser[]}) {
        const [err, xs] = await aFetch<{}[]>("PUT" , `/group/${groupId}/conference/${conferenceId}/externalUsers`, externalList.map(x => x.toJS()));
        return [err, (err ? undefined : xs.map(x => new ConferenceExternalUser(x)))] as const;
    }

    static async saveCaseloadExternalUsers({facultyId, caseloadId, conferenceId, externalList}:{facultyId:DbIdentity, caseloadId:DbIdentity, conferenceId:DbIdentity, externalList: ConferenceExternalUser[]}) {
        const [err, xs] = await aFetch<{}[]>("PUT" , `/faculty/${facultyId}/caseload/${caseloadId}/conference/${conferenceId}/externalUsers`, externalList.map(x => x.toJS()));
        return [err, (err ? undefined : xs.map(x => new ConferenceExternalUser(x)))] as const;
    }
    //#endregion
}

export class ConferenceModule {
    conferenceId   : DbIdentity = DefaultId;
    moduleId      ?: string;
    module        ?: IModule = { moduleId: "", moduleParentId: "",  moduleIndex: 0};
    conference     = new Conference();
    classId        : DbIdentity = DefaultId;
    caseloadId     : DbIdentity = DefaultId;

    constructor(data?:any) {
        makeObservable(this, {
            conferenceId      : observable ,
            moduleId          : observable ,
            module            : observable ,
            conference        : observable.ref,
            classId           : observable ,
            caseloadId        : observable ,
            set_conferenceId  : action    ,
            set_moduleId      : action    ,
        });
        if (data != null) {
            Object.assign(this, data);
        }
    }

    get params() { return ({ classId: String(this.classId), moduleId:(this.moduleId || ''), conferenceId:String(this.conferenceId) }) }

    set_conferenceId   = (v : number ) => { this.conferenceId   = v; }
    set_moduleId       = (v?: string ) => { this.moduleId       = v; }

    toJS() {
        return ({
            conferenceId   : this.conferenceId  ,
            moduleId       : this.moduleId      ,
            module         : this.module,
            classId        : this.classId       ,
        });
    }

    clone() { return new ConferenceModule(this.toJS()) }

    async add(facultyId:DbIdentity, classId:DbIdentity) {
        const [err, data] = await aFetch<ConferenceModule>("POST", `/faculty/${facultyId}/class/${classId}/conference/${this.conferenceId}/moduleAdd`, this.toJS());
        return [err, (err ? undefined : new ConferenceModule(data))!] as const;
    }
    async update(facultyId:DbIdentity, classId:DbIdentity) {
        const [err, data] = await aFetch<ConferenceModule>("PUT", `/faculty/${facultyId}/class/${classId}/conference/${this.conferenceId}/moduleUpdate`, this.toJS());
        return [err, (err ? undefined : new ConferenceModule(data))!] as const;
    }
    async remove(facultyId:DbIdentity, classId:DbIdentity) {
        const [err, data] = await aFetch<ConferenceModule>("DELETE", `/faculty/${facultyId}/class/${classId}/conference/${this.conferenceId}/module/${this.moduleId}`);
        return [err, (err ? undefined : new ConferenceModule(data))!] as const;
    }

    static async fetchModuleConferenceAsFaculty({facultyId, classId, signal}:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/class/${classId}/moduleConferences`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchModuleConferenceAsStudent({studentId, classId, signal}:{studentId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/student/${studentId}/class/${classId}/moduleConferences`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchModuleConferenceAsParent({studentId, classId, signal}:{studentId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/parent/${studentId}/class/${classId}/moduleConferences`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchModuleConferenceForPublicClass({publicLinkId, signal}:{publicLinkId:string, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/public/publicClass/${publicLinkId}/moduleConferences`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchModuleConferenceAsPreviewStudent({facultyId, classId, signal}:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/previewStudent/class/${classId}/moduleConferences`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }
}

export class ConferenceCaseloadModule {
    conferenceId   : DbIdentity = DefaultId;
    moduleId      ?: string;
    caseloadModule?: IModule = { moduleId: "", moduleParentId: "",  moduleIndex: 0};
    conference     = new Conference();
    caseloadId     : DbIdentity = DefaultId;

    constructor(data?:any) {
        makeObservable(this, {
            conferenceId      : observable ,
            moduleId          : observable ,
            caseloadModule    : observable ,
            conference        : observable.ref,
            caseloadId        : observable ,
            set_conferenceId  : action    ,
            set_moduleId      : action    ,
        });
        if (data != null) {
            Object.assign(this, data);
        }
    }

    get params() { return ({ caseloadId: String(this.caseloadId), moduleId:(this.moduleId || ''), conferenceId:String(this.conferenceId) }) }

    set_conferenceId   = (v : number ) => { this.conferenceId   = v; }
    set_moduleId       = (v?: string ) => { this.moduleId       = v; }

    toJS() {
        return ({
            conferenceId: this.conferenceId  ,
            moduleId    : this.moduleId      ,
            module      : this.caseloadModule,
            caseloadId  : this.caseloadId    ,
        });
    }


    async add(facultyId:DbIdentity, caseloadId:DbIdentity) {
        const [err, data] = await aFetch<ConferenceCaseloadModule>("POST", `/faculty/${facultyId}/caseload/${caseloadId}/conference/${this.conferenceId}/caseloadModuleAdd`, this.toJS());
        return [err, (err ? undefined : new ConferenceCaseloadModule(data))!] as const;
    }

    static async fetch(facultyId:DbIdentity, caseloadId:DbIdentity) {
        const [err, data] = await aFetch<{conference: {}[], conferenceCaseloadModule: {}[]}>("GET", `/faculty/${facultyId}/caseload/${caseloadId}/moduleConferences`);
        const conferences = err ? [] : data.conference.map(c => new Conference(c));
        const conferenceCaseloadModules = err ? [] : data.conferenceCaseloadModule.map(c => new ConferenceCaseloadModule(c));
        return [err, {conferences, conferenceCaseloadModules}] as const;
    }
}

export class ConferenceLog {

    conferenceLogId           : DbIdentity = DefaultId;
    conferenceId              : DbIdentity = DefaultId;
    dateCreated               = Date.now();
    dateUpdated               = Date.now();
    scheduledStartDate       ?: number = undefined;
    scheduledEndDate         ?: number = undefined;
    scheduledStartDateDisplay?: number = undefined;
    scheduledEndDateDisplay  ?: number = undefined;
    actualStartDate          ?: number = undefined;
    actualEndDate            ?: number = undefined;
    duration                 ?: number = undefined;
    externalID               ?: string = undefined;
    publicMeetingId          ?: string = undefined;
    dateEventsProcessed      ?: number = undefined;
    eventProcessState        ?: ConferenceLogProcessStateEnum;
    conference                = new Conference();
    className                ?: string  = undefined;
    recordingOnlyForModerator?: boolean = true;
    isDeleted                ?: boolean = false;
    shouldDisable            ?: boolean = false;
    hostServer               ?: string  = undefined;
    notes                    ?: string  = ""       ;
    originalNotes            ?: string  = ""       ;
    openNoteModal             : boolean = false    ;

    constructor(data?:any) {
        makeObservable(this, {
            dateCreated              : observable,
            dateUpdated              : observable,
            scheduledStartDate       : observable,
            scheduledEndDate         : observable,
            scheduledStartDateDisplay: observable,
            scheduledEndDateDisplay  : observable,
            actualStartDate          : observable,
            actualEndDate            : observable,
            duration                 : observable,
            externalID               : observable,
            publicMeetingId          : observable,
            dateEventsProcessed      : observable,
            eventProcessState        : observable,
            conference               : observable.ref,
            className                : observable,
            recordingOnlyForModerator: observable,
            isDeleted                : observable,
            shouldDisable            : observable,
            notes                    : observable,
            originalNotes            : observable,
            openNoteModal            : observable,
            getOccurenceDescription  : computed,
            set_conferenceLogId      : action.bound,
            set_conferenceId         : action.bound,
            set_scheduledStartDate   : action.bound,
            set_scheduledEndDate     : action.bound,
            set_actualStartDate      : action.bound,
            set_actualEndDate        : action.bound,
            set_duration             : action.bound,
            set_externalID           : action.bound,
            set_publicMeetingId      : action.bound,
            set_className            : action.bound,
            set_shouldDisable        : action.bound,
            set_notes                : action.bound,
            set_originalNotes        : action.bound,
            set_openNoteModal        : action.bound,
            params                   : computed,
            getScheduledStartDate    : computed,
            getScheduledEndDate      : computed,
            canShare                 : computed,
            isHappeningNow           : computed,
            isUpcomming              : computed,
            hostServer               : observable,
        });

        if (data != null) {
            const {conference, ...pData} = data;
            Object.assign(this, pData);
            if (conference) this.conference = new Conference(conference);
            this.getDurationFromEndDate();
        }
    }

    /** Dertermine a conference log is a virtual one. */
    get isVirtual() { return this.conferenceLogId < 0 && this.conferenceId === -(this.conferenceLogId)}

    get getOccurenceDescription() {
        switch (this.conference.occurrence) {
            case OccurrenceEnum.DoesNotRepeat:
                return null;
            case OccurrenceEnum.EveryWeekday:
                return "weekday";
            case OccurrenceEnum.WeeklyOn:
                return moment(this.conference.startDate).format('ddd');
            case OccurrenceEnum.Custom: {
                switch (this.conference.repeatType) {
                    case ConferenceRepeatType.Day:
                    case ConferenceRepeatType.Month:
                        return ""
                    case ConferenceRepeatType.Week:
                        return this.conference.repeatsOn?.toString().split(',').map(x => DayOfWeek[Number(x)].substring(0, 3)).join(', ');
                }
            }
            default: return String(this.conference.occurrence);
        }
    }

    get getScheduledStartDate() {return this.scheduledStartDateDisplay;}
    get getScheduledEndDate() {return this.scheduledEndDateDisplay;}
    get canShare() {return !!this.publicMeetingId;}

    get isHappeningNow() {return !this.isDeleted && this.actualStartDate != null && this.actualEndDate == null;}
    get isUpcomming() {return !this.isDeleted && this.actualStartDate == null && this.actualEndDate == null;}

    set_conferenceLogId   (v : number ) { this.conferenceLogId    = v; };
    set_conferenceId      (v : number ) { this.conferenceId       = v; };
    set_scheduledStartDate(v?: number ) { this.scheduledStartDate = v; };
    set_scheduledEndDate  (v?: number ) { this.scheduledEndDate   = v; };
    set_actualStartDate   (v?: number ) { this.actualStartDate    = v; };
    set_actualEndDate     (v?: number ) { this.actualEndDate      = v; };
    set_duration          (v?: number ) { this.duration           = v; };
    set_externalID        (v?: string ) { this.externalID         = v; };
    set_publicMeetingId   (v?: string ) { this.publicMeetingId    = v; };
    set_className         (v?: string ) { this.className          = v; };
    set_shouldDisable     (v?: boolean) { this.shouldDisable      = v; };
    set_notes             (v?: string ) { this.notes              = v; };
    set_originalNotes     (v?: string ) { this.originalNotes      = v; };
    set_openNoteModal     (v : boolean) { this.openNoteModal      = v; };

    getDurationFromEndDate() {
        if (this.scheduledEndDate == null && this.actualEndDate == null) {
            this.duration = undefined;
            return;
        }
        var start=moment(this.scheduledStartDate);
        var end=moment(this.scheduledEndDate);
        if (this.actualStartDate != null && this.actualEndDate != null) {
            start=moment(this.actualStartDate);
            end=moment(this.actualEndDate);
        }
        var duration = moment.duration(end.diff(start));
        this.duration = moment().year(1970).month(0).date(1).hour(0).minute(duration.asMinutes()).startOf('minute').valueOf();
    }

    get params() { return ({conferenceId:String(this.conferenceId),conferenceLogId:String(this.conferenceLogId)}) }

    toJS() {
        return ({
            conferenceId       : this.conferenceId,
            scheduledStartDate : this.scheduledStartDate,
            scheduledEndDate   : this.scheduledEndDate,
            actualStartDate    : this.actualStartDate,
            actualEndDate      : this.actualEndDate,
            externalID         : this.externalID,
            publicMeetingId    : this.publicMeetingId,
            notes              : this.notes,
        });
    }

    clone() { return new ConferenceLog(this.toJS()) }

    static sorter = {
        startDate          : <T extends ConferenceLog>(a: T, b: T) => Conference.sorter.startDate( a.conference        ,         b.conference              ),
        endDate            : <T extends ConferenceLog>(a: T, b: T) => Conference.sorter.endDate  ( a.conference        ,         b.conference              ),
        title              : <T extends ConferenceLog>(a: T, b: T) => Conference.sorter.title    ( a.conference        ,         b.conference              ),
        scheduledStartDate : <T extends ConferenceLog>(a: T, b: T) =>                            ((a.scheduledStartDate || 0) - (b.scheduledStartDate || 0)),
        scheduledEndDate   : <T extends ConferenceLog>(a: T, b: T) =>                            ((a.scheduledEndDate   || 0) - (b.scheduledEndDate   || 0)),
        actualStartDate    : <T extends ConferenceLog>(a: T, b: T) =>                            ((a.actualStartDate    || 0) - (b.actualStartDate    || 0)),
        actualEndDate      : <T extends ConferenceLog>(a: T, b: T) =>                            ((a.actualEndDate      || 0) - (b.actualEndDate      || 0)),
        duration           : <T extends ConferenceLog>(a: T, b: T) =>                            ((a.duration           || 0) - (b.duration           || 0)),
        className          : <T extends ConferenceLog>(a: T, b: T) =>                            ('' + a.className).localeCompare(b.className + ''),
    };

    static filter = {
        happening : (x: ConferenceLog) => !x.isDeleted && x.actualStartDate != null && x.actualEndDate == null,
        upcoming  : (x: ConferenceLog) => !x.isDeleted && x.actualStartDate == null && x.actualEndDate == null,
        completed : (x: ConferenceLog) => !x.isDeleted && x.actualEndDate != null,
        deleted   : (x: ConferenceLog) =>  x.isDeleted,

        class     : (x: ConferenceLog) => x.conference.allClassIds && x.conference.allClassIds.length > 0,
        group     : (x: ConferenceLog) => x.conference.groupIds && x.conference.groupIds.length > 0,
        caseload  : (x: ConferenceLog) => x.conference.caseloadIds && x.conference.caseloadIds.length > 0,
    }

    async startConferenceAsFaculty({facultyId, classId, startRequest}:{facultyId:DbIdentity, classId:DbIdentity, startRequest: IMeetingStartRequest}) {
    const [err, x] = await aFetch<{}>("POST", `/faculty/${facultyId}/class/${classId}/conference/${this.conferenceId}/start`, startRequest);
        return [err, (err ? undefined : new ConferenceStartResponse(x))!] as const;
    }

    /** join conference as Guest Class */
    async joinConferenceAsFacultyFromGuestClass({facultyId, classId, conferenceLogId}:{facultyId:DbIdentity, classId:DbIdentity, conferenceLogId: DbIdentity}) {
        const [err, x] = await aFetch<{url?: string, lti?: string}>("POST", `/faculty/${facultyId}/class/${classId}/conferenceLog/${conferenceLogId}/join`);
        return [err, (err ? undefined : x!)] as const;
    }

    async startGroupConference({groupId, isForceCreateNew, conferenceServer}:{groupId:DbIdentity, isForceCreateNew ?: boolean, conferenceServer ?: ConferenceServerEnum}) {
        const [err, x] = conferenceServer
                ? await aFetch<{}>("POST", `/group/${groupId}/conference/${this.conferenceId}/${conferenceServer ?? ConferenceServerEnum.BBB}/start${isForceCreateNew == true ? '?isForceCreateNewConference=true' : ''}`)
                : await aFetch<{}>("POST", `/group/${groupId}/conference/${this.conferenceId}/start${isForceCreateNew == true ? '?isForceCreateNewConference=true' : ''}`);
        return [err, (err ? undefined : new ConferenceStartResponse(x))!] as const;
    }

    async startCaseloadConference({facultyId, caseloadId, isForceCreateNew, conferenceServer}:{facultyId:DbIdentity, caseloadId:DbIdentity, isForceCreateNew ?: boolean, conferenceServer ?: ConferenceServerEnum}) {
        const [err, x] = conferenceServer
                ? await aFetch<{}>("POST", `/faculty/${facultyId}/caseload/${caseloadId}/conference/${this.conferenceId}/${conferenceServer ?? ConferenceServerEnum.BBB}/start${isForceCreateNew == true ? '?isForceCreateNewConference=true' : ''}`)
                : await aFetch<{}>("POST", `/faculty/${facultyId}/caseload/${caseloadId}/conference/${this.conferenceId}/start${isForceCreateNew == true ? '?isForceCreateNewConference=true' : ''}`);
        return [err, (err ? undefined : new ConferenceStartResponse(x))!] as const;
    }

    static async startConferenceAsFacultyByLogId({facultyId, classId, conferenceLogId, conferenceServer}:{facultyId:DbIdentity, classId:DbIdentity, conferenceLogId:DbIdentity, conferenceServer ?: ConferenceServerEnum}) {
        const [err, x] = await aFetch<{}>("POST", `/faculty/${facultyId}/class/${classId}/conferencelog/${conferenceLogId}/${conferenceServer ?? ConferenceServerEnum.BBB}/start`);
        return [err, (err ? undefined : new ConferenceStartResponse(x))!] as const;
    }

    static async startGroupConferenceByLogId({groupId, conferenceLogId, conferenceServer}:{groupId:DbIdentity, conferenceLogId:DbIdentity, conferenceServer ?: ConferenceServerEnum}) {
        const [err, x] = conferenceServer
            ? await aFetch<{}>("POST", `/group/${groupId}/conferencelog/${conferenceLogId}/${conferenceServer ?? ConferenceServerEnum.BBB}/start`)
            : await aFetch<{}>("POST", `/group/${groupId}/conferencelog/${conferenceLogId}/start`);
        return [err, (err ? undefined : new ConferenceStartResponse(x))!] as const;
    }

    static async startCaseloadConferenceByLogId({facultyId, caseloadId, conferenceLogId, conferenceServer}:{facultyId:DbIdentity, caseloadId:DbIdentity, conferenceLogId:DbIdentity, conferenceServer ?: ConferenceServerEnum}) {
        const [err, x] = conferenceServer
            ? await aFetch<{}>("POST", `/faculty/${facultyId}/caseload/${caseloadId}/conferencelog/${conferenceLogId}/${conferenceServer ?? ConferenceServerEnum.BBB}/start`)
            : await aFetch<{}>("POST", `/faculty/${facultyId}/caseload/${caseloadId}/conferencelog/${conferenceLogId}/start`);
        return [err, (err ? undefined : new ConferenceStartResponse(x))!] as const;
    }

    async joinConferenceAsStudent({studentId, classId}:{studentId:DbIdentity, classId:DbIdentity}) {
        const [err, x] = await aFetch<{joinUrl?:string, lti?:any}>("POST", `/student/${studentId}/class/${classId}/conferencelog/${this.conferenceLogId}/join`);
        return [err, x] as const;
    }

    async joinCaseloadConferenceAsStudent({studentId, caseloadId}:{studentId:DbIdentity, caseloadId:DbIdentity}) {
        const [err, x] = await aFetch<string>("POST", `/student/${studentId}/caseload/${caseloadId}/conferencelog/${this.conferenceLogId}/join`);
        return [err, x] as const;
    }

    joinPublicConference(client:PublicConferenceRequest) {
        return aFetch<string>("POST", `/public/conference/${client.publicMeetingId}/join`, client.toJS());
    }

    async endConference({facultyId, classId}:{facultyId:DbIdentity, classId:DbIdentity}) {
        const [err, data] = await aFetch<ConferenceLog>("POST", `/faculty/${facultyId}/class/${classId}/conferencelog/${this.conferenceLogId}/end`);
        return [err, (err ? undefined : new ConferenceLog(data))!] as const;
    }

    async endGroupConference({groupId}:{groupId:DbIdentity}) {
        const [err, data] = await aFetch<ConferenceLog>("POST", `/group/${groupId}/conferencelog/${this.conferenceLogId}/end`);
        return [err, (err ? undefined : new ConferenceLog(data))!] as const;
    }

    async endCaseloadConference({facultyId,caseloadId}:{facultyId:DbIdentity,caseloadId:DbIdentity}) {
        const [err, data] = await aFetch<ConferenceLog>("POST", `/faculty/${facultyId}/caseload/${caseloadId}/conferencelog/${this.conferenceLogId}/end`);
        return [err, (err ? undefined : new ConferenceLog(data))!] as const;
    }

    static async getConferenceLogByIdAsFaculty({facultyId, classId, conferenceLogId}:{facultyId:DbIdentity, classId:DbIdentity, conferenceLogId: DbIdentity}) {
        const [err, x] = await aFetch<ConferenceLog>("GET", `/faculty/${facultyId}/class/${classId}/conferenceLog/${conferenceLogId}`);
        return [err, (err ? undefined : x!)] as const;
    }

    async saveConferenceLog(facultyId:DbIdentity, classId:DbIdentity, conferenceLogId: DbIdentity) {
        const [err, x] = await aFetch<ConferenceLog>("PUT", `/faculty/${facultyId}/class/${classId}/conferenceLog/${conferenceLogId}`, {notes: this.notes});
        return [err, (err ? undefined : x!)] as const;
    }

    async saveConferenceLogForGroup(groupId:DbIdentity, conferenceLogId: DbIdentity) {
        const [err, x] = await aFetch<ConferenceLog>("PUT", `/group/${groupId}/conferenceLog/${conferenceLogId}`, {notes: this.notes});
        return [err, (err ? undefined : x!)] as const;
    }

    async saveConferenceLogForCaseload(facultyId:DbIdentity, caseloadId:DbIdentity, conferenceLogId: DbIdentity) {
        const [err, x] = await aFetch<ConferenceLog>("PUT", `/faculty/${facultyId}/caseload/${caseloadId}/conferenceLog/${conferenceLogId}`, {notes: this.notes});
        return [err, (err ? undefined : x!)] as const;
    }

    /**
     * Delete a conference log.
     * @param facultyId Requesting user ID. User must be the teacher of class or owner of group that the conferece belongs to.
     * @param logId Conference Log ID to be deleted
     * @returns Error from server if any.
     */
    static async deleteConferenceLog({facultyId, logId}:{facultyId:DbIdentity, logId:DbIdentity}) {
        const [err] = await aFetch<ConferenceLog>("DELETE", `/faculty/${facultyId}/conferencelog/${logId}`);
        return err;
    }

    /**
     * Restore a deleted conference log. Do nothing if conference log not found.
     * @param facultyId Requesting user ID.
     * @param logId Conference Log ID to be restored.
     * @returns [Error from server if any | The data of restored conference].
     */
    static async restoreConferenceLog({facultyId, logId}:{facultyId:DbIdentity, logId:DbIdentity}) {
        const [err, data] = await aFetch<ConferenceLog>("PUT", `/faculty/${facultyId}/conferencelog/${logId}/restore`);
        return [err, (err ? undefined : new ConferenceLog(data))!] as const;
    }

    static async setRecordingOnlyForModerator(facultyId: DbIdentity, classId:DbIdentity, conferenceLogId: DbIdentity, enable:boolean) {
        const [err, data] = await aFetch<ConferenceLog>("POST", `/faculty/${facultyId}/class/${classId}/conferencelog/${conferenceLogId}/recordingOnlyForModerator`, enable);
        return [err, (err ? undefined : new ConferenceLog(data))!] as const;
    }

    static async setGroupRecordingOnlyForModerator(groupId:DbIdentity, conferenceLogId: DbIdentity, enable:boolean) {
        const [err, data] = await aFetch<ConferenceLog>("POST", `/group/${groupId}/conferencelog/${conferenceLogId}/recordingOnlyForModerator`, enable);
        return [err, (err ? undefined : new ConferenceLog(data))!] as const;
    }

    static async setCaseloadRecordingOnlyForModerator(facultyId: DbIdentity, caseloadId:DbIdentity, conferenceLogId: DbIdentity, enable:boolean) {
        const [err, data] = await aFetch<ConferenceLog>("POST", `/faculty/${facultyId}/caseload/${caseloadId}/conferencelog/${conferenceLogId}/recordingOnlyForModerator`, enable);
        return [err, (err ? undefined : new ConferenceLog(data))!] as const;
    }

    async save(facultyId:DbIdentity, classId:DbIdentity) {
        const {conferenceId, conferenceLogId } = this;
        if (conferenceLogId < 1 || Math.abs(conferenceLogId) == conferenceId) {
            const [err, data] = await aFetch<GeneralDto>("POST", `/faculty/${facultyId}/class/${classId}/conference/${conferenceId}/conferenceLog`, this.toJS());
            return [err, (err ? undefined : new ConferenceLog(data))!] as const;
        }
        const [err, data] = await aFetch<GeneralDto>("PUT", `/faculty/${facultyId}/class/${classId}/conference/${conferenceId}/conferenceLog/${conferenceLogId}`, this.toJS());
        return [err, (err ? undefined : new ConferenceLog(data))!] as const;
    }

    async saveForGroup(groupId:DbIdentity) {
        const {conferenceId, conferenceLogId } = this;
        if (conferenceLogId < 1 || Math.abs(conferenceLogId) == conferenceId) {
            const [err, data] = await aFetch<GeneralDto>("POST", `/group/${groupId}/conference/${conferenceId}/conferenceLog`, this.toJS());
            return [err, (err ? undefined : new ConferenceLog(data))!] as const;
        }
        const [err, data] = await aFetch<GeneralDto>("PUT", `/group/${groupId}/conference/${conferenceId}/conferenceLog/${conferenceLogId}`, this.toJS());
        return [err, (err ? undefined : new ConferenceLog(data))!] as const;
    }
}

export class ConferenceLogRecording {

    conferenceLogId          : DbIdentity = DefaultId;
    conferenceLogRecordingId : DbIdentity = DefaultId;
    audioRecordingURL       ?: string;
    videoRecordingURL       ?: string;
    transcriptRecordingURL  ?: string;
    dateCreated             ?: number;
    conferenceLog            = new ConferenceLog();

    constructor(data?:any) {
        makeObservable(this, {
            audioRecordingURL      : observable,
            videoRecordingURL      : observable,
            transcriptRecordingURL : observable,
            dateCreated            : observable,
            conferenceLog          : observable.ref,
            params                 : computed,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }

    get params() { return ({conferenceLogRecordingId:String(this.conferenceLogRecordingId)}) }

    static async fetchProviders({confereceIds, facultyId}: { confereceIds: DbIdentity[]; facultyId: DbIdentity; }) {
        const [err, data] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/conference/providers`,{"cid": confereceIds.sort()});
        return [err, err ? [] : data.map(x => new ConferenceProvider(x))] as const;
    }

    static async deleteAllRecordings(facultyId: DbIdentity, conferenceLogId: DbIdentity) {
        const [err, data] = await aFetch<ConferenceLogRecording[]>("DELETE", `/faculty/${facultyId}/conferencelog/${conferenceLogId}/deleteRecordings`);
        return [err, err ? [] : data.map(x => new ConferenceLogRecording(x))] as const;
    }

    static async deleteAllGroupRecordings(groupId: DbIdentity, conferenceLogId: DbIdentity) {
        const [err, data] = await aFetch<ConferenceLogRecording[]>("DELETE", `/group/${groupId}/conferencelog/${conferenceLogId}/deleteRecordings`);
        return [err, err ? [] : data.map(x => new ConferenceLogRecording(x))] as const;
    }

    static async deleteAllCaseloadRecordings(facultyId: DbIdentity, caseloadId: DbIdentity, conferenceLogId: DbIdentity) {
        const [err, data] = await aFetch<ConferenceLogRecording[]>("DELETE", `/faculty/${facultyId}/caseload/${caseloadId}/conferencelog/${conferenceLogId}/deleteRecordings`);
        return [err, err ? [] : data.map(x => new ConferenceLogRecording(x))] as const;
    }

    static async deleteRecording(facultyId: DbIdentity, recordingId: DbIdentity) {
        const [err, data] = await aFetch<{}>("DELETE", `/faculty/${facultyId}/conferencelog/${recordingId}/deleteRecording`);
        return [err, (err ? undefined : new ConferenceLogRecording(data))!] as const;
    }

    static async deleteGroupRecording(groupId: DbIdentity, recordingId: DbIdentity) {
        const [err, data] = await aFetch<{}>("DELETE", `/group/${groupId}/conferencelog/${recordingId}/deleteRecording`);
        return [err, (err ? undefined : new ConferenceLogRecording(data))!] as const;
    }

    static async deleteCaseloadRecording(facultyId: DbIdentity, caseloadId: DbIdentity, recordingId: DbIdentity) {
        const [err, data] = await aFetch<{}>("DELETE", `/faculty/${facultyId}/caseload/${caseloadId}/conferencelog/${recordingId}/deleteRecording`);
        return [err, (err ? undefined : new ConferenceLogRecording(data))!] as const;
    }
}

export class ConferenceExternalUser {
    conferenceExternalUserId  : DbIdentity = DefaultId;
    conferenceId              : DbIdentity = DefaultId;
    email                     : string = "";
    scheduledStartDate       ?: number = undefined;
    publicMeetingId          ?: string = undefined;
    dateCreated               = Date.now();
    dateUpdated               = Date.now();
    isDeleted                ?: boolean = false;
    conference                = new Conference();

    constructor(data?:any) {
        makeObservable(this, {
            conferenceExternalUserId     : observable,
            conferenceId                 : observable,
            email                        : observable,
            scheduledStartDate           : observable,
            publicMeetingId              : observable,
            dateCreated                  : observable,
            dateUpdated                  : observable,
            isDeleted                    : observable,
            conference                   : observable.ref,
            set_conferenceExternalUserId : action.bound,
            set_conferenceId             : action.bound,
            set_email                    : action.bound,
            set_scheduledStartDate       : action.bound,
            set_publicMeetingId          : action.bound,
            params                       : computed
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }

    set_conferenceExternalUserId(v : number ) { this.conferenceExternalUserId= v; };
    set_conferenceId            (v : number ) { this.conferenceId            = v; };
    set_email                   (v : string ) { this.email                   = v; };
    set_scheduledStartDate      (v : number ) { this.scheduledStartDate      = v; };
    set_publicMeetingId         (v?: string ) { this.publicMeetingId         = v; };

    get params() { return ({conferenceExternalUserId:String(this.conferenceExternalUserId)})}

    toJS() {
        return ({
            conferenceExternalUserId : this.conferenceExternalUserId,
            conferenceId             : this.conferenceId            ,
            email                    : this.email                   ,
            scheduledStartDate       : this.scheduledStartDate      ,
            publicMeetingId          : this.publicMeetingId         ,
        });
    }

    clone() { return new ConferenceExternalUser(this.toJS()) }
}

class ConferenceStartResponse {
    conferenceLogs                ?: ConferenceLog[] = undefined;
    joinUrl                       ?: string          = undefined;
    lti                           ?: any             = undefined;
    existingHappeningConferenceLog?: ConferenceLog   = undefined;

    constructor(data?:any) {
        makeObservable(this, {
            conferenceLogs                : observable.shallow,
            joinUrl                       : observable,
            lti                           : observable,
            existingHappeningConferenceLog: observable.ref
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }
}

export class ConferenceStats {
    meetingName           !:string;
    meetingID             !:string;
    internalMeetingID     !:string;
    createTime            !:string;
    createDate            !:string;
    voiceBridge           !:string;
    dialNumber            !:string;
    attendeePW            !:string;
    moderatorPW           !:string;
    running               !:boolean;
    duration              !:number;
    hasUserJoined         !:boolean;
    recording             !:boolean;
    hasBeenForciblyEnded  !:boolean;
    startTime             !:number;
    endTime               !:number;
    actualStartTime       !:number;
    actualEndTime         !:number;
    participantCount      !:number;
    listenerCount         !:number;
    voiceParticipantCount !:number;
    videoCount            !:number;
    maxUser               !:number;
    moderatorCount        !:number;
    attendees             !:ConferenceAttendee[];
    notExAttendees        !:ConferenceAttendee[];
    polls                 !:ConferencePoll[];
    answers               !:ConferenceAttendeePoll[];
    comments              !:ConferenceLogRecordingComment[];
    returnCode            !:string;
    isSuccess             !:boolean;
    activity              ?:Activity;
    isSpecificAssign      !:boolean;
    assigneeIds           ?:number[];
    isMicrosoftTeams      !:boolean;

    get startDate() { return Math.max(this.startTime!, this.actualStartTime!); }

    get userId2PollVoteAnswers() { return new Map(map(groupBy(this.answers.flatMap(x => x.answers), x => x.userId), x => [x[0]!.userId, x])); }
    get cAttendees() {
        for (const a of this.attendees) {
            a.joinDiff = moment(a.joinDate).startOf("minute").valueOf() - moment(this.startDate).startOf("minute").valueOf();
            a.pollVotes = this.userId2PollVoteAnswers.get(a.userId)?.length ?? 0;
        }
        return this.attendees;
    }

    get cNotExAttendees() {
        return this.notExAttendees;
    }

    get cDuration() {
        return moment(this.actualEndTime).startOf("second").valueOf() - moment(this.actualStartTime).startOf("second").valueOf();
    }

    constructor(data?:any) {
        makeObservable(this, {
            cAttendees      : computed,
            cNotExAttendees : computed,
        });

        if (data != null) {
            const { startTime, endTime, activity } = data;
            Object.assign(this, data);
            if (startTime) this.startTime = Number(startTime)
            if (endTime  ) this.endTime   = Number(endTime)
            if (activity ) this.activity  = new Activity(activity);
            if (!Array.isArray(data.answers)) this.answers = [];
        }
    }

    static async fetchAsFaculty({facultyId, classId, conferenceLogId}:{facultyId:DbIdentity, classId:DbIdentity, conferenceLogId:DbIdentity}) {

        const recalculate =  new URLSearchParams(location.search).get('recalculate');

        const [err, data] = await aFetch<{}>("GET", `/faculty/${facultyId}/class/${classId}/conferenceLog/${conferenceLogId}/stats?timeZone=${GetCurrentTimeZone().timeZone}${recalculate === 'true' ? "&recalculate=true" : ""}`);
        return [err, (err ? undefined : new ConferenceStats(data))!] as const;
    }
}

export class CaseloadConferenceStats extends ConferenceStats {
    assigneeUsers     : User[]    = [];
    constructor(data?: any) {
        super(data);
        if (data != null) {
            const { assigneeUsers, ...pData } = data;
            Object.assign(this, pData);
            if (Array.isArray(assigneeUsers)) this.assigneeUsers = assigneeUsers.map(x => new User(x));
        }
        makeObservable(this, {
            assigneeUsers: observable.shallow
        });
    }

    static async fetchAsCaseloadFaculty({ facultyId, caseloadId, conferenceLogId }: { facultyId: DbIdentity, caseloadId: DbIdentity, conferenceLogId: DbIdentity }) {

        const recalculate = new URLSearchParams(location.search).get('recalculate');

        const [err, data] = await aFetch<{}>("GET", `/faculty/${facultyId}/caseload/${caseloadId}/conferenceLog/${conferenceLogId}/stats?timeZone=${GetCurrentTimeZone().timeZone}${recalculate === 'true' ? "&recalculate=true" : ""}`);
        return [err, (err ? undefined : new CaseloadConferenceStats(data))!] as const;
    }
}

export interface IGroupAttendent {
    userId            : DbIdentity;
    fullName          : string;
    firstName         : string;
    lastName          : string;
    role              : "VIEWER"|"MODERATOR";
    isPresenter       : boolean;
    isListeningOnly   : boolean;
    hasJoinedVoice    : boolean;
    hasVideo          : boolean;
    clientType        : string;
    joinDate          : number;
    leftDate          : number;
    numMessages       : number;
    numTimesRaisedHand: number;
    numTimesTalked    : number;
    numTimesUsedEmojis: number;
    totalSecondTalked : number;
    totalTimeSpent    : number;
    pollVotes         : number;
    isParent          : boolean;
    children          : ConferenceAttendee[] | undefined;
    isModerator       : boolean;
    isOutsideAttendee : boolean;
    isActiveStudent  ?: boolean;
}

export class ConferenceAttendee {
    userId         : DbIdentity           = DefaultId;
    fullName       : string               = "";
    firstName      : string               = "";
    lastName       : string               = "";
    role           : "VIEWER"|"MODERATOR" = "VIEWER";
    isPresenter        : boolean = false;
    isListeningOnly    : boolean = false;
    hasJoinedVoice     : boolean = false;
    hasVideo           : boolean = false;
    clientType         : string  = ""   ;
    joinDate           : number  = 0    ;
    leftDate           : number  = 0    ;
    numMessages        : number  = 0    ;
    numTimesRaisedHand : number  = 0    ;
    numTimesTalked     : number  = 0    ;
    numTimesUsedEmojis : number  = 0    ;
    totalSecondTalked  : number  = 0    ;
    totalTimeSpent     : number  = 0    ;
    joinDiff           : number  = 0    ;
    pollVotes          : number  = 0    ;
    isParent           : boolean = false;

    constructor(data?:any) {
        makeObservable(this, {
            userId             : observable,
            fullName           : observable,
            firstName          : observable,
            lastName           : observable,
            role               : observable,
            isPresenter        : observable,
            isListeningOnly    : observable,
            hasJoinedVoice     : observable,
            hasVideo           : observable,
            clientType         : observable,
            leftDate           : observable,
            joinDate           : observable,
            numMessages        : observable,
            numTimesRaisedHand : observable,
            numTimesTalked     : observable,
            numTimesUsedEmojis : observable,
            totalSecondTalked  : observable,
            totalTimeSpent     : observable,
            joinDiff           : observable,
            isParent           : observable,
            isOutsideAttendee  : computed,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }

    static sorter = {
        fullName          : (a?: ConferenceAttendee, b?: ConferenceAttendee) => ( a?.fullName           ?? "").localeCompare(b?.fullName           ?? ""),
        joinDate          : (a?: ConferenceAttendee, b?: ConferenceAttendee) => ((a?.joinDate           || 0) - (            b?.joinDate           || 0)),
        messages          : (a?: ConferenceAttendee, b?: ConferenceAttendee) => ((a?.numMessages        || 0) - (            b?.numMessages        || 0)),
        timesRaisedHand   : (a?: ConferenceAttendee, b?: ConferenceAttendee) => ((a?.numTimesRaisedHand || 0) - (            b?.numTimesRaisedHand || 0)),
        timesTalked       : (a?: ConferenceAttendee, b?: ConferenceAttendee) => ((a?.numTimesTalked     || 0) - (            b?.numTimesTalked     || 0)),
        timesUsedEmojis   : (a?: ConferenceAttendee, b?: ConferenceAttendee) => ((a?.numTimesUsedEmojis || 0) - (            b?.numTimesUsedEmojis || 0)),
        totalSecondTalked : (a?: ConferenceAttendee, b?: ConferenceAttendee) => ((a?.totalSecondTalked  || 0) - (            b?.totalSecondTalked  || 0)),
    };

    // outside attendee have no user id for now
    get isOutsideAttendee() { return this.userId < 1; }
}
export class ConferencePoll {
    pollId         : string                 = "";
    pollDate       : number                 = 0;
    options        : ConferencePollOption[] = [];

    constructor(data?:any) {
        makeObservable(this, {
            pollId        : observable,
            pollDate      : observable,
            options       : observable,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }

    static sorter = {
        pollDate : (a?: ConferencePoll, b?: ConferencePoll) => ((a?.pollDate || 0) - (b?.pollDate || 0)),
    };

}

class ConferencePollOption {
    id  : number = DefaultId;
    key : string = "";

    constructor(data?:any) {
        makeObservable(this, {
            id  : observable,
            key : observable,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }
}

class ConferenceAttendeePoll {
    pollId         : string                 = "";
    answers        : ConferenceAttendeeAnswer[] = [];

    constructor(data?:any) {
        makeObservable(this, {
            pollId : observable,
            answers: observable,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }
}

class ConferenceAttendeeAnswer {
    userId      : DbIdentity = DefaultId;
    firstName   : string     = "";
    lastName    : string     = "";
    answerId    : number     = DefaultId;

    constructor(data?:any) {
        makeObservable(this, {
            userId    : observable,
            firstName : observable,
            lastName : observable,
            answerId  : observable,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }
}

export class ConferenceLogRecordingComment {
    avatar     ?: string     = undefined;
    userId      : DbIdentity = DefaultId;
    firstName   : string     = "";
    lastName    : string     = "";
    content     : string     = "";
    commentDate : number     = 0;

    constructor(data?:any) {
        makeObservable(this, {
            userId      : observable,
            avatar      : observable,
            firstName   : observable,
            lastName    : observable,
            content     : observable,
            commentDate : observable,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }
}

export class PublicConferenceRequest {
    publicMeetingId ?: string;
    firstName       ?: string;
    lastName        ?: string;

    constructor(data?:any) {
        if (data != null) {
            Object.assign(this, data);
        }
    }

    toJS() {
        return ({
            publicMeetingId : this.publicMeetingId,
            firstName       : this.firstName      ,
            lastName        : this.lastName       ,
        });
    }
}

export class PublicConferenceResponse {
    conferenceLog ?: ConferenceLog ;
    period        ?: string        ;
    className     ?: string        ;
    banner        ?: string        ;
    teacherName   ?: string        ;
    teacherEmail  ?: string        ;
    isInClass     ?: boolean = true;
    isMicrosoftTeams ?: boolean = false;

    constructor(data?:any) {
        if (data != null) {
            Object.assign(this, data);
        }
    }
}

class ConferenceStudent {
    conferenceId : DbIdentity = DefaultId;
    studentId    : DbIdentity = DefaultId;
    classId      : DbIdentity = DefaultId;

    constructor(data?:any) {
        makeObservable(this, {
            conferenceId: observable,
            studentId   : observable,
            classId     : observable,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }
}

class ConferenceSharing extends User {
    conferenceSharingId : DbIdentity = DefaultId;
    conferenceId        : DbIdentity = DefaultId;
    caseloadTagId       : DbIdentity = DefaultId;
    studentId           : DbIdentity = DefaultId;
    parentId            : DbIdentity = DefaultId;
    facultyId           : DbIdentity = DefaultId;

    constructor(data?:any) {
        super(data);
        makeObservable(this, {
            conferenceSharingId : observable,
            conferenceId        : observable,
            caseloadTagId       : observable,
            studentId           : observable,
            parentId            : observable,
            facultyId           : observable,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }
}

export class StudentInfo extends Student {
    classId      : DbIdentity = DefaultId;
    caseloadId   : DbIdentity = DefaultId;

    constructor(data?:{}) {
        super(data);

        if (data != null) {
            Object.assign(this, data);
        }

        makeObservable(this, {
            caseloadId : observable,
            classId    : observable,
        });
    }
}

export interface IConferenceNotifier {
    className: string;
    conferenceLogId: number;
    conferenceName: string | undefined;
    schoolId: number;
    studentIdList: number[];
    studentEmailSubject: string;
    parentEmailSubject: string;
    studentEmailContent: HtmlString;
    parentEmailContent: HtmlString;
}

export interface IConferenceClass {
    classId                : DbIdentity,
    categoryId             : DbIdentity,
    conferenceId           : DbIdentity,
    isGuestClass          ?: boolean   ,
    googleCalendarEventId  : string    ,
}

export class ConferenceNotifier {

    static async GetNotifiedUnattender(facultyId: number, classId: number, conferenceLogId: number) {
        const [err, xs] = await aFetch<number[]>("GET", `/faculty/${facultyId}/class/${classId}/conferenceLog/${conferenceLogId}/getNotifiedUnattender`);
        return [err, (err ? [] : xs)] as const;
    }

    static async NotifyUnattender(facultyId: number, classId: number, notifyData: IConferenceNotifier) {
        const [err, data] = await aFetch<number[]>("POST", `/faculty/${facultyId}/class/${classId}/conferenceLog/${notifyData.conferenceLogId}/notifyUnattender`, notifyData);
        return [err, (err ? [] : data)] as const;
    }
}
