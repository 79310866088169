import type { HexColorString } from "../models/types";

//Implement darken and lighten css in javascript
//https://codepen.io/jreyesgs/pen/MyKoRj
function addLight(color: string, amount: number) {
    const c = Math.min(parseInt(color, 16) + amount, 255);
    return c.toString(16).padStart(2, "0");
}

function subtractLight(color: string, amount: number) {
    const c = Math.max(parseInt(color, 16) - amount, 0);
    return c.toString(16).padStart(2, "0");
}

export function lighten(color: string, amount: number) {
    color = (color.indexOf("#") >= 0) ? color.substring(1, color.length) : color;
    amount = Math.floor((255 * amount) / 100);
    return `#${addLight(color.substring(0, 2), amount)}${addLight(color.substring(2, 4), amount)}${addLight(color.substring(4, 6), amount)}`;
}

export function darken(color: string, amount: number) {
    color = (color.indexOf("#") >= 0) ? color.substring(1, color.length) : color;
    amount = Math.floor((255 * amount) / 100);
    return `#${subtractLight(color.substring(0, 2), amount)}${subtractLight(color.substring(2, 4), amount)}${subtractLight(color.substring(4, 6), amount)}`;
}

export function getTextColorFromBackground(background:HexColorString): HexColorString {
    //https://codepen.io/DevillersJerome/pen/bpLPGe
    const [i, r, g, b] = hex2rgb(background);
    if (!i) return "#ffffff";

    const hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );
    return (hsp > 127.5) ? "#000000" : "#ffffff";
}
export function isColorValue(value?: string){
    return /^#([A-Za-z0-9]){6}$/.test(value ?? "");
}

function hex2rgb(hex: HexColorString): [boolean, number, number, number] {
    const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (rgb != null) {
        const r = parseInt(rgb[1]!, 16);
        const g = parseInt(rgb[2]!, 16);
        const b = parseInt(rgb[3]!, 16);
        return [true, r, g, b];
    }
    return [false, 0, 0, 0];
}

function rgb2hsl(r:number, g:number, b:number):[number, number, number] {
    r /= 255, g /= 255, b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);

    let l = (max + min) / 2;
    let h = 0, s = 0;
    const d = max - min;
    if (d != 0) {
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return [
        Math.round(360*h),
        Math.round(s*100),
        Math.round(l*100),
    ];
}

export function hex2hsl(hex: HexColorString): [boolean, number, number, number]  {
    const [i, r, g, b] = hex2rgb(hex);
    return i ? [i, ...rgb2hsl(r,g,b)] : [i, 0, 0, 0]
}
