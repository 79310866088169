import { makeObservable, observable, toJS } from "mobx";
import { aFetch } from "../services/api/fetch";
import { CaseloadActivity } from "./CaseloadActivity";
import { CaseloadAttachment } from "./CaseloadAttachment";
import { CaseloadDiscussion } from "./CaseloadDiscussion";
import { CaseloadFolder } from "./CaseloadFolder";
import { Conference, ConferenceCaseloadModule } from "./Conference";
import { IModule } from "./Module";
import { DbIdentity } from "./types";

export interface ICaseloadModule extends IModule {
    caseloadId    ?: DbIdentity;
}

export class CaseloadModule {
    caseloadId     ?: DbIdentity ;
    moduleId        : string = "";
    moduleParentId ?: string     ;
    color          ?: string     ;
    secondaryColor ?: string     ;
    moduleIndex     : number = 0 ;

    constructor(data?:any) {
        makeObservable(this, {
            caseloadId    : observable,
            moduleId      : observable,
            moduleParentId: observable,
            color         : observable,
            secondaryColor: observable,
            moduleIndex   : observable,
        });

        if (data != null) this.extends(data);
    }

    extends(data: any) {
        Object.assign(this, data);
        if (!this.moduleParentId && !data.moduleParentId) this.moduleParentId = undefined;
    }

    toJS() { return toJS(this) }

    static async fetchModuleAsFaculty(facultyId: DbIdentity, caseloadId: DbIdentity){
        const [err, data] = await aFetch<{}>("GET", `/faculty/${facultyId}/caseload/${caseloadId}/modules`);
        return [err, (err ? undefined : parseCaseloadViewModel(data))!] as const;
    }

    static async fetchModuleAsStudent(studentId: DbIdentity, caseloadId: DbIdentity){
        const [err, data] = await aFetch<{}>("GET", `/student/${studentId}/caseload/${caseloadId}/modules`);
        return [err, (err ? undefined : parseCaseloadViewModel(data))!] as const;
    }

    static async fetchCaseloadDeletedItem({ facultyId, caseloadId, signal } : { facultyId: DbIdentity, caseloadId: DbIdentity, signal?: AbortSignal }){
        const [err, data] = await aFetch<{}>('GET', `/faculty/${facultyId}/caseload/${caseloadId}/deleted`, { signal });
        return [err, (err ? undefined : parseCaseloadViewModel(data))!] as const;
    }

    static async hasDeletedModules(facultyId: DbIdentity, caseloadId: DbIdentity){
        const [err, hasDeletedModules] = await aFetch<boolean>('GET', `/faculty/${facultyId}/caseload/${caseloadId}/hasDeletedModules`);
        return [err, hasDeletedModules] as const;
    }
}

interface ICaseloadResources{
    activities               : CaseloadActivity        [],
    attachments              : CaseloadAttachment      [],
    discussions              : CaseloadDiscussion      [],
    modules                  : CaseloadModule          [],
    folders                  : CaseloadFolder          [],
    conferences              : Conference              [],
    conferenceCaseloadModules: ConferenceCaseloadModule[],
}

export function parseCaseloadViewModel(data:any): ICaseloadResources {
    const modules = Array.isArray(data.modules ) ? data.modules.map((a: any) => new CaseloadModule(a)) : [];
    const folders = Array.isArray(data.folders ) ? data.folders.map((a: any) => new CaseloadFolder(a)) : [];
    const activities  = Array.isArray(data.activities  ) ? data.activities .map((a: any) => new CaseloadActivity  (a)) : [];
    const attachments = Array.isArray(data.attachments ) ? data.attachments.map((a: any) => new CaseloadAttachment(a)) : [];
    const discussions = Array.isArray(data.discussions ) ? data.discussions.map((a: any) => new CaseloadDiscussion(a)) : [];
    const conferences = Array.isArray(data.conferences ) ? data.conferences.map((a: any) => new Conference        (a)) : [];
    const conferenceCaseloadModules = Array.isArray(data.conferenceCaseloadModules ) ? data.conferenceCaseloadModules.map((a: any) => new ConferenceCaseloadModule(a)) : [];

    return ({
        activities  : activities  ,
        attachments : attachments ,
        modules     : modules     ,
        folders     : folders     ,
        discussions : discussions ,
        conferences : conferences ,
        conferenceCaseloadModules : conferenceCaseloadModules
    });
}