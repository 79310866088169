import { action, computed, makeObservable, observable, toJS } from "mobx";
import { aFetch } from "../services/api/fetch";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import { ShoulderTapOwner } from "./ShoulderTapOwner";
import { ShoulderTapRecipient } from "./ShoulderTapRecipient";
import { DbIdentity, DefaultId, IUserShortInfo, NumberDate } from "./types";
import { User } from "./User";
import { ActOption } from "./ActDoc";
import { ShoulderTapStudent } from "./ShoulderTapStudent";
import { nanoid } from "nanoid";
interface IShoulderTapRequestData {
    shoulderTap : ShoulderTap           ,
    // doc      : ShoulderTapDoc        ,
    owners      : ShoulderTapOwner[]    ,
    recipients  : ShoulderTapRecipient[],
}

interface IShoulderTapData extends IShoulderTapRequestData {
    users       : User[],
}



export enum RecurrenceType {
    None    = 0,
    Daily   = 1,
    Weekly  = 2,
    Monthly = 3,
}


export enum ShoulderTapQuestionType {
    MoodGrid = 1,
    FreeResponse = 2,
    MultipleChoice = 3
}
export class ShoulderTap {
    shoulderTapId  : DbIdentity = DefaultId;
    shoulderName   : string     = ''       ;
    scheduledDate ?: NumberDate = undefined;
    totalResponses : NumberDate = 0;
    createdBy     ?: DbIdentity = undefined;
    sendImmediately: boolean    = true; set_sendImmediately(v ?: boolean) { this.sendImmediately = v ?? false; }
    allowStudentsToDismiss: boolean    = false; set_allowStudentsToDismiss(v ?: boolean) { this.allowStudentsToDismiss = v ?? false; }
    recurrence     : RecurrenceType = RecurrenceType.None; set_recurrence(v ?: RecurrenceType) { this.recurrence = v ?? RecurrenceType.None; }

    shoulderTapDocId  : string = ""; set_shoulderTapDocId(v ?: string) { this.shoulderTapDocId = v ?? ""; }

    constructor(data ?: {}) {
        makeObservable(this, {
            shoulderTapId      : observable,
            shoulderTapDocId   : observable, set_shoulderTapDocId: action.bound,
            shoulderName       : observable,
            scheduledDate      : observable,
            totalResponses     : observable,
            createdBy          : observable,
            sendImmediately    : observable, set_sendImmediately: action.bound,
            allowStudentsToDismiss : observable, set_allowStudentsToDismiss: action.bound,
            recurrence         : observable, set_recurrence     : action.bound,
            set_shoulderTapId  : action.bound,
            set_shoulderName   : action.bound,
            set_scheduledDate  : action.bound,
            params             : computed,

        });

        if (data != null) {
            Object.assign(this, data);
        }
    }
    get params() { return ({ shoulderTapId: String(this.shoulderTapId) }) }

    set_shoulderTapId(v: DbIdentity) { this.shoulderTapId = v };
    set_shoulderName(v: string) { this.shoulderName = v };
    set_scheduledDate(v ?: NumberDate) { this.scheduledDate = v };

    toJS() { return toJS(this); }

    async save({owners, recipients, facultyId}:{owners: ShoulderTapOwner[], recipients: ShoulderTapRecipient[], facultyId: DbIdentity}) {
        const body : IShoulderTapRequestData = { shoulderTap: this.toJS(), owners, recipients };
        if (this.shoulderTapId < 1) {
            const [err, data] = await aFetch<IShoulderTapData>("POST", `/faculty/${facultyId}/admin/shoulderTaps`, body);
            return [err, (err ? undefined : parseShoulderTapViewModel(data))!] as const;
        }
        const [err, data] = await aFetch<IShoulderTapData>("PUT", `/faculty/${facultyId}/admin/shoulderTaps/${this.shoulderTapId}`, body);
        return [err, (err ? undefined : parseShoulderTapViewModel(data))!] as const;
    }

    static async fetchShoulderTaps(facultyId: DbIdentity, signal?: AbortSignal) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/admin/shoulderTaps`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchShoulderTapById(shoulderTapId: DbIdentity, facultyId: DbIdentity, signal?: AbortSignal) {
        const [err, data] = await aFetch<IShoulderTapData>("GET", `faculty/${facultyId}/admin/shoulderTaps/${shoulderTapId}`, undefined, { signal });
        return [err, (err ? undefined : parseShoulderTapViewModel(data))!] as const;
    }

    static async fetchShoulderTapAsStudent(studentId: DbIdentity, signal?: AbortSignal) {
        const [err, data] = await aFetch<{shoulderTapId: DbIdentity, shoulderTapStudentId: DbIdentity, allowStudentsToDismiss: boolean, doc: ShoulderTapDoc, ownerUser: IUserShortInfo} | undefined>("GET", `student/${studentId}/getShoulderTap`, undefined, { signal });
        return [err, ((err || !data) ? undefined : {
            shoulderTapId : data.shoulderTapId,
            doc           : new ShoulderTapDoc(data.doc),
            ownerUser     : data.ownerUser,
            shoulderTapStudentId: data.shoulderTapStudentId,
            allowStudentsToDismiss : data.allowStudentsToDismiss
        } as ShoulderTapStudent)!] as const;
    }
    static async dismissShoulderTap(studentId: DbIdentity, shoulderTapStudentId: DbIdentity) {
        const err = await aFetch("DELETE", `/student/${studentId}/dismissShoulderTap/${shoulderTapStudentId}`, { });
        return err;
    }
    static async answerShoulderTap(studentId: DbIdentity, shoulderTapStudentId: DbIdentity, questionId: string, answer: string) {
        const err = await aFetch("PUT", `/student/${studentId}/answerShoulderTap/${shoulderTapStudentId}`, {
            shoulderTapStudentId,
            questionId,
            answer
         });
        return err;
    }

    static sorter = {
        shoulderName: <T extends ShoulderTap>(a:T, b:T) => (a.shoulderName.localeCompare(b.shoulderName)),
        scheduleDate: <T extends ShoulderTap>(a:T, b:T) => ((a.scheduledDate || 0) - (b.scheduledDate || 0)),

    }
}

export class ShoulderTapDoc {
    constructor(data ?: any) {
        makeObservable(this, {
            id                         : observable,
            items                      : observable.shallow,
            isCreateNew                : computed
        });

        if(data){
            const {items, ...pData} = data;
            if (Array.isArray(items)){
                this.items = items.map(x => new ShoulderTapDocItem(x));
            }
            Object.assign(this, pData);
        }

        if(this.items.length === 0 || this.isCreateNew){
            this.items.push(new ShoulderTapDocItem({}));
        }
    }
    id : string = "";
    get isCreateNew() { return !this.id }
    items            : ShoulderTapDocItem[] = [];

    toJS() {
        return ({
            id     : this.id,
            items  : this.items.map(item => (new ShoulderTapDocItem(item)).toJS()),
        });
    }

    static async fetchDoc({facultyId, shoulderTapId, signal }: { facultyId: DbIdentity, shoulderTapId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/admin/shoulderTaps/${shoulderTapId}/doc`, undefined, { signal });
        return [err, (err ? undefined : new ShoulderTapDoc(data))] as const;
    }

    async save(facultyId: DbIdentity, shoulderTapId:DbIdentity) {
        const data = this.toJS();
        if (this.id == '') {
            const [err, x] = await aFetch<{}>("POST", `/faculty/${facultyId}/admin/shoulderTaps/${shoulderTapId}/doc`, this.toJS())
            return [err, (err ? undefined : new ShoulderTapDoc(x))!] as const;
        }
        const [err, d] = await aFetch<{}>("PUT", `/faculty/${facultyId}/admin/shoulderTaps/${shoulderTapId}/doc`, data);
        return [err, (err ? undefined : new ShoulderTapDoc(d))!] as const;
    }


}
export class ShoulderTapDocItem{

    constructor(data ?: any) {
        makeObservable(this, {
            id                         : observable,
            type                       : observable,               set_type: action.bound,
            question                      : observable,            set_question: action.bound,
            choiceOptions      : observable.shallow, set_choiceOptions: action.bound,
            moodOptions                : observable.shallow,       set_moodOptions: action.bound,
            allowEmojiResponses        : observable,       set_allowEmojiResponses: action.bound,
            allowImageResponses        : observable,       set_allowImageResponses: action.bound,
            isCreateNew                : computed,
            resetMoodOptions           : action.bound,
            removeMoodOption           : action.bound,
        });

        if(data){
            const {moodOptions, choiceOptions, ...pData} = data;
            if (Array.isArray(moodOptions)){
                this.moodOptions = moodOptions.filter(m => mMoodItemName2Item.has(m));
            }
            if (Array.isArray(choiceOptions)){
                this.choiceOptions = choiceOptions.map(x => new ActOption(x));
            }

            Object.assign(this, pData);
            if(this.isCreateNew){
                this.id = nanoid();
                this.moodOptions = Array.from(mMoodItemName2Item.keys());
            }
        }
    }
    id            : string                  = "";
    question      : string                  = "";                                     set_question(v: string) { this.question = v }
    type          : ShoulderTapQuestionType = ShoulderTapQuestionType.MoodGrid;   set_type(v: ShoulderTapQuestionType) { this.type = v }
    moodOptions   : string[]   = [];                                                  set_moodOptions(v: string[]) { this.moodOptions = v };
    allowEmojiResponses: boolean  = false;                                               set_allowEmojiResponses(v: boolean) { this.allowEmojiResponses = v }
    allowImageResponses: boolean  = false;                                               set_allowImageResponses(v: boolean) { this.allowImageResponses = v }
    choiceOptions : ActOption[] = []; set_choiceOptions(v: ActOption[]) { this.choiceOptions = v };


    get isCreateNew() { return !this.id }

    toJS() {
        return ({
            type   : this.type,
            id     : this.id,
            question: this.question,
            moodOptions  : this.moodOptions,
            choiceOptions: this.choiceOptions,
            allowEmojiResponses: this.allowEmojiResponses,
            allowImageResponses: this.allowImageResponses,
        });
    }

    resetMoodOptions(){
        this.moodOptions = Array.from(mMoodItemName2Item.keys());
    }
    removeMoodOption(name: string){
        this.moodOptions = this.moodOptions.filter(m => m !== name);
    }

    set_multipleChoice_correctAnswers(isCorrect: boolean, index: number) {
        if (this.choiceOptions == undefined) this.choiceOptions = [];
        const nCorrectOption = this.choiceOptions.filter(o => o.isCorrect).length;

        // At least 1 correct answer
        if (nCorrectOption <= 1 && !isCorrect) return;
        this.choiceOptions[index]?.set_isCorrect(isCorrect);

        if (isCorrect) {
            this.choiceOptions.map((o, inx) => {
                if (inx != index) o.set_isCorrect(false);
            });
        }
    }

    is_multipleChoice_deleteAble(index: number): boolean {
        if (this.choiceOptions == undefined || this.choiceOptions.length <= 1) return false;
        return true;
    }
}

function parseShoulderTapViewModel(data: IShoulderTapData): IShoulderTapData {
    return ({
        shoulderTap : new ShoulderTap(data.shoulderTap),
        owners      : data.owners.map(o => new ShoulderTapOwner(o)),
        recipients  : data.recipients.map(r => new ShoulderTapRecipient(r)),
        users       : data.users.map(u => new User(u))
    });
}

export type MoodItem = {
    name             : string,
    src             ?: string,
    icon            ?: string,
    unicode          : string
    sortIndex        : number,
    i18nTitle        : string
}
export const MoodGridItemList: MoodItem[] = [
    { name: "happy"        , unicode: "1f600"      , sortIndex: 1,  i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.happy"                 , src: undefined },
    { name: "confused"     , unicode: "1f615"      , sortIndex: 2,  i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.confused"                 , src: undefined },
    { name: "anxious"      , unicode: "1f630"      , sortIndex: 3,  i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.anxious"                 , src: undefined },
    { name: "embarassed"   , unicode: "1f633"      , sortIndex: 4,  i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.embarassed"                 , src: undefined },
    { name: "angry"        , unicode: "1f621"      , sortIndex: 5,  i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.angry"                 , src: undefined },

    { name: "content"      , unicode: "1f60c"      , sortIndex: 6,  i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.content"                 , src: undefined },
    { name: "frustrated"   , unicode: "1f624"      , sortIndex: 7,  i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.frustrated"                 , src: undefined },
    { name: "annoyed"      , unicode: "1f644"      , sortIndex: 8,  i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.annoyed"                 , src: undefined },
    { name: "scared"       , unicode: "1f631"      , sortIndex: 9,  i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.scared"                 , src: undefined },
    { name: "excited"      , unicode: "1f606"      , sortIndex: 10, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.excited"                 , src: undefined },

    { name: "sad"          , unicode: "1f622"      , sortIndex: 11, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.sad"                 , src: undefined },
    { name: "sick"         , unicode: "1f637"      , sortIndex: 12, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.sick"                 , src: undefined },
    { name: "disappointed" , unicode: "1f61e"      , sortIndex: 13, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.disappointed"                 , src: undefined },
    { name: "silly"        , unicode: "1f61c"      , sortIndex: 14, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.silly"                 , src: undefined },
    { name: "confident"    , unicode: "1f60e"      , sortIndex: 15, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.confident"                 , src: undefined },

    { name: "stressed"     , unicode: "1f616"      , sortIndex: 16, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.stressed"                 , src: undefined },
    { name: "tired"        , unicode: "1f634"      , sortIndex: 17, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.tired"                 , src: undefined },
    { name: "loved"        , unicode: "1f60d"      , sortIndex: 18, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.loved"                 , src: undefined },
    { name: "surprised"    , unicode: "1f62e"      , sortIndex: 19, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.surprised"                 , src: undefined },
    { name: "guity"        , unicode: "1f636"      , sortIndex: 20, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.guity"                 , src: undefined },

    { name: "funny"        , unicode: "1f602"      , sortIndex: 21, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.funny"                 , src: undefined },
    { name: "worried"      , unicode: "1f62c"      , sortIndex: 22, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.worried"                 , src: undefined },
    { name: "insecure"     , unicode: "1f61f"      , sortIndex: 23, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.insecure"                 , src: undefined },
    { name: "lonely"       , unicode: "1f614"      , sortIndex: 24, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.lonely"                 , src: undefined },
    { name: "hurt"         , unicode: "1f915"      , sortIndex: 25, i18nTitle: "app.faculty.administrate.shoulderTaps.moodGrid.items.hurt"                 , src: undefined },
];
export const mMoodItemName2Item = new Map(MoodGridItemList.map(m => [m.name, m]));