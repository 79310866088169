import { DbIdentity, DefaultId, DocId} from "./types";
import { observable, computed, action, makeObservable } from "mobx";
import { aFetch } from "../services/api/fetch";
import { IActItem, ActItem, EActItemType } from "./ActDoc";
import { ResourceGroup } from "./ResourceGroup";

interface IQuestionDocItem {
    id              : DocId;
    question       ?: IQuestionDetail;
    answer         ?: String[];
    resourceGroupId :  DbIdentity
    parentFolderId ?:  DbIdentity
    items           : IActItem[];
}

interface IQuestionDetail {
    title           : string;
    items          ?: string[];
}

export class QuestionDocItem implements IQuestionDocItem {
    id              : DocId           = "";
    type            : EActItemType    = EActItemType.Text;
    question       ?: IQuestionDetail = { title: "", items: []};
    answer         ?: String[]        = [];
    items           : ActItem[]      = [];
    resourceGroupId : DbIdentity      = DefaultId;
    parentFolderId ?: DbIdentity      = undefined; set_parentFolderId(v?: DbIdentity) { this.parentFolderId  = v; };
    isTinyMCE      ?: boolean;

    resourceGroup = new ResourceGroup();

    constructor(data:any) {
        makeObservable(this, {
            type               : observable,
            question           : observable,
            answer             : observable,
            items              : observable.shallow,
            resourceGroupId    : observable,
            parentFolderId     : observable, set_parentFolderId : action,
            resourceGroup      : observable.ref,

            params             : computed,
            typeString         : computed,
            isTinyMCE          : observable,
        });

        if (data != null) {
            const {items, ...pData} = data;
            if (Array.isArray(items)) this.items = items.map(item => new ActItem(item));
            Object.assign(this, pData);

            if (items != null) {
                const item = new ActItem(items[0]);
                this.type = item.type;
                this.question = { title: (item.content? item.content : item.title), items: []};
                switch (item.type) {
                    case EActItemType.ChoiceQuiz:
                        item.options?.map(y => {
                            this.question?.items?.push(y.label);
                            if (y.isCorrect) this.answer?.push(y.label);
                        });
                        break;
                    case EActItemType.MatchQuiz:
                        item.matchQuizzItems.map(y => {
                            this.question?.items?.push(y.left);
                            this.answer?.push(y.right);
                        });
                        break;
                    case EActItemType.NumericQuestion:
                        if (item.numericMin) {
                            if (item.numericMax)
                                this.answer?.push(`${item.numericMin}, ${item.numericMax}`);
                            else
                                this.answer?.push(`${item.numericMin}`);
                        }
                        break;
                    case EActItemType.NumericSlider:
                        item.numericSliderOptions.map(x => {
                            this.question?.items?.push(x.label)
                            this.answer?.push(x.correctValue.toString());
                        });
                        break;
                    case EActItemType.PollQuiz:
                        item.pollItems?.map(x => this.question?.items?.push(x.label));
                        break;
                    case EActItemType.FillInBlank:
                        item.fillInBlankItems?.map(item => {
                            this.answer?.push(item.options.filter(x => x.isCorrect).map(y => y.getLabel).join(', '));
                        });
                        break;
                    case EActItemType.OrderList:
                        item.options?.map(y => {this.question?.items?.push(y.label);});
                        break;
                }
            }
        }
    }

    toJS() {
        return ({
            id              : this.id,
            type            : this.type,
            question        : this.question,
            answer          : this.answer,
            resourceGroupId : this.resourceGroupId,
            parentFolderId  : this.parentFolderId,
            items           : this.items.map(item => (new ActItem(item)).toJS()),
            isTinyMCE       : this.isTinyMCE
        });
    }

    get params() { return ({
        resourceGroupId:String(this.resourceGroupId),
        questionId:String(this.id)
    }) }

    get typeString() {
        switch (this.type) {
            case EActItemType.Heading        : return ('app.presentation.addBtn.heading');
            case EActItemType.Text           : return ('app.presentation.addBtn.text');
            case EActItemType.Media          : return ('app.presentation.addBtn.multimedia');
            case EActItemType.Embed          : return ('app.presentation.addBtn.googleDrive');
            case EActItemType.ChoiceQuiz     : return this.items[0]?.isTrueFalseItem ? ("app.presentation.addBtn.trueFalseQuestion" ) : ('app.presentation.addBtn.multipleChoice');
            case EActItemType.TextQuiz       : return ('app.presentation.addBtn.questionText');
            case EActItemType.NumericQuestion: return ('app.presentation.addBtn.numeric');
            case EActItemType.NumericSlider  : return ('app.presentation.addBtn.numericSlider');
            case EActItemType.MatchQuiz      : return ('app.presentation.addBtn.matchingOptions');
            case EActItemType.MatchQuizN     : return ('app.presentation.addBtn.matchingOptionsOneToN');
            case EActItemType.PollQuiz       : return ('app.presentation.addBtn.polling');
            case EActItemType.FillInBlank    : return ('app.presentation.addBtn.fillInTheBlank');
            case EActItemType.Discussion     : return ('app.presentation.addBtn.discussion');
            case EActItemType.OrderList      : return ('app.presentation.addBtn.orderList');
            case EActItemType.HighlightText  : return ('app.presentation.addBtn.highLight');
            case EActItemType.MarkUpImage    : return ('app.presentation.addBtn.markUpImage');
            default: return "";
        }
    }

    static sorter = {
        type    : <T extends QuestionDocItem>(a:T, b:T) => (EActItemType[a.type]!.toString().localeCompare(EActItemType[b.type]!.toString())),
        question: <T extends QuestionDocItem>(a:T, b:T) => ((a.question? a.question.title : "").localeCompare((b.question? b.question.title : ""))),
        answer  : <T extends QuestionDocItem>(a:T, b:T) => ((a.answer? a.answer.join(',') : '').localeCompare((b.answer? b.answer.join(',') : ''))),
    };

    async saveQuestion(resourceGroupId:DbIdentity) {
        const data = this.toJS();
        if (this.id == '') {
            const [err, x] = await aFetch<{}>("POST", `/resourceGroup/${resourceGroupId}/questionBank`, this.toJS())
            return [err, (err ? undefined : new QuestionDocItem(x))!] as const;
        }
        const [err, d] = await aFetch<{}>("PUT", `/resourceGroup/${this.resourceGroupId}/questionBank/${this.id}`, data);
        return [err, (err ? undefined : new QuestionDocItem(d))!] as const;
    }

    static async deleteQuestion(resourceGroupId:DbIdentity, questionBankId: DocId) {
        const [err, x] = await aFetch<{}>("DELETE", `/resourceGroup/${resourceGroupId}/questionBank/${questionBankId}`)
        return [err, (err ? undefined : new QuestionDocItem(x))!] as const;
    }

    static async fetchQuestionBanks({resourceGroupId,parentFolderId, signal }: { resourceGroupId: DbIdentity, parentFolderId ?: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}[]>("GET", `/resourceGroup/${resourceGroupId}/questionBanks${parentFolderId?`?parentFolderId=${parentFolderId}`:``}`, undefined, { signal });
        return [err, (err ? [] : data.map(rs => new QuestionDocItem(rs)))] as const;
    }

    static async fetchQuestionBank({resourceGroupId,questionBankId, signal }: { resourceGroupId:DbIdentity,questionBankId:DocId, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}>("GET", `/resourceGroup/${resourceGroupId}/questionBank/${questionBankId}`, undefined, { signal });
        return [err, (err ? undefined : new QuestionDocItem(data))] as const;
    }
}
