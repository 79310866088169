import { editorCustomData, suneditorCustom } from "../../config";

const getFillInBlankElement = (element: HTMLElement) => {
    // use for both of new and old editors (sun/tiny)
    let lists = element.querySelectorAll(`[class~='${suneditorCustom.fill_in_blank}'], [class~='${editorCustomData.fillInBlank}']`);
    const elements = Array.from(lists).map(e => e as HTMLElement);
    return elements;
}

const getFillInBlankElementInHtml = (html: string) => {
    const doc = document.createElement("div");
    doc.innerHTML = html;
    doc.remove();
    return getFillInBlankElement(doc);
}

export {
    getFillInBlankElement,
    getFillInBlankElementInHtml,
}