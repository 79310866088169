import { observable, action, computed, toJS, makeObservable } from "mobx";

import { DbIdentity, DefaultId, SchoolShortInfo } from "./types"
import { aFetch } from "../services/api/fetch";
import { IUser, UserDetailFieldEnum, UserExt } from "./User";
import { GeneralDto, ISchoolFaculty, parseGeneralViewModel } from "./GeneralViewModel";
import { firstLastName, lastFirstName } from "../utils/stringUtils";
import { notNull } from "../utils/list";
import type { PrimaryRoleEnum } from "./User";
import { ClassCoTeacher } from "../FacultyPortal/models/ClassCoTeacher";
import { UserExtension } from "./UserExtension";

interface ISchoolAdmin {
    schoolId  : number;
    facultyId : number;
}

interface ISchoolFacultyRecord{
    schoolId : DbIdentity;
    isAdmin  : boolean   ;
}

export interface IFacultyOverview {
    courseCount              : number,
    studentCount             : number,
    caseloadCount            : number,
    caseloadStudentCount     : number
}

export class Faculty extends UserExtension implements IUser {
    facultyId       : DbIdentity = DefaultId;
    externalId      : string     = ""       ;
    userId          : DbIdentity = DefaultId;
    email           : string     = "";
    firstName       : string     = "";
    middleName      : string     = "";
    lastName        : string     = "";
    firstNameAlias  : string     = "";
    middleNameAlias : string     = "";
    lastNameAlias   : string     = "";
    salutation      : string     = "";
    displayName     : string     = "";
    phoneNumber     : string     = "";
    avatar          : string     = "";
    aeriesAvater    : string     = "";
    oneRosterId    ?: string     = undefined;
    sendConfirmEmail: boolean    = false;
    primaryRole    ?: PrimaryRoleEnum;

    constructor(data?:{}) {
        super(data);
        if (data != null) Object.assign(this, data);

        makeObservable(this, {
            userId          : observable,
            externalId      : observable, set_externalId      : action.bound,
            email           : observable, set_email           : action.bound,
            firstName       : observable, set_firstName       : action.bound,
            middleName      : observable, set_middleName      : action.bound,
            lastName        : observable, set_lastName        : action.bound,
            salutation      : observable,
            displayName     : observable, set_displayName     : action.bound,
            phoneNumber     : observable, set_phoneNumber     : action.bound,
            avatar          : observable,
            oneRosterId     : observable, set_oneRosterId     : action.bound,
            sendConfirmEmail: observable, set_sendConfirmEmail: action.bound,
            fullName        : computed,
            flName          : computed,
            flNameAlias     : computed,
            lfName          : computed,
            lfNameAlias     : computed,
            userName        : computed,
            getLastNameAlias: computed,
            primaryRole     : observable, set_primaryRole     : action.bound,

        });

        if (this.firstName       == null) this.firstName       = '';
        if (this.middleName      == null) this.middleName      = '';
        if (this.lastName        == null) this.lastName        = '';
        if (this.firstNameAlias  == null) this.firstNameAlias  = '';
        if (this.middleNameAlias == null) this.middleNameAlias = '';
        if (this.lastNameAlias   == null) this.lastNameAlias   = '';
        if (this.salutation      == null) this.salutation      = '';
        if (this.displayName     == null) this.displayName     = '';
    }

    extends(x:Faculty) {
        Object.assign(this, x);
    }

    toJS() {
        return toJS(this);
    }

    set_externalId (v: string) { this.externalId  = v };
    set_email      (v: string) { this.email       = v };
    set_firstName  (v: string) { this.firstName   = v };
    set_middleName (v: string) { this.middleName  = v };
    set_lastName   (v: string) { this.lastName    = v };
    set_displayName(v: string) { this.displayName = v };
    set_phoneNumber(v: string) { this.phoneNumber = v };
    set_sendConfirmEmail(v: boolean) { this.sendConfirmEmail = v};
    set_oneRosterId (v: string) { this.oneRosterId  = v };
    set_primaryRole (v: PrimaryRoleEnum) { this.primaryRole  = v };

    get getLastNameAlias() {return (this.firstNameAlias?.length > 0 && this.lastNameAlias?.length > 0) ? this.lastNameAlias : ''}
    get fullName() { return this.displayName || (this.salutation ? `${this.salutation} ${this.lastName}` : this.flName) }
    get flName() { return firstLastName(this.firstName, '', this.lastName) }
    get flNameAlias() { return firstLastName(this.firstNameAlias, '', this.getLastNameAlias) || this.flName }
    get lfName() { return lastFirstName(this.firstName, '', this.lastName) }
    get lfNameAlias() { return lastFirstName(this.firstNameAlias, '', this.getLastNameAlias) || lastFirstName(this.firstName, '', this.lastName) }
    get userName() { return this.displayName?.trim() || ((this.firstNameAlias || this.firstName) + " " + (this.getLastNameAlias || this.lastName))?.trim()}
    get displayFullMixName() { return this.displayName?.trim() || this.flNameAlias};

    async save(schools: DbIdentity[], sisUserIDs: string[]) {
        const body = {...this.toJS(), schools, sisUserIDs};
        if (this.facultyId < 1) {
            const [err, x] = await aFetch<{}[]>("POST", `/admin/faculty`, body);
            return [err, (err ? undefined : new Faculty(x))!] as const;
        }

        const body2 = {...this.toJS(), schools, sisUserIDs};
        const [err, x] = await aFetch<{}[]>("PUT", `/admin/faculty/${this.facultyId}`, body2);
        return [err, (err ? undefined : new Faculty(x))!] as const;
    }

    static async getFacultyAsAdmin({facultyId, signal}:{facultyId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{ faculty: Faculty, schoolFaculties: ISchoolFaculty[], schoolAdmins: ISchoolAdmin[], isDistrictAdmin: boolean}>("GET", `/admin/faculty/${facultyId}`, undefined, { signal });
        return [err, ...(err ? undefined : [new Faculty(data.faculty), data.schoolFaculties, data.schoolAdmins, data.isDistrictAdmin ?? false] as const )!] as const;
    }

    static async fetchUserSisIdsActive({userId, signal}: {userId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}>("GET", `/admin/user/userSis/${userId}`, undefined, { signal });
        const value = err ? undefined : (Array.isArray(data)? data.map(x => String(x.sisUserId)) : []);
        return [err, value as string[]] as const;
    }

    async changeSchoolFacultiesAsAdmin({schoolRecords, isDistrictAdmin} : {schoolRecords: ISchoolFacultyRecord[], isDistrictAdmin: boolean}) {
        return await aFetch<{}>("PUT", `admin/faculty/${this.facultyId}/schoolFaculties`, { schoolRecords, isDistrictAdmin });
    }

    static async getFacultyOfSchoolAsAdmin({schoolId, facultyId, signal}:{schoolId: DbIdentity, facultyId: DbIdentity, signal?: AbortSignal}) {
        const [err, x] = await aFetch<{}>("GET", `/admin/school/${schoolId}/faculty/${facultyId}`, undefined, { signal });
        return [err, (err ? undefined : new Faculty(x))!] as const;
    }

    /**
     * @param facultyId facultyId of **Current user**.
     * @param manageFacultyId the faculty that **Current user** want to get.
    */
    static async getFacultyOfSchoolAsFacultyAdmin({facultyId, manageFacultyId, signal}:{facultyId: DbIdentity, manageFacultyId: DbIdentity, signal?: AbortSignal}) {
        const [err, x] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/admin/faculty/${manageFacultyId}`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(x))!] as const;
    }

    static async fetchUserSisIdsActiveFacultyAdmin({ currentFacultyId, userId, signal }: { currentFacultyId: DbIdentity, userId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}>("GET", `/faculty/${currentFacultyId}/admin/user/sisUser/${userId}`, undefined, { signal });
        const value = err ? undefined : (Array.isArray(data)? data.map(x => String(x.sisUserId)) : []);
        return [err, value as string[]] as const;
    }

    static async fetchFacultiesForNewClassAsFacultyAdmin( {facultyId, query, signal} : {
            facultyId: DbIdentity,
            query : {searchText: string, faculties: number[]},
            signal ?: AbortSignal
        }
    ) {
        return await aFetch<{total: number, faculties: []}>("GET", `/faculty/${facultyId}/admin/faculty/search`, query, { signal });
    }

    static async fetchFacultiesBySchoolAsFacultyAdmin( {facultyId, query, signal} : {
        facultyId: DbIdentity,
        query : {schoolId: DbIdentity, searchTeacherText: string},
        signal ?: AbortSignal
    }
    ) {
        return await aFetch<{total: number, faculties: []}>("GET", `/faculty/${facultyId}/admin/faculty/searchBySchool`, query, { signal });
    }

    static async searchFacultiesForUsageReport( {facultyId, query, signal} : {
        facultyId: DbIdentity,
        query : {schoolId: DbIdentity, searchTeacherText: string},
        signal ?: AbortSignal
    }
    ) {
        return await aFetch<Faculty[]>("GET", `/faculty/${facultyId}/feature-usage-report/searchFacultyForAutoComplete`, query, { signal });
    }


    /**
     * @param facultyId facultyId of **Current user**.
     * @param manageFacultyId the faculty that **Current user** want to get.
    */
    async saveFacultyAsFacultyAdmin({facultyId, manageFacultyId, schools, customRoles, sisUserIDs}:{facultyId: DbIdentity, manageFacultyId: DbIdentity, schools: DbIdentity[], customRoles?: DbIdentity[], sisUserIDs: string[]}) {
        const body = {...this.toJS(), schools, customRoles, sisUserIDs};
        if (this.facultyId < 1) {
            const [err, x] = await aFetch<{}>("POST", `/faculty/${facultyId}/admin/faculty`, body);
            return [err, (err ? undefined : new Faculty(x))!] as const;
        }

        const [err, x] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/admin/faculty/${manageFacultyId}`, body);
        return [err, (err ? undefined : new Faculty(x))!] as const;
    }

    static async fetchFacultiesOfSchoolsAsFacultyAdmin( {facultyId, paging, signal}
                                                    : {
                                                        facultyId: DbIdentity,
                                                        paging : {schoolId: number, searchText: string, offset: number, page: number, limit: number, orderBy: string, total: number},
                                                        signal ?: AbortSignal
                                                    }
        ) {
        return await aFetch<{total: number, faculties: []}>("GET", `/faculty/${facultyId}/admin/faculty`, paging, { signal });
    }

    static async fetchFacultiesForSharingProgram({ facultyId, signal }: { facultyId: DbIdentity, signal?: AbortSignal }) {
        return await aFetch<{}[]>("GET", `/faculty/${facultyId}/admin/faculties`, { signal });
    }

    /** @deprecated Load too much data */
    static async fetchFacultiesOfSchoolAsFaculty({schoolId, facultyId, signal}:{schoolId: DbIdentity, facultyId: DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/school/${schoolId}/faculty`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchFacultiesOfSchoolAsFacultyPaging({schoolId, facultyId, paging, signal}:{schoolId: DbIdentity, facultyId: DbIdentity, paging: any, signal?: AbortSignal}) {
        const [err, rs] = await aFetch<{total: number, faculties: []}>("GET", `/faculty/${facultyId}/school/${schoolId}/faculty/paging`, paging, { signal });
        return [err, rs] as const;
    }

    static async addCustomRoles({schoolId, facultyId, roles}:{schoolId: DbIdentity, facultyId: DbIdentity, roles: string[]}) {
        const [err, x] =  await aFetch<{}, {[key:string]:string[]}>("POST", `admin/school/${schoolId}/faculty/${facultyId}/roles`, { customRoles: roles});
        return [err, (err ? undefined : x)!] as const;
    }

    static async updateNewPassword(facutlyId: DbIdentity, studentId: DbIdentity, password: string) {
        const [err, data] = await aFetch<{succeeded:boolean}>("PUT", `/faculty/${facutlyId}/manage/student/${studentId}/setpassword`, password);
        return [err, (err ? undefined : data)!] as const;
    }

    static sorter = {
        email      : <T extends Faculty>(a?: T, b?: T) => (a?.email  ?? "").localeCompare(b?.email  ?? ""),
        fullName   : <T extends Faculty>(a?: T, b?: T) => (a?.fullName  ?? "").localeCompare(b?.fullName  ?? ""),
        firstName    : <T extends Faculty>(a?: T, b?: T) => (a?.firstName?.trim()   ?? "").localeCompare(b?.firstName?.trim() ?? ""),
        lastName     : <T extends Faculty>(a?: T, b?: T) => (a?.lastName?.trim()    ?? "").localeCompare(b?.lastName?.trim()  ?? ""),
        firstNameDesc: <T extends Faculty>(a?: T, b?: T) => -((a?.firstName?.trim() ?? "").localeCompare(b?.firstName?.trim() ?? "")),
        lastNameDesc : <T extends Faculty>(a?: T, b?: T) => -((a?.lastName?.trim()  ?? "").localeCompare(b?.lastName.trim()  ?? "")),
        teacherOnRecordDesc : <T extends ClassCoTeacher>(a?: T, b?: T) => -(Number(a?.isTeacherOnRecord  ?? 1) - Number(b?.isTeacherOnRecord  ?? 1)),
    };
}

export class FacultyExt extends Faculty{
    //FacultyExt contains some additional infomation that show in faculty admin
    schools       : SchoolShortInfo[]    =  [];

    constructor(data?:any) {
        super(data);
        makeObservable(this, {
            displaySchools    : computed,
            schools      : observable.shallow,
            clone        : action.bound,

        });
        if (data != null) {
            const { schools, schoolIds, ...pData } = data;
            Object.assign(this, pData);
            if(Array.isArray(schools)) this.schools = schools.map(x => x as SchoolShortInfo).filter(notNull);
        }
    }
    get displaySchools() { return this.schools.map(s=> s.schoolName).filter(s => !!s?.trim()).join(", "); }

    static async updateFacultyInfoAsFacultyAdmin({currentFacultyId, facultyId, field, info }:{currentFacultyId: DbIdentity, facultyId: DbIdentity, field: UserDetailFieldEnum, info: FacultyExt}) {
        const [err, data] = await aFetch<{}[]>("PUT", `/faculty/${currentFacultyId}/admin/faculty/${facultyId}/updateFacultyInfo/${field}`, info.toJS());
        return [err, err ? undefined : new FacultyExt(data)] as const;
    }
    static async fetchFacultyAsFacultyAdmin({ currentFacultyId, facultyId, signal }: { currentFacultyId: DbIdentity, facultyId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}>("GET", `/faculty/${currentFacultyId}/admin/facultyInfo/${facultyId}`, undefined, { signal });
        return [err, new FacultyExt(data)] as const;
    }
    static async fetchFacultyOverview({ currentFacultyId, facultyId, signal }:{ currentFacultyId:DbIdentity, facultyId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<IFacultyOverview>("GET", `/faculty/${currentFacultyId}/admin/facultyInfo/${facultyId}/overview`, undefined, { signal })
        const vm = err ? undefined : data as IFacultyOverview;
        return [err, vm!] as const;
    }
    clone() {
        return new FacultyExt(this.toJS());
    }

    override toJS() {
        return ({
            ...super.toJS(),
            schools: toJS(this.schools),
            schoolIds: this.schools.map(s => s.schoolId)
        });
    }
}

export class ClassFacultyInfo extends Faculty {
    classId           : DbIdentity = DefaultId;
    className         : string     = ""       ;
    isTeacherOnRecord : boolean    = false    ;
    permissionBitmap  : number = 0;

    constructor(data?:{}) {
        super(data);

        makeObservable(this, {
            classId           : observable,
            className         : observable,
            isTeacherOnRecord : observable,
            permissionBitmap  : observable,
        });

        if (data != null) Object.assign(this, data);
    }

    static async fetchFaculties({facultyId, classId, signal}: { facultyId: DbIdentity, classId: DbIdentity, signal?: AbortSignal}){
        const [err, data] = await aFetch<ClassFacultyInfo[]>("GET", `/faculty/${facultyId}/class/${classId}/faculties`, undefined, {signal});
        return [err, (err ? undefined : data.map(x => new ClassFacultyInfo(x)))!] as const;
    }
}
