import { observable, action, makeObservable } from "mobx";

import { DbIdentity, DefaultId, NumberDate } from "./types"

import { aFetch } from "../services/api/fetch";
import type { IGeneralViewModel } from "./GeneralViewModel";
import { User } from "./User";

export class StudentStaffNote {
    studentStaffNoteId : DbIdentity = DefaultId;
    studentId          : DbIdentity = DefaultId;
    content            : string     = "";
    isPublic          ?: boolean    = undefined;
    createdBy         ?: DbIdentity = undefined;
    dateCreated        : NumberDate = -1;
    createdByUser     ?: User       = undefined;

    constructor(data?: {}) {
        makeObservable(this, {
            studentId    : observable,
            content      : observable,
            isPublic     : observable,
            createdBy    : observable,
            dateCreated  : observable,
            createdByUser: observable,
            set_content  : action.bound,
            set_isPublic : action.bound,
        });

        if (data != null) Object.assign(this, data);
    }

    set_content (v : string ) { this.content  = v }
    set_isPublic(v?: boolean) { this.isPublic = v }

    toJS() {
        return ({
            studentStaffNoteId  :   this.studentStaffNoteId,
            studentId           :   this.studentId,
            content             :   this.content,
            isPublic            :   this.isPublic,
        });
    }

    clone() {
        return new StudentStaffNote(this.toJS());
    }

    static async fetchStudentStaffNotesAsFaculty({facultyId, studentId, signal}:{facultyId: DbIdentity, studentId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<IGeneralViewModel>("GET", `faculty/${facultyId}/student/${studentId}/staffNote`, undefined, { signal });
        return [err, data ? fromGeneralViewModel(data) : undefined ] as const;
    }

    static async fetchStudentStaffNotesAsAdmin({studentId, signal}: {studentId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<IGeneralViewModel>("GET", `admin/student/${studentId}/staffNote`, undefined, { signal });
        return [err, data ? fromGeneralViewModel(data) : undefined ] as const;
    }

    async createStudentStaffNotes({facultyId, studentId}:{facultyId: DbIdentity, studentId: DbIdentity}) {
        const [err, data] = await aFetch<{}>("POST", `faculty/${facultyId}/student/${studentId}/staffNote`, this.toJS());
        return [err, !err ? new StudentStaffNote(data) : undefined] as const;
    }

    async updateStudentStaffNotes({facultyId, studentId, studentStaffNoteId}:{facultyId: DbIdentity, studentId: DbIdentity, studentStaffNoteId: DbIdentity}) {
        const [err, data] = await aFetch<{}>("PUT", `faculty/${facultyId}/student/${studentId}/staffNote/${studentStaffNoteId}`, this.toJS());
        return [err, !err ? new StudentStaffNote(data) : undefined] as const;
    }

    static sorter = {
        dateCreated: (a:StudentStaffNote, b:StudentStaffNote) => b.dateCreated - a.dateCreated,
    }
}

function fromGeneralViewModel(vm: IGeneralViewModel) {
    const notes = vm.studentStaffNotes?.map(item => {
        const note = new StudentStaffNote(item);
        const user = vm.users.find(u => u.userId === item.createdBy);
        if(user) note.createdByUser = new User(user);
        return note;
    });

    return notes;
}
