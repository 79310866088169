import { makeObservable, observable, toJS } from "mobx";
import { DbIdentity, DefaultId } from "./types";

export class CaseloadStudent {
    caseloadId: DbIdentity = DefaultId;
    studentId : DbIdentity = DefaultId;

    constructor(data?: any) {
        makeObservable(this, {
            caseloadId: observable,
            studentId: observable,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }
    toJS() { return toJS(this); }
}

