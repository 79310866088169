import { getThemeProp } from "../components/themes/useTheme";
import { ratio } from "wcag-color";
export function stripHtml(html?: string) {
    if (!html) return html;
    const div = document.createElement("div");
    div.innerHTML = html;
    /**
     * note: we have used stripHtml to detect whether the html content is empty or not. Sometimes, user just embed an iframe, audio or video without adding any text
     * That makes detector result is not correct because despite of not contains any text but html contains some displayable content and it is not empty obviously
     */
    if(div.innerHTML.includes('<img') || div.innerHTML.includes('<figure') || div.querySelector("iframe,audio,video")) {
        disposeVideoElements(div);
        div.remove();
        return html;
    }
    const result = escapeUnprintCharacter(div.innerText) ?? "";
    disposeVideoElements(div);
    div.remove();
    return result;
}
/**stripHtml2: Text allowing line breaks and remove media tag*/
export function stripHtml2(html?: string) {
    if (!html) return html;
    const newHtml = replaceLineBreaks(html);
    const resultHtml = replaceMediaTag(newHtml);
    const div = document.createElement("div");
    div.innerHTML = resultHtml;
    const result = div.innerText;
    div.remove();
    return result;
}

/** will wrapped by a div element, so need get firstChildElement to get origin */
export function stringToHtml(text: string) {
    if (!text) return '';
    const div = document.createElement("div");
    div.innerHTML = text;
    return div as HTMLElement;
}

export function isSunEditorDefault(html ?: string) {
    if (!html) return true;
    const div = document.createElement("div");
    div.innerHTML = escapeUnprintCharacter(html) ?? "";

    // check empty with suneditor default
    // ex:
    // <p><span style="font-family: &quot;Roboto Serif&quot;;"></span><br/></p>
    // <p><span></span><br></p>
    // <p><span></span><br></p>
    // references: https://regex101.com/r/0Iz6bA/1
    const regexSuneditorDefault = /^<p>((<span(.*?)><\/span>)|\s*)<br\s*\/?><\/p>$/m;
    const result = div.textContent == "" && regexSuneditorDefault.test(div.innerHTML);
    disposeVideoElements(div);
    div.remove();
    return result;
}

export function htmlIsEqual(a: string, b: string) {
    const divA = document.createElement("div");
    divA.innerHTML = escapeUnprintCharacter(a) ?? "";
    const divB = document.createElement("div");
    divB.innerHTML = escapeUnprintCharacter(b) ?? "";
    const result = (isSunEditorDefault(divA.innerHTML) && isSunEditorDefault(divB.innerHTML)) || divA.innerHTML == divB.innerHTML;
    disposeVideoElements(divA);
    disposeVideoElements(divB);
    divA.remove();
    divB.remove();
    return result;
}

export function extractContent(html ?: string) {
    if (!html) return "";
    const div = document.createElement("div");
    div.innerHTML = html;
    const textContent = div.innerText;
    disposeVideoElements(div);
    div.remove();
    return escapeUnprintCharacter(textContent) ?? "";
}
export function disposeVideoElements(container: HTMLElement | DocumentFragment){
    /**NOTE: if we don't dispose video element properly, it becomes orphan element and still be in memory despite of being remove from dom tree
     * And if the video element are loading an heavy video src from our azure blob( for example in this way https://dotnetthoughts.net/streaming-videos-from-azure-blob-storage/)
     * It causes the browser heavy works because videos are still being streamed in background process and ...bump...crashed if it is too heavy
    */
    var vEles = container.querySelectorAll("video");
    for (const v of vEles) v.src = "";
}
export function extractContentWithLineBreak(html ?: string) {
    if (!html) return "";

    html = html.replace(/<br>/gi, " ");
    html = html.replace(/<\/[^>]+>/g, ' $&'); // add space before each close tag

    return extractContent(html);
}

export function removePTagsAtRootElement(html ?: string) {
    if (!html) return "";
    const div = document.createElement("div");
    div.innerHTML = html;
    const children = div.children;
    if (children.length == 1 && children[0] instanceof HTMLParagraphElement && !children[0].hasAttributes()) {
        div.innerHTML = children[0].innerHTML;
    }
    const result = div.innerHTML;
    disposeVideoElements(div);
    div.remove();
    return result;
}

export function hasDisplayableContent(html: string) {
    if (!html) return false;

    const div = document.createElement("div");
    div.innerHTML = html;
    //TODO: please be careful when using this function on Safari
    // hasDisplayableContent("<p><br></p>") on Safari will return true but false in other browsers
    const result = (div.querySelector(`img,figure,hr,.spacer,iframe,audio,video`) != null) || (0 < div.innerText.length);
    disposeVideoElements(div);
    div.remove();
    return result;
}

export function htmlContainsMediaTags(html: string){
    if (!html) return false;

    const div = document.createElement("div");
    div.innerHTML = html;
    const result = div.querySelector(`img,video,audio,iframe,canvas`) != null;
    disposeVideoElements(div);
    div.remove();
    return result;
}

const urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
export function text2Html(text:string) {
    return text.replace(urlRegex, function(url) {
        const a = document.createElement("a");
        a.href = url;
        a.rel = "noopener noreferrer";
        a.target = "_blank";
        a.textContent = url;
        return a.outerHTML;
    });
}

function normalizeLineBreakTag(v: string) {
    const brTagRegex = /<br\s*\/?>/gi;
    return v.replace(brTagRegex, "<br />");
}

function escapeNLineBreak(v: string) {
    const regexp = /\n/g; // remove: \n
    return v.replace(regexp, "");
}

export function replaceSpecialChar(v: string | undefined) {
    const regexpBreak = /\n/g;
    const regexpTab = /\t/g;
    return v?.replace(regexpBreak, "<br/>").replace(regexpTab, "&nbsp;&nbsp;&nbsp;") ?? "";
}

export function replaceLineBreaks(v: string | undefined) {
    const regexpBr = /<br\s*\/?>/gi;
    return v?.replace(regexpBr, "\n") ?? "";
}

export function replaceMediaTag(v: string | undefined) {
    if (!v) return "";
    const mediaTags = [
        /<img[^>]*src="([^"]*)"[^>]*>/gi,
        /<figure[^>]*src="([^"]*)"[^>]*>/gi,
        /<iframe[^>]*src="([^"]*)"[^>]*>/gi,
        /<audio[^>]*src="([^"]*)"[^>]*>/gi,
        /<video[^>]*src="([^"]*)"[^>]*>/gi
    ];
    mediaTags.map(regex => {
        return v = v?.replace(regex, (match, src) => `MediaLink:${src}`);
    })
    return v;
}

export function escapeTab(v: string) {
    const regexp = /&emsp;/g; // remove: tab
    return v.replace(regexp, "");
}

export function escapeZeroWidthSpace(v: string) {
    const regexp = /\u200b|\u200c|\u2029|\u00a0/g; // Absolutly remove: \u200b (Zero width space)
    return v.replace(regexp, "");
}

export function escapeUnprintCharacter(raw: string | undefined) {
    if (!raw) return raw;

    let result = raw;
    result = normalizeLineBreakTag(result);
    result = escapeZeroWidthSpace(result);
    result = escapeNLineBreak(result);

    return result;
}

export function isHtmlContent (v: string)  {
    return /<\/?[a-z][\s\S]*>/i.test(v);
}

const DataTinyEditorAttr = 'data-editor';
const DataTinyEditorValue = 'tiny';

export function markAsTinyEditor(value: string) {
    if (!value) return '';

    const htmlContent = (stringToHtml(value) as HTMLElement);

    var divElement = htmlContent.firstElementChild;
    // EK-404: add extra empty character to prevent bug when delete all rows of table
    if (divElement == null || divElement.tagName != "DIV" || divElement.nextSibling) return `<div ${DataTinyEditorAttr}="${DataTinyEditorValue}">${value}</div>`;
    if (divElement.getAttribute(DataTinyEditorAttr) == null) divElement.setAttribute(DataTinyEditorAttr, DataTinyEditorValue);

    return htmlContent.innerHTML;
}

export function isTinyEditorContent(value ?: string) {
    if (!value) return true;

    return value.indexOf(`${DataTinyEditorAttr}="${DataTinyEditorValue}"`) > 0 ||
            value.indexOf(`${DataTinyEditorAttr}='${DataTinyEditorValue}'`) > 0;
}

export function showTinyEditor(canShowTinyMCE ?: boolean, value ?: string) {
    //if the value is TinyMCE content, should use TinyEditor (ignore currentUser.showTinyMce)
    const content = stripHtml(value ?? '');
    return (canShowTinyMCE && (content == "" || !isHtmlContent(value ?? ""))) || (content != "" && isTinyEditorContent(value));
}

/**
 * @description Remove whitespace between tags in HTML string.
 * https://github1s.com/JiHong88/SunEditor/blob/HEAD/src/lib/util.js#L1750-L1758
 * @param {String} html HTML string
 * @returns {String}
 */
export function htmlRemoveWhiteSpace(html: string) {
    if (!html) return '';
    return html.trim().replace(/<\/?(?!strong|span|font|b|var|i|em|u|ins|s|strike|del|sub|sup|mark|a|label|code|summary)[^>^<]+>\s+(?=<)/ig, function (m) { return m.replace(/\n/g, '').replace(/\s+/, ' '); });
}
export function correctPastingData(container: Node, ) {
    for (var n = 0; n < container.childNodes.length; n++) {
        var child = container.childNodes[n]!;
        if (child.nodeType === Node.COMMENT_NODE) {
            container.removeChild(child);
            n--;
        } else if (child.nodeType === Node.ELEMENT_NODE) {
            const ele = child as HTMLElement;
            const cssClasses = Array.from(ele.classList.values());
            if(cssClasses.some(c => c.startsWith("SCXP"))){
                /**
                 * NOTE: when copy from MS Powerpoint on https://onedrive.live.com/ and paste, it always included grey background color
                 * because user need to make selection order to copy content. So, that grey background is unwanted and need to be removed
                 */
                ele.style.removeProperty("background-color");
            }
            correctPastingData(child);
        }
    }
}


const REMOVE_COLOR_CONTRAST_RATIO_THRESHOLD = 1.05;  //bg-color: #ffff00 color: #ff0000
export function removeThemeColors(html: string, currentThemeId: string){
    const container = document.createElement('div');
    container.innerHTML = html;
    const validateColorRegEx = /^(#|rgb(a)?\()/i;
    const textElements = container.querySelectorAll<HTMLElement>(`span[style], strong[style], h1[style], h2[style], h3[style], h4[style], h5[style], h6[style], em[style], b[style], i[style], u[style], del[style], p[style]`);
    for (const span of textElements) {
        if (!span.style.color && !span.style.backgroundColor) continue;
        const componentBg = getThemeProp(currentThemeId, "--component-background");
        let {color: spanColor, backgroundColor: spanBg} = span.style;
        if(validateColorRegEx.test(spanBg) === false || validateColorRegEx.test(componentBg) === false) continue;
        const bgRate = ratio(spanBg, componentBg);
        if(bgRate < REMOVE_COLOR_CONTRAST_RATIO_THRESHOLD){
            span.style.backgroundColor = '';
            span.style.color = '';
        }
    }
    const result = container.innerHTML;
    disposeVideoElements(container);
    container.remove();
    return result;
}

export function cleanUpContent(content?:string){
    /**
         * NOTE: to prevent issue comes from https://github.com/nsfw-filter/nsfw-filter. It makes images on content invisibled
         * "When a web page is loaded, all the images remain hidden until they are found to be NSFW or not. If they are found to be NSFW, they remain hidden. Otherwise, they become visible."
         */
    if(!content || content.indexOf("data-nsfw-filter-status") === -1) return content;
    const div = document.createElement("div");
    div.innerHTML = content;
    const imgEles = div.querySelectorAll<HTMLImageElement>("img[data-nsfw-filter-status]")
    for (const img of imgEles) {
        img.style.removeProperty("visibility");
        img.style.removeProperty("filter");
        img.removeAttribute("data-nsfw-filter-status");
    }
    const result = div.innerHTML;
    disposeVideoElements(div);
    div.remove();
    return result;
}

export function replaceTagsWithNewTags(SourceElement: HTMLElement, options? : {targetTag?: Array<string>, newTag?: string, copyStyle?: boolean, className?: string}): void {
    /**
     * NOTE: to prevent issue from semantic or paragraph and header tags like <p> when printing with div tag.
     * function used to replace 'p','h1','h2','h3','h4','h5','h6','pre' into div or target tag to another tag when defining target tag and new tag in option.
     * can choose to keep style and add className in the option.
     */
    const defaultTargetTag = ['p','h1','h2','h3','h4','h5','h6','pre']; //paragraph and header tag in text editor
    const targetEle = (options?.targetTag ?? defaultTargetTag).toString();
    const copyStyles = (sourceEl: Element, targetEl: HTMLElement) => {
        const computedStyle = getComputedStyle(sourceEl);
        for (const style of computedStyle) {
            targetEl.setAttribute('style', `${style}: ${computedStyle.getPropertyValue(style)}`)
        }
        const atrs = sourceEl.attributes;
        for (const atr of atrs) {
            targetEl.setAttribute(atr.name, atr.value)
        };
    };
    Array.from(SourceElement.querySelectorAll<HTMLElement>(targetEle)).forEach(element => {
        const div = document.createElement(options?.newTag ?? 'div');
        if (options?.copyStyle) copyStyles(element, div);
        if (options?.className) div.className = options.className;
        div.innerHTML = element.innerHTML;
        const divNode = div.cloneNode(true);
        element.parentNode?.replaceChild(divNode, element);
    })
};