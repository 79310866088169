import { observable, action, computed, makeObservable } from "mobx";
import { DocId, DbIdentity, UrlString, HtmlString, DefaultId } from '../types';
import { aFetch } from "../../services/api/fetch";
import { BaseDoc } from "../BaseDoc";
import { BlendOptions } from "../BlendOptions";
import { sumBy, compact } from "lodash-es";
import { IBanner } from "../Banner";
import { IActItem } from "./ActItem";

export enum HighlightTextType {
    paragraph = 1,
    sentence  = 2,
    word      = 3,
}

export interface IActItemDefault {
    defaultPenaltyPercentage ?: number;
    defaultAllorNothing      ?: boolean;
    lockOrderOfAnswers       ?: boolean;
}

export interface IActDoc extends IBanner {
    id                  : DocId;
    activityId          : DbIdentity;
    title               : string;
    banner              : UrlString;
    bannerBlendOptions ?: BlendOptions;
    summary             : HtmlString;
    items               : IActItem[];
    isTinyMCE          ?: boolean;
}

export class ActDoc extends BaseDoc {
    /** @deprecated */
    activityId             : DbIdentity = DefaultId;
    comments               : DocId[]    = [];
    totalStudent           : number     = 0;
    isStudentMarkCompleted?: boolean    = undefined;

    constructor(data ?: any) {
        super(data);

        makeObservable(this, {
            comments                  : observable.shallow,
            totalStudent              : observable,
            isStudentMarkCompleted    : observable,
            set_activityId            : action.bound,
            set_totalStudent          : action.bound,
            set_isStudentMarkCompleted: action.bound,
            totalPoints               : computed,
        });

        if (data != null) {
            const {bannerBlendOptions, banner, items, ...pData} = data;
            Object.assign(this, pData);
        }
    }

    override toJS(): IActDoc {
        return ({
            id                 : this.id                                 ,
            activityId         : this.activityId                         ,
            title              : this.title                              ,
            banner             : this.banner                             ,
            summary            : this.summary                            ,
            items              : this.items     .map(item => item.toJS()),
            bannerBlendOptions : this.bannerBlendOptions,
            isTinyMCE          : this.isTinyMCE
        });
    }

    set_activityId(v: DbIdentity) { this.activityId   = v }
    set_totalStudent(v: number) { this.totalStudent = v }
    set_isStudentMarkCompleted(v?: boolean) { this.isStudentMarkCompleted = v }

    get totalPoints() {
        const pointScores = compact(this.items.filter(s => !s.isExtraCredit).map(s => s.pointScore));
        return sumBy(pointScores, s => s)
    }

    async createNotification(facultyId:DbIdentity, activityId:DbIdentity) {
        const [err] = await aFetch<{}>("POST", `/faculty/${facultyId}/activity/${activityId}/notification`)
        return err;
    }

    async saveResourceActQuestionDoc({ resourceGroupId, activityId } : {resourceGroupId:DbIdentity, activityId:DbIdentity}) {
        if (!this.id) {
            const [err, x] = await aFetch<{}>("POST", `/resourceGroup/${resourceGroupId}/activity/${activityId}/doc`, this.toJS())
            return [err, (err ? undefined : new ActDoc(x))!] as const;
        }
        const [err, x] = await aFetch<{}>("PUT", `/resourceGroup/${resourceGroupId}/activity/${activityId}/doc`, this.toJS())
        return [err, (err ? undefined : new ActDoc(x))!] as const;
    }
    /** @deprecated: [this function is duplicated] */
    async saveResourceActIntructionDoc({ resourceGroupId, activityId } : {resourceGroupId:DbIdentity, activityId:DbIdentity}) {
        const [err, x] = await aFetch<{}>("PUT", `/resourceGroup/${resourceGroupId}/activity/${activityId}/instructionDoc`, this.toJS())
        return [err, (err ? undefined : new ActDoc(x))!] as const;
    }
}