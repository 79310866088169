import { UploadFile } from "antd/lib/upload/interface";
import { HtmlString, UrlString } from "../types";
import { stripHtml } from "../../utils/html";
import { action, computed, makeObservable, observable } from "mobx";
import { MatchQuizzAnswer } from "../StudentActDoc";
import { nanoid } from "nanoid";
import { notNull } from "../../utils/list";

export interface IMatchQuizLeftItem {
    left       : string;
    leftImage  : UrlString;
}

export interface IMatchQuizRightItem {
    right      : string;
    rightImage : UrlString;
}

export interface IMatchQuizItem extends IMatchQuizLeftItem, IMatchQuizRightItem {}

export interface IMatchQuizOption {
    id         : string    ,
    label      : HtmlString,
    image      : UrlString ,
    isLocked   : boolean
}

export interface IMatchQuizRowItem {
    leftOptions  : IMatchQuizOption[],
    rightOptions : IMatchQuizOption[],
    isEmptyQuestion: boolean;
}

export class MatchQuizzOption implements IMatchQuizOption {
    id          : string     = ""       ;
    label       : HtmlString = ""       ;
    image       : UrlString  = ""       ;
    imageFile  ?: UploadFile = undefined;
    isLocked    : boolean    = false    ;

    constructor(data?:{}) {
        makeObservable(this, {
            id            : observable,
            label         : observable,
            image         : observable,
            imageFile     : observable.ref,
            isLocked      : observable,
            set_id        : action.bound,
            set_label     : action.bound,
            set_image     : action.bound,
            set_imageFile : action.bound,
            set_isLocked  : action.bound,
            clear_image   : action.bound,
            hasValue      : computed,
        });

        if (data != null) Object.assign(this, data);
    }

    set_id         (v : string        ) { this.id         =                 v           };
    set_label      (v : HtmlString    ) { this.label      =                 v           };
    set_image      (v : UrlString     ) { this.image      =                 v           };
    set_imageFile  (v?: UploadFile    ) { this.imageFile  =                 v           };
    set_isLocked   (v : boolean       ) { this.isLocked   =                 v           };
    clear_image    (                  ) { this.imageFile  = undefined; this.image = ""; };
    get hasValue   () {
        return _stripHtml(this.label)  != "" || this.imageFile != undefined || this.image  != "";
    }

    toJS(): IMatchQuizOption {
        return ({
            id         : this.id,
            label      : this.label,
            image      : this.image,
            isLocked   : this.isLocked,
        });
    }

}

export class MatchQuizzRowItem implements IMatchQuizRowItem {
    leftOptions : MatchQuizzOption[] = [];
    rightOptions: MatchQuizzOption[] = [];
    isEmptyQuestion: boolean = false;

    constructor(data?:{}) {
        makeObservable(this, {
            leftOptions      : observable,
            rightOptions     : observable,
            set_leftOptions  : action.bound,
            set_rightOptions : action.bound,
            isEmptyQuestion  : observable,
        });

        if (data != null) Object.assign(this, data);
    }

    set_leftOptions (v : MatchQuizzOption[]) { this.leftOptions  = v };
    set_rightOptions(v : MatchQuizzOption[]) { this.rightOptions = v };

    toJS() {
        return ({
            leftOptions  : this.leftOptions.map(x => new MatchQuizzOption(x).toJS()),
            rightOptions : this.rightOptions.map(x => new MatchQuizzOption(x).toJS()),
            isEmptyQuestion: this.isEmptyQuestion,
        });
    }

}

export class MatchQuizzItem implements IMatchQuizItem {
    id            ?: string     = "";
    left           : string     = "";
    right          : string     = "";
    leftImage      : UrlString  = "";
    rightImage     : UrlString  = "";
    leftImageFile ?: UploadFile = undefined;
    rightImageFile?: UploadFile = undefined;

    constructor(data?:{}) {
        makeObservable(this, {
            id                : observable,
            left              : observable,
            right             : observable,
            leftImage         : observable,
            rightImage        : observable,
            set_left          : action.bound,
            set_right         : action.bound,
            set_leftImage     : action,
            set_rightImage    : action,
            leftImageFile     : observable.ref,
            rightImageFile    : observable.ref,
            clear_leftImage   : action.bound,
            clear_rightImage  : action.bound,
            set_leftImageFile : action.bound,
            set_rightImageFile: action.bound,
            hasLeftValue      : computed,
            hasRightValue     : computed,
            isLeftRequired    : computed,
            isRightRequired   : computed,
        });

        if (data != null) Object.assign(this, data);
    }

    set_left          (v: string             ) {this.left  = v       };
    set_right         (v: string             ) {this.right = v       };
    set_leftImage     (v: UrlString          ) { this.leftImage  = v };
    set_rightImage    (v: UrlString          ) { this.rightImage = v };
    clear_leftImage   (                      ) { this.leftImageFile  = undefined; this.leftImage  = ""; };
    clear_rightImage  (                      ) { this.rightImageFile = undefined; this.rightImage = ""; };
    set_leftImageFile (v:UploadFile|undefined) { this.leftImageFile  = v };
    set_rightImageFile(v:UploadFile|undefined) { this.rightImageFile = v };

    get hasLeftValue   () { return _stripHtml(this.left)  != "" || this.leftImageFile != undefined || this.leftImage  != ""; }
    get hasRightValue  () { return _stripHtml(this.right) != "" || this.rightImageFile!= undefined || this.rightImage != ""; }
    get isLeftRequired () { return !this.hasLeftValue && this.hasRightValue; }
    get isRightRequired() { return !this.hasRightValue && this.hasLeftValue; }

    isEqual(item: IMatchQuizItem){
        return _areEqualLeft(this, item) && _areEqualRight(this, item);

    }
    isEqualLeft(item: IMatchQuizItem){
        return _areEqualLeft(this, item);

    }
    toJS(): IMatchQuizItem {
        return ({
            left       : this.left,
            leftImage  : this.leftImage,
            right      : this.right,
            rightImage : this.rightImage
        });
    }

    static sorter = {
        leftValueDes : (a: MatchQuizzItem, b: MatchQuizzItem) => {
            if (a.hasLeftValue == b.hasLeftValue) return 0;
            return a.hasLeftValue ? -1 : 1;
        },
    };

}

const _stripHtml = (s :string) => stripHtml(s).trim();
const _areEqualLeft  = (a: IMatchQuizLeftItem  , b : IMatchQuizLeftItem ) => (_stripHtml(a.left)  == _stripHtml(b.left)  ) && (a.leftImage  == b.leftImage  );
const _areEqualRight = (a: IMatchQuizRightItem , b : IMatchQuizRightItem) => (_stripHtml(a.right)  == _stripHtml(b.right) ) && (a.rightImage == b.rightImage );

// Sort current MatchQuizItem based on the left keys of an existed QuizItem.
export const reorderByReservedLeftItems = (sortingItems: MatchQuizzAnswer[], leftItems : MatchQuizzItem[]): MatchQuizzAnswer[] => {
    //Set id for emplty questions
    leftItems.filter(x => !x.hasLeftValue && !x.id).map(x => {
        x.id = nanoid();
        const item = sortingItems.find(y => !y.hasLeftValue && !y.id);
        if (item) item.id = x.id;
    });

    var matchedItems = leftItems.map(li =>
        sortingItems.find(x => (li.hasLeftValue && _areEqualLeft(x, li)) || (!li.hasLeftValue && li.id == x.id))
    ).filter(notNull);

    const items = [... matchedItems, ...sortingItems.filter(x => !matchedItems.includes(x))];

    return items;
}