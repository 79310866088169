import { action, makeObservable, observable } from "mobx";
import { DbIdentity, DefaultId } from "../types";
import { IVideoSettings } from "./IVideoSettings";

export class ActivityVideoExtension implements IVideoSettings {
    activityId                   : DbIdentity = DefaultId;
    autoStart                   ?: boolean = false; set_autoStart                   (v ?: boolean) { this.autoStart                   = v; }
    allowForwardSeeking         ?: boolean = false; set_allowForwardSeeking         (v ?: boolean) { this.allowForwardSeeking        = v; }//
    allowPauseOnWindowUnfocused ?: boolean = false; set_allowPauseOnWindowUnfocused (v ?: boolean) { this.allowPauseOnWindowUnfocused = v; }
    allowResume                 ?: boolean = false; set_allowResume                 (v ?: boolean) { this.allowResume                 = v; }
    autoCompleteLesson          ?: boolean = false; set_autoCompleteLesson          (v ?: boolean) { this.autoCompleteLesson          = v; }
    showMarkCompleteButton      ?: boolean = false; set_showMarkCompleteButton      (v ?: boolean) { this.showMarkCompleteButton      = v; }

    constructor(data?:any) {
        makeObservable(this, {
            autoStart                   : observable, set_autoStart                  : action.bound,
            allowForwardSeeking         : observable, set_allowForwardSeeking        : action.bound,
            allowPauseOnWindowUnfocused : observable, set_allowPauseOnWindowUnfocused: action.bound,
            allowResume                 : observable, set_allowResume                : action.bound,
            autoCompleteLesson          : observable, set_autoCompleteLesson         : action.bound,
            showMarkCompleteButton      : observable, set_showMarkCompleteButton     : action.bound,
        });

        if (data) {
            Object.assign(this, data);
        }
    }
}