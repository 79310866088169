import { observable, action, computed, makeObservable, toJS } from "mobx";

import { DbIdentity, DefaultId, DeletedStatus, NumberDate } from './types';
import { PublishStatusEnum } from "./Activity";
import type { IModule } from "./Module";

import { aFetch } from "../services/api/fetch";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";

export interface IFolderPublish {
    folderId: DbIdentity;
    start?: NumberDate;
    end?: NumberDate;
}

export enum FolderType {
    EPortfolio = 1,
    Activity   = 2,
    Library    = 3,
    Question   = 4,
    SharedWithMeEPortfolio = 5,
    SharedWithTeacherEPortfolio = 6
}

export interface IFolder {
    folderId          : DbIdentity,
    name              : string;
    parentFolderId    : DbIdentity;
    type             ?: FolderType;
    childrenFolder   ?: IFolder[] ;
    publishStartDate ?: NumberDate;
    publishEndDate   ?: NumberDate;
    sortIndex         : number    ;
    classId          ?: DbIdentity;
    moduleId          : string;
    module           ?: IModule;
    readOnly         ?: boolean
}

export class Folder implements IFolder {
    folderId         : DbIdentity = DefaultId;
    name             : string     = "";
    parentFolderId   : DbIdentity = Folder.rootFolderId;
    sortIndex        = 0;
    type            ?: FolderType = FolderType.EPortfolio;
    childrenFolder        ?: Folder[]   = [];
    classId         ?: DbIdentity = undefined;
    publishStartDate?: NumberDate = undefined;
    publishEndDate  ?: NumberDate = undefined;
    lockedDate      ?: NumberDate = undefined;
    moduleId         : string     = "";
    module          ?: IModule    = { moduleId: "", moduleParentId: "",  moduleIndex: 0};
    dateCreated     ?: NumberDate = undefined;
    dateUpdated     ?: NumberDate = undefined;
    deletedStatus    = DeletedStatus.NotDeleted;
    userId           : DbIdentity = DefaultId;
    readOnly        ?: boolean = undefined;
    isLocked         : boolean = false;
    size             = 0;
    constructor(data?:any) {
        makeObservable(this, {
            name              : observable,
            folderId          : observable,
            parentFolderId    : observable,
            sortIndex         : observable,
            type              : observable,
            childrenFolder    : observable,
            classId           : observable,
            publishStartDate  : observable,
            publishEndDate    : observable,
            lockedDate        : observable,
            module            : observable,
            dateCreated       : observable,
            set_name          : action.bound,
            set_type          : action,
            params            : computed,
            publishStatus     : computed,
            isPublished       : computed,
            readOnly          : observable,
            isLocked          : observable,
            size              : observable,
        });

        if (data != null) {
            Object.assign(this, data);
        }
        if (this.parentFolderId == null || this.parentFolderId < 1) this.parentFolderId = Folder.rootFolderId;
        if (this.module && !this.module.moduleParentId) Object.assign(this.module, {...this.module, moduleParentId : ""});
    }

    set_name(v: string    ) { this.name           = v };
    set_type(v: FolderType) { this.type           = v };

    toJS() {
        return ({
            folderId        : this.folderId,
            parentFolderId  : this.parentFolderId,
            name            : this.name,
            type            : this.type,
            classId         : this.classId,
            module          : this.module,
            sortIndex       : this.sortIndex,
            publishStartDate: this.publishStartDate,
            publishEndDate  : this.publishEndDate,
            lockedDate      : this.lockedDate,
            color           : this.module?.color,
            secondaryColor  : this.module?.secondaryColor,
            size            : this.size
        });
    }

    clone() { return new Folder(this.toJS()) }

    get params() { return ({ folderId: String(this.folderId), classId: this.classId ? String(this.classId) : '' }) }

    get isPublished() {
        return this.publishStatus == PublishStatusEnum.Published;
    };

    get publishStatus() {
        const now =  Date.now();
        if (this.publishStartDate == null) return PublishStatusEnum.Unpublished;
        if (now < this.publishStartDate) return PublishStatusEnum.Pending;
        if (this.publishEndDate != null && this.publishEndDate < now) return PublishStatusEnum.Unpublished;
        return PublishStatusEnum.Published;
    };

    async save() {
        if (this.folderId < 1) {
            const [err, data] = await aFetch<{}>("POST", `/folder`, this.toJS());
            return [err, (err ? this : new Folder(data))] as const;
        }

        const [err, dto] = await aFetch<GeneralDto>("PUT", `/folder/${this.folderId}`, this.toJS());
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, err ? this : new Folder(vm!.folders.find(x => x.folderId==this.folderId))] as const;
    }

    static async batchCreate(data :BatchCreateFolder) {
        const [err, dto] = await aFetch<{ mTempModuleId2ModuleId ?: {tempModuleId: string, moduleId: string }[], folders ?: Folder[] }>("POST", `/folder/batch`, toJS(data));
        const folders = err ? [] : dto?.folders?.map(x => new Folder(x)) ?? [];
        const mTempModuleId2ModuleId = new Map<string, string>();
        dto?.mTempModuleId2ModuleId?.forEach(m => { mTempModuleId2ModuleId.set(m.tempModuleId, m.moduleId) });
        return [err, folders, mTempModuleId2ModuleId] as const;
    }

    static async batchUpdate(xs:Folder[]) {
        const [err, ys] = await aFetch<{}[]>("PUT", `/folder`, xs.map(x => x.toJS()));
        return [err, err ? [] : ys.map(y => new Folder(y))] as const;
    }

    static async getFoldersByType({type, signal}: {type: FolderType, signal?: AbortSignal}) {
        const [error, data] = await aFetch<{}[]>("GET", `/folder/folderByType/${type}`, undefined, { signal });
        return [error, (error ? undefined : data.map(p => new Folder(p)))!] as const;
    }

    static async getSharedWithMeEportfolioFolders({signal}: {signal?: AbortSignal}) {
        const [error, data] = await aFetch<{}[]>("GET", `/folder/sharedWithMeEportfolioFolders`, undefined, { signal });
        return [error, (error ? undefined : data.map(p => new Folder(p)))!] as const;
    }

    static async getFoldersInClass({classId, type, isViewer, ...init}: {classId: DbIdentity, type?: FolderType, isViewer?: boolean, signal?: AbortSignal, cache?: RequestCache }) {
        const [error, data] = await aFetch<{}[]>("GET", `/folder/class/${classId}`, { type, isViewer }, init);
        return [error, (error ? undefined : data.map(p => new Folder(p)))!] as const;
    }

    static async getUnpublishedFoldersInClass({classId, type, ...init}: {classId: DbIdentity, type?: FolderType, signal?: AbortSignal, cache?: RequestCache }) {
        const [error, data] = await aFetch<{}[]>("GET", `/folder/class/${classId}/unpublished`, { type }, init);
        return [error, (error ? undefined : data.map(p => new Folder(p)))!] as const;
    }

    static async getFoldersInClassAsFaculty({classId, type, signal}: {classId: DbIdentity, type?: FolderType, signal?: AbortSignal}) {
        const [error, data] = await aFetch<{}[]>("GET", `/folder/previewStudent/class/${classId}`, { type }, { signal });
        return [error, (error ? undefined : data.map(p => new Folder(p)))!] as const;
    }

    static async fetchFoldersForPublicClass({publicLinkId, signal}: {publicLinkId: string, signal?: AbortSignal}) {
        const [error, data] = await aFetch<{}[]>("GET", `/public/publicClass/${publicLinkId}/folders`,undefined, { signal });
        return [error, (error ? undefined : data.map(p => new Folder(p)))!] as const;
    }

    static async getFolderChildren({folderId, ...qs}:{folderId?: DbIdentity, type?: FolderType}) {
        const [error, data] = await aFetch<{folders:{}[], files:{}[]}>("GET", `/folder/${folderId == null ? "" : folderId}`, qs);
        return [error, (error ? {folders:[], files:[]} : {
            folders: data.folders.map(f => new Folder(f)),
            files  : data.files  .map(f => new UserFile(f)),
        })!] as const;
    }

    static async deleteFolder(folderId: DbIdentity) {
        const [err, data] = await aFetch<{}>("DELETE", `/folder/${folderId}`);
        return [err, (err ? undefined : new Folder(data))] as const;
    }

    static async setPublish(folderId:DbIdentity, start: number | undefined, end: number| undefined, isApplySettingsSubcontent: boolean | undefined, sectionClassIds ?: DbIdentity[]) {
        const [err, dto] = await aFetch<GeneralDto>("PUT", `/folder/${folderId}/publish`, {"start": start, "end": end, "isApplySettingsSubcontent": isApplySettingsSubcontent, "sectionClassIds" : sectionClassIds });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    static async duplicate({facultyId, moduleId}:{facultyId:DbIdentity, moduleId: string}, copyTo:IDuplicateModule) {
        const [err, data] = await aFetch<GeneralDto>("POST", `/faculty/${facultyId}/module/${moduleId}/duplicate`, copyTo);
        return [err, !!data ? parseGeneralViewModel(data) : undefined] as const;
    }

    static async fetchFoldersLinkedSections({folderId, sectionClassIds}:{folderId:DbIdentity, sectionClassIds:  DbIdentity[]}) {
        const [err, data] = await aFetch<Folder[]>("GET", `/folder/${folderId}/linkedFolders`, {sectionClassIds});
        return [err, (err ? undefined : data.map(p => new Folder(p)))!] as const;
    }

    static async setPublishLinkedSectionFolders(folderId:DbIdentity, folderList: IFolderPublish[], isApplySettingsSubcontent: boolean | undefined) {
        const [err, dto] = await aFetch<GeneralDto>("PUT", `/folder/${folderId}/publishLinkedFolders`, {"foldersLinkedSections" : folderList, "isApplySettingsSubcontent": isApplySettingsSubcontent });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, vm!] as const;
    }

    static sorter = {
        name     : (a?: Folder, b?: Folder) => (a?.name.trim()  ?? "").localeCompare(b?.name.trim()  ?? ""),
        sortIndex: (a?: Folder, b?: Folder) => ((a ? a.sortIndex : 0) - (b ? b.sortIndex : 0)),
        sortIndexThenName: (a?: Folder, b?: Folder) => {
            const i = Folder.sorter.sortIndex(a, b);
            return i != 0 ? i : Folder.sorter.name(a, b);
        },
        dateCreated: (a: Folder, b: Folder) => ((a.dateCreated || 0) - (b.dateCreated || 0)),
        sortIndexThenDateCreated: (a?: Folder, b?: Folder) => {
            const i = Folder.sorter.sortIndex(a, b);
            return i != 0 ? i : Folder.sorter.dateCreated(a!, b!);
        }
    };

    static rootFolderId: DbIdentity = 0;
}

export class UserFile {
    fileId         : DbIdentity = DefaultId;
    name           : string     = "";
    contentType    : string     = "";
    url            : string     = "";
    size           : number     = 0;
    parentFolderId?: DbIdentity;

    constructor(data?:any) {
        makeObservable(this, {
            name          : observable,
            contentType   : observable,
            url           : observable,
            size          : observable,
            parentFolderId: observable,
        });

        if (data != null) {
            Object.assign(this, data);
        }
        if (this.parentFolderId != null && this.parentFolderId < 1) this.parentFolderId = undefined;
    }

    toJS() {
        return ({
            fileId        : this.fileId,
            name          : this.name,
            contentType   : this.contentType,
            size          : this.size,
            url           : this.url,
            parentFolderId: this.parentFolderId,
        });
    }

    clone() { return new UserFile(this.toJS()) }

    async save() {
        if (this.fileId < 1) {
            const [err, data] = await aFetch<{}>("POST", `/folder/file`, this.toJS());
            return [err, (err ? undefined : new UserFile(data))!] as const;
        } else {
            const [err, data] = await aFetch<{}>("PUT", `/folder/file/${this.fileId}`, this.toJS());
            return [err, (err ? undefined : new UserFile(data))!] as const;
        }
    }

    static async remove(fileId: DbIdentity) {
        const [err, data] = await aFetch<Folder>("DELETE", `/folder/file/${fileId}`);
        return [err, (err ? undefined : new UserFile(data))] as const;
    }

    static createFromFile(file:File) {
        return new UserFile({ name:file.name, contentType:file.type, size: file.size });
    }
}

export class TempFolder extends Folder {
    tempModuleId        : string = "";
    tempModuleParentId  : string = "";
    constructor(data ?: any) {
        super(data);
        if (data) Object.assign(this, data);
    }
}
export interface BatchCreateFolder {
    rootModuleParentId: string,
    type              : FolderType,
    classId           : DbIdentity,
    folders           : TempFolder[],
}

export interface BatchCreateResourceFolder {
    rootModuleParentId: string,
    type              : FolderType,
    resourceGroupId   : DbIdentity,
    folders           : TempFolder[],
}


export class IDuplicateModule {
    classId     : DbIdentity   = DefaultId;
    moduleId   ?: string                  ;
    titlePrefix : string       = ""       ;
    fromClassId : DbIdentity   = DefaultId;
    isAddModuleItemToTop ?: boolean = false;
}

export const ColorList = [
    {color: '#37BC9B', secondary: '#6FCF97'},
    {color: '#F2994A', secondary: '#FFBA7D'},
    {color: '#3C4EBC', secondary: '#7479EF'},
    {color: '#FE0061', secondary: '#FF9FC4'},
    {color: '#2F80ED', secondary: '#64A5FF'},
    {color: '#A7BEEC', secondary: '#D0E0FF'},
    {color: '#F2C94C', secondary: '#F7DF94'},
    {color: '#d93131', secondary: '#EB6767'},
];

export const defModuleColor = "#A7BEEC";
export const defModuleSecondColor = "#D0E0FF";
