
import { UploadFile } from "antd/lib/upload/interface";
import { ActivityType } from "./Activity";
import type { MediaTypeEnum } from "./MediaTypeEnum";
import { StudentExt } from "./Student";
import { UserExt } from "./User";
import { ISupportedDocumentCenter } from "./DocumentCenter/ISupportedDocumentCenter";

export type NumberDate     = number;
export type NumberTime     = number;
export type StringDate     = string;
export type JSONString<T>  = string;
export type HtmlString     = string;
export type UrlString      = string;
export type GuidString     = string;
export type ColorString    = string;
export type HexColorString = string;
export type DateOnly        = string;

export type ActionTriggerStringType = 'click' | 'hover';
export type ActionTriggerType = ActionTriggerStringType[];

/** sometimes tooltip don't need ```click``` trigger event, let change to empty */
export const getTooltipTrigger = (v ?: ActionTriggerStringType[]) => {  return (v && v[0] === 'click') ? [] : v; }

// just add here to make sure we have many date format in our system. Then, we can format dates consistently later if possible.
export const DateOnlyFormat  = "YYYY-MM-DD";
export const CommonDateOnlyFormat  = "MM/DD/YYYY";

export type DbIdentity = number;
export type DbIdentityCallback = (id: DbIdentity) => void;

export function isValidIdentity(id: DbIdentity|null|undefined): id is DbIdentity {
    return id != null && !Number.isNaN(id) && id > 0;
}
export function isValidStringRequired(str: string|null|undefined): str is string {
    return str != null && str.trim() != "";
}
export function isValidModuleId(id: string|null|undefined): id is string {
    return isValidStringRequired(id);
}
export interface DocId      extends String {};

export const DefaultId:DbIdentity = -1;

export interface IMediaLink extends ISupportedDocumentCenter
{
    mediaType  : MediaTypeEnum
    link       : string
    contentType: string
    name       : string
    file       ?: UploadFile
}
export type UploadFileType = UploadFile & ISupportedDocumentCenter;

export type AttachmentType = UploadFileType | IMediaLink;

export const attachmentTypeChecker = {
    isUploadFile : (attach: AttachmentType) : attach is UploadFileType => {
        return (attach as UploadFileType).uid !== undefined;
    },
    isMediaLink : (attach: AttachmentType) : attach is IMediaLink => {
        return (attach as IMediaLink).mediaType !== undefined;
    }
}


export enum EUserRole {
    Unknow      = "Unknow",
    Faculty     = "Faculty",
    Admin       = "Admin",
    Student     = "Student",
    Parent      = "Parent",
    SchoolAdmin = "SchoolAdmin",
    All         = "All"
}


export enum ECustomLinkPortal {
    Admin       = 1 ,
    Faculty     = 2 ,
    Parent      = 3 ,
    Student     = 4 ,
}

export enum DayOfWeek {
    Sunday    = 0,
    Monday    = 1,
    Tuesday   = 2,
    Wednesday = 3,
    Thursday  = 4,
    Friday    = 5,
    Saturday  = 6,
}

enum EGoogleDocExt {
    SpreadSheets = 'gsheet' ,
    Document     = 'gdoc'   ,
    Presentation = 'gslides',
    Forms        = 'gform'  ,
    Drawing      = 'gdraw'  ,
}

export enum EGoogleDocMimeType {
    SpreadSheets = 'application/vnd.google-apps.spreadsheet'  ,
    Document     = 'application/vnd.google-apps.document'     ,
    Presentation = 'application/vnd.google-apps.presentation' ,
    Forms        = 'application/vnd.google-apps.form'         ,
    Drawing      = 'application/vnd.google-apps.drawing'      ,
    Folder       = 'application/vnd.google-apps.folder'       ,
}

export enum EDocMimeType {
    GoogleSpreadSheet = 'application/vnd.google-apps.spreadsheet'  ,
    GoogleDocument     = 'application/vnd.google-apps.document'     ,
    GooglePresentation = 'application/vnd.google-apps.presentation' ,
    MicrosoftDoc       = 'application/msword',
    MicrosoftDocx      = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    MicrosoftXls       = 'application/vnd.ms-excel',
    MicrosoftXlsx      = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    MicrosoftPpt       = 'application/vnd.ms-powerpoint',
    MicrosoftPptx      = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    OpenOfficeDocument            = 'application/vnd.oasis.opendocument.text',
    OpenOfficeSpreadSheet         = 'application/vnd.oasis.opendocument.spreadsheet',
    OpenOfficePresentation        = 'application/vnd.oasis.opendocument.presentation',
}

export enum EGoogleDocViewType {
    SpreadSheets = 'spreadsheets',
    Document     = 'document'    ,
    Presentation = 'presentation',
    Forms        = 'forms'       ,
    Drawing      = 'drawings'    ,
    Files        = 'file'        ,
}
export function docExtToGoogleDocViewType(ext: string) {
    switch (ext) {
        case "doc":
        case "docx":
        case "gdoc":
            return EGoogleDocViewType.Document;
        case "ppt":
        case "pptx":
        case "gslides":
            return EGoogleDocViewType.Presentation;
        case "xls":
        case "xlsx":
        case "gsheet":
            return EGoogleDocViewType.SpreadSheets;
        default:
            return EGoogleDocViewType.Files;
    }
}

export function docMimeTypeToGoogleDocViewType(mimeType: string) {
    switch (mimeType) {
        case EDocMimeType.GoogleDocument:
        case EDocMimeType.MicrosoftDoc:
        case EDocMimeType.MicrosoftDocx:
        case EDocMimeType.OpenOfficeDocument:
            return EGoogleDocViewType.Document;
        case EDocMimeType.GooglePresentation:
        case EDocMimeType.MicrosoftPpt:
        case EDocMimeType.MicrosoftPptx:
        case EDocMimeType.OpenOfficePresentation:
            return EGoogleDocViewType.Presentation;
        case EDocMimeType.GoogleSpreadSheet:
        case EDocMimeType.MicrosoftXls:
        case EDocMimeType.MicrosoftXlsx:
        case EDocMimeType.OpenOfficeSpreadSheet:
            return EGoogleDocViewType.SpreadSheets;
        default:
            return EGoogleDocViewType.Files;
    }
}
export const GoogleDocExt = new Set([EGoogleDocExt.Document, EGoogleDocExt.SpreadSheets, EGoogleDocExt.Presentation, EGoogleDocExt.Drawing, EGoogleDocExt.Forms] as string[]);

export function googleMimeTypeToViewType(mimeType: string) {
    switch (mimeType) {
        case EGoogleDocMimeType.Document    : return EGoogleDocViewType.Document     ;
        case EGoogleDocMimeType.Presentation: return EGoogleDocViewType.Presentation ;
        case EGoogleDocMimeType.SpreadSheets: return EGoogleDocViewType.SpreadSheets ;
        case EGoogleDocMimeType.Drawing     : return EGoogleDocViewType.Drawing      ;
        case EGoogleDocMimeType.Forms       : return EGoogleDocViewType.Forms        ;
        default                             : return EGoogleDocViewType.Files        ;
    }
}

export function googleExtToMime(ext: string) {
    switch (ext) {
        case EGoogleDocExt.Document    : return EGoogleDocMimeType.Document     ;
        case EGoogleDocExt.Presentation: return EGoogleDocMimeType.Presentation ;
        case EGoogleDocExt.SpreadSheets: return EGoogleDocMimeType.SpreadSheets ;
        case EGoogleDocExt.Drawing     : return EGoogleDocMimeType.Drawing      ;
        case EGoogleDocExt.Forms       : return EGoogleDocMimeType.Forms        ;
        default                        : return undefined;
    }
}
export function isGoogleWorkspaceDocument(mimeType: string){
    return ['application/vnd.google-apps.spreadsheet', 'application/vnd.google-apps.document', 'application/vnd.google-apps.presentation', 'application/vnd.google-apps.drawing'].includes(mimeType);
}

export enum DeletedStatus {
    NotDeleted = 0,
    Deleted    = 1,
    OnCascade  = 2,
}


export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC"
}

export interface ISorting
{
    sortBy: number,
    sortDirection ?: SortDirection
}

export interface ISorter
{
    order: string,
    columnKey: string
}
export interface IAllowFileTypeInfo {
    ext: string,
    mimeType: string
}
export interface ISearchActivityLogResult {
    actionType : string,
    name     : string,
    url      : string,
    timeStamp: number
}
export enum ActionLogObjectType
{
    Unknown                        = 0,
    Activity                       = 1,
    Assessment                     = 2,
    Assignment                     = 3,
    Discussion                     = 4,
}
export function mapActivityTypeToActionLogObjectType(activityType?: ActivityType){
    switch (activityType) {
        case ActivityType.Activity   : return ActionLogObjectType.Activity  ;
        case ActivityType.Assessment : return ActionLogObjectType.Assessment;
        case ActivityType.Assignment : return ActionLogObjectType.Assignment;
        case ActivityType.Discussion : return ActionLogObjectType.Discussion;
    }
    return ActionLogObjectType.Unknown;
}
export interface IStudentUserExt{
    userInfo: UserExt,
    studentInfo: StudentExt
}
export type SchoolShortInfo = {
    schoolId : DbIdentity;
    schoolName?: string;
}
export interface IUserShortInfo{
    /** @deprecated */ fullName    ?: string,
    displayName   ?: string,
    userId         : DbIdentity,
    firstName      : string,
    middleName     : string,
    lastName       : string,
    firstNameAlias : string,
    middleNameAlias: string,
    lastNameAlias  : string,
    avatar         : string,
    aeriesAvatar   : string,

}

export type UserTokenData = {
    userToken: string,
    expiredAt: NumberDate
}

export enum EMSOneDriveMimeType {
    Word       = 'docx',
    Excel      = 'xlsx',
    PowerPoint = 'pptx',
}

export const PDFMimeType = 'application/pdf';