import moment, { unitOfTime } from "moment";

interface IPublicSchedule{
    publishStartDate?: number;
    publishEndDate?: number;
}

export function isPublishedNow({publishStartDate, publishEndDate}: IPublicSchedule) {
    return _isPublishedNow(publishStartDate, publishEndDate);
}


function _isPublishedNow(publishStart: number | undefined, publishEnd: number | undefined) {
    const now = Date.now();
    return (publishStart != null && publishStart < now && (publishEnd == null || now < publishEnd));
}


export function getDate(d?: number) {
    if (d == undefined) return;
    const options = {weekday: 'long'} as const;
    const input = new Date(d);
    const now = new Date();

    if (now.setHours(0,0,0,0) == input.setHours(0,0,0,0)){
        return "Today";
    }
    else if (input.getDate() - now.getDate() == 1){
        return "Tomorrow";
    } else {
        return new Intl.DateTimeFormat('en-US', options).format(input);
    }
}

/** Remove second from date time. */
export function trimSeconds(value?: number){
    return !value ? 0 : moment(value).seconds(0).milliseconds(0).valueOf();
}

export function getStartOfDate(d: number) {
    return moment(d).startOf('d').valueOf();
}

export const maxDate = new Date(8640000000000000);
export const minDate = new Date(-8640000000000000);

export function secondsToReadableFormat(seconds: number): string {
    const duration = moment.duration(seconds, 'seconds');
    return duration.format("h[h] m[m] s[s]");
}