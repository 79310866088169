import { action, computed, makeObservable, observable } from "mobx";
import { stripHtml } from "../../utils/html";
import { HtmlString } from "../types";
import { nanoid } from "nanoid";
import { IActItem } from "./ActItem";

export interface INumericSliderOption {
    id              : string,
    label           : string,
    correctValue   ?: number,
    thresholdValue ?: number,
    numberAnswer   ?: number
    isValidLabel    : () => boolean,
    isValid         : (item: IActItem) => boolean,
}

export class NumericSliderOption implements INumericSliderOption {
    id              : string = "" ;
    label           : string = "" ; set_label (v : string ) { this.label = v; }
    correctValue    : number = 0  ; set_correctValue (v ?: number ) { this.correctValue = v ?? 0; }
    thresholdValue  : number = 0  ; set_thresholdValue (v ?: number ) { this.thresholdValue = v ?? 0; }
    numberAnswer   ?: number = 0  ; set_numberAnswer (v ?: number ) { this.numberAnswer = v; }

    constructor(data?:{}) {
        makeObservable(this, {
            id                 : observable  ,
            label              : observable  ,
            correctValue       : observable  ,
            thresholdValue     : observable  ,
            numberAnswer       : observable  ,
            set_label          : action.bound,
            set_correctValue   : action.bound,
            set_thresholdValue : action.bound,
            set_numberAnswer   : action.bound,
            getLabel           : computed    ,
            isValid            : action      ,
            isValidLabel       : action      ,
            isValidCorrectValue: action      ,
        });
        if (data != null) Object.assign(this, data);
        if (!this.id) {
            this.id = nanoid();
        }
    }

    toJS() {
        return {
            id: this.id,
            label: this.label,
            correctValue: this.correctValue,
            thresholdValue: this.thresholdValue,
            numberAnswer: this.numberAnswer?.toFixed(2)
        }
    }

    isValid(item: IActItem) {return (this.isValidLabel() && this.isValidCorrectValue(item))}
    isValidLabel() {return (!!this.label)}
    isValidCorrectValue(item: IActItem) {
        if (typeof(item.numericMin) !== "number" || typeof(item.numericMax) !== "number") return false;
        const numericSliderInterval = (typeof(item.numericSliderInterval) !== "number" || item.numericSliderInterval == 0)  ? 1 : item.numericSliderInterval;
        return (this.correctValue != null && this.correctValue >= item.numericMin && this.correctValue <= item.numericMax &&
                Math.round((this.correctValue - item.numericMin) * 100) % Math.round(numericSliderInterval * 100) == 0
        )
    }

    get getLabel(): HtmlString {
        return stripHtml(this.label)?.trim() ?? "";
    }

    isEqual(item: NumericSliderOption): boolean{
        return (this.id == item.id);
    }
}