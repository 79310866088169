import { observable, makeObservable, computed } from "mobx";
import { DbIdentity, DefaultId, isValidIdentity } from "./types";
import { aFetch } from "../services/api/fetch";
import { firstLastName, lastFirstName } from "../utils/stringUtils";
import { UserTypeEnum } from "./User";
import { IMemberSearchView } from "../components/SearchMember";

export class EventMemberSearchView implements IMemberSearchView{
    id             : DbIdentity   = DefaultId;
    firstName      : string       = "";
    middleName     : string       = "";
    lastName       : string       = "";
    firstNameAlias : string       = "";
    middleNameAlias: string       = "";
    lastNameAlias  : string       = "";
    displayName    : string       = "";
    avatar         : string       = "";
    aeriesAvatar   : string       = "";
    email          : string       = "";
    userType       : UserTypeEnum = UserTypeEnum.Student;
    isOwner        : boolean      = false;

    constructor(data?:any) {
        if (data != null)Object.assign(this, data);

        makeObservable(this, {
            id             : observable,
            firstName      : observable,
            middleName     : observable,
            lastName       : observable,
            firstNameAlias : observable,
            middleNameAlias: observable,
            lastNameAlias  : observable,
            displayName    : observable,
            avatar         : observable,
            aeriesAvatar   : observable,
            email          : observable,
            userType       : observable,
            isOwner        : observable,
            flName         : computed,
            lfName         : computed,
            flNameAlias    : computed,
            lfNameAlias    : computed,
            getDisplayName    : computed
        });
    }
    get flName      () { return firstLastName(this.firstName, '', this.lastName) }
    get lfName      () { return lastFirstName(this.firstName, '', this.lastName) }
    get flNameAlias () { return this.firstNameAlias?.trim().length > 0 ? firstLastName(this.firstNameAlias, '', this.lastNameAlias || this.lastName) : this.flName }
    get lfNameAlias () { return this.firstNameAlias?.trim().length > 0 ? lastFirstName(this.firstNameAlias, '', this.lastNameAlias || this.lastName) : this.lfName }
    get getDisplayName() { return this.displayName?.trim() || this.flNameAlias }

    static async search({groupId, eventId, signal, ...query}:{groupId: DbIdentity, eventId: DbIdentity, signal ?: AbortSignal, searchText: string}) {
        if(isValidIdentity(groupId)){
            //search user to add for event in group
            const [err, data] = await aFetch<{}[]>("GET", `/event/${eventId}/group/${groupId}/addMember/search`, query, { signal });
            return [err, (err ? [] : data.map(s => new EventMemberSearchView(s)))] as const;
        }
        const [err, data] = await aFetch<{}[]>("GET", `/event/${eventId}/addMember/search`, query, { signal });
        return [err, (err ? [] : data.map(s => new EventMemberSearchView(s)))] as const;
    }
}
