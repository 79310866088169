import { observable, action, makeObservable, toJS } from "mobx";

export class BlendOptions {
    effectEnabled : boolean = false; set_effectEnabled(v  : boolean) { this.effectEnabled = v; }
    blendedColor  : string  = "#3c4ebc"   ; set_blendedColor (v ?: string ) { this.blendedColor  = v ?? ""; }
    constructor(data ?: any) {
        makeObservable(this, {
            effectEnabled : observable.ref, set_effectEnabled: action.bound,
            blendedColor  : observable.ref, set_blendedColor : action.bound,
            toJS          : action.bound
        });
        if (data) Object.assign(this, data);
    }
    toJS() {
        return toJS(this);
    }
}