import { observable, action, makeObservable } from "mobx";

import { DbIdentity, DefaultId, NumberDate } from "./types"

import { aFetch } from "../services/api/fetch";
import type { IGeneralViewModel } from "./GeneralViewModel";
import { User } from "./User";

export class StudentFlag {
    studentFlagId  : DbIdentity = DefaultId;
    studentId      : DbIdentity = DefaultId;
    createdBy     ?: DbIdentity = undefined;
    dateCreated    : NumberDate = -1;
    expirationDate : NumberDate = -1;
    createdByUser ?: User       = undefined;
    student       ?: User       = undefined;

    constructor(data?: {}) {
        makeObservable(this, {
            studentId     : observable,
            createdBy     : observable,
            dateCreated   : observable,
            expirationDate: observable,
            createdByUser : observable,
            student       : observable,
        });

        if (data != null) Object.assign(this, data);
    }

    static async fetchStudentFlagAsFaculty({facultyId, studentId, signal}:{facultyId: DbIdentity, studentId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<IGeneralViewModel>("GET", `faculty/${facultyId}/student/${studentId}/flag`, undefined, { signal });
        return [err, data? fromGeneralViewModel(data) : undefined ] as const;
    }

    static async flagOrUnFlagStudent({facultyId, studentId}:{facultyId: DbIdentity, studentId: DbIdentity}, isFlagged: boolean) {
        const [err, data] = await aFetch<StudentFlag>("POST", `faculty/${facultyId}/student/${studentId}/flag`, isFlagged);
        return [err, data ? data : undefined ] as const;
    }

    static async fetchStudentFlagInClassAsFaculty({facultyId, classId}:{facultyId: DbIdentity, classId: DbIdentity}) {
        const [err, data] = await aFetch<IGeneralViewModel>("GET", `faculty/${facultyId}/class/${classId}/flag`);
        return [err, data? fromGeneralViewModel(data) : undefined ] as const;
    }

}

function fromGeneralViewModel(vm: IGeneralViewModel) {
    const flags = vm.studentFlags?.map(item => {
        const flag = new StudentFlag(item);
        const faculty = vm.users.find(u => u.userId === flag.createdBy);
        if(faculty) flag.createdByUser = new User(faculty);
        const student = vm.users.find(u => u.userId === flag.studentId);
        if(student) flag.student = new User(student);
        return flag;
    });

    return flags;
}
