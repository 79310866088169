import { observable, action, toJS, makeObservable, computed } from "mobx";

import { DbIdentity, DefaultId } from "./types"
import { GradeRange } from "./GradeRange";

import { aFetch } from "../services/api/fetch";

export const defaultGradingScale = {
    items: [
        {mark: "A+", from: 0.97, to: 1.00},
        {mark: "A" , from: 0.93, to: 0.97},
        {mark: "A-", from: 0.90, to: 0.93},
        {mark: "B+", from: 0.87, to: 0.90},
        {mark: "B" , from: 0.83, to: 0.87},
        {mark: "B-", from: 0.80, to: 0.83},
        {mark: "C+", from: 0.77, to: 0.80},
        {mark: "C" , from: 0.73, to: 0.77},
        {mark: "C-", from: 0.70, to: 0.73},
        {mark: "D+", from: 0.67, to: 0.70},
        {mark: "D" , from: 0.63, to: 0.67},
        {mark: "D-", from: 0.60, to: 0.63},
        {mark: "F" , from: 0.00, to: 0.60},
    ],
};

export class GradingScale {
    gradingScaleId : DbIdentity   = DefaultId;
    schoolId       : DbIdentity   = DefaultId;
    name           : string       = "";
    description    : string       = "";
    isSchoolDefault: boolean      = false;
    items          : GradeRange[] = [];
    createdBy      : DbIdentity   = DefaultId;
    get params() { return ({ schoolId: String(this.schoolId), gradingScaleId: String(this.gradingScaleId) })}

    constructor(data?:any) {
        makeObservable(this, {
            schoolId       : observable, changeSchool       : action.bound,
            name           : observable, set_name       : action.bound,
            description    : observable, set_description: action.bound,
            isSchoolDefault: observable,
            items          : observable.shallow,
            params         : computed,
            refine         : action.bound,
            toJS           : action.bound,
            getMark        : action,
        });

        if (data != null) {
            const {items, ...pData} = data;
            Object.assign(this, pData);
            if (Array.isArray(items)) this.items = items.map(x => new GradeRange(x));
        }
    }

    set_name       (v: string) { this.name        = v }
    set_description(v: string) { this.description = v }

    toJS() { return toJS(this); }

    changeSchool(schoolId: DbIdentity) {
        if (this.schoolId == schoolId) return;
        this.schoolId = schoolId;
    }

    getMark(grade?:number) {
        if (grade == null || this.items.length < 1) return "";

        const item = this.items.find(r => r.from <= grade && grade < r.to);
        if (item != null) return item.mark;

        const maxGrade = this.items.reduce((max, r) => max.to < r.to ? r : max, this.items[0]);
        if (maxGrade.to < grade) return maxGrade.mark;

        const minGrade = this.items.reduce((min, r) => r.from < min.from ? r : min, this.items[0]);
        if (grade < minGrade.from) return minGrade.mark;

        {
            if (this.items[0].to <= grade) return this.items[0].mark;

            for (var i = 1; i < this.items.length; i++) {
                const [a, b] = [this.items[i], this.items[i - 1]]
                if (a.to < grade && grade <= b.to)
                    return b.mark;
            }
            return this.items[this.items.length - 1].mark;
        }
    }

    refine() { this.items = this.items.filter(i => !!i.mark.trim() && i.from != null && i.to != null); }

    async save({facultyId}:{facultyId:DbIdentity}) {
        const body = this.toJS();
        const { schoolId, gradingScaleId } = this;

        if (gradingScaleId < 1) {
            const [err, x] = await aFetch<{}>("POST", `/faculty/${facultyId}/school/${schoolId}/gradingScale`, body);
            return [err, (err ? undefined : new GradingScale(x))!] as const;
        }

        const [err, x] = await aFetch<{}>("PUT", `/faculty/${facultyId}/school/${schoolId}/gradingScale/${gradingScaleId}`, body);
        return [err, (err ? undefined : new GradingScale(x))!] as const;
    }

    async saveAsSchoolDefault({schoolId}:{schoolId:DbIdentity}) {
        const body = this.toJS();

        const [err, x] = await aFetch<{}>("PUT", `/admin/school/${schoolId}/gradingScale`, body);
        return [err, (err ? undefined : new GradingScale(x))!] as const;
    }

    static async fetchManyAsFaculty({schoolId, facultyId}:{schoolId:DbIdentity, facultyId: DbIdentity}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/school/${schoolId}/gradingScale`);
        return [err, (err ? [] : xs.map(x => new GradingScale(x)))] as const;
    }
    
    static async fetchAllGradingScales({facultyId}:{facultyId: DbIdentity}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/gradingScales`);
        return [err, (err ? [] : xs.map(x => new GradingScale(x)))] as const;
    }

    static async deleteAsFaculty({schoolId, facultyId, gradingScaleId, isConfirm = false}:{schoolId:DbIdentity, facultyId: DbIdentity, gradingScaleId:DbIdentity, isConfirm ?: boolean}) {
        const [err, x] = await aFetch<{}>("DELETE", `/faculty/${facultyId}/school/${schoolId}/gradingScale/${gradingScaleId}?isConfirm=${isConfirm}`);
        return [err, (err ? undefined : new GradingScale(x))!] as const;
    }

    static async fetchOne({ gradingScaleId, signal }: { gradingScaleId:DbIdentity, signal?: AbortSignal}) {
        const [err, x] = await aFetch<{}>("GET", `/public/gradingScale/${gradingScaleId}`, undefined, { signal });
        return [err, (err ? undefined : new GradingScale(x))!] as const;
    }

    static async fetchSchoolDefault({schoolId, signal}: {schoolId:DbIdentity, signal?: AbortSignal}) {
        const [err, x] = await aFetch<{}>("GET", `/public/school/${schoolId}/gradingScale`, undefined, { signal });
        return [err, (err ? undefined : new GradingScale(x))!] as const;
    }

    static async fetchAll({gradingScaleIds, schoolIds} : {gradingScaleIds: DbIdentity[], schoolIds: DbIdentity[]}){
        const [err, xs] = await aFetch<{}[]>("POST", `/public/gradingScale/all`, toJS({gradingScaleIds, schoolIds}));
        return [err, (err ? [] : xs.map(x => new GradingScale(x)))] as const;
    }

    static sorter = {
        name: <T extends GradingScale>(a?: T, b?: T) => ((a?.name ?? "").localeCompare(b?.name ?? "")),
    };
}
