import { action, computed, makeObservable } from "mobx";
import { ItemContainer } from "../ItemContainer";
import { ActItem } from "./ActItem";
import { EActItemType } from "./EActItemType";
import { IErrorData } from "../../services/api/AppError";
import { isValidActItems } from "../../utils/ActDoc/ActItemUtils";

export class ActItemContainer extends ItemContainer<ActItem> {
    constructor(data ?:  ItemContainer<ActItem>) {
        super(data);
        makeObservable(this, {
            hasGoogleAssignmentItems : computed,

            addDefaultTextItem : action.bound,
            refine             : action.bound,
        }
    );

        if (data != null) {
            const { items } = data;
            if (Array.isArray(items)) this.items = items == null ? [] : items.map(item => new ActItem(item));
        }
    }

    override toJS() {
        return ({
            items : this.items.map(item => item.toJS()),
        });
    }

    get hasGoogleAssignmentItems() { return this.items.some(x => x.type == EActItemType.GoogleAssignment); }

    override removeItem(item: ActItem) {
        this.items.map((x) => {
            if (x.discussionItem == item)
                x.discussionItem =  undefined;
        });
        super.removeItem(item);
        if (this.items.length == 0) this.items.push(new ActItem({type: EActItemType.Text, content: ""}));
    }

    /** only for add default when items length < 1 */
    addDefaultTextItem() {
        if (this.items.length == 0) this.addItem(new ActItem({type: EActItemType.Text, content: ""}));
    }

    override get isEmptyList() {
        if (super.isEmptyList) return true;
        const validItems = this.items.filter(x => x.type == EActItemType.Text && !x.isEmptyContent);
        return validItems && validItems.length < 1;
    }
    /**
     * @description: remove/fix and validate ActItem[] data before submitting to server
     * @returns [error, error]
     */
    refine(): [IErrorData<{code:string}>|undefined, ActItem|undefined] {
        if (this == null) return [undefined, undefined];
        this.items = this.items.filter(x => x.type != EActItemType.Text || (x.type == EActItemType.Text && !x.isEmptyContent))
        const eitem = isValidActItems(this.items);
        if (eitem) {
            this.addDefaultTextItem();
            return [eitem.error, eitem.item];
        }
        return [undefined, undefined];
    }
}