import {observable, action, computed, makeObservable} from "mobx";

import { DbIdentity, DefaultId } from "./types";
import { GradingTerm, GradingTermType } from "./GradingTerm";

import { aFetch } from "../services/api/fetch";
import { uploadImage } from "../services/api/upload";

import type { UploadFile } from "antd/lib/upload/interface";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import { GradingScale } from "./GradingScale";
import { ISortable } from "../utils/changeSortIndex";

export enum SchoolType {
    Normal  = 1,
    Virtual = 2,
}

export class School implements ISortable {
    districtId          : DbIdentity    = DefaultId;
    schoolId            : DbIdentity    = DefaultId;
    currentGradingTerm  : DbIdentity    = DefaultId;
    schoolType          : SchoolType    = SchoolType.Normal;
    schoolName          : string        = "";
    logoUrl             : string        = "";
    darkmodeLogoUrl     : string        = "";
    iconUrl             : string        = "";
    googleAccount       : string        = "";
    googleCalendarId    : string        = "";
    tGradingTerm        : GradingTerm[] = [];
    tValidGradingTerm   : GradingTerm[] = [];
    tGradingScale       : GradingScale[]= [];
    principalId        ?: DbIdentity;
    minGrade           ?: number        = undefined; set_minGrade(v?: number){ this.minGrade = v; }
    maxGrade           ?: number        = undefined; set_maxGrade(v?: number){ this.maxGrade = v; }
    feedsToSchoolId    ?: DbIdentity    = undefined; set_feedsToSchoolId(v?: DbIdentity){ this.feedsToSchoolId = v; }
    academicPlanLevel  ?: number = undefined;  set_academicPlanLevel(v?: number) { this.academicPlanLevel = v; }

    sortIndex          ?: number        = 10000; set_sortIndex(v ?: number) { this.sortIndex = v; }

    constructor(data?:any) {
        if (data != null) {
            const {tGradingTerm, tGradingScale, ...pData} = data;
            Object.assign(this, pData);
            if (Array.isArray(tGradingTerm)) this.tGradingTerm = tGradingTerm.map(x => new GradingTerm(x));
            if (Array.isArray(tGradingScale)) this.tGradingScale = tGradingScale.map(x => new GradingScale(x)).sort(GradingScale.sorter.name);
        }

        if(!this.logoUrl) this.logoUrl = "";
        if(!this.iconUrl) this.iconUrl = "";
        if(!this.darkmodeLogoUrl) this.darkmodeLogoUrl = "";

        makeObservable(this, {
            schoolName              : observable         ,
            currentGradingTerm      : observable         ,
            districtId              : observable         ,
            logoUrl                 : observable         ,
            darkmodeLogoUrl         : observable         ,
            iconUrl                 : observable         ,
            googleAccount           : observable         ,
            googleCalendarId        : observable         ,
            sortIndex               : observable         ,
            tGradingTerm            : observable.shallow ,
            tValidGradingTerm       : observable.shallow ,
            tGradingScale           : observable.shallow ,
            principalId             : observable,
            set_logoUrl             : action,
            set_darkmodeLogoUrl     : action,
            set_iconUrl             : action,
            set_googleAccount       : action,
            set_sortIndex           : action,
            currentGradingTermObject: computed,
            params                  : computed,

            normalGradingTerms      : computed,
            virtualGradingTerm      : computed,
            gradingTermList         : computed,
            Id2GradingTerm          : computed,
            darkmodeLogo            : computed,
            logo                    : computed,
            minGrade                : observable,                set_minGrade: action.bound,
            maxGrade                : observable,                set_maxGrade: action.bound,
            feedsToSchoolId         : observable,                set_feedsToSchoolId: action.bound,
            academicPlanLevel       : observable,                set_academicPlanLevel: action.bound,
        });
    }

    set_logoUrl(v: string) { this.logoUrl = v; }
    set_darkmodeLogoUrl(v: string) { this.darkmodeLogoUrl = v; }
    set_iconUrl(v: string) { this.iconUrl = v; }
    set_googleAccount(v: string) { this.googleAccount = v; }

    get currentGradingTermObject(){
        return this.tGradingTerm.find(c => c.gradingTermId==this.currentGradingTerm);
    }

    get normalGradingTerms(){
        return this.tGradingTerm
            .filter(c => c.gradingTermType != GradingTermType.Virtual)
            .sort((a,b) => b.endDate - a.endDate);
    }

    get virtualGradingTerm(){
        return this.tGradingTerm
            .filter(c => c.gradingTermType == GradingTermType.Virtual);
    }

    get gradingTermList() { return this.normalGradingTerms.concat(this.virtualGradingTerm); }

    get Id2GradingTerm(){
        return new Map(this.tGradingTerm.map(x => [x.gradingTermId, x]));
    }

    get logo(){ return this.logoUrl ?? this.iconUrl; }
    get darkmodeLogo(){ return this.darkmodeLogoUrl || this.logo; }

    toJS() {
        return ({
            schoolId           : this.schoolId,
            schoolName         : this.schoolName,
            currentGradingTerm : this.currentGradingTerm,
            districtId         : this.districtId,
            logoUrl            : this.logoUrl,
            darkmodeLogoUrl    : this.darkmodeLogoUrl,
            iconUrl            : this.iconUrl,
            sortIndex          : this.sortIndex,
            principalId        : this.principalId,
            minGrade           : this.minGrade,
            maxGrade           : this.maxGrade,
            feedsToSchoolId    : this.feedsToSchoolId,
            academicPlanLevel  : this.academicPlanLevel
        });
    }

    toSchoolIndexesJS() {
        return ({
            schoolId           : this.schoolId,
            sortIndex          : this.sortIndex,
        });
    }

    get params() { return ({schoolId:String(this.schoolId), districtId:String(this.districtId)}) }

    static async createSchool({school, gradingTerm}:{school: School, gradingTerm: GradingTerm}) {
        const [err, x] = await aFetch<{}[]>("POST", `/admin/school`, {
            schoolName          : school.schoolName,
            logoUrl             : school.logoUrl,
            darkmodeLogoUrl     : school.darkmodeLogoUrl,
            iconUrl             : school.iconUrl,
            minGrade            : school.minGrade,
            maxGrade            : school.maxGrade,
            feedsToSchoolId     : school.feedsToSchoolId,
            academicPlanLevel   : school.academicPlanLevel,
            gradingTermName     : gradingTerm.name,
            startDate           : gradingTerm.startDate.valueOf(),
            endDate             : gradingTerm.endDate.valueOf(),
            gradingStartTime    : gradingTerm.gradingStartTime.valueOf(),
            gradingEndTime      : gradingTerm.gradingEndTime.valueOf()
        });
        return [err, (err ? undefined : new School(x))!] as const;
    }

    static async saveSchoolAsAdmin({school}:{school: School}) {
        const [err, x] = await aFetch<{}[]>("PUT", `/admin/school/${school.schoolId}`, school.toJS());
        return [err, (err ? undefined : new School(x))!] as const;
    }

    static async saveSchoolAsFacultyAdmin({school, facultyId}:{school: School, facultyId: DbIdentity}) {
        const [err, x] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/admin/school/${school.schoolId}`, school.toJS());
        return [err, (err ? undefined : new School(x))!] as const;
    }

    static async fetchAllSchoolsInDistrict({ facultyId, signal }: { facultyId: DbIdentity, signal?: AbortSignal }) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/school/all`, undefined, { signal });
        return [err, err ? [] : xs.map(x => new School(x))] as const;
    }

    static async fetchAllRubricSchoolsByFacultyInDistrict({ facultyId, signal }: { facultyId: DbIdentity, signal?: AbortSignal }) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/school/rubrics`, undefined, { signal });
        return [err, err ? [] : xs.map(x => new School(x))] as const;
    }

    static async fetchAllPointScoreSchoolsByFacultyInDistrict({ facultyId, signal }: { facultyId: DbIdentity, signal?: AbortSignal }) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/school/pointscores`, undefined, { signal });
        return [err, err ? [] : xs.map(x => new School(x))] as const;
    }

    static async fetchAllGSSchoolsByFacultyInDistrict({ facultyId, signal }: { facultyId: DbIdentity, signal?: AbortSignal }) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/school/gradingscale`, undefined, { signal });
        return [err, err ? [] : xs.map(x => new School(x))] as const;
    }

    static async getSchoolById(facultyId:DbIdentity, schoolId:DbIdentity) {
        const [err, data] = await aFetch("GET", `/faculty/${facultyId}/school/${schoolId}`);
        const school = (err ? undefined : new School(data));
        return [err, school!] as const;
    }

    static async getSchool(schoolId:DbIdentity) {
        const [err, data] = await aFetch("GET", `/School/${schoolId}`);
        const school = (err ? undefined : new School(data));
        return [err, school!] as const;
    }

    static async getSchoolsAsFaculty(facultyId:DbIdentity) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/school`);
        return [err, err ? [] : xs.map(x => new School(x))] as const;
    }

    static async fetchSchoolsAsStudent(studentId:DbIdentity) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/student/${studentId}/school`);
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async getSchoolsOfDistrictAsAdmin({districtId, signal}: {districtId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/admin/district/${districtId}/school`, undefined, { signal });
        return [err, err ? [] : xs.map(x => new School(x))] as const;
    }

    static async getFacultyAdminSchools({facultyId, signal}: {facultyId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/admin/schools`, undefined, { signal });
        return [err, err ? [] : xs.map(x => new School(x))] as const;
    }

    static async getSchoolAsAdmin({districtId, schoolId, signal}:{districtId:DbIdentity, schoolId:DbIdentity, signal?: AbortSignal}) {
        const [err, x] = await aFetch<{}>("GET", `/admin/district/${districtId}/school/${schoolId}`, undefined, { signal });
        return [err, (err ? undefined : new School(x))!] as const;
    }

    static async programFetchSchools({facultyId, schoolIds, signal}: {facultyId:DbIdentity, schoolIds?: DbIdentity[], signal?: AbortSignal}) {
        const [err, xs] = await aFetch<School[]>("GET", `/faculty/${facultyId}/admin/schoolList`, { schoolIds }, { signal });
        return [err, err ? [] : xs.map(x => new School(x))] as const;
    }

    static async batchUpdateSchoolIndexes({facultyId}: {facultyId:DbIdentity}, schools: School[]) {
        const [err, xs] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/schools`, schools.map(x => x.toSchoolIndexesJS()));
        const cs = (err ? [] : xs.map(x => new School(x)));
        return [err, cs] as const;
    }

    static async batchUpdateStudentSchoolIndexes({studentId}: {studentId:DbIdentity}, schools: School[]) {
        const [err, xs] = await aFetch<{}[]>("PUT", `/student/${studentId}/schools`, schools.map(x => x.toSchoolIndexesJS()));
        const cs = (err ? [] : xs.map(x => new School(x)));
        return [err, cs] as const;
    }

    static sorter = {
        typeDescThenSchoolName: <T extends School>(a:T, b:T) => (b.schoolType - a.schoolType) || a.schoolName.localeCompare(b.schoolName),
        schoolName: <T extends School>(a?:T, b?:T) => (a ? a.schoolName : "").localeCompare(b ? b.schoolName : ""),
        sortIndex: <T extends School>(a?:T, b?:T) => (a?.sortIndex || 0) - (b?.sortIndex || 0),
    }
}

export class EditableSchool extends School {
    logoFile?: UploadFile = undefined;
    set_logoFile(v?:UploadFile|undefined) { this.logoFile = v; if(!v) this.logoUrl = ""; }
    clear_logo() { this.logoUrl = ""; this.logoFile = undefined; }

    darkmodeLogoFile?: UploadFile = undefined;
    set_darkmodeLogoFile(v?:UploadFile|undefined) { this.darkmodeLogoFile = v; if(!v) this.darkmodeLogoUrl = ""; }
    clear_darkmodeLogo() { this.darkmodeLogoUrl = ""; this.darkmodeLogoFile = undefined; }

    iconFile?: UploadFile = undefined;
    set_iconFile(v?:UploadFile|undefined) { this.iconFile = v }
    clear_icon() { this.iconUrl = ""; this.iconFile = undefined; }

    set_schoolName         (v: string    ) { this.schoolName         = v }
    set_currentGradingTerm (v: DbIdentity) { this.currentGradingTerm = v }
    set_principalId (v?: DbIdentity)        { this.principalId = v }

    constructor(data?:any) {
        super(data);
        makeObservable(this, {
            logoFile              : observable.ref,
            set_logoFile          : action.bound,
            clear_logo            : action.bound,
            darkmodeLogoFile      : observable.ref,
            set_darkmodeLogoFile  : action.bound,
            clear_darkmodeLogo    : action.bound,
            iconFile              : observable.ref,
            set_iconFile          : action.bound,
            clear_icon            : action.bound,
            set_schoolName        : action.bound,
            set_principalId       : action.bound,
            set_currentGradingTerm: action.bound,
        });
    }

    static from(school:School) {
        return new EditableSchool({
            ...school.toJS(),
            tGradingTerm:school.tGradingTerm.map(t => t.toJS()),
        });
    }
    toSchool() {
        return new School({
            ...this.toJS(),
            tGradingTerm:this.tGradingTerm.map(t => t.toJS()),
        });
    }

    async uploadLogos(){
        const pUploadLogo = (async () => {
            if (this.logoFile == null) return;
            const f = this.logoFile.originFileObj || (this.logoFile as any as File);
            const [err, url] = await uploadImage({ file: f, size: 'avatar', access: 'public' });
            if (!err) {
                this.set_logoFile(undefined);
                this.set_logoUrl(url);
                this.set_darkmodeLogoUrl(url);
            }return err;
        })();

        const pUploadDarkmodeLogo = (async () => {
            if (this.darkmodeLogoFile == null) return;
            const f = this.darkmodeLogoFile.originFileObj || (this.darkmodeLogoFile as any as File);
            const [err, url] = await uploadImage({ file: f, size: 'avatar', access: 'public' });
            if (!err) {
                this.set_darkmodeLogoFile(undefined);
                this.set_darkmodeLogoUrl(url);
            }
            return err;
        })();

        const pUploadIcon = (async () => {
            if (this.iconFile == null) return;
            const f = this.iconFile.originFileObj || (this.iconFile as any as File);
            const [err, url] = await uploadImage({ file: f, size: 'favIcon', access: 'public' });
            if (!err) {
                this.set_iconFile(undefined);
                this.set_iconUrl(url);
            }
            return err;
        })();

        const [uploadLogoErr, uploadIconErr, uploadDarkmodeErr] = await Promise.all([pUploadLogo, pUploadIcon, pUploadDarkmodeLogo]);
        return (uploadLogoErr || uploadIconErr || uploadDarkmodeErr);
    }

    async save(gradingTerm: GradingTerm) {
        const uploadErr = await this.uploadLogos();
        if (uploadErr != null) return [uploadErr, this] as const;

        const [err, s] = (this.schoolId < 1)
            ? await School.createSchool({gradingTerm, school: this})
            : await School.saveSchoolAsAdmin({school: this});
        if (!!err) return [err, this] as const;
        return [err, EditableSchool.from(s)] as const
    }
}

const isActualSchool = (s: School) => s.schoolType === SchoolType.Normal;

/** If the district contain only one actual school. Get that school*/
export const getOnlyActualSchool = (schools: School[]) => {
    var actualSchools = schools.filter(isActualSchool);
    if (actualSchools.length !== 1) return null;

    return actualSchools[0]!;
}