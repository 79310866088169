import { observable, action, computed, makeObservable } from "mobx";

import { DefaultId, DbIdentity, NumberDate, UrlString } from "./types";
import { Class } from "./Class";
import type { QualityMatrix } from "./QualityMatrix";
import { ActivityType, ActivityGroup, ICategoryConsumer } from "./Activity";
import { SubmissionTypeEnum } from "./SubmissionTypeEnum";
import { IModule } from "./Module";
import { IGradingSetting } from "./IGradingSetting";
import { stripHtml } from "../utils/html";
export class BaseActivity implements ICategoryConsumer, IGradingSetting {
    activityId         : DbIdentity = DefaultId;
    type               = ActivityType.Assignment;
    title              = '';
    instructionSummary = '';
    banner             : UrlString = '';
    isDeleted          = false;

    moduleId           : string = '';
    module            ?: IModule = { moduleId: "", moduleParentId: "",  moduleIndex: 0};

    color              = "#ffffff";
    // grading
    isGraded           = true;
    categoryId        ?: DbIdentity = undefined;
    maxScore           = 10;
    weight             = 1;
    isCredit           = false;
    rubricScoreGuideId?: DbIdentity = undefined;
    pointScoreGuideId ?: DbIdentity = undefined;
    isPublishGrade     = true;
    submissionType     = SubmissionTypeEnum.Online;
    timeLimit         ?: number = undefined;
    isGroup            = false;
    //
    instructionDocId   : string = '';
    ActDocId           : string = '';

    dateDue           ?: NumberDate = undefined;
    dateAssigned      ?: NumberDate = undefined;
    dateCutoff        ?: NumberDate = undefined;
    hasSubmitted       = false;
    groups             : ActivityGroup[]      = [];
    sortIndex          = -1;
    dateCreated        = Date.now();
    dateUpdated        = Date.now();
    class              = new Class();
    submissionCount    = 0;

    qualityIds         : DbIdentity[]         = [];

    selectedTags       : QualityMatrix[]      = [];

    // UI
    timeLimitHour     ?: number = undefined;
    timeLimitMinute   ?: number = undefined;

    isFormative        : boolean = false;

    constructor(data?:any) {
        makeObservable(this, {
            type                    : observable,
            title                   : observable,
            dateDue                 : observable,
            dateAssigned            : observable,
            dateCutoff              : observable,
            categoryId              : observable,
            color                   : observable,
            isGraded                : observable,
            maxScore                : observable,
            weight                  : observable,
            isCredit                : observable,
            isGroup                 : observable,
            hasSubmitted            : observable,
            groups                  : observable.shallow,
            sortIndex               : observable,
            dateCreated             : observable,
            dateUpdated             : observable,
            class                   : observable.ref,
            rubricScoreGuideId      : observable,
            pointScoreGuideId       : observable,
            submissionCount         : observable,
            timeLimit               : observable,
            timeLimitHour           : observable,
            timeLimitMinute         : observable,
            isDeleted               : observable,
            qualityIds              : observable,
            selectedTags            : observable,
            isPublishGrade          : observable,
            submissionType          : observable,
            moduleId                : observable,
            module                  : observable,
            isFormative             : observable,
            set_type                : action.bound,
            set_title               : action.bound,
            set_banner              : action.bound,
            set_dateDue             : action.bound,
            set_dateAssigned        : action.bound,
            set_dateCutoff          : action.bound,
            set_categoryId          : action.bound,
            set_color               : action.bound,
            set_isGraded            : action.bound,
            set_maxScore            : action.bound,
            set_weight              : action.bound,
            set_isCredit            : action.bound,
            set_hasSubmitted        : action.bound,
            set_isGroup             : action.bound,
            set_isDeleted           : action.bound,
            set_rubricScoreGuideId  : action.bound,
            set_pointScoreGuideId   : action.bound,
            set_submissionCount     : action.bound,
            set_timeLimit           : action.bound,
            set_timeLimitHour       : action.bound,
            set_timeLimitMinute     : action.bound,
            set_isPublishGrade      : action.bound,
            set_submissionType      : action.bound,
            set_groups              : action.bound,
            set_isExclude           : action.bound,
            set_isFormative         : action.bound,
            params                  : computed,
            timeLimitHourFormatted  : computed,
            timeLimitMinuteFormatted: computed,
            timeLimitFormatted      : computed,
            isGradable              : computed,
        });

        if (data != null) {
            if (data.timeLimit) {
                this.set_timeLimitHour(Math.trunc(data.timeLimit / 60));
                this.set_timeLimitMinute(data.timeLimit % 60);
            }
            Object.assign(this, data);
        }
        if (!this.color) this.color = "#ffffff";
    }

    set_type               (v : ActivityType       ) {          this.type               = v;                                                        };
    set_title              (v : string             ) {          this.title              = v;                                         };
    set_banner             (v : UrlString          ) {          this.banner             = v;                                                        };
    set_dateDue            (v?: number             ) {          this.dateDue            = v;                                                        };
    set_dateAssigned       (v?: number             ) {          this.dateAssigned       = v;                                                        };
    set_dateCutoff         (v?: number             ) {          this.dateCutoff         = v;                                                        };
    set_categoryId         (v?: DbIdentity         ) {          this.categoryId         = v;                                                        };
    set_color              (v : string             ) {          this.color              = v;                                                        };
    set_isGraded           (v : boolean            ) {          this.isGraded           = v;                                                        };
    set_maxScore           (v : number             ) {          this.maxScore           = v;                                                        };
    set_weight             (v : number             ) {          this.weight             = v;                                                        };
    set_isCredit           (v : boolean            ) {          this.isCredit           = v;                                                        };
    set_hasSubmitted       (v : boolean            ) {          this.hasSubmitted       = v;                                                        };
    set_isGroup            (v : boolean            ) {          this.isGroup            = v;                                                        };
    set_isDeleted          (v : boolean            ) {          this.isDeleted          = v;                                                        };
    set_rubricScoreGuideId (v?: DbIdentity         ) {          this.rubricScoreGuideId = v; if (v != null) this.set_pointScoreGuideId (undefined); };
    set_pointScoreGuideId  (v?: DbIdentity         ) {          this.pointScoreGuideId  = v; if (v != null) this.set_rubricScoreGuideId(undefined); };
    set_submissionCount    (v : number             ) {          this.submissionCount    = v;                                                        };
    set_timeLimit          (v?: number             ) {          this.timeLimit          = v;                                                        };
    set_timeLimitHour      (v?: number             ) {          this.timeLimitHour      = v; this.getTime();                                        };
    set_timeLimitMinute    (v?: number             ) {          this.timeLimitMinute    = v; this.getTime();                                        };
    set_isPublishGrade     (v : boolean            ) {          this.isPublishGrade     = v;                                                        };
    set_submissionType     (v : SubmissionTypeEnum ) {          this.submissionType     = v;                                                        };
    set_groups             (v : ActivityGroup[]    ) {          this.groups             = v;                                                        };
    set_isExclude          (v : boolean            ) { if (v) { this.weight             = 0; } else { if (this.weight <= 0) this.weight = 1.; }     };
    set_isFormative        (v : boolean            ) {          this.isFormative        = v;                                                        };

    get params() { return ({activityId:String(this.activityId)}) }

    getTime() {
        const timeLimit = ((this.timeLimitHour ?? 0) * 60) + (this.timeLimitMinute ?? 0)
        return this.timeLimit = (timeLimit <= 0 ? undefined : timeLimit);
    }
    get timeLimitHourFormatted() { return `${("00" + this.timeLimitHour).slice(-2)}` }
    get timeLimitMinuteFormatted() { return `${("00" + this.timeLimitMinute).slice(-2)}` }
    get timeLimitFormatted() { return `${this.timeLimitHourFormatted}:${this.timeLimitMinuteFormatted}` }

    get isGradable() {
        return  this.type == ActivityType.Assignment
            ||  this.type == ActivityType.Assessment
            || (this.type == ActivityType.Discussion && this.isGraded);
    }
    isOverdue() {
        return this.dateDue != null && this.dateDue < Date.now();
    }

    static sorter = {
        dateDue         : <T extends BaseActivity>(a: T, b: T) => ((a.dateDue || 0) - (b.dateDue || 0)),
        dateDueNA       : <T extends BaseActivity>(a: T, b: T) => {
            if(a.dateDue == null || a.dateDue <= 0) {
                return (b.dateDue == null || b.dateDue <= 0) ? 0 : 1;
            } else {
                if (b.dateDue == null || b.dateDue <= 0) return -1;
                return ((a.dateDue || 0) - (b.dateDue || 0));
            }
        },
        dateAssigned    : <T extends BaseActivity>(a: T, b: T) => ((a.dateAssigned || 0) - (b.dateAssigned || 0)),
        dateCreated     : <T extends BaseActivity>(a: T, b: T) => ((a.dateCreated || 0) - (b.dateCreated || 0)),
        dateUpdated     : <T extends BaseActivity>(a: T, b: T) => ((a.dateUpdated || 0) - (b.dateUpdated || 0)),
        title           : <T extends BaseActivity>(a: T, b: T) => (stripHtml(a.title).localeCompare(stripHtml(b.title))),
        sortIndex       : <T extends BaseActivity>(a: T, b: T) => (a.sortIndex - b.sortIndex),
        weight          : <T extends BaseActivity>(a: T, b: T) => (a.weight    - b.weight   ),

        className       : <T extends BaseActivity>(a: T, b: T) => (a.class.className.localeCompare(b.class.className)),
        isGraded        : <T extends BaseActivity>(a: T, b: T) => ((a.isGraded ? 1 : 0) - (b.isGraded ? 1 : 0)),
    };
}
