import { Modal as ModalAndtd, Statistic  } from "antd";
import type { ModalFuncProps } from "antd/lib/modal";
import { Trans } from "react-i18next";
import i18n from "../../i18n";

import { sessionStorage } from "../../utils/localStorage";
import { ClientErrorLog } from "../../models/ClientErrorLog";

const { Countdown } = Statistic;

export const isShowedConnectionFailModal = "isShowedConnectionFailModal";

export class ModalAntd {
    static error (props: ModalFuncProps){
        props.title = !props.title ? i18n.t('component.modal.error') : props.title;
        props.okText = !props.okText ? i18n.t('form.ok') : props.okText;
        return ModalAndtd.error(props);
    }

    static info (props: ModalFuncProps){
        props.title = !props.title ? i18n.t('component.modal.info') : props.title;
        props.okText = !props.okText ? i18n.t('form.ok') : props.okText;
        return ModalAndtd.info(props);
    }

    static success (props: ModalFuncProps){
        props.title = !props.title ? i18n.t('component.modal.success') : props.title;
        props.okText = !props.okText ? i18n.t('form.ok') : props.okText;
        return ModalAndtd.success(props);
    }

    static warning (props: ModalFuncProps){
        props.title = !props.title ? i18n.t('component.modal.warning') : props.title;
        props.okText = !props.okText ? i18n.t('form.ok') : props.okText;
        return ModalAndtd.warning(props);
    }

    static deleteConfirm(props: ModalFuncProps){
        return this.confirm({
            title     : i18n.t('component.modal.confirm.delete'),
            okText    : i18n.t("form.yes"),
            okType    : "danger",
            cancelText: i18n.t("form.no"),
            zIndex    : 1060,
            ...props
        });
    }

    static confirm(props: Omit<ModalFuncProps, "onOk"|"onCancel">){
        props.title      = !props.title      ? i18n.t('component.modal.confirm'): props.title;
        props.okText     = !props.okText     ? i18n.t('form.yes')               : props.okText;
        props.cancelText = !props.cancelText ? i18n.t('form.no')                : props.cancelText;
        props.zIndex = 1060;
        return new Promise<boolean>(resolve => {
            ModalAndtd.confirm({
                ...props,
                onOk() { resolve(true) },
                onCancel() { resolve(false) }
            });
        });
    }


    static async connectionFail(errLog: ClientErrorLog){
        if(!sessionStorage.getItem(isShowedConnectionFailModal)){
            sessionStorage.setItem(isShowedConnectionFailModal, "true");

            // send error detail to server to log
            const [err, clientLogId] = await ClientErrorLog.addLog(errLog);

            function onOk(){
                sessionStorage.removeItem(isShowedConnectionFailModal);
                location.reload();
            }

            function onCancel(){
                sessionStorage.removeItem(isShowedConnectionFailModal);
            }

            const deadline = Date.now() + 1000 * 15;//refresh page after 15 seconds
            const modal = ModalAndtd.confirm({
                title: i18n.t('component.modal.error'),
                content:<Trans i18nKey="component.modal.err.connectionLost.content"><Countdown value={deadline} onFinish={onOk} format="ss" valueStyle={{"font-size": '16px', "font-weight":"500"}} className="inline-block" />{clientLogId?`LogId=${clientLogId}. `:''}</Trans>,
                className : "multi-line-text", //apply line break \n to content
                okText: i18n.t('component.modal.err.retryNow'),
                cancelText: i18n.t('component.modal.err.close'),
                zIndex: 1060,
                onOk:onOk,
                onCancel: onCancel,
                autoFocusButton: 'cancel'
              });
            return modal;
        }
        return;
    }
}

export const Modal = ModalAntd;
