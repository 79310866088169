import { IErrorData } from "../../services/api/AppError";
import { ActDoc } from "../ActDoc";
import { DbIdentity } from "../types";

export enum ImportMethod {
    None        = 0,
    ActDoc      = 1 << 0,
    Instruction = 1 << 1,
    LessonPlan  = 1 << 2,
    All         = ActDoc | Instruction | LessonPlan,
    /**
     * lesson plan is a nested doc in instruction Doc
     * @see InstructionActDoc.lessonPlanDoc
    */ 
    InstructionOrLessonPlan = Instruction | LessonPlan,
}

export type ImportType = 'activity' | 'resource' | 'template';

export interface IImportSource {
    type             : ImportType
}

export type ResourceImport = IImportSource & {
    type             : 'resource',
    resourceGroupId  : DbIdentity,
    activityId       : DbIdentity
}

export type ActivityImport = IImportSource & {
    type             : 'activity',
    activityId       : DbIdentity
}

export type TemplateImport = IImportSource & {
    type               : 'template',
    activityTemplateId : DbIdentity
}

export type ImportSourceType = ResourceImport | TemplateImport | ActivityImport;

export interface IImportedValue {
    source                 : ImportSourceType,
    importMethod           : ImportMethod ,
    isMerge                : boolean      ,
    isImportingAttachment ?: boolean      ,
}

export interface IImportedActDoc {
    importActItems   (doc   : ActDoc) : Promise<IErrorData<any> | undefined>,
    importDocument   (value : Omit<IImportedValue, 'isImportingAttachment'>) : Promise<IErrorData<any> | undefined>,
    importAttachment (source: IImportSource) : Promise<void>,
}