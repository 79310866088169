import { aFetch, downloadFileAsBlob } from "../../services/api/fetch";
import { OneDriveItem } from "../../modules/Microsoft/models/FilePicking";
import { action, makeObservable, observable, toJS } from "mobx";
import { DbIdentity, DefaultId, EMSOneDriveMimeType } from "../types";
import { normalizeOneDriveFileEmbed } from "../../modules/Microsoft/utils/url";
import { ActivityType } from "../ActivityType";
import { qs2URLSearchParams } from "../../utils/url";
import { extractFileNameFromUrl, processSpecialFile } from "../../utils/file";
import { uploadGeneralFile } from "../../services/api/upload";
import { ActItem } from "../ActDoc";

export class OneDrive {
    static async createShareLink(data : OneDriveItem) {
        const [err, sharedUrl] = await aFetch<string>("PUT", `/MicrosoftApi/OneDrive/CreateShareLink`, toJS({driveId: data.parentReference.driveId, driveItemId: data.id}));
        return [err, (err ? undefined : sharedUrl)] as const;
    }

    static async createFile(file: CreateMicrosoftAssignmentRequest) {
        const [err, result] = file.classId ? await aFetch<{}>("POST", `/MicrosoftApi/OneDrive/file`, file.toJS())
                                           : await aFetch<{}>("POST", `/MicrosoftApi/OneDrive/cloneFileForResourceActivity`, file.toJS());
        return [err, !result ? undefined : new MicrosoftFile(result)] as const;
    }

    static async createNewFile(file: CreateMicrosoftAssignmentRequest) {
        const [err, result] = file.classId ? await aFetch<{}>("POST", `/MicrosoftApi/OneDrive/createFile`, file.toJS())
                                           : await aFetch<{}>("POST", `/MicrosoftApi/OneDrive/createFileForResourceActivity`, file.toJS());
        return [err, !result ? undefined : new MicrosoftFile(result)] as const;
    }
    static async markFileAsArchived({classId, activityId, items}:{classId ?: number, activityId: number, items: string[]}) {
        const [err] = await aFetch<{}>("POST", `/MicrosoftApi/OneDrive/markArchived`, {
            classId     : classId,
            activityId  : activityId,
            activeFiles : items
        });
        return err;
    }

    /** this request will download file on the backend, then copy to blob and return the url that file storage in our blob */
    static async downloadAndPublicMicrosoftFile(item: MicrosoftFile, createThumb ?: boolean) {
        const [err, url] = await aFetch<string>("POST", `/MicrosoftApi/OneDrive/transferToBlob?${qs2URLSearchParams({isPublic: true, createThumb}).toString()}`, toJS(item));
        if(!err && /\.heic$/i.test(url)){
            const fileName = extractFileNameFromUrl(url);
            const processedFile = await processSpecialFile({
                name: fileName,
                url: url
            });
            const [uErr, uploadedFile] = await uploadGeneralFile(processedFile, true);
            if(!uErr && !!uploadedFile){
                return [undefined, uploadedFile.link] as const;
            }
        }
        return [err, (err ? undefined : url)!] as const;
    }

    /** this request just try to download file from MS and return file stream */
    static async downloadMicrosoftFile(item: MicrosoftFile) {
        return await downloadFileAsBlob("POST",'/MicrosoftApi/OneDrive/download',toJS(item));
    }

    static async getExistingMicrosoftItemAsFaculty(classId: DbIdentity, facultyId: DbIdentity, activityType ?: ActivityType ) {
        const [err, rs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/class/${classId}/microsoftActItems` + (activityType ? `?activityType=${activityType}` : ""));
        return [err, !rs ? [] : rs.map(r => new MicrosoftFile(r))] as const;
    }
}

interface IBaseMicrosoftFile {
    id      : string;
    name    : string;
    set_name: (v?: string) => void
}

export class BaseMicrosoftFile implements IBaseMicrosoftFile {
    id   : string = "";
    name : string = ""; set_name(v?: string) {this.name = v ?? "";}
    constructor(data ?: {}) {
        makeObservable(this, {
            name     : observable,
            set_name : action.bound,
        });
        if (data) Object.assign(this, data);
    }

    toJS() { return toJS(this); }
}

export class MicrosoftFile implements IBaseMicrosoftFile {
    type     ?: string = undefined;
    embedUrl ?: string = undefined;
    sharedUrl?: string = undefined;
    id        : string = ""       ;
    name      : string = ""       ; set_name(v?: string) { this.name = v ?? ""; }
    typeName    : string = ""     ;
    displayName : string = ""     ;
    constructor(data ?: any) {
        if (data) {
            const { embedUrl, sharedUrl, type, ...pData} = data;
            Object.assign(this, pData);
            this.embedUrl = !embedUrl ? undefined : normalizeOneDriveFileEmbed(embedUrl??"");
            this.sharedUrl = !sharedUrl ? undefined : normalizeOneDriveFileEmbed(sharedUrl??"");
            this.type        = type;
            this.typeName    = getTypeName(type);
            this.displayName = this.name.replace(`.${type}`, "").trim();
        }
    }

    static sorter = {
        name : (a: MicrosoftFile, b: MicrosoftFile) => a.displayName.localeCompare(b.displayName),
    }
    static mapFrom(item: ActItem) {
        return new MicrosoftFile({
            id            : item.embedId    ,
            mimeType      : item.embedContentType,
            embedId       : item.embedId         ,
            name          : item.embedName,
            type          : item.embedType,
            lastEditedUtc : item.embedLastEdited,
        });
    }
}

export class CreateMicrosoftAssignmentRequest implements IBaseMicrosoftFile {
    id         : string = "";
    name       : string = ""; set_name(v?: string){ this.name = v ?? ""; }
    classId    : DbIdentity = DefaultId;
    activityId : DbIdentity = DefaultId;
    type       : EMSOneDriveMimeType = EMSOneDriveMimeType.Word; set_type(v: EMSOneDriveMimeType){ this.type = v; }

    constructor(data?: any) {
        makeObservable(this, {
            type     : observable,
            set_type : action.bound,
            name     : observable,
            set_name : action.bound,
        });

        if (data) Object.assign(this, data);
    }
    toJS() { return ({
        id         : this.id,
        name       : this.name.trim(),
        classId    : this.classId,
        activityId : this.activityId,
        type       : this.type,
    })}
}

function getTypeName(type?: string){
    switch(type){
        case "docx": return "Word";
        case "xlsx": return "Excel";
        case "pptx": return "PowerPoint";
        default: return ""
    }
}