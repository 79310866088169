export enum ApplicationRoleValue
{
    Unknown     = 0,
    Admin       = 1 << 0,
    Faculty     = 1 << 1,
    Parent      = 1 << 2,
    Student     = 1 << 3,
    SuperAdmin  = 1 << 4,
    SchoolAdmin = 1 << 5,
    All         = 0xfffffff,
}
