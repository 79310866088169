import { observable, toJS, makeObservable } from "mobx";
import { aFetch } from "../services/api/fetch";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";

import { DbIdentity, DefaultId, NumberDate } from "./types";

export class ClassInvitation {
    constructor(data?: {}) {
        if (data != null) {
            Object.assign(this, data);
        }

        makeObservable(this, {
            classInvitationId  : observable,
            classId            : observable,
            studentId          : observable,
            email              : observable,
            invitationId       : observable,
            isViewed           : observable,
            isDeclined         : observable,
            createdDateAccount : observable,
            joinedDate         : observable,
        });
    }

    classInvitationId   : DbIdentity = DefaultId;
    classId             : DbIdentity = DefaultId;
    studentId          ?: DbIdentity = undefined;
    email               : string     = "";
    invitationId        : string     = "";
    isViewed            : boolean = false;
    isDeclined          : boolean = false;
    createdDateAccount ?: NumberDate = undefined;
    joinedDate         ?: NumberDate = undefined;

    toJS() {
        return toJS(this);
    }

    static async getInvitations({ facultyId, classId, signal }: { facultyId: DbIdentity, classId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<GeneralDto>("GET", `faculty/${facultyId}/class/${classId}/invitations`);
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async sendInvitationByEmails({ facultyId, classId, listData, signal }: { facultyId: DbIdentity, classId: DbIdentity, listData: string, signal?: AbortSignal }) {
        const [err, d] = await aFetch<{data: GeneralDto, errorData ?: string}>("POST", `faculty/${facultyId}/class/${classId}/sendInvitationByEmails`, listData);
        return [err, d?.errorData, (err ? undefined : parseGeneralViewModel(d.data))!] as const;
    }

    static async resendInvitation({ facultyId, classId, classInvitationId, signal }: { facultyId: DbIdentity, classId: DbIdentity, classInvitationId: DbIdentity, signal?: AbortSignal }) {
        const [err] = await aFetch<{}>("POST", `faculty/${facultyId}/class/${classId}/invitation/${classInvitationId}/resend`);
        return [err] as const;
    }

    static async deleleInvitation({ facultyId, classId, classInvitationId, signal }: { facultyId: DbIdentity, classId: DbIdentity, classInvitationId: DbIdentity, signal?: AbortSignal }) {
        const [err] = await aFetch<{}>("DELETE", `faculty/${facultyId}/class/${classId}/invitation/${classInvitationId}`);
        return [err] as const;
    }

    static async updateIsViewed({ publicLinkId, invitationId, signal }: { publicLinkId: string, invitationId: string, signal?: AbortSignal }) {
        const [err] = await aFetch<{}>("PUT", `public/class/${publicLinkId}/invitation/${invitationId}/updateIsViewed`);
        return [err] as const;
    }

    static async joinClass({ studentId, publicLink, joinPassword, signal }: { studentId: DbIdentity, publicLink: string, joinPassword ?: string, signal?: AbortSignal }) {
        const [err, classId] = await aFetch<DbIdentity>("POST", `student/${studentId}/joinClass`, {publicLink, joinPassword});
        return [err, classId] as const;
    }

    static sorter = {
        email        : (a : ClassInvitation, b : ClassInvitation) => ((a.email??"").localeCompare(b.email??"")),
    }
}
