import {DbIdentity, DefaultId } from "./types";
import { aFetch } from "../services/api/fetch";
import { BaseDoc } from "./BaseDoc";
import { ActItem } from "./ActDoc";
import { action, makeObservable, observable } from "mobx";

export class PageDoc extends BaseDoc {
    pageId: DbIdentity = DefaultId;
    hasBlockWord : boolean = false; set_hasBlockWord(v: boolean) { this.hasBlockWord = v; };

    constructor(data:any) {
        super(data);
        makeObservable(this, {
            hasBlockWord       : observable,
            set_hasBlockWord   : action.bound,
        })
        // has been init from BaseDoc
        // if (data != null) {
        //     const {items, bannerBlendOptions, banner, ...pData} = data;
        //     if (Array.isArray(items)) this.items = items == null ? [] : items.map(item => new ActItem(item));
        //     Object.assign(this, pData);
        // }
        if (this.title && !/<[a-z][\s\S]*>/i.test(this.title)) {
            this.title = "<p>" + this.title + "</p>";
        }
    }

    override toJS() {
        return ({
            id                 : this.id,
            pageId             : this.pageId,
            title              : this.title,
            banner             : this.banner,
            summary            : this.summary,
            bannerBlendOptions : this.bannerBlendOptions,
            isTinyMCE          : this.isTinyMCE,
            items              : this.items.map(item => (new ActItem(item)).toJS()),
        });
    }

    async save({ groupId, pageId }:{groupId:DbIdentity, pageId:DbIdentity}) {
        if (!this.id) {
            const [err, x] = await aFetch<{}>("POST", `/group/${groupId}/page/${pageId}/doc`, this.toJS())
            return [err, (err ? undefined : new PageDoc(x))!] as const;
        }
        const [err, x] = await aFetch<{}>("PUT", `/group/${groupId}/page/${pageId}/doc`, this.toJS())
        return [err, (err ? undefined : new PageDoc(x))!] as const;
    }

    async saveEventDoc({ eventId, pageId }:{eventId:DbIdentity, pageId:DbIdentity}) {
        const [err, x] = await aFetch<{}>("PUT", `/event/${eventId}/page/${pageId}/doc`, this.toJS())
        return [err, (err ? undefined : new PageDoc(x))!] as const;
    }
}
