import { action, computed, makeObservable, observable } from "mobx";
import { DbIdentity, HtmlString, UrlString } from "../types";
import { UploadFile } from "antd/lib/upload/interface";
import { stripHtml } from "../../utils/html";
import { BuildCacheName_StudentDoc } from "../../config";
import { StudentCacheIdbStore } from "../../utils/idbKeyval";
import { aFetch } from "../../services/api/fetch";
import { ActItemPollType } from "./ActItem";

export interface IPollQuizItem {
    label    : HtmlString,
    image    : UrlString,
}

export interface IVote{
  pollType: ActItemPollType;
  voteIds: number[];
  voteText: string;
}

export class PollQuizItem implements IPollQuizItem {
    label: HtmlString = "";
    image: UrlString  = "";
    votes: number     = 0;

    constructor(data?:{}) {
        makeObservable(this, {
            label        : observable,
            image        : observable,
            votes        : observable,
            set_label    : action.bound,
            set_image    : action,
            imageFile    : observable.ref,
            clear_image  : action.bound,
            set_imageFile: action.bound,
            getLabel     : computed,
            isRequired   : computed,
        });

        if (data != null) Object.assign(this, data);
    }

    set_label(v: HtmlString ) { this.label     = v };
    set_image(v: UrlString  ) { this.image     = v };

    imageFile?:UploadFile = undefined;
    clear_image() { this.imageFile = undefined; this.image = ""; };
    set_imageFile(v:UploadFile|undefined) { this.imageFile = v };

    get getLabel(): HtmlString {
        return stripHtml(this.label).trim();
    }

    get isRequired(): boolean|undefined {
        return !(this.getLabel  != "" || this.imageFile != undefined || this.image  != "");
    }

    static calculatePercent(votes: number, totalStudent:number) {
        return (totalStudent == 0) ? 0 : Math.round(votes * 100 / totalStudent);
    }

    static async fetchAsStudent(studentId:DbIdentity, activityId:DbIdentity, pollQuizId: string, withCache ?: boolean) {
        if (withCache) { //if exists studentdoc in cache, we wont get poll results for db.
            const cacheName = BuildCacheName_StudentDoc(studentId, activityId);
            if (await new StudentCacheIdbStore().has(cacheName)) return [undefined, undefined] as const;
        }

        const [err, x] = await aFetch<IVote>("GET", `/student/${studentId}/activity/${activityId}/pollQuizId/${pollQuizId}`);
        const d = (err ? undefined : x)!
        return [err, d] as const;
    }


    static async saveVote(studentId:DbIdentity, activityId:DbIdentity, pollQuizId: string, vote: IVote){
        const [err] = await aFetch<PollQuizItem>("PUT", `/student/${studentId}/activity/${activityId}/pollQuizId/${pollQuizId}/votes`, vote);
        return err;
    }

    toJS(): IPollQuizItem {
        return ({
            label     : this.label,
            image     : this.image
        });
    }
}