import { NumberDate } from "../models/types";

export function shuffle<T>(xs:T[]): T[] {
    //👍
    return xs.map(x => ({r:Math.random(), x}))
             .sort((a,b) => a.r - b.r)
             .map(({x}) => x);
    //👎
    return [...xs].sort(_ => (Math.random() - .5));
}

export function notNull<T>(x:T|null|undefined): x is T {
    return (x != null);
}

export function sortXThenY<T>(sorts:Array<(a?:T, b?:T) => number>) {
    return (a?:T, b?:T) => {
        for (const sort of sorts) {
            const d = sort(a, b);
            if (d != 0) return d;
        }
        return 0;
    };
}

export function sortXThenYNotNull<T>(sorts:Array<(a:T, b:T) => number>) {
    return (a:T, b:T) => {
        for (const sort of sorts) {
            const d = sort(a, b);
            if (d != 0) return d;
        }
        return 0;
    };
}


export function stringSorter<T>(selector: (s: T) => string) { return (a: T, b: T) => selector(a).localeCompare(selector(b)) }
export function stringSorterDesc<T>(selector: (s: T) => string) { return (a: T, b: T) => -(selector(a).localeCompare(selector(b))) }
export function dateSorter<T>(selector: (s: T) => NumberDate | undefined) { return (a: T, b: T) => (selector(a) ?? -1) - (selector(b) ?? -1) }
export function dateSorterDesc<T>(selector: (s: T) => NumberDate | undefined) { return (a: T, b: T) => -((selector(a) ?? -1) - (selector(b) ?? -1)) }
export function numberSorter<T>(selector: (s: T) => number) { return (a: T, b: T) => (selector(a) ?? -1) - (selector(b) ?? -1) }
export function numberSorterDesc<T>(selector: (s: T) => number) { return (a: T, b: T) => (selector(b) ?? -1) - (selector(a) ?? -1) }
/**
 * Ex: <br/>
 * ```
 * const list = ["1aaa", "2bc", "10aaa", "10ab", "3ddd"];
 * const sortedList = list.sort(stringAsNumericSorterDesc(x => x))
 * /// returns: sortedList = ["1aaa", "2bc", "3ddd", "10aaa", "10ab"]
 * ```
 */
export function stringAsNumericSorterDesc<T>(selector: (s: T) => string) { return (a: T, b: T) => selector(a).localeCompare(selector(b), undefined, {numeric: true, sensitivity: 'base'}) }

/**
 * Replace matched items in array
 */
export function inArrayReplace<T>(xs: T[], replacer: T[], indexer: (x:T) => string | number) {
    replacer.forEach(item => {
        const key = indexer(item);
        const index = xs.findIndex(x => indexer(x) === key);
        if (index > 0) {
            xs.splice(index, 1, item);
        }
    });
    return xs;
}

