import { observable, makeObservable, toJS } from "mobx";

import {DbIdentity, DefaultId} from "./types";

import { aFetch } from "../services/api/fetch";
import { IFolder, FolderType, BatchCreateFolder, BatchCreateResourceFolder } from "./Folder";
import { QuestionDocItem } from "./QuestionItemDoc";
import { ResourceModule } from "./ResourceModule";

interface IResourceFolder extends IFolder {
    resourceGroupId    ?: DbIdentity,
}
export class ResourceFolder implements IResourceFolder {
    folderId        : DbIdentity       = DefaultId;
    name            : string           = "";
    moduleId        : string           = "";
    /** @deprecated */
    parentFolderId  : DbIdentity       = ResourceFolder.rootFolderId;

    moduleParentId ?: string           = undefined;
    type           ?: FolderType       = FolderType.Activity;
    resourceGroupId?: DbIdentity       = undefined;

    childrenFolder  : ResourceFolder[] = [];
    module         ?: ResourceModule   = undefined;

    constructor(data?:any) {
        makeObservable(this, {
            name              : observable,
            parentFolderId    : observable,
            type              : observable,
            childrenFolder    : observable,
            resourceGroupId   : observable,
            moduleParentId    : observable,
            module            : observable,
        });

        if (data != null) {
            Object.assign(this, data);
            this.moduleParentId = this.moduleParentId ?? data.resourceModule?.moduleParentId;
            this.module =  new ResourceModule(this.module ?? data.resourceModule);
        }
        if (this.parentFolderId == null || this.parentFolderId < 1) this.parentFolderId = ResourceFolder.rootFolderId;

    }

    toJS() {
        return ({
            folderId       : this.folderId,
            parentFolderId : this.parentFolderId,
            moduleParentId : this.moduleParentId,
            name           : this.name,
            type           : this.type,
            resourceGroupId: this.resourceGroupId,
            moduleId       : this.moduleId,
            module         : this.module?.toJS(),
        });
    }

    clone() { return new ResourceFolder(this.toJS()) }

    async save(data?: {}) {
        if (this.folderId < 1) {
            const [err, vm] = await aFetch<IVm>("POST", `resourceGroup/${this.resourceGroupId}/folder`, { ...this.toJS(), ...data });
            return [err, parseVm(err ? {} : vm)] as const;
        }

        const [err, vm] = await aFetch<IVm>("PUT", `resourceGroup/${this.resourceGroupId}/folder/${this.folderId}`, { ...this.toJS(), ...data });
        return [err, parseVm(err ? {} : vm)] as const;
    }

    static async getFoldersByType(resourceGroupId: DbIdentity, type: FolderType) {
        const [error, data] = await aFetch<{}[]>("GET", `resourceGroup/${resourceGroupId}/folder/folderByType/${type}`);
        return [error, (error ? undefined : data.map(p => new ResourceFolder(p)))!] as const;
    }

    static async getFoldersByResourceGroup({ resourceGroupId, type, signal }: { resourceGroupId: DbIdentity, type?: FolderType, signal?: AbortSignal }) {
        const [error, data] = await aFetch<{}[]>("GET", `resourceGroup/${resourceGroupId}/folder`, { type }, { signal });
        return [error, (error ? [] : data.map(p => new ResourceFolder(p)))] as const;
    }

    async createFolder() {
        const [err, data] = await aFetch<ResourceFolder>("POST", `resourceGroup/${this.resourceGroupId}/folder`, this.toJS());
        return [err, (err ? this : new ResourceFolder(data))] as const;
    }

    async updateFolder({folderId}:{resourceGroupId: DbIdentity, folderId: DbIdentity}) {
        const [err, data] = await aFetch<ResourceFolder>("PUT", `resourceGroup/${this.resourceGroupId}/folder/${folderId}`, this.toJS());
        const vm = err ? undefined : new ResourceFolder(data);
        return [err, vm] as const;
    }

    static async deleteFolder(resourceGroupId: DbIdentity, folderId: DbIdentity) {
        const [err, data] = await aFetch<ResourceFolder>("DELETE", `resourceGroup/${resourceGroupId}/folder/${folderId}`);
        return [err, (err ? undefined : new ResourceFolder(data))] as const;
    }

    static async moveQuestionToFolder({resourceGroupId, folderId, questionId}: {resourceGroupId: DbIdentity, folderId?: DbIdentity, questionId:string}) {
        const [err, data] = await aFetch<{}[]>("PUT", `resourceGroup/${resourceGroupId}/folder/${folderId == null ? "" : `${folderId}/`}/questionBank/${questionId}`);
        return [err, (err ? undefined : new QuestionDocItem(data))] as const;
    }

    static async batchCreate(resourceGroupId: DbIdentity, data: BatchCreateResourceFolder) {
        const [err, dto] = await aFetch<{ mTempModuleId2ModuleId ?: {tempModuleId: string, moduleId: string }[], folders ?: ResourceFolder[] }>("POST", `resourceGroup/${resourceGroupId}/folder/batch`, toJS(data));
        const folders = err ? [] : dto?.folders?.map(x => new ResourceFolder(x)) ?? [];
        const mTempModuleId2ModuleId = new Map<string, string>();
        dto?.mTempModuleId2ModuleId?.forEach(m => { mTempModuleId2ModuleId.set(m.tempModuleId, m.moduleId) });
        return [err, folders, mTempModuleId2ModuleId] as const;
    }

    static async batchUpdate(resourceGroupId: DbIdentity, xs:ResourceFolder[]) {
        const [err, ys] = await aFetch<{}[]>("PUT", `resourceGroup/${resourceGroupId}/folder`, xs.map(x => x.toJS()));
        return [err, err ? [] : ys.map(y => new ResourceFolder(y))] as const;
    }

    static sorter = {
        name: (a?: ResourceFolder, b?: ResourceFolder) => (a?.name.trim()  ?? "").localeCompare(b?.name.trim()  ?? ""),
        //sortIndex: (a?: ResourceFolder, b?: ResourceFolder) => ((a ? a.sortIndex : 0) - (b ? b.sortIndex : 0)),
        //sortIndexThenName: (a?: ResourceFolder, b?: ResourceFolder) => {
        //    const i = ResourceFolder.sorter.sortIndex(a, b);
        //    return i != 0 ? i : ResourceFolder.sorter.name(a, b);
        //},
    };

    static rootFolderId: DbIdentity = 0;
}

interface IVm { resourceFolders?: {}[], resourceModules?:{}[]  }
function parseVm(data: IVm) {
    const resourceFolders = Array.isArray(data.resourceFolders) ? data.resourceFolders.map(f => new ResourceFolder(f)) : [];
    const resourceModules = Array.isArray(data.resourceModules) ? data.resourceModules.map(f => new ResourceModule(f)) : [];
    return { resourceFolders, resourceModules };
}
