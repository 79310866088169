import type { FocusEvent, SyntheticEvent } from "react";
import { ActivityType } from "../models/Activity";
import type { Comment } from "../models/Comment";
import type { DbIdentity } from "../models/types";
import i18n from "../i18n";

/** show if the use is working on addBtn */
export const addBtnActiveClassName = "addBtn-doc-edit-active";
// add specific class selector to check if it is in edit mode
const isInEdittingModeClassSelectors = [addBtnActiveClassName];

export function isInEdittingMode(event: FocusEvent<HTMLDivElement, Element>) {
    if(!event.target) return false; // for tinymce hotfix
    if (event.target.classList.contains('annotator-widget')
        || (event.relatedTarget != null && event.currentTarget.contains(event.relatedTarget as any))
        || event.currentTarget.contains(document.activeElement)
        || isAntUpload(event.target as Element)
        || isAntModal(event.relatedTarget as Element)
        || isvideoRecording(event.relatedTarget as Element)
        || isGooglePickerDialog(event.relatedTarget as Element)
        || isColorPickerPopover(event.relatedTarget as Element)
        || isActiveUploadFrom(event.relatedTarget as Element)
        || isBlendOptions(event.target as Element)
        || event.target.matches('.se-btn.se-tooltip')
        || isSunEditorImageChosenEvent(event.nativeEvent)
        || (isTinyMce(event) && hasDisplayedModal(document))
        || classSelectorsExist(isInEdittingModeClassSelectors)
    ) return true;
    return false;
}

/** check for click/touch Event */
export function isInEditingModeWhenClick(event: MouseEvent | TouchEvent) {
    const targetEle = event.target as Element;
    if(!targetEle) return false;
    return (isAntModal(targetEle)
        || isAntSelect(targetEle)
        || isvideoRecording(targetEle)
        || isGooglePickerDialog(targetEle)
        || isColorPickerPopover(targetEle)
        || isActiveUploadFrom(targetEle)
        || isBlendOptions(targetEle)
        || targetEle.matches('.se-btn.se-tooltip')
        || hasDisplayedModal(document)
        || classSelectorsExist(isInEdittingModeClassSelectors)
        || isFocusInElements(targetEle, [submitButtonClass])
    );
}

/**
 * Check classNames (class Selector) that we want to check if it is in edit mode.
 * Use when we want to check:
 * - if the class is in edit mode only and not in other mode
 * - if this class exist in the document that mean it is in edit mode
*/
const classSelectorsExist = (classNames ?: string[]) => {
    if (!classNames || classNames.length < 1) return false;
    for (let classSelector of classNames) {
        if (document.getElementsByClassName(classSelector).length > 0) return true;
    }
    return false;
}

export const submitButtonClass = "submit-button";
const isFocusInElements = (e: Element, classNames ?: string[]) => {
    if (!e || !classNames || classNames.length < 1) return false;
    for (let classSelector of classNames) {
        if (e.closest(`.${classSelector}`)) return true;
    }
    return false;
}

const isBlendOptions = (e: Element) =>{
    return e != null && (
        (e.closest(".ant-dropdown-open") != null)
    );
}
const isvideoRecording = (e: Element) =>{
    return e != null && (
        (e.closest("#divVideoRecordingPlaceholder") != null)
    );
}

/** when target element is Ant Modal */
export const isAntModal = (e: Element) => {
    return e != null && (
        (e.closest(".ant-modal-root") != null)
    );
};

/** when target element is Ant Modal */
export const isAntSelect = (e: Element) => {
    return e != null && (
        (e.closest(".ant-select-item") != null)
    );
};


/** when target element is Ant Upload */
export const isAntUpload = (e: Element) => {
    return e != null && (
        (e.closest(".ant-upload") != null || e.parentElement?.closest(".ant-modal"))
    );
};

/** when target element is Google picker dialog */
export const isGooglePickerDialog = (e: Element) => {
    return e != null && (
        (e.closest(".picker-dialog") != null)
    );
};

/** when target element is color picker popover */
export const isColorPickerPopover = (e: Element) => {
    return e != null && (
        (e.closest(".github-picker") != null)
    );
};

/** when target element is SunEditor image chosen event */
export const isSunEditorImageChosenEvent = (e: Event) => {
    return e != null && (
        e.srcElement?.classList.contains("se-btn-primary") && !!e.srcElement?.closest(".se-dialog-footer")
    );
};

/** when target element is Ant Modal close button */
export const isAntModalClosed = (e: Element) => {
    return e != null && (
        (e.closest(".ant-modal-close") != null)
    );
};

export const getActivityNameByType = (type: ActivityType) => {
    let result: string = "";
    switch (type) {
        case ActivityType.Assessment:
            result = "assessment";
            break;
        case ActivityType.Assignment:
            result = "assignment";
            break;
        case ActivityType.Attachment:
            result = "attachment";
            break;
        case ActivityType.Discussion:
            result = "discussion";
            break;
        case ActivityType.Conference:
            result = "conference";
            break;
        case ActivityType.SCORM:
            result = "scorm";
            break;
        case ActivityType.GradeOnlyActivity:
            result = "GradeOnlyActivity";
            break;
        default:
            result = "activity";
            break;
    }
    return result;
};

export const tableLocale = {
    triggerDesc: 'Sort descending',
    triggerAsc: 'Sort ascending',
    cancelSort: 'Cancel sort',
};

export function ParseChatShortName(name: string) {
    if (name.length <= 2) return name;
    if (/\s/.test(name) == false) return name.substr(0, 2);
    return `${name.substr(0, 1)}${name.substr(name.indexOf(" ") + 1, 1)}`;
}
export function ShortAvatarName(f: string, l: string) {
    return f && l ? f[0]! + l[0]! : (f ? f.slice(0, 2) : (l ? l.slice(0, 2) : ''));
}



export function simplifyPhone(input: string) {
    if (input == null || input.trim() == "") return '';
    if (!input.startsWith('+'))
        input = `+1${input}`;
    return input.replace(/[^0-9+]/g, '');
}

export function getChildrenCommentsByCommentId(array: Comment[], commentId: DbIdentity): Comment[] {
    if (array.length < 1 || !commentId) return [];
    return array.flatMap((obj, _, array) => obj.replyTo == commentId ? [obj].concat(getChildrenCommentsByCommentId(array, obj.commentId)) : []);
}

const pr = new Intl.PluralRules("en-US", { type:"ordinal" });
const suffixes = ({
    'one'  : 'st',
    'two'  : 'nd',
    'few'  : 'rd',
    'other': 'th',
});
export function displayedGrade(level ?: number) {
    if (level === undefined) return "";
    if (level === 0) return "K";
    return `${level}${suffixes[pr.select(level)]}`;
}
/**NOTE
 * This is to support
 * -2: Pre-School
 * -1: Pre-Kindergarten
 *  0: Kindergarten
 *  1: 1st
 * ...
 * 16: 16th
 */
export function displayedFullGrade(level ?: number) {
    if (level === undefined) return "";
    if (level === -2) return i18n.t("app.grade.preSchool");
    if (level === -1) return i18n.t("app.grade.preKindergarten");
    if (level === 0) return i18n.t("app.grade.kinderGarten");
    return `${level}${suffixes[pr.select(level)]}`;
}

/** reverse to activity route name */
export function reverseToActivity(type: ActivityType){
    switch(type){
        case ActivityType.Activity          : return "classActivities";
        case ActivityType.Assignment        : return "classAssignments";
        case ActivityType.Assessment        : return "classAssessments";
        case ActivityType.Discussion        : return "discussionList";
        case ActivityType.SCORM             : return "classGradebook";
        case ActivityType.GradeOnlyActivity : return "classGradebook";

    }
    return `ActivityType(${type})`;
}
export const booleanify = (value?: string): boolean => {
    const truthy: string[] = [ 'true', '1' ];
    return !!value && truthy.includes(value.toLocaleLowerCase());
}

function isTinyMce(event: FocusEvent<HTMLDivElement, Element>) {
    return (event != null &&
        event.target != null &&
        event.target.querySelector('div.tinymce-wrapper') !== null)
        || document.activeElement?.querySelector('.custom_ui_selector') !== null;
}

// NOTE: checking ant-modal-root to know if ant modal is displayed.
export function hasDisplayedModal(document: Document): any {
    const modalItems = document.querySelectorAll<HTMLElement>(".ant-modal-root,.tox-dialog");
    if (modalItems == null) return false;

    return Array.from(modalItems).some((modal) =>  (modal.firstChild as HTMLElement).style.display !== 'none') ;
}

export const wait = (ms: number) => new Promise(res => setTimeout(res, ms));

export function findMaxEnumValue(enumObject: any): number {
    let maxValue = -1;
    for (let key in enumObject) {
        if (typeof enumObject[key] === 'number' && enumObject[key] > maxValue) {
            maxValue = enumObject[key];
        }
    }
    return maxValue;
}

export function sortMapByKeyAscending(map: Map<any, any>): Map<any, any> {
    const sortedEntries = [...map.entries()].sort((a, b) => {
        // Compare keys for sorting
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0;
    });

    // Create a new Map from the sorted entries
    const sortedMap = new Map(sortedEntries);

    return sortedMap;
}

export function sortMapByKeyDescending(map: Map<any, any>): Map<any, any> {
    const sortedEntries = [...map.entries()].sort((a, b) => {
        // Compare keys for sorting in descending order
        if (a[0] > b[0]) return -1;
        if (a[0] < b[0]) return 1;
        return 0;
    });

    // Create a new Map from the sorted entries
    const sortedMap = new Map(sortedEntries);

    return sortedMap;
}


export type VoidFn<T> = (x: T) => void;

export interface MobileUiProps { isMobile?: boolean}

export function getPropertyName<T extends {}>(propertyFunction: (p:T) => any) {
    return /\.([^\\.;]+)$/.exec(propertyFunction.toString())?.[1] || "";
}

export function isNullOrUndefined<T>(v: T | null | undefined) : v is T{ return v === null || v === undefined;}
export function isNotNullOrUndefined<T>(v: T | null | undefined) : v is T { return !isNullOrUndefined(v);}

export function stopPropagation(event?: SyntheticEvent | MouseEvent) {
    event?.stopPropagation();
}
const isActiveUploadFrom = (e:Element) => {
    return e != null && (
        (e.closest(".upload-from-menu-list") != null)
    );
}