import { UploadFile } from "antd/lib/upload/interface";
import { observable, action, makeObservable, toJS, computed } from "mobx";
import { FaviconDomainUrl } from "../config";
import { IErrorData } from "../services/api/AppError";
import { aFetch } from "../services/api/fetch";
import { Activity } from "./Activity";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import { DbIdentity, DefaultId, EUserRole } from "./types";

export enum EIntegrationType {
    LTI         = 1, // Learning Tools Interoperability
    SSO         = 2, // Single Sign-On
    eKadenceAPI = 3, // Public eKadence Api
    DirectLink  = 4, // Simple url link
}

export enum EAppType {
    App         = 1,
    Textbook    = 2,
    Conference  = 3,
}

/**
 * Describe AppIntegration Tabs in Class.Tools
 */
export enum EAppTab {
    App         = 1,
    Textbook    = 2,
    InstallApp  = 3,
    Badges      = 4,
}

export class UserAppIntegration {
    appIntegrationId: DbIdentity = DefaultId;
    classId         : DbIdentity = DefaultId;
    userId          : DbIdentity = DefaultId;
    constructor (data : {}) {
        if (data) Object.assign(this, data);
    }

    static async linkAppToUser({facultyId, classId, appIntegrationId, isAddNew} : {facultyId: DbIdentity, classId: DbIdentity, appIntegrationId : DbIdentity, isAddNew: boolean}) {
        const [err, data] = await aFetch<UserAppIntegration>("PUT", `faculty/${facultyId}/class/${classId}/app-integration/${appIntegrationId}/link-app-to-user`, isAddNew);
        return [err, (err ? undefined : new UserAppIntegration(data))!] as const;
    }
}
export class AppIntegration {
    appIntegrationId  : DbIdentity       = DefaultId           ;
    districtId        : DbIdentity       = DefaultId           ;
    schoolId         ?: DbIdentity                             ; set_schoolId         (v: number          ) { this.schoolId         = v };
    userId           ?: DbIdentity                             ; set_userId           (v: number          ) { this.schoolId         = v };
    appType           : EAppType         = EAppType.App        ;
    integrationType   : EIntegrationType = EIntegrationType.LTI; set_integrationType  (v: EIntegrationType) { this.integrationType  = v };
    appName           : string           = ""                  ; set_appName          (v: string          ) { this.appName          = v };

    appTitle          : string           = ""                  ; set_appTitle         (v: string          ) { this.appTitle         = v };
    appDescription    : string           = ""                  ; set_appDescription   (v: string          ) { this.appDescription   = v };
    imageUrl          : string           = ""                  ; set_imageUrl         (v: string          ) { this.imageUrl         = v };
    isActive          : boolean          = false               ; set_isActive         (v: boolean         ) { this.isActive         = v };
    url               : string           = ""                  ;

    set_url (v: string) {
        if (this.url !== v){
            /// Only assign Image URL if there is none
            if (!this.imageUrl?.trim().length && !this.iconFile) {
                this.set_imageUrl(FaviconDomainUrl + v);
                this.set_iconFile(undefined);
            }
        }
        this.url = v;
    };
    consumerKey       : string           = ""                  ; set_consumerKey      (v: string          ) { this.consumerKey      = v };
    sharedSecret      : string           = ""                  ; set_sharedSecret     (v: string          ) { this.sharedSecret     = v };
    customParameters  : string           = ""                  ; set_customParameters (v: string          ) { this.customParameters = v?.trim() };

    isAppCenter       : boolean = false ; set_isAppCenter       (v: boolean ) { this.isAppCenter       = v };
    isConference      : boolean = false ; set_isConference      (v: boolean ) { this.isConference      = v };
    isClass           : boolean = false ; set_isClass           (v: boolean ) { this.isClass           = v };
    isActivity        : boolean = false ; set_isActivity        (v: boolean ) { this.isActivity        = v };
    isContentBlock    : boolean = false ; set_isContentBlock    (v: boolean ) { this.isContentBlock    = v };

    visibleToStudents : boolean = false ; set_visibleToStudents (v: boolean ) { this.visibleToStudents = v };

    dateCreated      ?: number                                 ;

    iconFile         ?: UploadFile = undefined                 ; set_iconFile(v?:UploadFile|undefined     ) { this.iconFile = v };

    clear_icon() { this.imageUrl = ""; this.iconFile = undefined; }
    constructor(data?:{}) {
        makeObservable(this, {
            appIntegrationId : observable,
            districtId       : observable,
            schoolId         : observable,
            userId           : observable,
            integrationType  : observable,
            appType          : observable,
            appName          : observable,
            appDescription   : observable,
            imageUrl         : observable,
            isActive         : observable,
            url              : observable,
            consumerKey      : observable,
            sharedSecret     : observable,
            customParameters : observable,
            appTitle         : observable,
            iconFile         : observable.ref,
            set_schoolId         : action.bound,
            set_userId           : action.bound,
            set_integrationType  : action.bound,
            set_appType          : action.bound,
            set_appName          : action.bound,
            set_appDescription   : action.bound,
            set_imageUrl         : action.bound,
            set_isActive         : action.bound,
            set_url              : action.bound,
            set_consumerKey      : action.bound,
            set_sharedSecret     : action.bound,
            set_customParameters : action.bound,
            set_iconFile         : action.bound,
            clear_icon           : action.bound,
            set_appTitle         : action.bound,

            isAppCenter       : observable, set_isAppCenter      : action.bound,
            isConference      : observable, set_isConference     : action.bound,
            isClass           : observable, set_isClass          : action.bound,
            isActivity        : observable, set_isActivity       : action.bound,
            isContentBlock    : observable, set_isContentBlock   : action.bound,
            visibleToStudents : observable, set_visibleToStudents: action.bound,
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }

    toJS() {
        if (this.schoolId === DefaultId) this.schoolId = undefined;
        if (this.userId === DefaultId) this.userId = undefined;
        return toJS(this);
    }

    set_appType(v: EAppType) {
        this.appType = v;
        if (v == EAppType.Conference) this.set_isConference(true);
    };

    async save(facultyId: DbIdentity) {
        let err: IErrorData<any> | undefined = undefined;
        let data : any = {};
        if (this.appIntegrationId === DefaultId) [err, data] = await aFetch<{}>("POST", `faculty/${facultyId}/admin/app-integration`, this.toJS());
        else [err, data] = await aFetch<{}>("PUT", `faculty/${facultyId}/admin/app-integration/${this.appIntegrationId}`, this.toJS());
        return [err, (err ? undefined : new AppIntegration(data))!] as const;
    }

    /**
     * Save Custom App (also know as Custom External Tool)
     * @param facultyId
     * @param classId
     * @returns
     */
    async saveCustomApp(facultyId: DbIdentity, classId: DbIdentity) {
        let err: IErrorData<any> | undefined = undefined;
        let data : any = {};
        if (this.appIntegrationId === DefaultId) [err, data] = await aFetch<{}>("POST", `faculty/${facultyId}/class/${classId}/custom-app-integration`, this.toJS());
        else [err, data] = await aFetch<{}>("PUT", `faculty/${facultyId}/class/${classId}/custom-app-integration/${this.appIntegrationId}`, this.toJS());
        return [err, (err ? undefined : new AppIntegration(data))!] as const;
    }

    async delete(facultyId: DbIdentity) {
        const [err, x] = await aFetch<{}>("DELETE", `faculty/${facultyId}/admin/app-integration/${this.appIntegrationId}`);
        return [err, (err ? undefined : new AppIntegration(x))!] as const;
    }

    static async setActive({facultyId, appIntegrationId, isActive} : {facultyId: DbIdentity, appIntegrationId : DbIdentity, isActive: boolean}) {
        const [err, x] = await aFetch<{}>("PUT", `faculty/${facultyId}/admin/app-integration/${appIntegrationId}/active`, isActive);
        return [err, (err ? undefined : new AppIntegration(x))!] as const;
    }

    static async fetchAsAdmin({ facultyId, signal }: { facultyId: DbIdentity, signal?: AbortSignal}) {
        const [error, data] = await aFetch<AppIntegration[]>("GET", `faculty/${facultyId}/admin/app-integration`, undefined, { signal });
        return [error, (error ? undefined : data.map(p => new AppIntegration(p)))!] as const;
    }

    static async fetchById({ facultyId, appIntegrationId, signal }: { facultyId: DbIdentity, appIntegrationId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}>("GET", `faculty/${facultyId}/admin/app-integration/${appIntegrationId}`, undefined, { signal });
        return [err, (err ? undefined : new AppIntegration(data))!] as const;
    }

    static async fetchCustomAppById({ facultyId, classId, appIntegrationId, signal }: { facultyId: DbIdentity, classId: DbIdentity, appIntegrationId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}>("GET", `faculty/${facultyId}/class/${classId}/custom-app-integration/${appIntegrationId}`, undefined, { signal });
        return [err, (err ? undefined : new AppIntegration(data))!] as const;
    }

    static async fetchClassInstalledApps({ facultyId, classId, signal }: {facultyId: DbIdentity, classId: DbIdentity, signal?: AbortSignal}) {
        const [error, data] = await aFetch<GeneralDto>("GET", `faculty/${facultyId}/class/${classId}/installed-app-integration`, undefined, { signal });
        return [error, (error ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchAppCenter({ role, signal }: { role: EUserRole, signal?: AbortSignal}) {
        const [error, data] = await aFetch<AppIntegration[]>("GET", `/appCenter/${role}`, undefined, { signal });
        return [error, (error ? undefined : data.map(a => new AppIntegration(a)))!] as const;
    }

    /**
     * This will fetch all activity apps belong to specific class. The API will base on current user role to get user appIntegrations accordingly
     * @param
     * @returns
     */
    static async fetchActivityApps({ classId, signal }: { classId: DbIdentity, signal?: AbortSignal}) {
        const [error, data] = await aFetch<AppIntegration[]>("GET", `/appCenter/activity-app/class/${classId}`, undefined, { signal });
        return [error, (error ? undefined : data.map(a => new AppIntegration(a)))!] as const;
    }

    static async fetchSingleActivityApp({ activityId, signal }: { activityId: DbIdentity, signal?: AbortSignal}) {
        const [error, data] = await aFetch<AppIntegration>("GET", `/appCenter/activity-app/activity/${activityId}`, undefined, { signal });
        return [error, (error ? undefined : data)] as const;
    }

    static async fetchPublicSingleActivityApp({ publicLinkId, activityId, signal }: { publicLinkId: string, activityId: DbIdentity, signal?: AbortSignal}) {
        const [error, data] = await aFetch<AppIntegration>("GET", `/publicClass/${publicLinkId}/activity-app/activity/${activityId}`, undefined, { signal });
        return [error, (error ? undefined : data)] as const;
    }

    static async verifyActivityLinksToApp({ activityId, signal }: { activityId: DbIdentity, signal?: AbortSignal}) {
        const [error, data] = await aFetch<boolean>("GET", `/appCenter/activity-app-verify/activity/${activityId}`, undefined, { signal });
        return [error, (error ? undefined : data)] as const;
    }

    static async fetchClassInstalledAppsAsStudent({ studentId, classId, signal }: {studentId: DbIdentity, classId: DbIdentity, signal?: AbortSignal}) {
        const [error, data] = await aFetch<AppIntegration[]>("GET", `student/${studentId}/class/${classId}/installed-app-integration`, undefined, { signal });
        return [error, (error ? undefined : data.map(p => new AppIntegration(p)))!] as const;
    }

    static async fetchClassInstalledAppsAsParent({ studentId, classId, signal }: {studentId: DbIdentity, classId: DbIdentity, signal?: AbortSignal}) {
        const [error, data] = await aFetch<AppIntegration[]>("GET", `parent/student/${studentId}/class/${classId}/installed-app-integration`, undefined, { signal });
        return [error, (error ? undefined : data.map(p => new AppIntegration(p)))!] as const;
    }

    static async fetchClassAvailableApp({ facultyId, classId, signal }: {facultyId: DbIdentity, classId: DbIdentity, signal?: AbortSignal}) {
        const [error, data] = await aFetch<GeneralDto>("GET", `faculty/${facultyId}/class/${classId}/available-app-integration`, undefined, { signal });
        return [error, (error ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchAllTextbooks({ userId, classId, signal }: {userId: DbIdentity, classId: DbIdentity, signal?: AbortSignal}) {
        const [error, data] = await aFetch<GeneralDto>("GET", `textbooks/class/${classId}/items`, undefined, { signal });
        return [error, (error ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchActiveTextbooks({ userId, classId, signal }: {userId: DbIdentity, classId: DbIdentity, signal?: AbortSignal}) {
        const [error, data] = await aFetch<GeneralDto>("GET", `textbooks/class/${classId}/active-items`, undefined, { signal });
        return [error, (error ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async countActiveTextbooks({ classId, signal }: {classId: DbIdentity, signal?: AbortSignal}) {
        const [error, data] = await aFetch<number>("GET", `textbooks/class/${classId}/count`, undefined, { signal });
        return [error, (error ? undefined :data)!] as const;
    }

    static async fetchLTIConfigfromUrl(configurationUrl: string) {
        const [err, data] = await aFetch<{title: string, iconUrl: string, description: string, launchUrl: string}>("POST", `itl/fetchLTIConfigfromUrl`, configurationUrl);
        return [err, (err ? undefined : data)!] as const;
    }

    static async fetchLtiRequestInfoByAppIntegration(appIntegrationId: DbIdentity, classId ?: DbIdentity, activityId ?: DbIdentity, role?: EUserRole) {
        const requestPath = activityId ? `/lti/activity/${activityId}/launch/${appIntegrationId}` :
                            classId ? `/lti/class/${classId}/launch/${appIntegrationId}` : `/lti/launch/${appIntegrationId}`;
        const [err, data] = await aFetch<any>("GET", requestPath, {portal: role});
        return [err, data] as const;
    }

    static async fetchLtiRequestInfoByActivity(activityId: DbIdentity, classId: DbIdentity) {
        const [err, data] = await aFetch<any>("GET", `/lti/class/${classId}/launch_activity_lti/${activityId}`);
        return [err, data] as const;
    }

    static async fetchUCIReflectionAsStudent({activityId, currentUserId}: {activityId: DbIdentity, currentUserId: DbIdentity}): Promise<readonly [IErrorData<any> | undefined, string]> {
        const [err, url] = await aFetch<string>("GET", `/lti/edsight/5c/student/${currentUserId}/activity/${activityId}`);
        return [err, url] as const;
    }

    static async fetchUCIReflectionAsFaculty({activityId, currentUserId}: {activityId: DbIdentity, currentUserId: DbIdentity}): Promise<readonly [IErrorData<any> | undefined, string]> {
        const [err, url] = await aFetch<string>("GET", `/lti/edsight/5c/faculty/${currentUserId}/activity/${activityId}`);
        return [err, url] as const;
    }

    static async openUciLtiClassDashboard({classId, currentUserId, frameId}: {classId: DbIdentity, currentUserId: DbIdentity, frameId: string}){
        const [err, props] = await aFetch<any>("GET", `/lti/edsight/5c/faculty/${currentUserId}/class/${classId}`);
        if (!!err) {
            return err;
        }
        // prepare data for form POST
        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", props.url);
        form.setAttribute("target", frameId);
        for (var key in props) {
            if (!(props.hasOwnProperty(key) && key != "url")) continue;
            const value = props[key];

            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            form.appendChild(input);
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
        return;
    }

    static async openByActivityLti(activity: Activity){
        const [err, props] = await AppIntegration.fetchLtiRequestInfoByActivity(activity.activityId, activity.classId);
        if (!!err) {
            return [err, props] as const;
        }
        // prepare data for form POST
        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", activity.attachmentUrl!);
        form.setAttribute("target", "_blank");
        for (var key in props) {
            if (!(props.hasOwnProperty(key) && key != "url")) continue;
            const value = props[key];

            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            form.appendChild(input);
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
        return [undefined, undefined] as const;
    }

    async open(classId: DbIdentity){
        const [err, props] = await AppIntegration.fetchLtiRequestInfoByAppIntegration(this.appIntegrationId, classId);
        if (!!err) {
            return [err, props] as const;
        }

        // prepare data for form POST
        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", this.url);
        form.setAttribute("target", "_blank");
        for (var key in props) {
            if (!(props.hasOwnProperty(key) && key != "url")) continue;
            const value = props[key];

            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            form.appendChild(input);
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
        return [undefined, undefined] as const;
    }

    static sorter = {
        appName     : (a?: AppIntegration, b?: AppIntegration) => (a?.appName ?? "").localeCompare(b?.appName ?? ""),
        dateCreated : (a?: AppIntegration, b?: AppIntegration) => ((a?.dateCreated || 0) - (b?.dateCreated || 0)),
    };
}