export enum ActivityType {
    Unknown           = 0,
    Activity          = 1,
    Assignment        = 2,
    Assessment        = 3,
    Discussion        = 4,
    Attachment        = 5,
    Conference        = 6,
    SCORM             = 7,
    GradeOnlyActivity = 8,
    ExternalLink      = 9,
    AttachmentImage   = 10, //this activityType is virtual, (this) AttachmentImage = Attachment and isShowAsImage == true
    Video             = 11,
    GradeOnlyScoreType = 12, // for the activity that created from Activity Score Type Only
}

export const activityTypesHaveDoc = new Set([
    ActivityType.Activity,
    ActivityType.Assessment,
    ActivityType.Assignment,
    ActivityType.Discussion,
]);

export const activityTypesCanMarkComplete = new Set([
    ActivityType.Activity,
    ActivityType.Video,
]);

export const activityTypesShouldHideEnableInterclass = new Set([
    ActivityType.Activity,
    ActivityType.Video,
]);

export const activityTypesHasQuestion = new Set([
    ActivityType.Assessment,
    ActivityType.Assignment,
]);

export const activityTypesHiddenInModule = new Set([
    ActivityType.Conference,
    ActivityType.GradeOnlyActivity,
    ActivityType.GradeOnlyScoreType,
]);

export const activityTypesHasLessonAutoCompleteSection = new Set([
    ActivityType.Video
]);

export const activityTypesHasThumbnail = new Set([
    ActivityType.Video,
]);

export const activityTypesNotAllowComment = new Set([
    ActivityType.Unknown,
    ActivityType.Activity,
    ActivityType.Attachment,
    ActivityType.Video,
]);

export const activityTypesHasPublishRange = new Set([ ActivityType.Activity, ActivityType.Attachment, ActivityType.Video, ActivityType.GradeOnlyScoreType ]);
/** we have a confuse logic to hide Due Date in Activity Settings, for now. So I create a separate value for it */
export const activityTypesHaveNoDueDate = activityTypesHasPublishRange;
export const activityTypesHasOnlyStart    = new Set([ ActivityType.Assignment, ActivityType.Assessment, ActivityType.Discussion, ActivityType.SCORM, ActivityType.ExternalLink ]);

export const activityTypesHasScore = new Set([
    ActivityType.Assessment,
    ActivityType.Assignment,
    ActivityType.Discussion,
    ActivityType.Conference,
    ActivityType.GradeOnlyActivity,
    ActivityType.SCORM,
]);
export const activityTypesGradable = activityTypesHasScore;
export const activityTypesGradeOnly = new Set([ ActivityType.GradeOnlyActivity, ActivityType.GradeOnlyScoreType ]);

export const activityTypesIncludeLessonPlan = new Set([ActivityType.Assessment, ActivityType.Assignment]);
export const activityTypesIncludeInstruction = new Set([ActivityType.Assessment, ActivityType.Assignment]);

export const activityTypesHaveGoogleEvent = new Set([ActivityType.Assessment, ActivityType.Assignment, ActivityType.Discussion, ActivityType.SCORM, ActivityType.Conference]);
export const activityTypesHavePollAnswer = new Set([ActivityType.Activity, ActivityType.Assignment, ActivityType.Assessment]);
export const activityTypesFlaggable = new Set([ActivityType.Assignment, ActivityType.Assessment, ActivityType.Discussion]);
export const activityTypesAutomaticClassEPortfolios = new Set([ActivityType.Assignment, ActivityType.Assessment, ActivityType.Discussion]);

/**
 * @description: can comment for instruction
 * @see Activity.enableComment
 * */
export const activityTypesCanCommentInInstruction = new Set([ActivityType.Activity, ActivityType.Assignment, ActivityType.Assessment]);
export const activityTypesHaveSkillDevelopmentOpportunities = new Set([ActivityType.Assignment, ActivityType.Assessment, ActivityType.Discussion]);