import { observable, action, toJS, makeObservable } from "mobx";

import {DbIdentity, DefaultId} from "./types"
import { ClassPermission } from "./ClassPermission";

import { aFetch } from "../services/api/fetch";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import { Class, GoogleCourse, IClassFaculty, IClassroom } from "./Class";
import { FacultyClass } from "../FacultyPortal/models/FacultyClass";
import { isEnableNewCoteacherUI } from "../config";
import { Faculty } from "./Faculty";

/**  Pick one main faculty to show on class homepage, prefer class creator then class faculty sort order.
 * @param  {ClassFaculty[]} classFaculties List of class faculty record.
 * @param  {DbIdentity} classCreator? The createor of class, if any.
 */
export const pickMainFaculty = (classFaculties: IClassFaculty[], classCreator?: DbIdentity) => classFaculties
    .filter(x => x.isTeacherOnRecord)
    .sort((a,b) => {
        if (a.facultyId === classCreator) return -1;
        if (b.facultyId === classCreator) return 1;

        return  a.sortIndex - b.sortIndex;
    })
    .find(x => x);

export class ClassFaculty {
    readonly classId  : DbIdentity      = DefaultId           ;
    facultyId         : DbIdentity      = DefaultId           ;
    sortIndex         : number          = 10000               ;
    permissionBitmap  : ClassPermission = ClassPermission.None; set_permissionBitmap(v:ClassPermission) { this.permissionBitmap = v };
    showAboutMe       : boolean         = false               ; set_showAboutMe(v:boolean) { this.showAboutMe = v };
    isTeacherOnRecord : boolean         = false               ;
    showClassOnDashboard : boolean      = true                ; set_showClassOnDashboard(v:boolean){this.showClassOnDashboard = v};
    myClassIds        : DbIdentity[] = [];
    coClassIds        : DbIdentity[] = [];
    customLabel       : string          = ""                  ; set_customLabel(v: string) { this.customLabel = v };

    constructor(data?:{}) {
        makeObservable(this, {
            sortIndex            : observable,
            permissionBitmap     : observable,
            set_permissionBitmap : action.bound,
            isTeacherOnRecord    : observable,
            showClassOnDashboard : observable,
            set_showClassOnDashboard: action.bound,
            showAboutMe          : observable,
            set_showAboutMe      : action.bound,
            myClassIds           : observable,
            coClassIds           : observable,
        });

        if (data != null) Object.assign(this, data);
    }


    get pk() { return ({ classId:this.classId, facultyId:this.facultyId, }) }
    toJS() { return toJS(this) }
    clone() { return new ClassFaculty(this.toJS()) }

    async save({facultyId}:{facultyId:DbIdentity}) {
        const [err, xs] = await aFetch<{facultyInfo?:{}, classFaculties: {}[]}>("PUT", `/faculty/${facultyId}/class/${this.classId}/classFaculty/${this.facultyId}`, this.toJS());
        return err ? [err, undefined, undefined] as const : [err, xs.classFaculties.map(x => new ClassFaculty(x)), new Faculty(xs.facultyInfo)] as const;
    }

    async saveShowCoTeacher({facultyId}:{facultyId:DbIdentity}) {
        const [err, x] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/class/${this.classId}/classFaculty/${this.facultyId}/homepage`, this.toJS());
        return [err, (err ? undefined : new ClassFaculty(x))!] as const;
    }

    static async fetchCoteacherActiveClasses({facultyId, classId, signal}:{facultyId:DbIdentity, classId: DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/class/${classId}/coteacherActiveClasses`, undefined, { signal });
        return [err, err ? [] : xs.map(x => new ClassFaculty(x)),
        ] as const;
    }

    static async fetchCoteacherLinkedClasses({facultyId, classId, signal}:{facultyId:DbIdentity, classId: DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{classFaculties: {}[], faculties:{}[]}>("GET", `/faculty/${facultyId}/class/${classId}/coteacherLinkedClasses`, undefined, { signal });
        return err ? [err, [], []] as const : [err, xs.classFaculties.map(x => new ClassFaculty(x)), xs.faculties.map(x => new Faculty(x))] as const;
    }

    static async fetchCoteacherActiveClassesByClassIds({ facultyId, classIds, signal}:{ facultyId:DbIdentity, classIds: DbIdentity[], signal?: AbortSignal })
    {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/coteacherActiveClassesByClassIds`, { classIds }, { signal });
        return [err, err ? [] : xs.map(x => new ClassFaculty(x)),
        ] as const;
    }

    static async fetchClassFacultiesOfClass({facultyId, classId }:{facultyId:DbIdentity, classId: DbIdentity}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/class/${classId}/classFaculty`, undefined);
        return [err, err ? [] : xs.map(x => new ClassFaculty(x))] as const;
    }

    static async fetchClassFacultiesOfClasses({facultyId, signal }:{ facultyId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/classFaculty`, undefined, { signal });
        const vm = err == null ? parseGeneralViewModel(dto) : undefined;
        return [err, vm!] as const;
    }

    static async deleteAsFaculty({facultyId, classId}:{facultyId:DbIdentity, classId:DbIdentity}, coFacultyId:DbIdentity) {
        const [err, x] = await aFetch<{}[]>("DELETE", `/faculty/${facultyId}/class/${classId}/classFaculty/${coFacultyId}`);
        return [err, (err ? undefined : new ClassFaculty(x))!] as const;
    }

    static async deleteAsFacultyNew({facultyId, classId}:{facultyId:DbIdentity, classId:DbIdentity}, coFacultyId:DbIdentity, isAllClasses: boolean = false) {
        const [err, xs] = await aFetch<{}[]>("DELETE", `/faculty/${facultyId}/class/${classId}/classFaculty/${coFacultyId}/new?isAllClasses=${isAllClasses}`);
        return [err, (err ? undefined : xs.map(x => new ClassFaculty(x)))!] as const;
    }

    static async setClassOneRosterId({ facultyId, classId, oneRosterId }:{ facultyId:DbIdentity, classId: DbIdentity, oneRosterId: string }) {
        const [err, data] = await aFetch<{classId: DbIdentity, oneRosterId?: string}>("PUT", `/faculty/${facultyId}/class/${classId}/oneRosterId`, oneRosterId);
        return [err, data] as const;
    }

    static async setShowClassOnDashboard({facultyId, classId, isShow }:{facultyId:DbIdentity, classId: DbIdentity, isShow: boolean}) {
        const [err, data] = await aFetch<{classId: DbIdentity, oneRosterId?: string}>("PUT", `/faculty/${facultyId}/class/${classId}/showOnDashboard`, isShow);
        return [err, data] as const;
    }

    static async setGoogleClassroomId({classId, classroomId }:{classId: DbIdentity, classroomId?: string}) {
        const updateClassRoomPromise = !!classroomId
            ? await aFetch<IClassroom>("PUT", `/class/${classId}/google-classroom/${classroomId}`, classroomId)
            : await aFetch<IClassroom>("POST", `/class/${classId}/google-classroom`);

        const [err, data] = await updateClassRoomPromise;
        return [err, new GoogleCourse(data)] as const;
    }

    static async removeGoogleClassroomId({classId }:{classId: DbIdentity}) {
        const err = await aFetch<void>("DELETE", `/class/${classId}/google-classroom`);
        return err;
    }

    static async getGoogleClassrooms({classId, signal }:{signal?: AbortSignal, classId: DbIdentity}) {
        const [err, data] = await aFetch<{id: string, name: string}[]>("GET", `/class/${classId}/google-classroom/unlinked`, undefined, { signal } );
        return [err, (err ? [] : data)] as const;
    }

    static async makeTeacherOnrecord({facultyId, classId, coFacultyId}:{facultyId:DbIdentity, classId:DbIdentity, coFacultyId:DbIdentity}) {
        const [err, xs] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/class/${classId}/classFaculty/${coFacultyId}/makeTeacherOnrecord`);
        return [err, (err ? undefined : xs.map(x => new ClassFaculty(x)))] as const;
    }
}
