import { toJS } from "mobx"
import { DbIdentity, DefaultId } from "./types";
import { aFetch } from "../services/api/fetch";

export enum VisibilityType {
    Unknown    = 0,
    Page       = 1,
    Activity   = 2,
    Discussion = 3,
}

export enum VisibilityCriteria {
    Unknown  = 0,
    View     = 10,
    Comment  = 20,
    Submit   = 30,
    Grade    = 40,
}

export class ActivityVisibility {
    targetType       = VisibilityType.Unknown;
    targetId         : DbIdentity = DefaultId;
    subjectType      = VisibilityType.Unknown;
    subjectId        : DbIdentity = DefaultId;
    pageId          ?: DbIdentity;
    activityId      ?: DbIdentity;
    discussionId    ?: DbIdentity;
    criteria         = VisibilityCriteria.Unknown;
    minScore        ?: number;

    constructor(data ?: {}) {
        if(data) Object.assign(this, data);
    }
    toJS() {
        return toJS(this)
    }
    static async fetch({facultyId, activityId, signal}:{facultyId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}[]>("GET" , `faculty/${facultyId}/activity/${activityId}/visibility`, undefined, { signal });
        return [err, err ? [] : data.map(a => new this(a))] as const;
    }
    static async fetchDiscussion({facultyId, classId, discussionId, signal}:{facultyId:DbIdentity, classId:DbIdentity, discussionId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}[]>("GET" , `faculty/${facultyId}/class/${classId}/discussion/${discussionId}/visibility`, undefined, { signal });
        return [err, err ? [] : data.map(a => new this(a))] as const;
    }
    static async save({facultyId, activityId}:{facultyId:DbIdentity, activityId:DbIdentity}, xs:ActivityVisibility[]) {
        const [err, data] = await aFetch<{}[]>("PUT" , `faculty/${facultyId}/activity/${activityId}/visibility`, xs.map(x => x.toJS()));
        return [err, err ? [] : data.map(a => new this(a))] as const;
    }
    static async saveDiscussion({facultyId, classId, discussionId}:{facultyId:DbIdentity, classId:DbIdentity, discussionId:DbIdentity}, xs:ActivityVisibility[]) {
        const [err, data] = await aFetch<{}[]>("PUT" , `faculty/${facultyId}/class/${classId}/discussion/${discussionId}/visibility`, xs.map(x => x.toJS()));
        return [err, err ? [] : data.map(a => new this(a))] as const;
    }
}
