import { DefaultId, DbIdentity, NumberDate } from "./types";
import { aFetch } from "../services/api/fetch";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import { CaseloadModule, parseCaseloadViewModel } from "./CaseloadModule";
import { CaseloadResourceType } from "./Caseload";
import { isGoogleDriveUrl } from "../components/GoogleApis/utils";
import { isBlobFile } from "../utils/file";

export class CaseloadAttachment {
    attachmentId : DbIdentity = DefaultId    ;
    createdBy   ?: DbIdentity = undefined    ;
    caseloadId   : DbIdentity = DefaultId    ;
    title        : string = ""               ;
    moduleId     : string = ""               ;
    dateCreated ?: NumberDate = undefined    ;
    startTime   ?: NumberDate = undefined    ;
    endTime     ?: NumberDate = undefined    ;
    isDeleted   ?: boolean = undefined       ;
    type      : DbIdentity     = CaseloadResourceType.Attachment;
    module?   : CaseloadModule = undefined     ;
    attachmentUrl          : string = ""       ;
    attachmentContentType ?: string = undefined;
    isShowAsImage         ?: boolean           ;
    set_title    (v: string      ){ this.title     = v; }

    get attachmentType(){
        if (isGoogleDriveUrl(this.attachmentUrl ?? "")) return "googleUrl";
        if (this.attachmentUrl && isBlobFile(this.attachmentUrl)) return "file";
        return "url";
    }

    constructor(data?: any) {
        if (data != null) {
            Object.assign(this, data);
        }
        
        makeObservable(this, {
            caseloadId     : observable       ,
            title          : observable       ,
            set_title      : action.bound     ,
            dateCreated    : observable       ,
            startTime      : observable       ,
            endTime        : observable       ,
            isDeleted      : observable       ,
            moduleId       : observable       ,
            module         : observable       ,
            attachmentUrl  : observable       ,
            isShowAsImage  : observable       ,
            attachmentType : computed         ,
            attachmentContentType : observable,
            set_startTime  : action.bound      ,
            set_endTime    : action.bound      ,
        });
    }
    toJS() { return toJS(this); }
    set_startTime(v ?: NumberDate){this.startTime = v;}
    set_endTime(v ?: NumberDate){this.endTime = v;}

    async create(facultyId:DbIdentity, caseloadId:DbIdentity, createData: {}) {
        const [err, data] = await aFetch<{}>("POST", `/faculty/${facultyId}/caseload/${caseloadId}/attachment`, { ...this.toJS(), ...createData });
        return [err, (err ? undefined : parseCaseloadViewModel(data))!] as const;
    }

    async save(facultyId:DbIdentity, caseloadId:DbIdentity) {
        const [err, data] = await aFetch<{}>("PUT", `/faculty/${facultyId}/caseload/${caseloadId}/attachment/${this.attachmentId}`, this.toJS());
        return [err, (err ? undefined : new CaseloadAttachment(data))!] as const;
    }

    static async delete(facultyId: DbIdentity, caseloadId:DbIdentity, attachmentId:DbIdentity) {
        const [err, data] = await aFetch<{}>("DELETE", `/faculty/${facultyId}/caseload/${caseloadId}/attachment/${attachmentId}`);
        return [err, (err ? undefined : new CaseloadAttachment(data))!] as const;
    }
}