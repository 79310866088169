import { observable, action, computed, makeObservable } from "mobx";

const DefaultColor = "#FFFFFF"
export class GradeRange {
    mark    : string = "";
    from    : number = 0;
    to      : number = 0;
    color  ?: string = "";

    static Step = 0.01;

    constructor(data?:{}) {
        makeObservable(this, {
            mark         : observable,
            from         : observable,
            to           : observable,
            color        : observable,
            set_mark     : action.bound,
            set_from     : action.bound,
            set_to       : action.bound,
            set_color    : action.bound,
            mask_from    : computed,
            mask_to      : computed,
            mask_step    : computed,
            set_mask_from: action.bound,
            set_mask_to  : action.bound,
            isMax        : computed,
        });

        if (data != null) {
            Object.assign(this, data);
            if (this.color == "") this.color = DefaultColor;
        }
    }

    set_mark      (v: string) { this.mark = v;                          }
    set_from      (v: number) { this.from = GradeRange.round(v)!;       }
    set_to        (v: number) { this.to   = GradeRange.round(v)!;       }
    set_mask_from (v: number) { this.from = GradeRange.unMaskGrade(v)!; }
    set_mask_to   (v: number) { this.to   = GradeRange.unMaskGrade(v)!; }
    set_color     (v: string) { this.color = v;                         }

    toJS() {
        return ({
            mark : this.mark,
            from : this.from,
            to   : this.to,
            color: this.color,
        });
    }

    get isMax       ()          { return this.to == 1;                           }
    get mask_from   () : number { return GradeRange.maskGrade(this.from)!;       }
    get mask_to     () : number { return GradeRange.maskGrade(this.to)!;         }
    get mask_step   () : number { return GradeRange.maskGrade(GradeRange.Step)!; }

    static round        (v:number|null|undefined) { return v == null ? v : (Math.round(v*10000) / 10000); }
    static maskGrade    (v:number|undefined)      { return GradeRange.round(v && v*100);                  }
    static unMaskGrade  (v:number|undefined)      { return v &&  v > 0 ? v/100 : 0;                       }
}
