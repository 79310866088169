import { observable, action, computed, makeObservable, toJS } from "mobx";

import { aFetch, apiHost, uploadFile2, versionPrefix } from "../services/api/fetch";

import { DbIdentity, DefaultId, ISearchActivityLogResult, NumberDate, SchoolShortInfo, StringDate } from "./types";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import type { Activity } from "./Activity";
import type { ActivityScore } from './ActivityScore';

import type { ParentStudent } from "./ParentStudent";
import type { AdminStudent } from "../FacultyPortal/stores/AdminStudentListStore";
import type { UploadFile } from "antd/lib/upload/interface";
import { lastFirstName, firstLastName, isNotEmpty } from "../utils/stringUtils";
import { simplifyPhone } from "../utils/misc";
import { UserDetailFieldEnum, UserExt } from "./User";
import { notNull } from "../utils/list";
import { UserExtension } from "./UserExtension";
import { ISchoolStudent } from "./SchoolStudent";
import { StudentSuccessStatistic } from "./StudentSuccessStatistic";
import type { Parent } from "./Parent";
import { ClassGradebookStudent } from "./ClassGradebookStudent";

interface IClassStudents {
    classId         : DbIdentity;
    studentId       : DbIdentity;
    cumulativeGrade?: number;
    isActive        : boolean;
}

interface IUploadIepDoc{
    studentIEPDocumentID: number,
    address: string,
    aiLogId: number,
}
export interface IStudentIepProps {
    studentId  : DbIdentity,
    hasIep     : boolean,
    has504     : boolean,
    hasBIP     : boolean,
    iepDoc    ?: IIepDocProps,
    i504Doc   ?: IIepDocProps
    bipDoc    ?: IIepDocProps,
}

interface IClassStudentStatisticInfo {
    numIepStudents: number,
    numBirthdayStudents: number,
    hasIepStudentsLinkedSections: boolean,
    hasNeedsAckNewStudents: boolean,
    hasNeedsAckInactiveStudents: boolean,
    hasNeedsAckIepStudents: boolean
}

export interface IIepDocProps{
    address?: string,
    caseCarrierId?: DbIdentity,
    studentIEPDocumentID: DbIdentity,
    type: StudentIEPDocumentType
}

class RosterSyncResult {
    addedCount:number = 0;
    deletedCount:number = 0;

    constructor(data?:{}) {
        if (data != null) Object.assign(this, data);
    }
}

export enum StudentIEPDocumentType{
    iep = 1,
    i504 = 2,
    bip = 3,
}

export interface IStudentProgressOverview {
    classCount              : number,
    totalEndeavourCount     : number,
    completedEndeavourCount : number,
    activeProgramCount      : number
}

export class RStudent extends UserExtension {
    studentId            : DbIdentity = DefaultId;
    externalId           : string     = ""       ;
    studentNumber        : string     = ""       ;
    grade               ?: number     = undefined;
    email                : string     = ""       ;
    firstName            : string     = ""       ;
    middleName           : string     = ""       ;
    lastName             : string     = ""       ;
    firstNameAlias       : string     = ""       ;
    middleNameAlias      : string     = ""       ;
    lastNameAlias        : string     = ""       ;
    salutation           : string     = ""       ;
    displayName          : string     = ""       ;
    aboutMe              : string     = ""       ;
    phoneNumber          : string     = ""       ;
    avatar               : string     = ""       ;
    aeriesAvatar         : string     = ""       ;
    dob                 ?: StringDate     = undefined;
    age                 ?: number                ;
    lastActive          ?: number     = undefined;
    email2               : string     = ""       ;
    preferredCTEPathway ?: number     = undefined; set_preferredCTEPathway(v?: number) { this.preferredCTEPathway = v }
    messengerJid         : string     = ""       ;
    totalGPA            ?: number     = undefined;
    academicGPA         ?: number     = undefined;
    hasIEP              ?: boolean    = undefined; set_hasIEP(v?:boolean){this.hasIEP = v;}
    has504              ?: boolean    = undefined; set_has504(v?:boolean){this.has504 = v;}
    hasBIP              ?: boolean    = undefined; set_hasBIP(v?:boolean){this.hasBIP = v;}
    isLanguageLearner    : boolean    = false    ;
    oneRosterId         ?: string     = undefined; set_oneRosterId (v: string) { this.oneRosterId  = v };
    sendConfirmEmail     : boolean    = false    ; set_sendConfirmEmail(v: boolean) { this.sendConfirmEmail = v};
    avatarVersion        : number     = 1        ; set_avatarVersion(){this.avatarVersion ++;} // this property use for try to get new avatar has iep version
    canResendInvitation  : boolean    = false    ;
    canBeImpersonatedInClass: boolean = false    ;
    hasFacultyRole ?: boolean = undefined; set_hasFacultyRole(v:boolean){this.hasFacultyRole = v}

    constructor(data?: any) {
        super(data);
        if (data != null) {
            const { phoneNumber, ...pData } = data;
            // format old phone number in bad format
            this.phoneNumber = simplifyPhone(phoneNumber);
            Object.assign(this, pData);
            if (this.firstName       == null) this.firstName       = '';
            if (this.middleName      == null) this.middleName      = '';
            if (this.lastName        == null) this.lastName        = '';
            if (this.firstNameAlias  == null) this.firstNameAlias  = '';
            if (this.middleNameAlias == null) this.middleNameAlias = '';
            if (this.lastNameAlias   == null) this.lastNameAlias   = '';
            if (this.salutation      == null) this.salutation      = '';
            if (this.displayName     == null) this.displayName     = '';
        };

        makeObservable(this, {
            externalId          : observable, set_externalId         : action.bound,
            studentNumber       : observable, set_studentNumber      : action.bound,
            grade               : observable, set_grade              : action.bound,
            email               : observable, set_email              : action.bound,
            firstName           : observable, set_firstName          : action.bound,
            middleName          : observable, set_middleName         : action.bound,
            lastName            : observable, set_lastName           : action.bound,
            salutation          : observable,
            displayName         : observable, set_displayName          : action.bound,
            aboutMe             : observable,
            phoneNumber         : observable, set_phoneNumber        : action.bound,
            avatar              : observable,
            dob                 : observable,
            age                 : observable,
            lastActive          : observable,
            email2              : observable,
            preferredCTEPathway : observable, set_preferredCTEPathway: action.bound,
            messengerJid        : observable,
            totalGPA            : observable,
            academicGPA         : observable,
            oneRosterId         : observable, set_oneRosterId     : action.bound,
            hasIEP              : observable, set_hasIEP          : action.bound,
            has504              : observable, set_has504          : action.bound,
            hasBIP              : observable, set_hasBIP          : action.bound,
            sendConfirmEmail    : observable, set_sendConfirmEmail: action.bound,
            avatarVersion       : observable, set_avatarVersion   : action.bound,
            userId              : computed,
            params              : computed,
            flName              : computed,
            lfName              : computed,
            lfNameAlias         : computed,
            flNameAlias         : computed,
            studentName         : computed,
            showNameInFaculty   : computed,
            fullDetailName      : computed,
            getLastNameAlias    : computed,
            canResendInvitation : observable,
            hasFacultyRole       : observable, set_hasFacultyRole    : action.bound,
            clone               : action.bound,
        });
    }
    toJS() {
        return ({
            studentId           : this.studentId                 ,
            externalId          : this.externalId                ,
            studentNumber       : this.studentNumber             ,
            grade               : this.grade                     ,
            email               : this.email                     ,
            phoneNumber         : this.phoneNumber               ,
            firstName           : this.firstName?.trim()          ,
            middleName          : this.middleName?.trim()        ,
            lastName            : this.lastName?.trim()           ,
            salutation          : this.salutation                ,
            displayName         : this.displayName?.trim()       ,
            aboutMe             : this.aboutMe                   ,
            avatar              : this.avatar                    ,
            dob                 : this.dob                       ,
            age                 : this.age                       ,
            lastActive          : this.lastActive                ,
            email2              : this.email2                    ,
            preferredCTEPathway : this.preferredCTEPathway       ,
            messengerJid        : this.messengerJid              ,
            hasIEP              : this.hasIEP                    ,
            sendConfirmEmail    : this.sendConfirmEmail          ,
            oneRosterId         : this.oneRosterId
        });
    }
    clone() {
        return new RStudent(this.toJS())
    }
    extends(c: RStudent) {
        Object.assign(this, c.toJS());
    }

    get userId() { return this.studentId }
    set userId(v:DbIdentity) { this.studentId = v }

    get params() { return ({studentId: String(this.studentId)})}

    get getLastNameAlias() {return (this.firstNameAlias?.length > 0 && this.lastNameAlias?.length > 0) ? this.lastNameAlias : ''}
    get flName() { return firstLastName(this.firstName, '', this.lastName); }
    get lfName() { return lastFirstName(this.firstName, '', this.lastName); }
    get lfNameAlias() { return this.firstNameAlias?.trim().length > 0 ? lastFirstName(this.firstNameAlias, '', (this.getLastNameAlias || this.lastName)) : this.lfName;}
    get flNameAlias() { return this.firstNameAlias?.trim().length > 0 ? firstLastName(this.firstNameAlias, '', (this.getLastNameAlias || this.lastName)) : this.flName;}
    get studentName() { return this.displayName?.trim() || this.flNameAlias}
    get showNameInFaculty () {return ((this.firstNameAlias || this.getLastNameAlias || this.displayName) ? this.lfName + " (" + this.studentName + ")" : this.lfName);}
    get fullDetailName() { return this.lfName + (!(this.firstNameAlias || this.getLastNameAlias || this.displayName) ? "" : ` (${this.studentName})`)}
    get flMixName() { return firstLastName(this.firstNameAlias || this.firstName, '', this.getLastNameAlias || this.lastName)};
    get displayFullMixName() { return this.displayName?.trim() || this.flMixName};

    set_externalId   (v: string    ) { this.externalId          = v }
    set_studentNumber(v: string    ) { this.studentNumber       = v }
    set_firstName    (v: string    ) { this.firstName           = v }
    set_middleName   (v: string    ) { this.middleName          = v }
    set_lastName     (v: string    ) { this.lastName            = v }
    set_displayName  (v: string    ) { this.displayName         = v }
    set_phoneNumber  (v: string    ) { this.phoneNumber         = v }
    set_email        (v: string    ) { this.email               = v }
    set_grade        (v: number    ) { this.grade               = v }

    async save(SisUserIds:string[]) {
        const body = { ...this.toJS(), SisUserIds };
        const [err, x] = await aFetch<{}>("PUT", `/admin/student/${this.studentId}`, body);
        return [err, (err ? undefined : new RStudent(x))!] as const;
    }

    async create(schoolId:DbIdentity, SisUserIds:string[]) {
        const body = { ...this.toJS(), schoolId, SisUserIds };
        const [err, x] = await aFetch<{}>("POST", `/admin/student`, body);
        return [err, (err ? undefined : new RStudent(x))!] as const;
    }

    static async fetchStudentsInDistrictAsAdmin({districtId, paging, signal}: {districtId:DbIdentity, paging: any, signal?: AbortSignal}) {
        const [err, rs] = await aFetch<{dto:GeneralDto, total: number}>("GET", `/admin/district/${districtId}/student`, paging, { signal });
        return [err, (err ? undefined : {dto:parseGeneralViewModel(rs.dto), total: rs.total})!] as const;
    }

    static async resetStudentPasswordAsAdmin(districtId:DbIdentity, emailText: string ) {
        const [err, _] = await aFetch<{}>("POST", `/admin/district/${districtId}/student/resendinvite`, {emails: emailText});
        return err;
    }

    async createStudentsAsFaculty({ facultyId, classId, classStudent, SisUserIds }: { facultyId:DbIdentity, classId:DbIdentity, classStudent:{}, SisUserIds:string[] }) {
        const body = { ...this.toJS(), ...classStudent, SisUserIds };
        const [err, data] = await aFetch<{}>("POST", `/faculty/${facultyId}/class/${classId}/student`, body);
        return [err, (err ? undefined : new RStudent(data))!] as const;
    }

    async updateStudentsAsFaculty({ facultyId, classId, classStudent }: { facultyId:DbIdentity, classId:DbIdentity, classStudent:{} }) {
        const [err, data] = await aFetch<{}>("PUT", `/faculty/${facultyId}/class/${classId}/student/${this.studentId}`, {...this.toJS(), ...classStudent});
        return [err, (err ? undefined : new RStudent(data))!] as const;
    }
    static async pullFromSis({facultyId, classId}: {facultyId: DbIdentity, classId: DbIdentity}) {
        const [err, data] = await aFetch<{}>("POST", `/faculty/${facultyId}/class/${classId}/student/pullfromsis`);
        return [err, (err ? undefined : new RosterSyncResult(data))!] as const;
    }

    static async pullStudentClassesFromSis({facultyId, classId, studentId}: {facultyId: DbIdentity, classId: DbIdentity, studentId: DbIdentity}) {
        const [err, data] = await aFetch<{}>("POST", `/faculty/${facultyId}/class/${classId}/student/${studentId}/pullClassfromsis`);
        return [err, (err ? undefined : new RosterSyncResult(data))!] as const;
    }

    static async fetchAsAdmin({studentId, signal}: {studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/admin/student/${studentId}`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchAsFacultyAdmin({ facultyId, studentId, signal }: { facultyId:DbIdentity,studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/student/${studentId}`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    async updateStudentsAsFacultyAdmin(facultyId:DbIdentity) {
        const [err, data] = await aFetch<{}>("PUT", `/faculty/${facultyId}/student/${this.studentId}`, {...this.toJS()});
        return [err, (err ? undefined : new RStudent(data))!] as const;
    }

    static async fetchStudentAsStudent({studentId, signal}: {studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, x] = await aFetch<Student>("GET", `/student/${studentId}/detail`, undefined, { signal });
        return [err, (err ? undefined : new Student(x))!] as const;
    }

    static async fetchCurrentLocation({studentId, signal}: {studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, x] = await aFetch<Student>("GET", `/student/${studentId}/currentLocation`, undefined, { signal });
        return [err, (err ? undefined : new Student(x))!] as const;
    }

    async updateStudent() {
        const [err, x] = await aFetch<Student>("PUT", `/student/${this.studentId}/extendUpdate`, {preferredCTEPathway: this.preferredCTEPathway});
        return [err, (err ? undefined : new Student(x))!] as const;
    }

    static async resendInvitationAsFaculty({ facultyId, studentId }:{facultyId: DbIdentity, studentId: DbIdentity}) {
        const [err, x] = await aFetch<Student>("POST", `/faculty/${facultyId}/student/${studentId}/resendInvitation`);
        return [err, (err ? undefined : new Student(x))!] as const;
    }

    static sorter = {
        studentId    : <T extends RStudent>(a?: T, b?: T) => (a? a.studentId || 0 : 0) - (b ? b.studentId || 0 : 0),
        fullName     : <T extends RStudent>(a?: T, b?: T) => (a?.lfName           ?? "").localeCompare(b?.lfName         ?? ""),
        firstName    : <T extends RStudent>(a?: T, b?: T) => (a?.firstName?.trim().replace((/\-/g), "")   ?? "").localeCompare(b?.firstName?.trim().replace((/\-/g), "") ?? ""),
        lastName     : <T extends RStudent>(a?: T, b?: T) => (a?.lastName?.trim().replace((/\-/g), "")    ?? "").localeCompare(b?.lastName?.trim().replace((/\-/g), "")  ?? ""),
        middleName   : <T extends RStudent>(a?: T, b?: T) => (a?.middleName?.trim().replace((/\-/g), "")    ?? "").localeCompare(b?.middleName?.trim().replace((/\-/g), "")  ?? ""),
        firstNameDesc: <T extends RStudent>(a?: T, b?: T) => -((a?.firstName?.trim().replace((/\-/g), "") ?? "").localeCompare(b?.firstName?.trim().replace((/\-/g), "") ?? "")),
        lastNameDesc : <T extends RStudent>(a?: T, b?: T) => -((a?.lastName?.trim().replace((/\-/g), "")  ?? "").localeCompare(b?.lastName.trim().replace((/\-/g), "")  ?? "")),
        middleNameDesc : <T extends RStudent>(a?: T, b?: T) => -((a?.middleName?.trim().replace((/\-/g), "")  ?? "").localeCompare(b?.middleName.trim().replace((/\-/g), "")  ?? "")),
        studentNumber: <T extends RStudent>(a?: T, b?: T) => (a?.studentNumber      ?? "").localeCompare(b?.studentNumber    ?? ""),
        email : <T extends RStudent>(a?: T, b?: T) => (a?.email ?? "").localeCompare(b?.email ?? ""),
        showNameInFaculty : <T extends RStudent>(a?: T, b?: T) => (a?.showNameInFaculty?.trim()?? "").localeCompare(b?.showNameInFaculty?.trim() ?? ""),
        birthday     : <T extends RStudent>(a?: T, b?: T) => (!a?.dob ? 0 : Number(a?.dob)) - (!b?.dob ? 0 : Number(b?.dob)),
    };
}
export class Student extends RStudent {
    /** deprecated */ cumulativeGrade        ?: number = undefined;
    /** deprecated */ cumulativePublishGrade ?: number = undefined;
    /** deprecated */ classes                 : DbIdentity[] = [];

    /** deprecated */ tActivityScore          : ActivityScore[] = [];
    /** deprecated */ get mActivityScore() { return new Map(this.tActivityScore.map<[DbIdentity, ActivityScore]>(s => [s.activityId, s])) }

    /** deprecated */ tClassStudent           : IClassStudents[] = [];
    /** deprecated */ get mClassStudent() { return new Map(this.tClassStudent.map<[DbIdentity, IClassStudents]>(s => [s.classId, s])) }

    schools                  : SchoolShortInfo[]    =  [];
    schedulingClasses        : string[]          = [];
    lastLoginDate           ?: NumberDate        = undefined;
    cumulativePublishGradeSbg ?: number; set_cumulativePublishGradeSbg(v: number) { this.cumulativePublishGradeSbg = v; }

    constructor(data?:any) {
        super(data);
        makeObservable(this, {
            cumulativeGrade       : observable        ,
            cumulativePublishGrade: observable        ,
            classes               : observable.shallow,
            tActivityScore        : observable.shallow,
            tClassStudent         : observable.shallow,
            mActivityScore        : computed,
            mClassStudent         : computed,
            extends               : action,
            schools               : observable.shallow,
            displaySchools        : computed,
            schedulingClasses     : observable.shallow,
            lastLoginDate         : observable,
            cumulativePublishGradeSbg         : observable,
            set_cumulativePublishGradeSbg: action.bound,
        });
        if (data != null) {
            const { schools, schoolIds, schedulingClasses, ...pData } = data;
            Object.assign(this, pData);
            if(Array.isArray(schools)) this.schools = schools.map(x => x as SchoolShortInfo).filter(notNull);
            if(Array.isArray(schedulingClasses)) this.schedulingClasses = schedulingClasses.map(x => String(x));

            if (this.firstName       == null) this.firstName       = '';
            if (this.middleName      == null) this.middleName      = '';
            if (this.lastName        == null) this.lastName        = '';
            if (this.firstNameAlias  == null) this.firstNameAlias  = '';
            if (this.middleNameAlias == null) this.middleNameAlias = '';
            if (this.lastNameAlias   == null) this.lastNameAlias   = '';
            if (this.salutation      == null) this.salutation      = '';
            if (this.displayName     == null) this.displayName     = '';
        }
    }
    get displaySchools() { return this.schools.map(s=> s.schoolName).filter(s => !!s?.trim()).join(", "); }
    override toJS() {
        return ({
            ...super.toJS(),
            grade                 : this.grade,
            cumulativeGrade       : this.cumulativeGrade,
            cumulativePublishGrade: this.cumulativePublishGrade,
        });
    }
    override clone() {
        return new Student(this.toJS());
    }

    override extends(c: Student) {
        super.extends(c);
        if (c.classes       .length > 0) this.classes        = c.classes;
        if (c.tClassStudent .length > 0) this.tClassStudent  = c.tClassStudent;
        if (c.tActivityScore.length > 0) this.tActivityScore = c.tActivityScore;
    }

    calculateMissingAssigment(tActivity:Activity[]) {
        return (tActivity.filter(a => this.classes.includes(a.classId) && a.isGradable && a.isOverdue)
                .map(a => this.mActivityScore.get(a.activityId))
                .filter(ac => ac == null || ac.savedDate == null)
            );
    }

    static getCumulativeGradePercentage(cumulativeGrade?:number){
        return cumulativeGrade?.toLocaleString("en", {style: "percent", minimumFractionDigits: 3});
    }

    static async fetchStudentsInSchoolPaginationAsFaculty(schoolId: DbIdentity
        , facultyId:DbIdentity
        , pageIndex:number
        , pageSize:number
        , excludedClassId:DbIdentity
        , filteredClassId: DbIdentity
        , searchText:string
        , sortColumnKey: string|undefined
        , sortOrder: boolean|"ascend"|"descend"|undefined
        ) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/school/${schoolId}/schoolStudent`,{
            page           : pageIndex,
            size           : pageSize,
            excludedClassId: excludedClassId,
            filteredClassId: filteredClassId,
            searchText     : searchText,
            sortColumnKey  : sortColumnKey ? sortColumnKey: "",
            sortOrder      : sortOrder!=undefined ? sortOrder : "",
        });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }
    static async fetchStudentsInSchoolTotalPaginationAsFaculty(schoolId: DbIdentity
        , facultyId:DbIdentity
        , excludedClassId:DbIdentity
        , filteredClassId: DbIdentity
        , searchText:string) {
        const [err, dto] = await aFetch<number>("GET", `/faculty/${facultyId}/school/${schoolId}/totalSchoolStudent`,{
            excludedClassId: excludedClassId,
            filteredClassId: filteredClassId,
            searchText:searchText
        });
        return [err, (err ? undefined : dto)!] as const;
    }

    static async fetchStudentsOfClassAsFaculty({facultyId, classId, signal }:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/class/${classId}/student`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchClassGradebookStudents({facultyId, classId, signal }:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<ClassGradebookStudent[]>("GET", `/faculty/${facultyId}/class/${classId}/gradebookStudents`, undefined, { signal });
        return [err, (err ? [] : data.map(x => new ClassGradebookStudent(x)))!] as const;
    }

    static async fetchAllStudentsOfClassAsFaculty({facultyId, classId, signal }:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/class/${classId}/allStudents`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    /** @deprecated */
    static async fetchInactiveStudentsOfClassAsFaculty({facultyId, classId, signal }:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/class/${classId}/inactiveStudents`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchDeletedStudentsOfClassAsFaculty({facultyId, classId, signal }:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/class/${classId}/deletedStudents`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchStudentsOfClassesAsFaculty({ facultyId, classIds, signal }: { facultyId:DbIdentity, classIds:DbIdentity[], signal?: AbortSignal }) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/studentInClasses`, { classIds }, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchStudentsOfCaseloadsAsFaculty({ facultyId, caseloadIds, signal }: { facultyId:DbIdentity, caseloadIds:DbIdentity[], signal?: AbortSignal }) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/studentInCaseloads`, { caseloadIds }, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchStudentsAsFaculty({ facultyId, signal }: { facultyId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/student`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchSchoolStudentsAsFacultyAdmin({ facultyId, schoolId, paging, signal }: { facultyId:DbIdentity, schoolId: DbIdentity, paging: any, signal?: AbortSignal }) {
        const [err, rs] = await aFetch<{dto:GeneralDto, total: number}>("GET", `/faculty/${facultyId}/school/${schoolId}/schoolStudents`, paging, { signal });
        return [err, (err ? undefined : {dto:parseGeneralViewModel(rs.dto), total: rs.total})!] as const;
    }
    static async fetchStudentAsFaculty({ facultyId, studentId, signal }:{ facultyId:DbIdentity, studentId:DbIdentity, signal?: AbortSignal,}) {
        const [err, data] = await aFetch<{student: Student, schoolStudent?: ISchoolStudent}>("GET", `/faculty/${facultyId}/student/${studentId}/currentGradingTerm`, undefined, { signal })
        return [err, err ? undefined : { student: new Student(data.student), schoolStudent: data.schoolStudent }] as const;
    }
    static async fetchStudentProgressOverview({ facultyId, studentId, signal }:{ facultyId:DbIdentity, studentId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<IStudentProgressOverview>("GET", `/faculty/${facultyId}/student/${studentId}/studentProgressOverview`, undefined, { signal })
        const vm = err ? undefined : data as IStudentProgressOverview;
        return [err, vm!] as const;
    }
    static async fetchGlance({ facultyId, studentId, classId, signal }:{ facultyId:DbIdentity, studentId:DbIdentity, classId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<string>("GET", `/faculty/${facultyId}/student/${studentId}/class/${classId}/summary`, undefined, { signal })
        return [err, data] as const;
    }

    static async updateStudentInfoAsFaculty({currentFacultyId, studentId, field, info }:{currentFacultyId: DbIdentity, studentId: DbIdentity, field: UserDetailFieldEnum, info?: Student}) {
        const [err, data] = await aFetch<{}>("PUT", `/faculty/${currentFacultyId}/student/${studentId}/updateStudentInfo/${field}`, info?.toJS());
        return [err, err ? undefined : new Student(data)] as const;
    }

    static async fetchClassStudentAsFaculty({ facultyId, classId, studentId, signal }:{facultyId:DbIdentity, classId:DbIdentity, studentId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/class/${classId}/student/${studentId}`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(data);
        return [err, vm!] as const;
    }

    static async fetchStudentGroupAsFaculty(facultyId:DbIdentity, groupId:string, activityId: DbIdentity) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/group/${groupId}/activity/${activityId}`);
        const vm = err ? undefined : parseGeneralViewModel(data);

        return [err, (vm == null ? undefined : vm.students)!] as const;
    }

    static async fetchStudentDetailInTerm({facultyId, studentId, gradingTermId, signal }:{facultyId:DbIdentity, studentId:DbIdentity, gradingTermId ?: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/gradingTerm/${gradingTermId}/student/${studentId}/detail`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchStudentDetailInCurrentGradingTerm({ facultyId, studentId, signal }: { facultyId: DbIdentity, studentId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/student/${studentId}/detail/currentGradingTerm`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchStudentDetailInClass({facultyId, studentId, classId, signal }:{facultyId:DbIdentity, studentId:DbIdentity, classId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/class/${classId}/student/${studentId}/detail`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchStudentListInGroupAsStudent({ studentId, activityId, groupId, signal }: {studentId:DbIdentity, activityId: DbIdentity, groupId:string, signal?: AbortSignal }) {
        const [err, data] = await aFetch<Student[]>("GET", `/student/${studentId}/activity/${activityId}/group/${groupId}/studentList`, undefined, { signal });
        const vm = err ? undefined : data.map(s => new Student(s));
        return [err, (vm == null ? undefined : vm)!] as const;
    }

    static async fetchStudentListInGroupAsParent({parentId, studentId, activityId, groupId, signal}: {parentId: DbIdentity, studentId:DbIdentity, activityId: DbIdentity, groupId:string, signal?: AbortSignal }) {
        const [err, data] = await aFetch<Student[]>("GET", `/parent/${parentId}/student/${studentId}/activity/${activityId}/group/${groupId}/studentList`, undefined, { signal });
        const vm = err ? undefined : data.map(s => new Student(s));
        return [err, (vm == null ? undefined : vm)!] as const;
    }

    static async fetchStudentDetaiInTermAsFaculty({ currentUserId, studentId, gradingTermId, signal }:{currentUserId:DbIdentity, studentId:DbIdentity, gradingTermId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${currentUserId}/gradingTerm/${gradingTermId}/student/${studentId}/detailAll`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchStudentDetaiAsFaculty({currentUserId, studentId, signal }:{currentUserId:DbIdentity, studentId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${currentUserId}/student/${studentId}/detailAll`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchStudentsAsFacultyAdmin({ facultyId, paging, signal }
        : {
            facultyId: DbIdentity,
            paging: { schoolId: number, searchText: string, offset: number, page: number, limit: number, orderBy: string, total: number },
            signal?: AbortSignal
        }
    ) {
        const [err, data] =  await aFetch<{students:AdminStudent[], parents: Parent[], parentStudents:ParentStudent[], total: number}>("GET", `/faculty/${facultyId}/admin/students`, paging, { signal });
        const students = data?.students ?? [];
        const parents = data?.parents ?? [];
        const parentStudents = data?.parentStudents ?? [];
        return [err, (err ? undefined : {students, parentStudents, parents, total: data.total})!] as const;
    }

    static async fetchStudentIep({studentId, facultyId, signal}:{studentId:DbIdentity, facultyId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<IStudentIepProps>("GET", `/faculty/${facultyId}/student/${studentId}/iep`, undefined, { signal });
        return [err, (err ? undefined : data)] as const;
    }

    static async getStudentStatisticInClass({facultyId, classId, signal}:{facultyId:DbIdentity, classId: DbIdentity | undefined, signal?: AbortSignal}) {
        const [err, data] = await aFetch<IClassStudentStatisticInfo>("GET", `/faculty/${facultyId}/class/${classId}/get_student_statistic`, undefined, { signal });
        return [err, (err ? undefined : data)] as const;
    }

    static async updateStudentIepCaseCarrier({studentId, facultyId, documentId, caseCarrierId}:{studentId: DbIdentity, facultyId: DbIdentity, documentId: DbIdentity, caseCarrierId: DbIdentity}){
        const [err, data] = await aFetch<any>("PUT", `/faculty/${facultyId}/student/${studentId}/case_carrier`, {studentIEPDocumentID: documentId, caseCarrierId});
        return [err, (err ? undefined : data)] as const;
    }

    static async updateStudentHasIep({studentId, facultyId, request, signal}:{studentId:DbIdentity, facultyId: DbIdentity, request:{flag: boolean, type: StudentIEPDocumentType}, signal?: AbortSignal}) {
        const [err, data] = await aFetch<IStudentIepProps>("POST", `/faculty/${facultyId}/student/${studentId}/updatestudenthasiep`, request, { signal });
        return [err, (err ? undefined : data)] as const;
    }

    static async uploadIepDoc({file, studentId, facultyId, type}:{file: UploadFile, studentId:DbIdentity, facultyId: DbIdentity, type: StudentIEPDocumentType}) {
        const requestUrl = `${apiHost}${versionPrefix}faculty/${facultyId}/student/${studentId}/iep-doc?type=${type}`;

        const [err, data] = await uploadFile2<{data: IUploadIepDoc, isSuccess: boolean}>("POST", requestUrl, file as any as File);
        return [err, (!!err ? undefined : data.data)] as const;
    }

    static async deleteIepDoc({studentId, facultyId, type}:{studentId:DbIdentity, facultyId: DbIdentity, type: StudentIEPDocumentType}) {
        const [err] = await aFetch<{}>("DELETE", `/faculty/${facultyId}/student/${studentId}/iep-doc?type=${type}`);
        return err;
    }

    //#region Parent
    static async fetchStudentsAsParent({ parentId, signal }: {parentId: DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/parent/${parentId}/students`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new Student(x)))] as const;
    }

    static async fetchStudentSuccessStatistic({studentId, signal, cache}:{studentId: DbIdentity, signal?: AbortSignal, cache ?: RequestCache}){
        const [err, x] = await aFetch<StudentSuccessStatistic>("GET", `/student/${studentId}/studentSuccess/statistic`, undefined, { signal, cache });
        const c = err ? undefined : new StudentSuccessStatistic(x);
        return [err, c] as const;
    }

    //#endregion
    static sorter = Object.assign({
        grade          : (a?: Student, b?: Student) => (a?.grade ?? -1) - (b?.grade ?? -1),
        cumulativePublishGrade: (a?: Student, b?: Student) => (a?.cumulativePublishGrade ?? -1) - (b?.cumulativePublishGrade ?? -1),
        cumulativePublishGradeDesc: (a?: Student, b?: Student) => (b?.cumulativePublishGrade ?? -1) - (a?.cumulativePublishGrade ?? -1),
        cumulativePublishGradeSbg: (a?: Student, b?: Student) => (a?.cumulativePublishGradeSbg ?? -1) - (b?.cumulativePublishGradeSbg ?? -1),
        cumulativePublishGradeSbgDesc: (a?: Student, b?: Student) => (b?.cumulativePublishGradeSbg ?? -1) - (a?.cumulativePublishGradeSbg ?? -1),
    }, RStudent.sorter);
}

export class StudentExt extends Student{
    //StudentExt contains some additional infomation that show in faculty admin

    constructor(data?:any) {
        super(data);
        makeObservable(this, {});
    }

    static async updateStudentInfoAsFacultyAdmin({currentFacultyId, studentId, field, info }:{currentFacultyId: DbIdentity, studentId: DbIdentity, field: UserDetailFieldEnum, info: StudentExt}) {
        const [err, data] = await aFetch<{}[]>("PUT", `/faculty/${currentFacultyId}/admin/student/${studentId}/updateStudentInfo/${field}`, info.toJS());
        return [err, err ? undefined : new StudentExt(data)] as const;
    }
    static async fetchStudentAsFacultyAdmin({ currentFacultyId, studentId, signal }: { currentFacultyId: DbIdentity, studentId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}>("GET", `/faculty/${currentFacultyId}/admin/studentInfo/${studentId}`, undefined, { signal });
        return [err, new StudentExt(data)] as const;
    }
    static async searchActivity({ currentFacultyId, studentId, ps, signal }: { currentFacultyId: DbIdentity, studentId: DbIdentity, ps: {keyword: string, fromDate?: number, toDate?: number, pageIndex: number, pageSize: number}, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{ activities: ISearchActivityLogResult[], totalRecord: number }>("GET", `/faculty/${currentFacultyId}/admin/studentInfo/${studentId}/searchActivity`, ps, { signal });
        return [err, err ? {activities: [], totalRecord: 0} : data] as const;
    }
    override clone() {
        return new StudentExt(this.toJS());
    }

    override toJS() {
        return ({
            ...super.toJS(),
            schools: toJS(this.schools),
            schoolIds: this.schools.map(s => s.schoolId)
        });
    }
}
