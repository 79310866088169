import { action, computed, makeObservable, observable } from "mobx";
import { HtmlString, UrlString } from "../types";
import { nanoid } from "nanoid";
import { UploadFile } from "antd/lib/upload/interface";
import { stripHtml } from "../../utils/html";

interface IActOption {
    id       : string,
    label    : HtmlString,
    image    : UrlString,
    isCorrect: boolean
    isLocked : boolean
}

export class ActOption implements IActOption  {
    id       : string     = "";
    label    : HtmlString = "";
    image    : UrlString  = "";
    isCorrect: boolean    = false;
    isLocked : boolean    = false;

    constructor(data?:{}) {
        makeObservable(this, {
            label        : observable,
            image        : observable,
            isCorrect    : observable,
            isLocked     : observable,
            set_label    : action.bound,
            set_isCorrect: action,
            set_isLocked : action,
            set_image    : action.bound,
            imageFile    : observable.ref,
            clear_image  : action.bound,
            set_imageFile: action.bound,
            isRequired   : computed,
            getLabel     : computed,
            isEqual      : action.bound,
        });

        if (data != null) Object.assign(this, data);
        if(!this.id) this.id = nanoid();
    }

    set_label    (v: HtmlString ) { this.label     = v };
    set_isCorrect(v: boolean    ) { this.isCorrect = v };
    set_image    (v: UrlString  ) { this.image     = v };
    set_isLocked (v: boolean    ) { this.isLocked  = v };

    imageFile?:UploadFile = undefined;
    clear_image() { this.imageFile = undefined; this.image = ""; };
    set_imageFile(v:UploadFile|undefined) { this.imageFile = v };
    get isRequired(): boolean|undefined {
        if(this.isCorrect){
            return !(this.getLabel  != "" || this.imageFile != undefined || this.image  != "");
        }

        return false;
    }
    get getLabel(): HtmlString {
        return stripHtml(this.label).trim();
    }
    isEqual(item: ActOption): boolean{
        return ((this.getLabel == item.getLabel && this.image == item.image) || (item.id.trim().length > 0 && item.id == this.id));
    }
    toJS(): IActOption {
        return ({
            id        : this.id,
            label     : this.label,
            image     : this.image,
            isCorrect : this.isCorrect,
            isLocked  : this.isLocked
        });
    }

    IsValid() {
        return !!this.getLabel || this.imageFile != null || !!this.image;
    }
}