export interface IErrorData<T> extends Error {
    status:number
    error?:{
        statusCode  : number;
        message     : string;
        errorCode   : ErrorCode;
        errorObject?: T;
    },
    requestUrl?: string,
    method ?: "GET"|"POST"|"PUT"|"DELETE"|"PATCH",
}

export type ErrorItemType<T> = {
    error: IErrorData<any> | undefined,
    item : T
};

export function createError<E extends Error, T = any>(error: E, status:number, requestUrl ?: string, method ?: "GET"|"POST"|"PUT"|"DELETE"|"PATCH"): IErrorData<T> {
    return Object.assign(error, {status, requestUrl, method});
}

export function createInvalidError<T = string | object>({ errorObject, errorCode } : { errorObject : T, errorCode ?: string }): IErrorData<T> {
    if (typeof errorObject === "string") return createError(new Error(errorObject, { cause: errorCode }), 500);
    else return Object.assign(createError(new Error(), 500), { error: { errorObject, errorCode } });
}

export enum ErrorCode {
    EmailNotConfirm            = "EmailNotConfirm",
    UserIsLockedOut            = "UserIsLockedOut",
    UserIsNotAllowed           = "UserIsNotAllowed",
    LoginRequiresTwoFactor     = "LoginRequiresTwoFactor",
    LoginInvalid               = "LoginInvalid",
    GoogleLoginInvalid         = "GoogleLoginInvalid",
    LoginGoogleUserNotFound    = "LoginGoogleUserNotFound",
    LoginInvalidEmail          = "LoginInvalidEmail",

    StudentNotFound            = "StudentNotFound",
    StudentNumberNotFound      = "StudentNumberNotFound",
    StudentPhoneNumberNotFound = "StudentPhoneNumberNotFound",

    SchoolIsRequired           = "SchoolIsRequired",
    DuplicateUserName          = "DuplicateUserName",
    DuplicateEmail             = "DuplicateEmail",
    DuplicateFileName          = "DuplicateFileName",
    DuplicatedOneRosterId      = "This OneRoster ID is registered for another class in organization",

    Unknown                    = "Unknown",

    ActivityRequiredPassword   = "ActivityRequiredPassword",
    ActivityPasswordIncorrect  = "ActivityPasswordIncorrect",
    PublicConferenceCompleted  = "PublicConferenceCompleted",

    GoogleDriveDisconnect      = "GoogleDriveDisconnect",
    ResourceDiscussionIsLinked = "ResourceDiscussionIsLinked",
    ImpersonateViewOnly        = "ImpersonateViewOnly",
    GoogleGoogleApiException   = "Google.GoogleApiException",
    ActivityNotFoundButExistLinkedActivity = "ActivityNotFoundButExistLinkedActivity",
    ZoomInvalidToken = "Tesseract.Core.Exceptions.ZoomInvalidTokenException",
    HighlightText = "HighlightText",

    MagicLinkPollingTrackerNull = "MagicLinkPollingTrackerNull",
    MagicLinkPollingTrackerUsed = "MagicLinkPollingTrackerUsed",
    MagicLinkInvalidToken = "MagicLinkInvalidToken",
    LoginSAMLUserNotFound    = "LoginSAMLUserNotFound",
    InstructionNotFound = "api.activity.error.studentActivityNotFound",
    MicrosoftApi = "MicrosoftApi",
    MicrosoftLoginInvalid         = "MicrosoftLoginInvalid",
    Validation = "Microsoft.AspNetCore.Mvc.ValidationProblemDetails"
}
