
import { MediaTypeEnum } from "../models/MediaTypeEnum";

import { aFetch } from "../services/api/fetch";
import { RegExpPatternUrl } from "../config";
import { getFileExtension } from "./file";

const videoSiteRegex = [
    /youtube\.com/i,
    /youtu\.be/i,
    /vimeo\.com/i
];
const audioSiteRegex = [
    /soundcloud\.com/i,
];
export const videoExts = [
    /\.mp4$/i,
    /\.3gp$/i,
    /\.avi$/i,
    /\.mkv$/i,
    /\.mov$/i,
    /\.moov$/i,
    /\.ogg$/i,
    /\.mpg$/i,
    /\.mpeg$/i,
    /\.qt$/i,
    /\.m4v$/i,
    /\.webm$/i,
    /\.wmv$/i,
    /\.ogv$/i,
];
export const audioExts = [
    /\.mp3$/i,
    /\.wav$/i,
    /\.wma$/i,
    /\.m4a$/i,
    /\.oga$/i,
    /\.mpa$/i,
    /\.mpg$/i,
    /\.mpeg$/i,
    /\.mod$/i,
    /\.mid$/i,
    /\.midi$/i,
];
export const imageExts = [
    /\.jpg$/i,
    /\.jpeg$/i,
    /\.bm$/i,
    /\.heic$/i,
    /\.heif$/i,
    /\.ico$/i,
    /\.png$/i,
    /\.gif$/i,
    /\.svg$/i,
    /\.webp$/i,
    /\.tiff$/i,
    /\.tif$/i,
    /\.bmp$/i,
];

/** not support in view for now */
export const excludeExts = [
    /\.ods$/i,
    /\.ai$/i,
    /\.eps$/i,
    /\.psd$/i
]

/** not support in view for now */
export const excludeMimetypes = [
    'application/octet-stream',
    'application/x-photoshop',
    'image/vnd.adobe.photoshop',
    'image/x-photoshop',
    'application/postscript',
]

export async function getMediaInfo(url:string): Promise<{mediaType:MediaTypeEnum, contentType:string}> {
    if (videoSiteRegex.some(regex => regex.test(url))) {
        const mediaType = MediaTypeEnum.VideoLink;
        let contentType = getContentType(mediaType)
        return { mediaType, contentType };
    }
    if (audioSiteRegex.some(regex => regex.test(url))) {
        const mediaType = MediaTypeEnum.AudioLink;
        let contentType = getContentType(mediaType)
        return { mediaType, contentType };
    }
    if (videoExts.some(regex => regex.test(url))) {
        const mediaType = MediaTypeEnum.VideoFile;
        let contentType = getContentType(mediaType)
        switch (url.split(".").pop()?.toLowerCase()) {
            case "mp4":
                contentType = "video/mpeg";
                break;
            case "mpeg":
                contentType = "video/mpeg";
                break;
            case "webm":
                contentType = "video/webm";
                break;
            case "ogv":
                contentType = "video/ogg";
                break;
            default:
                break;
        }
        return { mediaType, contentType };
    }
    if (audioExts.some(regex => regex.test(url))) {
        const mediaType = MediaTypeEnum.AudioFile;
        let contentType = getContentType(mediaType)
        switch (url.split(".").pop()?.toLowerCase()) {
            case "mp3":
                contentType = "audio/mpeg";
                break;
            case "wav":
                contentType = "audio/wav";
                break;
            case "m4a":
                contentType = "audio/m4a";
                break;
            case "oga":
                contentType = "audio/ogg";
                break;
            default:
                break;
        }
        return { mediaType, contentType };
    }
    if (imageExts.some(regex => regex.test(url))) {
        const mediaType = MediaTypeEnum.ImageFile;
        const contentType = getContentType(mediaType)
        return { mediaType, contentType};
    }

    const mediaInfo = await tryGetMediaInfo(url);
    const mediaType = mediaInfo?.mediaType ?? MediaTypeEnum.Unsupport;
    const contentType = getContentType(mediaType)
    return { mediaType, contentType};
}

const getContentType = (mediaType: MediaTypeEnum) => {
    switch (mediaType) {
        case MediaTypeEnum.AudioFile:
        case MediaTypeEnum.AudioLink:
            return "audio/*"
        case MediaTypeEnum.VideoFile:
        case MediaTypeEnum.VideoLink:
            return "video/*"
        case MediaTypeEnum.ImageFile:
        case MediaTypeEnum.ImageLink:
            return "image/*"
        default:
            return "";
    }
}

export function contentType2MediaType(contentType:string):MediaTypeEnum {
    if (/image\/\w+/i.test(contentType)) {
        return MediaTypeEnum.ImageFile;
    }
    if (/audio\/\w+/i.test(contentType)) {
        return MediaTypeEnum.AudioFile;
    }
    if (/video\/\w+/i.test(contentType)) {
        return MediaTypeEnum.VideoFile;
    }
    return MediaTypeEnum.Unsupport;
}


export function contentType2Extension(contentType:string):string {
    return (
        (contentType.startsWith("video/webm") ? "webm" : (
        (contentType.startsWith("video/mp4" ) ? "mp4"  : (
        (contentType.startsWith("audio/webm") ? "webm" : (
        (contentType.startsWith("audio/wav" ) ? "wav" : (
        (contentType.startsWith("audio/mpeg") ? "mp3" : (
        (contentType.startsWith("image/jpeg") ? "jpg"  : (
        (contentType.startsWith("image/png" ) ? "png"  : (
        (contentType.startsWith("image/bmp" ) ? "bmp"  : (
        (contentType.startsWith("image/gif" ) ? "gif"  : (
        (contentType.startsWith("image/heic") ? "heic"  : (
        (contentType.startsWith("image/x-icon" ) ? "ico"  : (
        (contentType.startsWith("image/tiff" ) ? "tiff"  : (
        (contentType.startsWith("image/webp" ) ? "webp"  : (
        ""))))))))))))))))))))))))))
    );
}

async function tryGetMediaInfo(src:string) {
    const isValidUrl = new RegExp(RegExpPatternUrl, 'g').test(src);
    if (!isValidUrl) return undefined;

    const imageInfo = await tryGetImageInfo(src);
    if (imageInfo != null) return imageInfo;
    const videoInfo = await tryGetVideoInfo(src);
    if (videoInfo != null) return videoInfo;
    const [err, info] = await aFetch<{contentType?:string, link?:string, mediaType:MediaTypeEnum}>("GET", `/Image/GetMediaInfo`, {url: src});
    if (!err && info.mediaType != MediaTypeEnum.Unknown) return info;
    return undefined;
}

async function tryGetVideoInfo(src:string) {
    return new Promise<{mediaType:MediaTypeEnum, width?:number, height?:number}>(resolve => {
        const video = document.createElement("video");
        video.onloadedmetadata = () => {
            resolve({
                mediaType: (video.videoTracks && video.videoTracks.length > 0) ? MediaTypeEnum.VideoFile : MediaTypeEnum.AudioFile,
                width: video.videoWidth,
                height: video.videoHeight,
            });
        };
        video.onerror = (err) => {
            console.warn(err);
            resolve(undefined)
        };
        video.src = src;
    });
}

export async function tryGetImageInfo(src:string) {
    return new Promise<{mediaType:MediaTypeEnum, width?:number, height?:number}>(resolve => {
        const img = new Image();
        img.onload = () => {
            resolve({
                mediaType: MediaTypeEnum.ImageFile,
                width: img.naturalWidth,
                height: img.naturalHeight,
            });
        };
        img.onerror = (err) => {
            console.warn(err);
            resolve(undefined)
        };
        img.src = src;
    });
}

export async function tryGetContentType(src:string) {
    const [err, info] = await aFetch<{contentType?:string, link?:string, mediaType:MediaTypeEnum}>("GET", `/Image/GetMediaInfo`, {url: src});
    if (!err && info.mediaType != MediaTypeEnum.Unknown) return info;
    return undefined;
}

export function isImage(fileType ?: string, fileName ?: string){
    return (fileType?.startsWith("image/") && fileType != "image/vnd.adobe.photoshop" && fileType != 'image/tiff') || imageExts.some(regex => regex.test(fileName ?? ""));
}

export function isTiff(fileType: string | undefined, fileName: string) {
    return ["image/tiff", "image/tif"].includes(fileType?.toLowerCase() ?? "") || ["tiff", "tif"].includes(getFileExtension(fileName));
}
