import { observable, toJS, action, computed, makeObservable } from "mobx";
import { aFetch } from "../services/api/fetch";

import { DbIdentity, DefaultId, EGoogleDocMimeType, googleMimeTypeToViewType } from "./types";
import type { ActivityType } from "./Activity";
import { IErrorData } from "../services/api/AppError";
import { nanoid } from "nanoid";
import { qs2URLSearchParams } from "../utils/url";
import { ActDoc, ActItem } from "./ActDoc";
import { extractFileNameFromUrl, processSpecialFile } from "../utils/file";
import { uploadGeneralFile } from "../services/api/upload";
import { ThumbnailView } from "./Google/GoogleSlides";
import { mockThumbnailViews } from "./Google/GoogleSlides.mockup";

export interface ISimpleGoogleFileInfo {
    id           : string,
    embedUrl     : string,
    type         : string,
    resourceKey ?: string,
}

/**
 * this Model is return by Google Picker
 */
export class GoogleDocItem implements ISimpleGoogleFileInfo {
    id           : string  = "";
    name        ?: string  = "";
    mimeType     : string  = "";
    type         : string  = "";
    embedUrl     : string  = "";
    iconUrl     ?: string | undefined;
    /** @deprecated use embedUrl */
    url         ?: string | undefined;
    description ?: string | undefined;
    isShared    ?: string | undefined;
    lastEditedUtc: number  = 0;
    serviceId   ?: string | undefined;
    sizeBytes    : number  = 0;
    resourceKey ?: string;
    isEmbedLink  : boolean = false;
    parentId     : string = "";
    uid          : string = nanoid(); //upload file id, use to identify this item from list of selected files for uploading
    // isNew       ?: boolean;
    parent      ?: GoogleDocItem = undefined;

    constructor(data ?: {}) {
        makeObservable(this, {
            id            : observable,
            name          : observable,
            mimeType      : observable,
            type          : observable,
            embedUrl      : observable,
            iconUrl       : observable,
            url           : observable,
            description   : observable,
            isShared      : observable,
            lastEditedUtc : observable,
            serviceId     : observable,
            sizeBytes     : observable,
            resourceKey   : observable,
            isEmbedLink   : observable,
            parentId      : observable,
            showInProgress: computed,
            uid           : observable,
            parent        : observable.ref,
        });

        if (data) Object.assign(this, data);
    }

    // cannot track in progress status in google drive file
    // some large file size cannot complete right after copy or create
    get showInProgress() { return (this.mimeType.startsWith("video") && Date.now().valueOf() - this.lastEditedUtc > 60*1e3); }

    static mapFrom(item: ActItem) {
        /** allow if item.type != EActItemType.GoogleAssignment, then data is empty */
        return new GoogleDocItem({
            id            : item.embedId    ,
            mimeType      : item.embedContentType,
            embedId       : item.embedId         ,
            name          : item.embedName,
            type          : item.embedType,
            lastEditedUtc : item.embedLastEdited,
        });
    }
}

export interface IActivityGoogleDocItem{
    embedId         : string,
    actItemId       : string,
    mimeType       ?: EGoogleDocMimeType | string,
    fileName       ?: string,
    activityId     ?: DbIdentity,
    title          ?: string,
}

export interface IExistingGoogleDocItemParam{
    classId         : string,
    facultyId       : string,
}
export class FilePermission {
    allowFileDiscovery        : string = "";
    deleted                   : string = "";
    displayName               : string = "";
    domain                    : string = "";
    eTag                      : string = "";
    emailAddress              : string = "";
    expirationTime            : string = "";
    id                        : string = "";
    kind                      : string = "";
    permissionDetails         : string = "";
    photoLink                 : string = "";
    role                      : string = "";
    teamDrivePermissionDetails: string = "";
    type                      : string = "";

    constructor() {
        makeObservable(this, {
            allowFileDiscovery        : observable,
            deleted                   : observable,
            displayName               : observable,
            domain                    : observable,
            eTag                      : observable,
            emailAddress              : observable,
            expirationTime            : observable,
            id                        : observable,
            kind                      : observable,
            permissionDetails         : observable,
            photoLink                 : observable,
            role                      : observable,
            teamDrivePermissionDetails: observable,
            type                      : observable,
        });
    }
}

interface IGoogleFileBatchResponse {
    failedItems     : GoogleDocItem[],
    successfulItems : GoogleDocItem[],
    errors         ?: {id : string, error: IErrorData<any>}[],
}

export class GoogleDrive {
    static async downloadAndPublicGoogleFile(doc: GoogleDocItem, createThumb ?: boolean) {
        const [err, url] = await aFetch<string>("POST", `/GoogleApi/drive/download?${qs2URLSearchParams({isPublic: true, createThumb}).toString()}`, toJS(doc));
        if(!err && /\.heic$/i.test(url)){
            const fileName = extractFileNameFromUrl(url);
            const processedFile = await processSpecialFile({
                name: fileName,
                url: url
            });
            const [uErr, uploadedFile] = await uploadGeneralFile(processedFile, true);
            if(!uErr && !!uploadedFile){
                return [undefined, uploadedFile.link] as const;
            }
        }
        return [err, (err ? undefined : url)!] as const;
    }

    static async exportAndPublicGoogleSlides(fileId: string) {
        const [err, data] = await aFetch<ThumbnailView[]>("POST", `/GoogleApi/slides/${fileId}/exportAsImage?${qs2URLSearchParams({isPublic: true}).toString()}`);
        // return [undefined, mockThumbnailViews] as const;
        return [err, (err ? undefined : data ?? [])!] as const;
    }

    static async exportPdfFile(doc: GoogleDocItem) {
        return await aFetch<string>("POST", `/GoogleApi/drive/downloadPdf`, toJS(doc));
    }

    static async downloadGoogleFile(doc: GoogleDocItem, isPublic?: boolean, createThumb ?: boolean) {
        const [err, url] = await aFetch<string>("POST", `/GoogleApi/drive/download?${qs2URLSearchParams({isPublic: isPublic, createThumb}).toString()}`, toJS(doc));
        if(!err && /\.heic$/i.test(url)){
            const fileName = extractFileNameFromUrl(url);
            const processedFile = await processSpecialFile({
                name: fileName,
                url: url
            });
            const [uErr, uploadedFile] = await uploadGeneralFile(processedFile, true);
            if(!uErr && !!uploadedFile){
                return [undefined, uploadedFile.link] as const;
            }
        }
        return [err, (err ? undefined : url)!] as const;
    }

    static async getFileInfo(googleFileId: string) {
        const [err, fileInfo] = await aFetch<GoogleFile | undefined>("GET", `/GoogleApi/drive/fileInfo/${googleFileId}`);
        return [err, (err ? undefined : new GoogleFile(fileInfo))!] as const;
    }
    static async shareFilesOrFolders(data : IGoogleFileBatchRequest) {
        const [err, vm] = await aFetch<IGoogleFileBatchResponse>("POST", `/GoogleApi/drive/shareFilesOrFolders`, toJS(data));
        return [err, (err ? [] : vm.successfulItems.map(x => new GoogleDocItem(x)))!, (err ? [] : vm.failedItems.map(x => new GoogleDocItem(x)))!, (err ? [] : vm.errors ?? [])] as const;
    }

    static async checkAuthorizedDriveByUser(userId : DbIdentity) {
        const [err, vm] = await aFetch<boolean>("GET", `/GoogleApi/drive/user/${userId}/isAuthorized`);
        return [err, vm] as const;
    }

    static async checkAuthorizedDriveByMainTeacherOfClass(classId : DbIdentity) {
        const [err, vm] = await aFetch<boolean>("GET", `/GoogleApi/drive/class/${classId}/isAuthorized`);
        return [err, vm] as const;
    }

    static async getFilesByParentFolders({folderIds, mimeTypes}: {folderIds: string[], mimeTypes: string[]}) {
        const [err, vm] = await aFetch<GoogleDocItem[][]>("POST", `/GoogleApi/drive/getFilesByParentFolders`, { folderIds, mimeTypes });
        return [err, (err ? [] : vm.map(x => new GoogleDocItem(x)))!] as const;
    }

    static async shareFile(data: IGoogleFileRequest) {
        const [err, result] = await aFetch<GoogleFile>("POST", `/GoogleApi/drive/sharefile`, toJS(data));
        return [err, (err ? undefined : result)!] as const;
    }

    static async createFile(file: CreateGoogleAssignmentRequestBase) {
        const [err, result] = await aFetch<{}>("POST", `/GoogleApi/drive/file`, file.toJS());
        return [err, (err ? undefined : new GoogleFile(result))!] as const;
    }

    static async getExistingGoogleItemAsFaculty(classId: DbIdentity, facultyId: DbIdentity, activityType ?: ActivityType ) {
        const [err, result] = await aFetch<IActivityGoogleDocItem[]>("GET", `/faculty/${facultyId}/class/${classId}/googleActItems` + (activityType ? `?activityType=${activityType}` : ""));
        return [err, (err ? undefined : result)!] as const;
    }

    //#region Faculty
    static async updateDriveClassFolderAsFaculty({ driveId, classId, facultyId }:{ driveId ?: string, classId: DbIdentity, facultyId: DbIdentity }) {
        const [err, result] = await aFetch<string>("PUT", `faculty/${facultyId}/class/${classId}/updateGoogleDriveClassFolder`, { driveId });
        return [err, (err ? undefined : result)!] as const;
    }

    static async fetchDriveClassFolderAsFaculty({ classId, facultyId, signal }:{ classId: DbIdentity, facultyId: DbIdentity, signal: AbortSignal }) {
        const [err, result] = await aFetch<{}>("GET", `faculty/${facultyId}/class/${classId}/classGoogleFolder`, undefined, { signal });
        return [err, (err ? undefined : new GoogleFile(result))!] as const;
    }

    static async fetchSharedDrives({ userId, signal }:{ userId: DbIdentity, signal: AbortSignal }) {
        const [err, data] = await aFetch<IDrive[]>("GET", `GoogleApi/user/${userId}/drive/sharedDrives`, undefined, { signal });
        return [err, (err ? undefined : data.map(x => x as IDrive))!] as const;
    }
    //#endregion
}

export interface IDrive {
    id                   : string,
    name                 : string,
    createdTime         ?: string,
    colorRgb            ?: string,
    backgroundImageLink ?: string,
    hidden              ?: boolean,
}

export class GoogleForm {
    static async importActItemFromGoogleForms(formId : string) {
        const [err, vm] = await aFetch<ActDoc>("PUT", `/GoogleApi/forms`, { formId });
        return [err, (err ? undefined : new ActDoc(vm))!] as const;
    }
}

/**
 *  return by Google API service
 */
export class GoogleFile implements ISimpleGoogleFileInfo {
    id               : string = "";
    name             : string = "";
    mimeType         : string = "";
    embedUrl         : string = "";
    resourceKey     ?: string;
    iconLink         : string = "";
    fullFileExtension: string = "";
    fileExtension    : string = "";
    size             : number = 0;
    createdTime      : number = 0;
    modifiedTime     : number = 0;
    canModifyContent : boolean = false;
    trashed          : boolean = false;
    driveId         ?: string;
    thumbnailLink   ?: string;
    thumbnailHeight ?: number;
    thumbnailWidth  ?: number;

    constructor(data ?: {}) {
        makeObservable(this, {
            name             : observable,
            mimeType         : observable,
            embedUrl         : observable,
            resourceKey      : observable,
            iconLink         : observable,
            fullFileExtension: observable,
            fileExtension    : observable,
            size             : observable,
            createdTime      : observable,
            modifiedTime     : observable,
            driveId          : observable,
            type             : computed,
            canModifyContent : observable,
            trashed          : observable,
            thumbnailLink    : observable,
            thumbnailHeight  : observable,
            thumbnailWidth   : observable,
        });

        if (data) Object.assign(this, data);
    }

    get type() {
        return googleMimeTypeToViewType(this.mimeType);
    }
}

interface IGoogleFileBatchRequest{
    googleFiles   : GoogleDocItem[],
    classId      ?: DbIdentity,
    activityId   ?: DbIdentity,
    queryChildrenFileIfFolder ?: boolean,
    mimeTypes    ?: string[]
}

interface IGoogleFileRequest{
    googleFileId  : string,
    classId      ?: DbIdentity,
    activityId   ?: DbIdentity,
}

class CreateGoogleAssignmentRequestBase {
    googleFileId : string             = "";
    name        ?: string             = undefined;
    mimeType     : EGoogleDocMimeType = EGoogleDocMimeType.Document;
    isEmbedLink  : boolean            = false;

    constructor(data ?: {}) {
        makeObservable(this, {
            googleFileId    : observable,
            name            : observable,
            mimeType        : observable,
            isEmbedLink     : observable,
            set_googleFileId: action.bound,
            set_name        : action.bound,
            set_mimeType    : action.bound,
            set_isEmbedLink : action.bound,
        });

        if (data) Object.assign(this, data);
    }

    clone() { return new CreateGoogleAssignmentRequestBase(this.toJS()); }

    toJS() { return ({
        name           : this.name          ,
        mimeType       : this.mimeType      ,
        googleFileId   : this.googleFileId  ,
        isEmbedLink    : this.isEmbedLink   ,
    }) }

    set_googleFileId(v ?: string            ) { this.googleFileId   = v ?? ""                         ; }
    set_name        (v ?: string            ) { this.name           = v                               ; }
    set_mimeType    (v ?: EGoogleDocMimeType) { this.mimeType       = v ?? EGoogleDocMimeType.Document; }
    set_isEmbedLink (v  : boolean           ) { this.isEmbedLink    = v                               ; }
}

export class CreateGoogleAssignmentRequest extends CreateGoogleAssignmentRequestBase {
    classId      : DbIdentity         = DefaultId;
    activityId   : DbIdentity         = DefaultId;

    constructor(data ?: {}) {
        super(data);

        makeObservable(this, {
            classId         : observable,
            activityId      : observable,
            set_classId     : action.bound,
            set_activityId  : action.bound,
        });

        if (data) Object.assign(this, data);
    }

    override clone() { return new CreateGoogleAssignmentRequest(this.toJS()); }

    override toJS() { return ({...super.toJS(),
        classId        : this.classId       ,
        activityId     : this.activityId    ,
    }) }

    set_classId     (v  : DbIdentity        ) { this.classId        = v                               ; }
    set_activityId  (v  : DbIdentity        ) { this.activityId     = v                               ; }
}

export class CreateEndeavourInsGoogleAssignmentRequest extends CreateGoogleAssignmentRequestBase {
    studentId           : DbIdentity         = DefaultId;
    programEndeavourId  : DbIdentity         = DefaultId;

    constructor(data ?: {}) {
        super(data);

        makeObservable(this, {
            studentId              : observable,
            programEndeavourId     : observable,
            set_studentId          : action.bound,
            set_programEndeavourId : action.bound,
        });

        if (data) Object.assign(this, data);
    }

    override clone() { return new CreateEndeavourInsGoogleAssignmentRequest(this.toJS()); }

    override toJS() { return ({...super.toJS(),
        studentId         : this.studentId,
        programEndeavourId: this.programEndeavourId,
    }) }

    set_studentId          (v : DbIdentity ) { this.studentId          = v ; }
    set_programEndeavourId (v : DbIdentity ) { this.programEndeavourId = v ; }
}
