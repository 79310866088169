import { makeObservable } from "mobx";
import { DbIdentity, DefaultId } from "./types";
export class ParentStudent {
    parentId: DbIdentity;
    studentId: DbIdentity;
    constructor(data?: {}) {

        makeObservable(this, {});

        this.parentId = DefaultId;
        this.studentId = DefaultId;

        if (data != null) Object.assign(this, data);
    }
}
