import { action, computed, makeObservable, observable } from "mobx";

export class Pager{
    pageNum      : number = 0;
    pageSize     : number = 10;
    totalRecord : number = 0;
    constructor(data?: any) {
        makeObservable(this, {
            pageNum: observable,
            pageSize: observable,
            totalRecord: observable,
            moveNext: action.bound,
            totalPage: computed,
            canPaging: computed,
            canMoveNext: computed,
            canMovePrev: computed,
        });
        if (data != null) {
            const { pageNum, pageSize, totalRecord, ...pData } = data;
            this.pageNum = pageNum ?? this.pageNum;
            this.pageSize = pageSize ?? this.pageSize;
            this.totalRecord = totalRecord ?? this.totalRecord;
        }
    }
    get totalPage(){
        return Math.ceil(this.totalRecord / this.pageSize);
    }
    get toParams(){
        return `pageSize=${this.pageSize}&pageNum=${this.pageNum}`;
    }
    get canPaging(){
        return this.totalRecord > this.pageSize;
    }
    get canMoveNext(){
        return this.pageNum < this.totalPage - 1;
    }
    get canMovePrev(){
        return this.pageNum > 0;
    }
    moveNext(){
        this.pageNum = Math.min(this.pageNum + 1, this.totalPage - 1);
    }
    movePrev(){
        this.pageNum = Math.max(0, this.pageNum - 1);
    }
}