import { action, makeObservable, observable, toJS } from "mobx";
import { aFetch } from "../services/api/fetch";
import { CaseloadModule } from "./CaseloadModule";
import { CaseloadTag } from "./CaseloadTag";
import { DiscussionStatistic } from "./Stats";
import { DbIdentity, DefaultId, NumberDate } from "./types";
import { showTinyEditor } from "../utils/html";
export enum CaseloadDiscussionShareType{
    All = 1,
    SelectedTags = 2
}

export class CaseloadDiscussion{
    generalDiscussionId ?: DbIdentity = DefaultId;
    title: string = "";
    content: string = "";
    caseloadId?: DbIdentity;
    selectedTagIds: DbIdentity[] = [];
    shareType: CaseloadDiscussionShareType = CaseloadDiscussionShareType.All;
    tags ?: CaseloadTag[] = undefined;
    createdFullName: string = "";
    dateCreated?: NumberDate = undefined;
    startTime?: NumberDate = undefined;
    endTime?: NumberDate = undefined;
    statistic ?: DiscussionStatistic = undefined;
    isDeleted ?: boolean = undefined;
    createdBy ?: DbIdentity = undefined;
    moduleId : string = "";
    threadId : string = "";
    module  ?: CaseloadModule = undefined;
    isTinyMCE ?: boolean;

    set_title(v: string) { this.title = v; }
    set_content(v: string) { this.content = v; }
    set_selectedTagIds(v: DbIdentity[]) { this.selectedTagIds = v; }
    set_shareType(v: CaseloadDiscussionShareType) { this.shareType = v; }
    set_startTime(v ?: NumberDate){this.startTime = v;}
    set_endTime(v ?: NumberDate){this.endTime = v;}
    set_isTinyMCE(v ?: boolean){this.isTinyMCE = v;}

    get isPublish() { return (this.startTime != null && this.startTime < Date.now()) }
    get isClosed() { return (this.endTime != null && this.endTime < Date.now()) }

    constructor(data?: any) {
        if (data != null) {
            Object.assign(this, data);

            if (this.tags && this.tags?.length > 0) {
                this.set_shareType(CaseloadDiscussionShareType.SelectedTags);
                this.set_selectedTagIds(this.tags?.map(x=>x.caseloadTagId)!);
            }
        }

        makeObservable(this, {
            generalDiscussionId: observable,
            caseloadId: observable,
            selectedTagIds: observable.shallow,
            title: observable,
            content: observable,
            shareType:observable,
            set_title: action.bound,
            set_content: action.bound,
            set_shareType: action.bound,
            set_selectedTagIds: action.bound,
            tags: observable.shallow,
            createdFullName: observable,
            dateCreated: observable,
            startTime: observable,
            endTime: observable,
            statistic: observable,
            set_startTime: action.bound,
            set_endTime: action.bound,
            isDeleted: observable,
            threadId: observable,
            moduleId: observable,
            module  : observable,
            isTinyMCE: observable,
            set_isTinyMCE: action.bound,
        });
    }

    clone() { return new CaseloadDiscussion(this.toJS()); }
    toJS() { return toJS(this); }

    static async Create(caseloadDiscussion: CaseloadDiscussion, facultyId: DbIdentity, extraData: any) {
        const [err, data] = await aFetch<{caseloadDiscussion:{}[], caseloadModule:{}[]}>("POST", `/faculty/${facultyId}/caseload/${caseloadDiscussion.caseloadId}/createDiscussion`, {...caseloadDiscussion.toJS(), ...extraData});
        const caseloadDiscussions = err ? [] : data.caseloadDiscussion.map(d => new CaseloadDiscussion(d));
        const caseloadModules = err ? [] : data.caseloadModule.map(m => new CaseloadModule(m));
        return [err, {caseloadDiscussions, caseloadModules}] as const;
    }

    static async Update(caseloadDiscussion: CaseloadDiscussion, facultyId: DbIdentity) {
        const [err, data] = await aFetch<{}>("PUT", `/faculty/${facultyId}/caseload/${caseloadDiscussion.caseloadId}/updateDiscussion`, caseloadDiscussion.toJS());
        return [err, (err ? undefined : new CaseloadDiscussion(data))!] as const;
    }

    static async Delete(caseloadDiscussion: CaseloadDiscussion, facultyId: DbIdentity) {
        const [err] = await aFetch<{}>("DELETE", `/faculty/${facultyId}/caseload/${caseloadDiscussion.caseloadId}/discussion/${caseloadDiscussion.generalDiscussionId}`);
        return err;
    }

    static async FetchCaseloadDiscussions(facultyId: DbIdentity, caseloadId: DbIdentity) {
        const [err, data] = await aFetch<CaseloadDiscussion[]>("GET", `/faculty/${facultyId}/caseload/${caseloadId}/discussions`);
        return [err, (err ? undefined : data.map(x=>new CaseloadDiscussion(x)))] as const;
    }

    static async FetchCaseloadDiscussion(facultyId: DbIdentity, caseloadId: DbIdentity, discussionId: DbIdentity) {
        const [err, data] = await aFetch<CaseloadDiscussion>("GET", `/faculty/${facultyId}/caseload/${caseloadId}/discussion/${discussionId}`);
        return [err, (err ? undefined : new CaseloadDiscussion(data))] as const;
    }

    static async FetchCaseloadDiscussionAsStudent(studentId: DbIdentity, caseloadId: DbIdentity, discussionId: DbIdentity, signal?: AbortSignal) {
        const [err, data] = await aFetch<CaseloadDiscussion>("GET", `/student/${studentId}/caseload/${caseloadId}/discussion/${discussionId}`,undefined,{signal});
        return [err, (err ? undefined : new CaseloadDiscussion(data))] as const;
    }

    async publishDiscussion({ facultyId, caseloadId, discussionId, toState }: { facultyId:DbIdentity, caseloadId: DbIdentity, discussionId:DbIdentity, toState:CaseloadDiscussionPublishStatusEnum }) {
        const [err] = await aFetch<{}>("PUT", `/faculty/${facultyId}/caseload/${caseloadId}/discussion/${discussionId}/publish`, toState.toString());
        return err;
    }

    static async FetchDiscussionsOfCaseloadAsStudent({studentId, caseloadId, signal }:{studentId:DbIdentity, caseloadId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<any>("GET", `/student/${studentId}/caseload/${caseloadId}/discussions`, undefined, { signal });
        const vm = err ? undefined : dto;
        return [err, vm!] as const;
    }

    static async Copy( facultyId: DbIdentity, caseloadId: DbIdentity,copyFrom: DbIdentity, copyTo: DbIdentity) {
        const [err] = await aFetch<{}>("GET", `/faculty/${facultyId}/caseload/${caseloadId}/${copyFrom}/${copyTo}/copy`);
        return err;
    }

}

export enum CaseloadDiscussionPublishStatusEnum{
    PublishNow = "PublishNow",
    Hide = "Hide",
    ReOpen = "ReOpen",
    Close = "Close"
}