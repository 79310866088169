import { observable, makeObservable, toJS, action} from "mobx";

import {DbIdentity, DefaultId, NumberDate} from "./types";

import { aFetch } from "../services/api/fetch";
import { IFolder, FolderType, TempFolder } from "./Folder";
import { CaseloadModule } from "./CaseloadModule";

export interface ICaseloadFolder extends IFolder{
    caseloadId       ?: DbIdentity;
}

export interface BatchCreateCaseloadFolder {
    rootModuleParentId: string,
    type              : FolderType,
    caseloadId        : DbIdentity,
    folders           : TempFolder[],
}

export class CaseloadFolder implements ICaseloadFolder {
    folderId        : DbIdentity       = DefaultId;
    name            : string           = "";
    moduleId        : string           = "";
    moduleParentId ?: string           = undefined;
    type           ?: FolderType       = FolderType.Activity;
    caseloadId     ?: DbIdentity       = undefined;
    facultyId      ?: DbIdentity       = undefined;
    module         ?: CaseloadModule   = undefined;
    parentFolderId  : DbIdentity       = DefaultId;
    sortIndex       : number           = DefaultId;
    startTime      ?: NumberDate       = undefined;
    endTime        ?: NumberDate       = undefined;
    dateCreated    ?: NumberDate       = undefined;
    dateUpdated    ?: NumberDate       = undefined;

    constructor(data?:any) {
        makeObservable(this, {
            folderId       : observable,
            caseloadId     : observable,
            facultyId      : observable,
            name           : observable,
            type           : observable,
            moduleParentId : observable,
            module         : observable,
            dateCreated    : observable,
            dateUpdated    : observable,
            startTime      : observable,
            endTime        : observable,
            set_startTime  : action.bound,
            set_endTime    : action.bound,
        });

        if (data != null) {
            Object.assign(this, data);
            this.moduleParentId = this.moduleParentId ?? data.caseloadModule?.moduleParentId;
            this.module =  new CaseloadModule(this.module ?? data.caseloadModule);
            this.folderId = data.folderId ?? DefaultId;
        }
    }

    set_startTime(v ?: NumberDate){this.startTime = v;}
    set_endTime(v ?: NumberDate){this.endTime = v;}
    toJS() { return toJS(this);}

    async save(data?: any){
        if (this.folderId == DefaultId){
            const [err, vm] = await aFetch<{caseloadModule: {}[], caseloadFolder: {}[]}>("POST", `/faculty/${this.facultyId}/caseload/${this.caseloadId}/folder`, {...this.toJS(), ...data});
            const caseloadModules = err ? [] : vm.caseloadModule.map(x => new CaseloadModule(x));
            const caseloadFolders = err ? [] : vm.caseloadFolder.map(x => new CaseloadFolder(x));
            return [err, {caseloadModules, caseloadFolders}] as const;
        }
        const [err, vm] = await aFetch<{caseloadModule: {}[], caseloadFolder: {}[]}>("PUT", `/faculty/${this.facultyId}/caseload/${this.caseloadId}/folder/${this.folderId}`, {...this.toJS(), ...data});
        const caseloadModules = err ? [] : vm.caseloadModule.map(x => new CaseloadModule(x));
        const caseloadFolders = err ? [] : vm.caseloadFolder.map(x => new CaseloadFolder(x));
        return [err, {caseloadModules, caseloadFolders}] as const;
    }

    static async batchCreate(facultyId: DbIdentity, data :BatchCreateCaseloadFolder) {
        const [err, dto] = await aFetch<{ mTempModuleId2ModuleId ?: {tempModuleId: string, moduleId: string }[], folders ?: CaseloadFolder[] }>("POST", `/faculty/${facultyId}/caseload/${data.caseloadId}/folder/batch`, toJS(data));
        const folders = err ? [] : dto?.folders?.map(x => new CaseloadFolder(x)) ?? [];
        const mTempModuleId2ModuleId = new Map<string, string>();
        dto?.mTempModuleId2ModuleId?.forEach(m => { mTempModuleId2ModuleId.set(m.tempModuleId, m.moduleId) });
        return [err, folders, mTempModuleId2ModuleId] as const;
    }
}