import { Pager } from "../../models/Pager";

import { EventSourcePolyfill }  from "event-source-polyfill"; // Not using native EventSource because we need to add HTTP headers
import { IErrorData, createError } from "./AppError";

import axios, { AxiosRequestConfig } from "axios";
import { omit } from "lodash-es";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { qs } from "../../utils/url";
import { LoginUrl } from "../../utils/app-url";
import { sessionStorage } from "../../utils/localStorage";

import { isShowedConnectionFailModal, Modal } from "../../components/Modal/ModalAntd";

import { StudentCacheIdbStore } from "../../utils/idbKeyval";
import i18n from "../../i18n";
import { ClientErrorLog, ClientErrorType } from "../../models/ClientErrorLog";
import { IsEnableServiceWorker } from "../../config";
import { IPaging } from "../../models/Pagination";
import { IRequestingValidatorParams, localBlobPattern, requestingValidator } from "../validatorService";

const StudentCacheDb = new StudentCacheIdbStore();

export const apiHost = new URL(process.env.REACT_APP_API!, window.location.href).toJSON();
export const versionPrefix = process.env.REACT_APP_API_PATH;
export const headers: {[key:string]:string} = {
    "Content-Type"  : "application/json",
    "Accept"        : "application/json",
    "Client-Version": process.env.REACT_APP_VERSION!,
}

export function setAuthtoken(token:string){
    if (!token) delete headers.Authorization;
    else headers.Authorization = `Bearer ${token}`;
}

export function getHeaders(): {[key:string]:string} {
    return {
        "Authorization": headers.Authorization!,
        "Client-Version": process.env.REACT_APP_VERSION!,
    };
}
export function getAuthToken() {
    return headers.Authorization?.replace("Bearer ", "");
}
export function createApiUrl(input: string, queryParams ?: {}) {
    const apiService = (`${versionPrefix}${input}`).replace(/([^:]\/)\/+/g, "$1");
    const url = new URL(apiService, apiHost);
    if (queryParams) return qs(url, queryParams) as URL;
    else return url;
}

export function createEventSource(input: string){
    const url = createApiUrl(input);
    return new EventSourcePolyfill(url.toJSON(), { headers } );
}


export async function bindResult<TError, T>(promise: Promise<[(IErrorData<TError>)|undefined, T[], Pager|undefined]> ) : Promise<readonly [IErrorData<any> | undefined, T[]]>{
    const [err, data] = await promise;
    return [err, err ? [] : data];
}

export function readChunks(reader:ReadableStreamDefaultReader) {
    return {
        async* [Symbol.asyncIterator]() {
            let readResult = await reader.read();
            while (!readResult.done) {
                yield readResult.value;
                readResult = await reader.read();
            }

            reader.releaseLock();
        },
    };
}

interface IJobProgressData<T> {percent:number, comment:string, result?:T}
type HttpMethodType = "GET"|"POST"|"PUT"|"DELETE"|"PATCH";

export async function aFetch<T, TError = any>(
    method: HttpMethodType,
    input : string,
    body ?: {},
    options ?: RequestInit & {progressCallback?: (x: number) => void},
) : Promise<[(IErrorData<TError>)|undefined, T , Pager|undefined]> {
    let responseStatus = undefined;
    const {progressCallback, ...init} = options ?? {};
    try {

        tryToReverifyLocalBlobUrl({ method, body });

        const apiService = (`${versionPrefix}${input}`).replace(/([^:]\/)\/+/g, "$1");
        const url = (method != "GET" || !body) ? new URL(apiService, apiHost) : qs(new URL(apiService, apiHost), body);
        embedTICIDHeader(input);
        const response = await fetch(url.toJSON(), {
            method,
            headers: headers,
            mode:"cors",
            body: (method == "GET" || body === undefined ? undefined : JSON.stringify(body)),
            ...init,
        });

        //remove isShowedConnectionFailModal variable in sessionStorage if connection not fail
        sessionStorage.removeItem(isShowedConnectionFailModal);

        responseStatus = response.status;
        if (response.ok) {
            try {
                const { data, pagination, jobId } = await response.json();
                if (typeof jobId === `string`) {
                    const interval = 1_000; // one sec
                    return new Promise(resolver => {
                        let h:any = 0;
                        const doWork = () => {
                            aFetch<IJobProgressData<T>>("GET", `user/job/${jobId}/view`).then(([err, progress]) =>{
                                if (!!err ) {
                                    console.warn("Get job progress failed", err);
                                    return;
                                }

                                progressCallback?.(progress.percent);
                                if (progress.percent < 1){
                                    h = setTimeout(doWork, interval);
                                    return;
                                }
                                resolver([undefined, progress.result as T, new Pager(pagination)])
                                clearInterval(h);
                            });
                        };

                        doWork();
                    })
                }

                return [undefined, data as T, new Pager(pagination)];
            } catch(e) {
                console.warn(e);
                if (init?.signal?.aborted) return [createError(new DOMException('Aborted', 'AbortError'), 499), undefined! as T, undefined]
                return [undefined, undefined! as T, undefined]
            }
        }
        if (response.status == 401) {
            window.location.assign(LoginUrl);
        }

        const err = createError(new Error(response.statusText || String(response.status)), response.status, input, method);
        try {
            const e = await response.json();
            return [Object.assign(err, e), undefined! as T, undefined];
        } catch {
            return [err, undefined! as T, undefined];
        }
    } catch (err: unknown) {
        if (err == null && init?.signal?.aborted) return [createError(new DOMException('Aborted', 'AbortError'), 499, input, method), undefined! as T, undefined];
        if (!IsEnableServiceWorker && isNetworkError(err) && !await checkConnection({signal: init?.signal})){
            const errLog = new ClientErrorLog({ errorType: ClientErrorType.ConnectionLost, endpointUrl: input, timeStamp: Date.now()});
            await Modal.connectionFail(errLog);
            return[undefined, undefined! as T, undefined];
        }

        return [createError(err, responseStatus, input, method), undefined! as T, undefined];
    }
}

export async function aFetchWithCache<T, TError = any>(
    cacheName : string, //ref: config => BuildCacheNameStudentDoc
    method: "GET"|"POST"|"PUT"|"DELETE"|"PATCH",
    input : string,
    body ?: {},
    init ?: RequestInit,
) : Promise<[(IErrorData<TError>)|undefined, T , Pager|undefined]> {
    if (method == "GET") {
        const fromLocalCached = await StudentCacheDb.get(cacheName);
        if (fromLocalCached) {
            return [undefined, fromLocalCached.data as T, undefined];
        }
    }

    const [err, data, pager] = await aFetch(method, input, body, init);

    if (err || !data) { //whatever the error, we need to cache data for safety.
        if (method=="PUT" || method=="POST") await StudentCacheDb.set(cacheName, body);
        return [err ?? createError(new Error(i18n.t("api.error.studentdoc.offline")), 400), undefined as T, undefined];
    }
    else {
        //remove
        await StudentCacheDb.del(cacheName);
    }

    return [err, data as T, pager];
}

export async function downloadFile(method: "GET"|"POST"|"PUT", input : string, fileExtension: string, body ?: {}, init ?: RequestInit) {
    try {
        const apiService = (`${versionPrefix}${input}`).replace(/([^:]\/)\/+/g, "$1");
        const url = (method != "GET" || !body) ? new URL(apiService, apiHost) : qs(new URL(apiService, apiHost), body);
        const response = await fetch(url.toJSON(), {
            method,
            headers: headers,
            mode:"cors",
            body: (method == "GET" || body === undefined ? undefined : JSON.stringify(body)),
            ...init,
        });

        //remove isShowedConnectionFailModal variable in sessionStorage if connection not fail
        sessionStorage.removeItem(isShowedConnectionFailModal);

        if (response.ok) {
            let filename : string | undefined = "";
            response.headers.forEach(function(val, key) {
                // look for header like this:
                // content-disposition: attachment; filename=hello.pdf; filename*=UTF-8''hello.pdf
                if(key == "content-disposition") {
                    if (val.indexOf("filename=") > -1) {
                        var parts = val.split(";");
                        if (parts && parts.length > 1) {
                            // get the filename and replace the quotes and single quotes
                            filename = parts[1]?.split("=")[1]?.replaceAll("\"", "").replaceAll("'", "");
                        }
                    }
                    return;
                }
            });

            const blob = await response.blob();
            // create blob link to download
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = filename ?? new Date().toJSON().slice(0,10) + fileExtension;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          } else {
            // Handle error response
            console.error('Error opening file:', response.status, response.statusText);
          }
    } catch(ex) {
        console.error('Error fetching file:', ex);
    }
}

/**download file and return as blob */
export async function downloadFileAsBlob(method: "GET" | "POST" | "PUT", input: string, body?: {}, init?: RequestInit)
: Promise<[(IErrorData<TError>)|undefined, Blob]>
{
    try {
        const apiService = (`${versionPrefix}${input}`).replace(/([^:]\/)\/+/g, "$1");
        const url = (method != "GET" || !body) ? new URL(apiService, apiHost) : qs(new URL(apiService, apiHost), body);
        const response = await fetch(url, {
            method,
            headers: headers,
            mode: "cors",
            body: (method == "GET" || body === undefined ? undefined : JSON.stringify(body)),
            ...init,
        });

        //remove isShowedConnectionFailModal variable in sessionStorage if connection not fail
        sessionStorage.removeItem(isShowedConnectionFailModal);

        if (response.ok) {
            const blob = await response.blob();
            return [undefined, blob as Blob] as const;
        } else {
            // Handle error response
            console.error('Error opening file:', response.status, response.statusText);
            const err = createError(new Error(response.statusText || String(response.status)), response.status, input, method);
            return [err, undefined! as Blob] as const
        }
    } catch (ex) {
        console.error('Error fetching file:', ex);
    }
}

export function embedTICIDHeader(input: string) {
    if (input.indexOf("/blobtoken") != -1 || input.indexOf("/needAck") != -1 || input.indexOf("/unreadCount") != -1){
        //setInteractionClassHeader(-1);
        setTICIDHeader("-1");
        return;
    }
    if (window.lastTrackedClassId == window.currentClassId && window.lastTracked && moment().diff(window.lastTracked, "minute") < 1) {
        //setInteractionClassHeader(-1);
        setTICIDHeader("-1");
        return;
    }
    window.lastTrackedClassId = window.lastTrackedClassId || window.currentClassId;
    window.lastTracked = moment();
    window.lastTrackedClassId = window.currentClassId;

    setTICIDHeader(window.lastTrackedClassId);
};

function setTICIDHeader(value: any){
    headers["TICID"] = value;
}

export async function uploadFile<T, TError = any>(
    method : "POST"|"PUT",
    input  : string,
    file   : Blob,
    body  ?: {},
    init  ?: RequestInit,
) : Promise<[(IErrorData<TError>)|undefined, T]> {
    if (file == null) return [createError(new Error("File is null"), 400), undefined!];

    const data = new FormData();
    data.append("files", file);
    const json = JSON.stringify(body);
    if (body) data.append("body", json);
    try {
        const apiService = (`${versionPrefix}${input}`).replace(/([^:]\/)\/+/g, "$1");
        const url = new URL(apiService, apiHost);
        const response = await fetch(url.toJSON(), {
            method,
            headers: omit(headers, ['Content-Type']),
            mode:"cors",
            body:data,
            ...init
        });
        if (response.ok) {
            try {
                const {data} = await response.json();
                return [undefined, data as T];
            } catch(e) {
                console.warn(e);
                return [undefined, undefined! as T]
            }
        }

        const err = createError(new Error(response.statusText || String(response.status)), response.status);

        try {
            const e = await response.json();
            return [Object.assign(err, e), undefined! as any];
        } catch {
            return [err, undefined! as any];
        }
    } catch (err) {
        return [err, undefined! as any];
    }
}

export async function uploadFile2<T, TError = any>(
    method : "POST"|"PUT",
    input  : string,
    file   : Blob|FormData,
    body  ?: {},
    config?: AxiosRequestConfig,
) : Promise<[(IErrorData<TError>)|undefined, T]> {
    if (file == null) return [createError(new Error("File is null"), 400), undefined! as T];

    const data = file instanceof FormData ? file : new FormData();
    if (file instanceof Blob) data.append("files", file);
    if (body) {
        const json = JSON.stringify(body);
        data.append("body", json);
    }

    try {
        const response = await axios.request<T>({
            method,
            url: input,
            headers: omit(headers, ['Content-Type']),
            // mode:"cors",
            data,
            withCredentials:true,
            ...config,
        });
        return [undefined, response.data as T];
    } catch (err) {
        return [err, undefined! as T];
    }
}

export async function uploadFiles<T, TError = any>(
    method : "POST"|"PUT",
    input  : string,
    files  : Blob[],
    body  ?: {},
    config?: AxiosRequestConfig,
    onProgress?: (progress: number) => void,
) : Promise<[(IErrorData<TError>)|undefined, T]> {
    if (files.length == 0) return [createError(new Error("File Required"), 400), undefined! as T];

    const data = new FormData();
    files.forEach(file => data.append("files", file));
    if (body) {
        const json = JSON.stringify(body);
        data.append("body", json);
    }
    const apiService = (`${versionPrefix}${input}`).replace(/([^:]\/)\/+/g, "$1");
    const url = new URL(apiService, apiHost).toString();

    try {
        const response = await axios.request<T>({
            method,
            url,
            headers: omit(headers, ['Content-Type']),
            // mode:"cors",
            data,
            withCredentials:true,
            onUploadProgress: (pe) => onProgress?.(pe.progress),
            ...config,
        });
        return [undefined, response.data as T];
    } catch (err) {
        return [err, undefined! as T];
    }
}

const uploadChunk = async (method : "POST"|"PUT",chunk_uri: string, chunk: Blob,
    fileGuid: string, counter: number, subChunkProgress: (chunkUploadedBytes: number, currentCounter: number) => void) => {
    try {
        var data = new FormData();
        data.append("file", chunk);
        const response = await axios.request({
        method,
        url: chunk_uri,
        headers: omit(headers, ['Content-Type']),
        params: {
            id: counter,
            fileName: fileGuid,
          },
        data,
        withCredentials:true,
        onUploadProgress: (pe) => {
            const { loaded } = pe;
            subChunkProgress(loaded, counter);
        }
    });
      return response.data.isSuccess;
} catch (error) {
      console.log('error', error)
      return false;
    }
  }

// we split file to upload as chunks
// `input url` = `the upload completed api url` merges chunks to file
// $`{input}_chunk` = the upload chunk api uri
export async function uploadFileChunks<T, TError = any>(
    method : "POST"|"PUT",
    chunkUri  : string,
    completeUri  : string,
    file   : File,
    setProgress: (uploadedBytes: number, totalBytes: number) => void,
    body?: any,
) : Promise<[(IErrorData<TError>)|undefined, T]> {

    if (file == null) return [createError(new Error("File is null"), 400), undefined! as T];

    // 4MB per chunk
    const chunkSize = 1048576 * 4;

    // Total count of chunks will have been upload to finish the file
    const totalCount = file.size! % chunkSize == 0 ? file.size / chunkSize : Math.floor(file.size / chunkSize) + 1;
    const fileSize = file.size!;
    const fileGuid = uuidv4() + "." + file.name.split('.').pop();

    var beginingOfTheChunk = 0;
    var endOfTheChunk = chunkSize;
    for(var counter = 0; counter < totalCount; counter ++)
    {
        var chunk = file.slice(beginingOfTheChunk, endOfTheChunk);
        var result = await uploadChunk(method, chunkUri, chunk, fileGuid, counter,
            (chunkLoaded, currentCounter) => {
                const loaded = currentCounter * chunkSize + chunkLoaded;
                setProgress(loaded, fileSize);
            });

        if(!result)
            return [result, undefined! as T];

        setProgress(fileSize, fileSize);

        beginingOfTheChunk = endOfTheChunk;
        endOfTheChunk += chunkSize;
    }

    try {
        const form = new FormData();
        form.append('body', JSON.stringify(body));
        const response = await axios.request<T>({
            method,
            url: completeUri,
            headers: omit(headers, ['Content-Type']),
            params: {fileName : fileGuid, originalFileName: file.name, totalChunks: totalCount},
            withCredentials:true,
            data: form,
        });
        return [undefined, response.data as T];
    } catch (err) {
        return [err as any, undefined! as T];
    }
}


const uploadChunkV2 = async (method : "POST"|"PUT",chunk_uri: string, chunk: Blob,
    fileName: string, chunkIndex: number, merge:boolean, subChunkProgress: (chunkUploadedBytes: number, currentCounter: number) => void) => {
    try {
        var data = new FormData();
        data.append("file", chunk);
        const response = await axios.request({
        method,
        url: chunk_uri,
        headers: omit(headers, ['Content-Type']),
        params: {
            chunkIndex: chunkIndex,
            fileName: fileName,
            merge: false
          },
        data,
        withCredentials:true,
        onUploadProgress: (pe) => {
            const { loaded } = pe;
            subChunkProgress(loaded, chunkIndex);
        }
    });
      return response.data.isSuccess;
} catch (error) {
      console.log('error', error)
      return false;
    }
  }

export async function uploadFileChunksV2<T, TError = any>(
    method : "POST"|"PUT",
    chunkUri  : string,
    file   : File,
    setProgress: (uploadedBytes: number, totalBytes: number) => void
) : Promise<[(IErrorData<TError>)|undefined, T]> {

    if (file == null) return [createError(new Error("File is null"), 400), undefined! as T];

    // 4MB per chunk
    const chunkSize = 1048576 * 4;

    // Total count of chunks will have been upload to finish the file
    const totalCount = file.size! % chunkSize == 0 ? file.size / chunkSize : Math.floor(file.size / chunkSize) + 1;
    const fileSize = file.size!;
    const fileName = (new Date().getTime() - new Date(1970, 0, 1).getTime()) * 10000 + "_" + file.name;

    var beginingOfTheChunk = 0;
    var endOfTheChunk = chunkSize;
    for(var counter = 0; counter < totalCount; counter ++)
    {
        var chunk = file.slice(beginingOfTheChunk, endOfTheChunk);
        var result = await uploadChunkV2(method, chunkUri, chunk, fileName, counter, false,
            (chunkLoaded, currentCounter) => {
                const loaded = currentCounter * chunkSize + chunkLoaded;
                setProgress(loaded, fileSize);
            });

        if(!result)
            return [result, undefined! as T];

        setProgress(fileSize, fileSize);

        beginingOfTheChunk = endOfTheChunk;
        endOfTheChunk += chunkSize;
    }

    try {
        const response = await axios.request<T>({
            method,
            url: chunkUri,
            headers: omit(headers, ['Content-Type']),
            params: {
                chunkIndex: totalCount,
                fileName: fileName,
                merge: true
            },
            withCredentials:true
        });
        return [undefined, response.data as T];
    } catch (err) {
        return [err, undefined! as T];
    }
}

/** Determine whether the err is network issue from fetch API */
export function isNetworkError(err: unknown) {
    return err != null &&
        typeof err === 'object' &&
        'message' in err &&
        err.message === 'Failed to fetch';
}

export async function checkConnection({signal}: { signal?: AbortSignal | null}) {
    /// District query is the most responsive anonymous API because of caching.
    const url = new URL(`${versionPrefix}district`, apiHost);
    try {
        await fetch(url.toJSON(), {
            method: "GET",
            headers: headers,
            mode:"cors",
            signal: signal,
        });

        return true;
    } catch (err: unknown) {
        return !isNetworkError(err);
    }
}

export type RequestPaging = Partial<Pick<IPaging, 'limit' | 'offset'>>;

/**
 * Can be save but throw error message to user
 * @description
 * 1. verify body content if method === "POST" || "PUT" || "PATCH"
 * 2. if it has local Blob url:
 *      - check if the local blob is exist (response 200 when fetching): show error message
 *      - else if the local blob return 404 when fetching: skip => this is old data
 * 3. Don't return error just throw it and still progress to make sure the user don't lose data
 */
async function tryToReverifyLocalBlobUrl(params: IRequestingValidatorParams) {
    try {
        // verify content if method === "POST" || "PUT" || "PATCH"
        const result = requestingValidator(params);
        if (result && result.message && result.data) {
            let hasNewInvalid = false;
            // const err = createError(new Error(result.message), 500, input, method);
            // remove local blob right after check them exist
            for (let blobUrl of result.data) {
                // check is valid local blob again
                if (localBlobPattern.test(blobUrl) === false) continue;
                try {
                    await fetch(blobUrl);
                    hasNewInvalid = true;
                    // to make sure the blob url will be invalid for the next time
                    URL.revokeObjectURL(blobUrl);
                    break;
                } catch (error) {
                    console.warn("There was old blob url in this content", error);
                }
            }
            if (hasNewInvalid) Modal.error({ content: i18n.t(result.message) })
        }
    } catch (ex) {
        console.error("error when validating the request", ex);
    }
}