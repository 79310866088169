import { observable, action, computed, toJS, makeObservable } from "mobx";
import { DbIdentity, DefaultId, NumberDate } from "./types";
import { aFetch } from "../services/api/fetch";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import { EventMemberSearchView } from "./EventMemberSearchView";
import { Student } from "./Student";
import { BlendOptions } from "./BlendOptions";

export enum VisibilityStatus {
    Public = 1,
    Private = 2,
}

export class EventSchedule {
    eventScheduleId ?: DbIdentity = undefined;
    eventId         ?: DbIdentity = undefined;
    startDate       ?: NumberDate = undefined;
    endDate         ?: NumberDate = undefined;

    constructor(data?: {}) {
        makeObservable(this, {
            startDate    : observable,
            endDate      : observable,
            set_startDate: action.bound,
            set_endDate  : action.bound
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }

    set_startDate(v:number|undefined) { this.startDate = v }
    set_endDate  (v:number|undefined) { this.endDate   = v }

    static sorter = {
        startDate : (a:EventSchedule, b:EventSchedule) => (a.startDate || -1) - (b.startDate || -1),
    }
}

export class Event {
    eventId          : DbIdentity = DefaultId;
    groupId          : DbIdentity = DefaultId;
    districtId       : DbIdentity = DefaultId;
    schoolId        ?: DbIdentity = undefined;
    name            ?: string     = "";
    cover           ?: string     = "";
    banner          ?: string     = "";
    bannerBlendOptions ?: BlendOptions;
    location        ?: string     = "";
    description     ?: string     = "";
    homepageId      ?: DbIdentity = undefined;
    visibilityStatus              = VisibilityStatus.Public;
    memberCanInvite               = true;
    showMemberList                = true;
    autoAddMember                 = false;
    startDate       ?: NumberDate = undefined;
    endDate         ?: NumberDate = undefined;
    dateCreated     ?: NumberDate = undefined;
    dateUpdated     ?: NumberDate = undefined;
    createdBy       ?: DbIdentity = undefined;
    updatedBy       ?: DbIdentity = undefined;
    isOwner          = false;
    memberCount      = 0;
    eventSchedules   = [new EventSchedule];

    constructor(data?: any) {
        makeObservable(this, {
            name                : observable,
            cover               : observable,
            banner              : observable,
            bannerBlendOptions  : observable,
            location            : observable,
            description         : observable,
            homepageId          : observable,
            visibilityStatus    : observable,
            memberCanInvite     : observable,
            showMemberList      : observable,
            autoAddMember       : observable,
            startDate           : observable,
            endDate             : observable,
            dateCreated         : observable,
            dateUpdated         : observable,
            createdBy           : observable,
            updatedBy           : observable,
            isOwner             : observable,
            memberCount         : observable,
            eventSchedules      : observable.shallow,
            set_name            : action.bound,
            set_cover           : action,
            set_banner          : action,
            set_bannerBlendOptions: action,
            set_location        : action.bound,
            set_description     : action.bound,
            set_visibilityStatus: action.bound,
            set_showMemberList  : action.bound,
            set_memberCanInvite : action.bound,
            set_autoAddMember   : action.bound,
            addSchedule         : action.bound,
            removeSchedule      : action.bound,
            params              : computed,
        });

        if (data != null) {
            const {eventSchedules, ...pData} = data;
            Object.assign(this, pData);
            if (Array.isArray(eventSchedules)) this.eventSchedules = eventSchedules.map(s => new EventSchedule(s))
        }
    }

    toJS() { return toJS(this); }
    clone() { return new Event(this.toJS()) }

    set_name            (v: string          ) { this.name             = v }
    set_cover           (v: string          ) { this.cover            = v }
    set_banner          (v: string          ) { this.banner           = v }
    set_bannerBlendOptions (v: BlendOptions ) { this.bannerBlendOptions = v }
    set_location        (v: string          ) { this.location         = v }
    set_description     (v: string          ) { this.description      = v }
    set_visibilityStatus(v: VisibilityStatus) { this.visibilityStatus = v }
    set_showMemberList  (v: boolean         ) { this.showMemberList   = v }
    set_memberCanInvite (v: boolean         ) { this.memberCanInvite  = v }
    set_autoAddMember   (v: boolean         ) { this.autoAddMember    = v }

    addSchedule() { this.eventSchedules.push(new EventSchedule); }
    removeSchedule(index: number) { this.eventSchedules.splice(index, 1); }

    get params() { return ({eventId:String(this.eventId), groupId:String(this.groupId)}) }

    async save(groupId: DbIdentity) {
        this.groupId = groupId;
        if (this.eventId < 1) {
            const [err, data] = await aFetch<{}>("POST", `/event`, this.toJS());
            return [err, (err ? undefined : new Event(data))!] as const;
        }

        const [err, data] = await aFetch<{}>("PUT", `/event`, this.toJS());
        return [err, (err ? undefined : new Event(data))!] as const;
    }

    async delete() {
        const [err, d] = await aFetch<{}>("DELETE", `/event/${this.eventId}`);
        return [err, (err ? undefined : new Event(d))!] as const;
    }

    async leave() {
        const [err, d] = await aFetch<{}>("DELETE", `/event/${this.eventId}/leave`);
        return [err, (err ? undefined : new EventMember(d))!] as const;
    }

    async join() {
        const [err, dto] = await aFetch<GeneralDto>("POST", `/event/${this.eventId}/join`);
        return [err, (err ? undefined :  parseGeneralViewModel(dto))!] as const;
    }

    async setAsHomePage() {
        const [err, d] = await aFetch<{}>("PUT", `/event/${this.eventId}/setAsHomepage/${this.homepageId}`);
        return [err, (err ? undefined : new Event(d))!] as const;
    }
    static async fetchEventsOfUser(groupId?: DbIdentity) {
        if (groupId) {
            const [err, dto] = await aFetch<GeneralDto>("GET", `group/${groupId}/myEvents`);
            return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
        } else {
            const [err, dto] = await aFetch<GeneralDto>("GET", `/event`);
            return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
        }
    }

    static async fetchPublicEventsOfUser(groupId?: DbIdentity) {
        if (groupId) {
            const [err, dto] = await aFetch<GeneralDto>("GET", `group/${groupId}/publicEvents`);
            return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
        } else {
            const [err, dto] = await aFetch<GeneralDto>("GET", `/event/public`);
            return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
        }
    }

    static async fecthEvent({eventId}: {eventId: DbIdentity}) {
        const [err, data] = await aFetch<{}>("GET", `/event/${eventId}`);
        return [err, (err ? undefined : new Event(data))!] as const;
    }

    static async fetchEventSettingsPage(eventId: DbIdentity) {
        const [err, data] = await aFetch<{}>("GET", `/event/${eventId}/eventSetting`);
        return [err, (err ? undefined : new Event(data))!] as const;
    }

    static async fetchEventDetail(eventId: DbIdentity) {
        const [err, data] = await aFetch<{}>("GET", `/event/${eventId}/detail`);
        return [err, (err ? undefined : new Event(data))!] as const;
    }

    static async fetchEventSetting({ eventId, signal }: {eventId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}>("GET", `/event/${eventId}/eventSetting`, undefined, { signal });
        return [err, (err ? undefined : new Event(data))!] as const;
    }

    static async fetchEventByGroup(groupId: DbIdentity) {
        const [err, data] = await aFetch<{}[]>("GET", `/event/group/${groupId}/eventList`);
        return [err, err ? [] : data.map(x => new Event(x))] as const;
    }

    static async fetchMembers({ eventId, signal }: { eventId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}[]>("GET", `/event/${eventId}/members`, undefined, { signal });
        return [err, err ? [] : data.map(x => new EventMemberSearchView(x))] as const;
    }

    static async addEventToMyCalendar(eventId: DbIdentity) {
        const [err, data] = await aFetch<{}>("PUT", `/event/${eventId}/addToMyCalendar`);
        return [err, err ? undefined : data] as const;
    }

    async createOrUpdateEvent() {
        if (this.eventId < 1) {
            const [err, data] = await aFetch<{}>("POST", `/event/eventSetting`, this.toJS());
            return [err, (err ? undefined : new Event(data))!] as const;
        }

        const [err, data] = await aFetch<{}>("PUT", `/event/${this.eventId}/eventSetting`, this.toJS());
        return [err, (err ? undefined : new Event(data))!] as const;
    }

    static async remove(eventId: DbIdentity, member: EventMemberSearchView) {
        const [err, data] = await aFetch<{}>("DELETE", `/event/${eventId}/user/${member.id}/remove`);
        return [err, (err ? undefined : new EventMember(data))!] as const;
    }

    static async updateOwnner(eventId: DbIdentity, member: EventMemberSearchView) {
        const [err, data] = await aFetch<EventMember>("PUT", `/event/${eventId}/user/${member.id}/updateOwner`);
        return [err, (err ? undefined : new EventMember(data))!] as const;
    }

    // For export to CSV
    static async fetchEventMembersByEventId(eventId: DbIdentity) {
        const [err, data] = await aFetch<Student[]>("GET", `/event/${eventId}/memberList`);
        return [err, err ? [] : data.map(x => new Student(x))] as const;
    }
    static sorter = {
        name      : (a:Event, b:Event) => (a.name!.localeCompare(b.name!)),
        dateCreated: (a:Event, b:Event) => (a.dateCreated || -1) - (b.dateCreated || -1),
        dateUpdated: (a:Event, b:Event) => (a.dateUpdated || -1) - (b.dateUpdated || -1),
        dateUpdatedDesc: (a:Event, b:Event) => (b.dateUpdated || -1) - (a.dateUpdated || -1),
    }
}


export class EventMember {
    eventId         : DbIdentity = DefaultId;
    userId          : DbIdentity = DefaultId;
    isOwner         : boolean    = false;

    constructor(data?:any) {
        if (data != null) {
            Object.assign(this, data);
        }
    }

    toJS() { return toJS(this); }
    clone() { return new EventMember(this.toJS()) }

    static async inviteMemberByClass(eventId: DbIdentity, classId: DbIdentity) {
        const [err, xs] = await aFetch<{}[]>("POST", `/event/${eventId}/class/${classId}/inviteMember`);
        return [err, (err ? [] : xs.map(x => new EventMember(x)))] as const;
    }

    static async inviteMember(eventId: DbIdentity, userId: DbIdentity) {
        const [err, x] = await aFetch<{}>("POST", `/event/${eventId}/inviteMember`, userId);
        return [err, (err ? undefined : new EventMember(x))] as const;
    }
}
