export enum EActItemType {
    Heading         =  1,
    Text            =  2,
    Media           =  3,
    Embed           =  5,
    Discussion      =  6,
    EmbedFrame      = 14,

    NumericQuestion =  7,
    ChoiceQuiz      =  8,
    MatchQuiz       =  9,
    TextQuiz        = 10,
    PollQuiz        = 11,
    /**
     * TODO: rename this type to Attachment, and all properties related with this type
    */
    Document        = 12,
    GoogleAssignment= 13,
    FillInBlank     = 15,
    MatchQuizN      = 16,
    HighlightText   = 18,
    OrderList       = 19,
    MarkUpImage     = 20,
    /** include linear scale / numeric slider */
    NumericSlider   = 21,
    TextAreaQuiz    = 22,
    DropDown        = 23,
    SchoolSelect    = 24,
    SelfReflection  = 25,
    UserName        = 26,
    UserEmail       = 27,
    UserRole        = 28,
    DateTime        = 29,
    UCIReflection   = 30,
    PdfForm         = 31,
    H5P             = 32,
    LtiContentBlock = 33,
    MicrosoftAssignment = 34,
    Video               = 35,
    Image               = 36
}


export const SupportedPrintingEActItemTypes = [
    EActItemType.ChoiceQuiz     ,
    EActItemType.Discussion     ,
    EActItemType.FillInBlank    ,
    EActItemType.Heading        ,
    EActItemType.HighlightText  ,
    EActItemType.MarkUpImage    ,
    EActItemType.MatchQuiz      ,
    EActItemType.MatchQuizN     ,
    EActItemType.Media          ,
    EActItemType.NumericQuestion,
    EActItemType.NumericSlider  ,
    EActItemType.OrderList      ,
    EActItemType.PollQuiz       ,
    EActItemType.Text           ,
    EActItemType.TextQuiz       ,
    EActItemType.PdfForm        ,
    EActItemType.GoogleAssignment, //for now, we support print google workspace document or pdf file in google drive by exporting it to pdf
];

/** Question with answer and auto grade */
export const QuestionWithAnswerType = [
    EActItemType.ChoiceQuiz     ,
    EActItemType.FillInBlank    ,
    EActItemType.HighlightText  ,
    EActItemType.MatchQuiz      ,
    EActItemType.MatchQuizN     ,
    EActItemType.NumericQuestion,
    EActItemType.NumericSlider  ,
    EActItemType.OrderList      ,
];

/** Manual grading question types */
export const ManualGradeQuestionTypes = [
    EActItemType.TextQuiz        ,
    EActItemType.PollQuiz        ,
    EActItemType.GoogleAssignment,
    EActItemType.MarkUpImage     ,
    EActItemType.PdfForm         ,
    EActItemType.H5P             ,
    EActItemType.LtiContentBlock ,
    EActItemType.MicrosoftAssignment,
    EActItemType.Video,
    EActItemType.Image
];

/** all question type with auto grading and manual grading */
export const QuestionTypes = new Set([
    ...QuestionWithAnswerType,
    ...ManualGradeQuestionTypes,
]);

export const SurveyStreamQuestionTypes = new Set([
    ...QuestionTypes,
    EActItemType.TextAreaQuiz,
    EActItemType.DropDown,
    EActItemType.SchoolSelect,
    EActItemType.SelfReflection,
    EActItemType.UserName,
    EActItemType.UserEmail,
    EActItemType.UserRole,
    EActItemType.DateTime,
]);

export const GettingAnswerQuestionTypes = [
    EActItemType.ChoiceQuiz     ,
    EActItemType.TextQuiz       ,
    EActItemType.NumericQuestion,
    EActItemType.Video,
    EActItemType.Image,
];

export const IntegrationAssignmentTypes = [
    EActItemType.GoogleAssignment   ,
    EActItemType.MicrosoftAssignment,
];