type HttpMethodType = "GET"|"POST"|"PUT"|"DELETE"|"PATCH";

export interface IRequestingValidatorParams {
    method: HttpMethodType,
    body  : any 
}

export interface IValidatorResult {
    message  : string,
    data    ?: string[], // invalid data
    type     : 'error' | 'warning'
}

/** 
* @description
* to detect absolutely blob only: blob:http://localhost:3000/f345228e-ea00-432c-b08a-65ab35c4926b"}
*/
export const localBlobPattern = /blob:https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}[\\.\\:][a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)/gi;

/** 
* @description
* can be start with ^blob... or =\"blob:
* Example:
*   - '{ "embedLink": "blob:http://localhost:3000/f345228e-ea00-432c-b08a-65ab35c4926b"}'
*   - '{ "content": "<div><img src=\"blob:http://localhost:3000/f345228e-ea00-432c-b08a-65ab35c4926b\"/></div>"}'
*/
export const invalidPattern = /(^\s*|=(\s*(\\|\\\\|))("|'))blob:https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}[\\.\\:][a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)/gi;

export function requestingValidator(params : IRequestingValidatorParams) : IValidatorResult | undefined {
    if (!params.body) return;
    switch (params.method) {
        case "PATCH":
        case "POST" :
        case "PUT"  :
            // check local Blob
            // will get blob with 
            const matchArr = findBlobByRegex(params.body, invalidPattern);
            const correctBlobUrls = matchArr.flatMap(x => x.match(localBlobPattern)).filter(x => x !== undefined).map(x => x!)
            if (correctBlobUrls.length > 0) {
                return { message: 'api.error.fetch.body.containLocalBlob', type: 'error', data: correctBlobUrls }
            }
    }
    return;
}

// Function to recursively search for blob URLs in a JSON object
function findBlobByRegex(obj: any, regExp: RegExp): string[] {
    let blobUrls: string[] = [];

    // sometimes the object will include an instance of File or Blob. So, ignore it
    if (obj instanceof File || obj instanceof Blob) return [];

    // if current node is an object
    if (typeof obj === 'object' && obj !== null) {
        if (Array.isArray(obj)) {
            obj.forEach(item => {
                blobUrls = blobUrls.concat(findBlobByRegex(item, regExp));
            });
        } else {
            Object.values(obj).forEach(value => {
                blobUrls = blobUrls.concat(findBlobByRegex(value, regExp));
            });
        }
    }
    // only check if current node is a string
    else if (typeof obj === 'string') {
        const matches = obj.match(regExp);
        if (matches) {
            blobUrls = blobUrls.concat(matches);
        }
    }

    return blobUrls;
}