import { openDB, DBSchema, IDBPDatabase } from 'idb/with-async-ittr';
import { DbIdentity, DefaultId, EUserRole } from "../models/types";

const EkadanceDB = 'ekadance' as const;
const UserKeyValueStoreName = 'UserKeyValue' as const;

type KvPortal = 'faculty'|'student'|'admin'|'parent'|'';

interface IUserKeyValueKey {
    userId  : number,
    portal  : KvPortal,
    schoolId: number,
    classId : number,
    key     : string,
}
interface IUserKeyValue extends IUserKeyValueKey {
    value   : any,
}

interface EkadanceDBSchema extends DBSchema {
    UserKeyValue: {
        key: [number, KvPortal, number, number, string],
        value: IUserKeyValue,
    },
}

export class KeyValueStore {
    db     !: IDBPDatabase<EkadanceDBSchema>;
    userId  = DefaultId;
    portal  : KvPortal = '';
    pInit  !: Promise<Error|undefined>;

    constructor() {
        this.pInit = (async () => {
            try {
                this.db = await openDB<EkadanceDBSchema>(EkadanceDB, 1, {
                    upgrade(db, oldVersion, newVersion, transaction) {
                        if (!db.objectStoreNames.contains(UserKeyValueStoreName)) {
                            const store = db.createObjectStore(UserKeyValueStoreName, { keyPath: ['userId', 'portal', 'schoolId', 'classId', 'key'] });
                        }
                    }
                });
                return;
            } catch(e) { return e }
        })();
    }

    get({userId, portal, schoolId, classId, key}: {userId?:DbIdentity, portal?:KvPortal, schoolId?:DbIdentity, classId?: DbIdentity, key:string}) {
        if(!this.db) return Promise.resolve(undefined);
        return this.db.get(UserKeyValueStoreName, [userId ?? this.userId, portal || this.portal, schoolId ?? DefaultId, classId ?? DefaultId, key])
    }
    put(v: {userId?:DbIdentity, portal?:KvPortal, schoolId?:DbIdentity, classId?: DbIdentity, key:string, value: any}) {
        let {userId = this.userId, portal = this.portal, schoolId = DefaultId, classId = DefaultId, key, value} = v;
        return this.db?.put(UserKeyValueStoreName, {userId, portal, schoolId, classId, key, value})
    }
    delete({userId, portal, schoolId, classId, key}: {userId?:DbIdentity, portal?:KvPortal, schoolId?:DbIdentity, classId?: DbIdentity, key:string}) {
        return this.db?.delete(UserKeyValueStoreName, [userId ?? this.userId, portal || this.portal, schoolId ?? DefaultId, classId ?? DefaultId, key])
    }

    async getLastPortal() {
        const x = await this.get({ portal:'', key:'LastPortal' })
        return x ? x.value as EUserRole : '';
    }
    saveLastPortal({ value }: { value: EUserRole }) {
        return this.put({ portal: '', key: `LastPortal`, value  })
    }

}
