import { observable, action, computed, toJS, makeObservable } from "mobx";
import { DbIdentity, DefaultId, NumberDate } from "./types";
import { aFetch } from "../services/api/fetch";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import { GroupMemberSearchView } from "./GroupMemberSearchView";
import { User } from "./User";
import { Student } from "./Student";
import { BlendOptions } from "./BlendOptions";

export enum visibilityStatus {
    public = 1,
    unListed = 2,
}

export class Group {
    groupId                : DbIdentity = DefaultId;
    districtId             : DbIdentity = DefaultId;
    schoolId              ?: DbIdentity;
    name                   : string = "";
    cover                 ?: string = "";
    bannerBlendOptions    ?: BlendOptions = new BlendOptions();
    isJoinPassword        ?: boolean;
    joinPassword          ?: string = "";
    homepageId            ?: DbIdentity;
    visibilityStatus       : visibilityStatus = visibilityStatus.public;
    requireApproval        : boolean = true;
    enableConferences      : boolean = false;
    memberCanInvite       ?: boolean = false;
    memberCanSeeEverybody  : boolean = false;
    allowDiscussion       ?: boolean = false;
    expirationDate        ?: NumberDate;
    memberCount            : number = 0;
    dateCreated           ?: NumberDate;
    dateUpdated           ?: NumberDate;
    createdBy             ?: DbIdentity;
    updatedBy             ?: DbIdentity;
    isOwner                : boolean = false;
    ownerCount             : number = 0;
    groupName              : string = "";
    enableAnnouncements   ?: boolean = false;
    memberCanViewPages    ?: boolean = false;

    constructor(data ?: {}) {
        makeObservable(this, {
            groupId                  : observable,
            name                     : observable,
            cover                    : observable,
            bannerBlendOptions       : observable,
            isJoinPassword           : observable,
            joinPassword             : observable,
            homepageId               : observable,
            visibilityStatus         : observable,
            requireApproval          : observable,
            enableConferences        : observable,
            enableAnnouncements      : observable,
            memberCanInvite          : observable,
            memberCanSeeEverybody    : observable,
            allowDiscussion          : observable,
            expirationDate           : observable,
            memberCount              : observable,
            dateCreated              : observable,
            dateUpdated              : observable,
            createdBy                : observable,
            updatedBy                : observable,
            isOwner                  : observable,
            groupName                : observable,
            ownerCount               : observable,
            memberCanViewPages       : observable,
            schoolId                 : observable,
            set_name                 : action.bound,
            set_joinPassword         : action.bound,
            set_visibilityStatus     : action.bound,
            set_requireApproval      : action.bound,
            set_memberCanInvite      : action.bound,
            set_allowDiscussion      : action.bound,
            set_expirationDate       : action.bound,
            set_memberCanSeeEverybody: action.bound,
            set_enableConferences    : action.bound,
            set_enableAnnouncements  : action.bound,
            set_memberCanViewPages   : action.bound,
            set_ownerCount           : action      ,
            params                   : computed,
        });

        if (data) Object.assign(this, data);
    }

    toJS() {
        return toJS(this);
    }
    clone() { return new Group(this.toJS()) }

    set_name                 (v : string          ) { this.name                  = v }
    set_cover                (v : string          ) { this.cover                 = v }
    set_bannerBlendOptions   (v?: BlendOptions    ) { this.bannerBlendOptions    = v }
    set_joinPassword         (v : string          ) { this.joinPassword          = v }
    set_visibilityStatus     (v : visibilityStatus) { this.visibilityStatus      = v }
    set_requireApproval      (v : boolean         ) { this.requireApproval       = v }
    set_memberCanInvite      (v : boolean         ) { this.memberCanInvite       = v }
    set_allowDiscussion      (v : boolean         ) { this.allowDiscussion       = v }
    set_expirationDate       (v?: NumberDate      ) { this.expirationDate        = v }
    set_memberCanSeeEverybody(v : boolean         ) { this.memberCanSeeEverybody = v }
    set_enableConferences    (v : boolean         ) { this.enableConferences     = v }
    set_enableAnnouncements  (v : boolean         ) { this.enableAnnouncements   = v }
    set_memberCanViewPages   (v : boolean         ) { this.memberCanViewPages    = v }
    set_ownerCount           (v : number         ) { this.ownerCount             = v }

    get params() { return ({ groupId : String(this.groupId), }) }

    async delete() {
        const [err, d] = await aFetch<{}>("DELETE", `/group/${this.groupId}`);
        return [err, (err ? undefined : new Group(d))!] as const;
    }

    async leave() {
        const [err, d] = await aFetch<{}>("DELETE", `/group/${this.groupId}/leave`);
        return [err, (err ? undefined : new GroupMember(d))!] as const;
    }

    async join() {
        const data = this.toJS();
        const [err, dto] = await aFetch<GeneralDto>("POST", `/group/${this.groupId}/join`, data.joinPassword);
        return [err, (err ? undefined :  parseGeneralViewModel(dto))!] as const;
    }

    async addMember(userId: DbIdentity) {
        const [err, xs] = await aFetch<{}[]>("POST", `/group/${this.groupId}/addMember`, userId);
        return [err, (err ? [] : xs.map(x => new GroupMember(x)))] as const;
    }

    async inviteMember(userId: DbIdentity) {
        const [err, xs] = await aFetch<{}[]>("POST", `/group/${this.groupId}/inviteMember`, userId);
        return [err, (err ? [] : xs.map(x => new PendingGroupMember(x)))] as const;
    }

    async addMemberByClassId(classId: DbIdentity) {
        const [err, xs] = await aFetch<{}[]>("POST", `/group/${this.groupId}/class/${classId}/addMember`);
        return [err, (err ? [] : xs.map(x => new GroupMember(x)))] as const;
    }

    async inviteMemberByClassId(classId: DbIdentity) {
        const [err, xs] = await aFetch<{}[]>("POST", `/group/${this.groupId}/class/${classId}/inviteMember`);
        return [err, (err ? [] : xs.map(x => new PendingGroupMember(x)))] as const;
    }

    async cancelJoin() {
        const data = this.toJS();
        const [err, d] = await aFetch<{}>("POST", `/group/${this.groupId}/cancelRequestJoin`, data);
        return [err, (err ? undefined : new PendingGroupMember(d))!] as const;
    }

    async setAsHomePage() {
        const [err, d] = await aFetch<{}>("PUT", `/group/${this.groupId}/setAsHomepage/${this.homepageId}`, this.toJS());
        return [err, (err ? undefined : new Group(d))!] as const;
    }
    static async fetchGroupOfUser({ signal }: { signal: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/group`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchPublicGroupOfUser({ signal }: { signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/group/public`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fecthGroup({ groupId, signal }: {groupId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}>("GET", `/group/${groupId}`, undefined, { signal });
        return [err, (err ? undefined : new Group(data))!] as const;
    }

    static async fetchGroupSettingsPage({ groupId, signal }: { groupId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}>("GET", `/group/${groupId}/groupSetting`, undefined, { signal });
        return [err, (err ? undefined : new Group(data))!] as const;
    }

    static async fetchGroupDetailsByUser(groupId: DbIdentity, signal?: AbortSignal) {
        const [err, data] = await aFetch<{}>("GET", `/group/${groupId}/details`, undefined, { signal });
        return [err, (err ? undefined : new Group(data))!] as const;
    }

    static async fetchMembers({ groupId, paging, signal }: {groupId: DbIdentity, paging: any, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{members: any[], total: number}>("GET", `/group/${groupId}/members`, paging, { signal });
        return [err, err ? undefined : {members: data.members?.map(x => new GroupMemberSearchView(x)) ?? [], total: data?.total ?? 0}] as const;
    }

    static async fetchConferenceSearchMembers({ groupId, conferenceId, searchText, signal }: {groupId: DbIdentity, conferenceId: DbIdentity, searchText: string, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}[]>("GET", `/group/${groupId}/conference/${conferenceId}/searchMembers`, {searchText}, { signal });
        return [err, err ? [] : data.map(x => new GroupMemberSearchView(x))] as const;
    }

    static async fetchMemberRequest(groupId: DbIdentity) {
        const [err, data] = await aFetch<{}[]>("GET", `/group/${groupId}/memberRequest`);
        return [err, err ? [] : data.map(x => new GroupMemberSearchView(x))] as const;
    }

    static async fetchStudentGroupsAsParent(parentId: DbIdentity, studentId: DbIdentity) {
        const [err, data] = await aFetch<{}[]>("GET", `parent/${parentId}/student/${studentId}/groups`);
        return [err, err ? [] : data.map(x => new Group(x))] as const;
    }

    async save(isTinyMCE ?: boolean) {
        if (this.groupId < 1) {
            const [err, data] = await aFetch<{}>("POST", `/group/groupSetting`, {...this.toJS(), isTinyMCE: isTinyMCE});
            return [err, (err ? undefined : new Group(data))!] as const;
        }

        const [err, data] = await aFetch<{}>("PUT", `/group/${this.groupId}/groupSetting`, this.toJS());
        return [err, (err ? undefined : new Group(data))!] as const;
    }

    static async approveRequest(groupId: DbIdentity, member: GroupMemberSearchView) {
        const [err, data] = await aFetch<GroupMember>("DELETE", `/group/${groupId}/user/${member.id}/approve`);
        return [err, (err ? undefined : new GroupMember(data))!] as const;
    }

    static async rejectRequest(groupId: DbIdentity, member: GroupMemberSearchView) {
        const [err, data] = await aFetch<PendingGroupMember>("DELETE", `/group/${groupId}/user/${member.id}/reject`);
        return [err, (err ? undefined : new PendingGroupMember(data))!] as const;
    }

    static async remove(groupId: DbIdentity, member: GroupMemberSearchView) {
        const [err, data] = await aFetch<GroupMember>("DELETE", `/group/${groupId}/user/${member.id}/remove`);
        return [err, (err ? undefined : new GroupMember(data))!] as const;
    }

    static async updateOwnner(groupId: DbIdentity, member: GroupMemberSearchView) {
        const [err, data] = await aFetch<GroupMember>("PUT", `/group/${groupId}/user/${member.id}/updateOwner`);
        return [err, (err ? undefined : new GroupMember(data))!] as const;
    }

    static async closeGroup(groupId: DbIdentity) {
        const [err, data] = await aFetch<{}>("PUT", `/group/${groupId}/close`);
        return [err, (err ? undefined : new Group(data))!] as const;
    }

    static async reopenGroup(groupId: DbIdentity) {
        const [err, data] = await aFetch<{}>("PUT", `/group/${groupId}/reopen`);
        return [err, (err ? undefined : new Group(data))!] as const;
    }

    static async fetchDeletedGroupsOfUser({ signal }: { signal: AbortSignal}) {
        const [err, data] = await aFetch<Group[]>("GET", `/group/deletedGroups`);
        return [err, err ? [] : data.map(x => new Group(x))] as const;
    }

    static async unDeleted(groupIds: DbIdentity[]) {
        const [err, data] = await aFetch<Group[]>("PUT", `/group/unDeleted`, groupIds);
        return [err, err ? [] : data.map(x => new Group(x))] as const;
    }

    static async unReactGroupAnnouncementCount(){
        const [err, data] = await aFetch<{ groupId: DbIdentity, count: number }[] | undefined>("GET", `/group/unReactAnnouncementCount`, undefined, undefined);
        return [err, (err ? [] : (data ?? []))!] as const;
    }

    static async fetchGroupMembersByGroupId(groupId: DbIdentity) {
        const [err, data] = await aFetch<Student[]>("GET", `/group/${groupId}/memberList`);
        return [err, err ? [] : data.map(x => new Student(x))] as const;
    }

    static sorter = {
        name      : (a:Group, b:Group) => (a.name!.localeCompare(b.name!)),
        dateCreated: (a:Group, b:Group) => (a.dateCreated || -1) - (b.dateCreated || -1),
        dateUpdated: (a:Group, b:Group) => (a.dateUpdated || -1) - (b.dateUpdated || -1),
        dateUpdatedDesc: (a: Group, b: Group) => (b.dateUpdated || -1) - (a.dateUpdated || -1),
    }
}

export class PendingGroupMember {
    groupId: DbIdentity = DefaultId;
    userId : DbIdentity = DefaultId;

    constructor(data?:any) {
        this.groupId = DefaultId;
        this.userId  = DefaultId;

        if (data != null) {
            Object.assign(this, data);
        }
    }

    toJS() {
        return toJS(this);
    }
    clone() { return new PendingGroupMember(this.toJS()) }
}

export class GroupMember extends User {
    groupId: DbIdentity = DefaultId;
    isOwner: boolean    = false;

    constructor(data?:any) {
        super(data);
        if (data != null) {
            Object.assign(this, data);
        }
    }

    toJS() {
        return ({
            ...super.toJS(),
            groupId: this.groupId,
            isOwner: this.isOwner,
        });
    }

    clone() { return new GroupMember(this.toJS()) }
}
