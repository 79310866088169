var CryptoJS = require('crypto-js');

export const encryptData = (data, key) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
}

export const decryptData = (data, key) => {
    var decrypted = CryptoJS.AES.decrypt(data, key);
    try {
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    }
    catch(err) {
        console.log(err);
        return null;
    }
}


