import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en-US";
// import vi from "./locales/vi-VN";

const resources = {
  en: { translation: en },
  "en-US": { translation: en },
  // vi: { translation: vi },
  // "vi-VN": { translation: vi },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: 'en',
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    // TODO remove if upgrading to v4
    compatibilityJSON: 'v3', 
  });

export default i18n;
