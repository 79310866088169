import { useEffect, RefObject } from "react";

import { hex2hsl } from "../../utils/color";

function darken (name:string, percent:number) { return `hsl(var(${name}__h_s), calc(var(${name}__l) - ${percent}%))` }
function lighten(name:string, percent:number) { return `hsl(var(${name}__h_s), calc(var(${name}__l) + ${percent}%))` }
function fade   (name:string, percent:number) { return `hsla(var(${name}__h_s), var(${name}__l), ${percent/100})` }

class CssVarTheme {
    constructor(private data:{[key:string]: string}) { }
    updateData(data:{[key:string]: string}) {
        this.data = Object.assign({}, this.data, data);
    }
    setProperty(element:ElementCSSInlineStyle) {
        for (const [variable, value] of Object.entries(this.data)) {
            if (typeof value == "string" && value.startsWith("#")) {
                const [i, h, s, l] = hex2hsl(value);
                if (i) {
                    element.style.setProperty(`${variable}__h_s`, `${h}, ${s}%`);
                    element.style.setProperty(`${variable}__l`, `${l}%`);
                    // element.style.setProperty(variable, `hsl(var(${variable}__h_s), var(${variable}__l))`);
                }
            }
            element.style.setProperty(variable, value);
        }
    }
}

const defaultTheme = ({
    "--switch-color": "#3C4EBC",
    "--switch-color-f80"     : fade("--switch-color", 80),

    "--primary-color"         : "#3C4EBC",
    "--info-color"            : "#3C4EBC",
    "--processing-color"      : "#3C4EBC",
    "--link-color"            : "#3C4EBC",
    "--link-hover-color"      : "#4959c5",
    "--success-color"         : "#24816a",
    "--submit-color"          : "#24816a",
    "--publish-color"         : "#24816a",
    "--missing-color"         : "#d93131",
    "--error-color"           : "#d93131",
    "--error-text-color"      : "#b92828",
    "--incomplete-color"      :"#EB5757",
    "--warning-color"         : "#F0B500",
    "--warning-text-color"    : "#8c6800",
    "--scheduled-color"       : "#9c7603",
    "--scheduled-text-color"  : "#856501",
    "--filter-color"          : "#ECA400",

   "--menu-highlight-color"   : "#3C4EBC",
   "--input-hover-border-color" : "#3C4EBC",
   "--menu-light-theme-bg": "#FFFFFF",
   "--menu-light-theme-icon-bg": "#F6F8FC",
   "--menu-light-theme-color-base": "#647286",
   "--item-hover-bg": "#F5F5F5",

    "--body-background": "#F6F8FC",
    "--background-color-light": "#F6F8FC",
    "--layout-body-background": "#F6F8FC",
    "--component-background": "#FFFFFF",
    "--component-background-f50"     : fade("--component-background", 50),
    "--component-background-f60"     : fade("--component-background", 60),
    "--component-background-f70"     : fade("--component-background", 70),
    "--component-background-f80"     : fade("--component-background", 80),
    "--component-background-f90"     : fade("--component-background", 90),
    "--component-background-f95"     : fade("--component-background", 95),
    "--default-background-gray": "#F5F5F5",
    "--mask-background-color": "#000000",

    "--text-color": "#29364D",
    "--heading-color": "#29364D",
    "--text-color-secondary": "#5f6c7f",
    "--text-color-not-open-yet": "#556072",

    "--border-checkbox-color": "#9E9E9E",
    "--border-color-darker": "#E3E3E3",
    "--border-color-base": "#E3E3E3",
    "--border-color-split": "#E3E3E3",

    "--black-color": "#000000",
    "--placeholder-color": fade("#222F3E", 70),
    "--disabled-color": "#727272",
    "--disabled-bg": fade("--black-color", 12),
    "--disabled-input-color": "#29364D",
    "--disabled-input-bg": "#F5F5F5",
    "--radio-dot-disabled-color": fade("--black-color", 20),
    "--shadow-color": "#DADADA",
    "--shadow-color-f6": fade("--shadow-color", 6),
    "--shadow-color-f7": fade("--shadow-color", 7),
    "--shadow-color-lighter": "#E3E3E3",
    "--skeleton-color": "#e1e8ef",
    "--skeleton-to-color": "#d6dce3",

    "--table-bg-color": "#efefef",
    "--table-row-bg-color": "#fbfbfb",
    "--table-gradebook-row-hovered-bg-color" : "#eeeeee",
    "--table-gradebook-row-active-bg-color" : "#cfcfcf",

    "--is-exclude-bg-color": fade("--black-color", 12),

    "--primary-color-f10"     : fade("--primary-color", 10),
    "--primary-color-f20"     : fade("--primary-color", 20),
    "--primary-color-f30"     : fade("--primary-color", 30),
    "--primary-color-f40"     : fade("--primary-color", 40),
    "--primary-color-f50"     : fade("--primary-color", 50),
    "--primary-color-f60"     : fade("--primary-color", 60),
    "--primary-color-f70"     : fade("--primary-color", 70),
    "--primary-color-f80"     : fade("--primary-color", 80),

    "--primary-color-d10"     : darken("--primary-color", 10),
    "--primary-color-l10"     : lighten("--primary-color", 10),
    
    "--tabs-hover-color"     : fade("--link-color", 80),
    "--info-color-d8"         : darken("--info-color", 8),

    "--success-color-f10"     : fade("--success-color", 10),
    "--submit-color-f10"      : "#f4fefc",
    "--publish-color-f10"      : fade("--publish-color", 10),
    "--warning-color-f10"     : fade("--warning-color", 10),
    "--warning-color-f20"     : fade("--warning-color", 20),
    "--warning-color-l38"     : lighten("--warning-color", 38),
    "--warning-color-d20"     : darken("--warning-color", 20),
    "--scheduled-color-f10"     : fade("--scheduled-color", 10),
    "--filter-color-f10"     : fade("--filter-color", 10),
    "--error-color-f10"       : fade("--error-color", 10),
    "--error-color-f20"       : fade("--error-color", 20),
    "--missing-color-f10"       : fade("--missing-color", 10),
    "--missing-color-f20"       : fade("--missing-color", 20),
    "--incomplete-color-f10"       : fade("--incomplete-color", 10),
    "--text-color-f10"          : fade("--text-color", 10),
    "--text-color-secondary-f10": "#f2f5fc",
    "--text-color-secondary-f24": fade("--text-color-secondary", 24),
    "--text-color-secondary-l10": lighten("--text-color-secondary", 10),
    "--affix-bg-color-f94"     : fade("--component-background", 94),

    // Case Carrier/Tags - Manage colors
    "--cl-hx-Df"       : "#EEEFF1", 
    "--cl-hx-G"         : "#dddfe3",
    "--cl-hx-R"         : "#fbdfdf",
    "--cl-hx-O"         : "#fdefe2",
    "--cl-hx-Y"         : "#fdf6e2",
    "--cl-hx-LG"         : "#e7fcd6",
    "--cl-hx-GR"         : "#dbeee3",
});

export const lightTheme = ({
    "--switch-color": "#3C4EBC",

    "--primary-color"         : "#3C4EBC",
    "--info-color"            : "#3C4EBC",
    "--processing-color"      : "#3C4EBC",
    "--link-color"            : "#3C4EBC",
    "--link-hover-color"      : "#4959c5",

    "--menu-highlight-color"   : "#3C4EBC",
    "--input-hover-border-color" : "#3C4EBC",

});
export const redTheme = ({
    "--switch-color": "#cd4545 ",

    "--primary-color"         : "#cd4545",
    "--info-color"            : "#cd4545",
    "--processing-color"      : "#cd4545",
    "--link-color"            : "#9c3333",
    "--link-hover-color"      : "#fe5858",

    "--menu-highlight-color"   : "#cd4545",
    "--input-hover-border-color" : "#cd4545",

});
export const blueTheme = ({
    "--switch-color": "#346be2",

    "--primary-color"         : "#346be2",
    "--info-color"            : "#346be2",
    "--processing-color"      : "#346be2",
    "--link-color"            : "#2855b6",
    "--link-hover-color"      : "#2c5abe",

    "--menu-highlight-color"   : "#346be2",
    "--input-hover-border-color" : "#346be2",
});
export const greenTheme = ({
    "--switch-color": "#008940",

    "--primary-color"         : "#008940",
    "--info-color"            : "#008940",
    "--processing-color"      : "#008940",
    "--link-color"            : "#006830",
    "--link-hover-color"      : "#008940",

    "--menu-highlight-color"   : "#008940",
    "--input-hover-border-color" : "#008940",
});
export const orangeTheme = ({
    "--switch-color": "#be5205",

    "--primary-color"         : "#be5205",
    "--info-color"            : "#be5205",
    "--processing-color"      : "#be5205",
    "--link-color"            : "#994101",
    "--link-hover-color"      : "#ec6709",
    "--success-color"         : "#24816a",

    "--menu-highlight-color"   : "#be5205",
    "--input-hover-border-color" : "#be5205",
});

export const darkTheme = ({
    "--switch-color": "#121212",

    "--primary-color"         : "#3C4DBC",
    "--info-color"            : "#9EA6DE",
    "--processing-color"      : "#9EA6DE",
    "--link-color"            : "#868dbd",
    "--link-hover-color"      : lighten("--link-color", 4),
    "--success-color"         : "#238069",
    "--submit-color"          : "#268d74",
    "--submit-color-f10"      : "#040e0c",
    "--error-color"           : "#f03636",
    "--error-text-color"      : "#f03636",
    "--warning-color"         : "#F0B500",
    "--warning-text-color"    : "#F0B500",
    "--warning-color-f10"     : fade("--warning-color", 10),
    "--warning-color-f20"     : fade("--warning-color", 20),
    "--warning-color-l38"     : lighten("--warning-color", 38),
    "--warning-color-d20"     : darken("--warning-color", 20),
    "--scheduled-color"       : "#957103",
    "--scheduled-text-color"  : "#9c7603",
    "--scheduled-color-f10"     : fade("--scheduled-color", 10),

   "--menu-highlight-color"   : "#9EA6DE",
   "--input-hover-border-color" : "#3C4DBC",
   "--menu-light-theme-bg": "#000102",
   "--menu-light-theme-icon-bg": fade("--switch-color", 32),
   "--menu-light-theme-color-base": fade("--white-color", 87),
   "--item-hover-bg": "#282b2f",

    "--body-background": "#121212",
    "--background-color-light": fade("--switch-color", 32),
    "--layout-body-background": "#121212",
    "--component-background": "#000102",
    "--default-background-gray": "#181818",
    "--mask-background-color": "#FFFFFF",

    "--white-color": "#FFFFFF",
    "--black-color": "#000000",
    "--text-color": fade("--white-color", 87),
    "--heading-color": fade("--white-color", 87),
    "--text-color-secondary": fade("--white-color", 60),
    "--text-color-secondary-f10": fade("--text-color-secondary", 10),
    "--text-color-not-open-yet": fade("--white-color", 60),

    "--border-checkbox-color": "#b0b0b0",
    "--border-color-darker": "#a5a5a5",
    "--border-color-base": "#2b2e31",
    "--border-color-split": "#2b2e31",

    "--placeholder-color": fade("--white-color", 60),
    "--disabled-color": "#747575",
    "--disabled-bg": fade("--white-color", 12),
    "--disabled-input-color": fade("--white-color", 38),
    "--disabled-input-bg": fade("--white-color", 12),
    "--radio-dot-disabled-color": fade("--white-color", 20),
    "--shadow-color": "#000000",
    "--shadow-color-f6": fade("--shadow-color", 6),
    "--shadow-color-f7": fade("--shadow-color", 7),
    "--shadow-color-lighter": "#2b2e31",
    "--skeleton-color": "#000102",
    "--skeleton-to-color": "#16181a",

    "--table-bg-color": "#323232",
    "--table-row-bg-color": "#525252",
    "--table-gradebook-row-hovered-bg-color" : fade("--white-color", 4),
    "--table-gradebook-row-active-bg-color" : fade("--white-color", 4),

    "--is-exclude-bg-color": "#121212",

    // Case Carrier/Tags - Manage colors
    "--cl-hx-Df"       : "#424242", 
    "--cl-hx-G"         : "#29364D",
    "--cl-hx-R"         : "#d93131",
    "--cl-hx-O"         : "#F2994A",
    "--cl-hx-Y"         : "#edb612",
    "--cl-hx-LG"         : "#49a800",
    "--cl-hx-GR"         : "#219653",
});
export const grayTheme = ({
    "--switch-color": "#333333",

    "--primary-color"         : "#5A69C6",
    "--primary-color-f10"     : "#262731",
    "--info-color"            : "#868dbd",
    "--processing-color"      : "#868dbd",
    "--link-color"            : "#939bcf",
    "--link-hover-color"      : lighten("--link-color", 4),
    "--success-color"         : "#21826b",
    "--submit-color"          : "#2caa8b",
    "--submit-color-f10"      : "#27302e",
    "--error-color"           : "#ff5d5d",
    "--error-text-color"      : "#ff5d5d",
    "--error-color-f10"       : "#392929",
    "--warning-color"         : "#F0B500",
    "--warning-text-color"    : "#F0B500",
    "--warning-color-f10"     : fade("--warning-color", 10),
    "--warning-color-f20"     : fade("--warning-color", 20),
    "--warning-color-l38"     : lighten("--warning-color", 38),
    "--warning-color-d20"     : darken("--warning-color", 20),
    "--scheduled-color"       : "#967100",
    "--scheduled-text-color"  : "#c49403",
    "--scheduled-color-f10"   : "#332f23",

   "--menu-highlight-color"   : "#868dbd",
   "--input-hover-border-color" : "#5A69C6",
   "--menu-light-theme-bg": "#272727",
   "--menu-light-theme-icon-bg": fade("--switch-color", 32),
   "--menu-light-theme-color-base": fade("--white-color", 87),
   "--item-hover-bg": "#636363",

    "--body-background": "#333333",
    "--background-color-light": fade("--switch-color", 32),
    "--layout-body-background": "#333333",
    "--component-background": "#272727",
    "--default-background-gray": "#424242",
    "--mask-background-color": "#FFFFFF",

    "--white-color": "#FFFFFF",
    "--black-color": "#000000",
    "--text-color": fade("--white-color", 87),
    "--heading-color": fade("--white-color", 87),
    "--text-color-secondary": fade("--white-color", 60),
    "--text-color-not-open-yet": fade("--white-color", 60),
    "--text-color-secondary-f10": fade("--text-color-secondary", 10),

    "--border-checkbox-color": "#b0b0b0",
    "--border-color-darker": "#a5a5a5",
    "--border-color-base": "#626262",
    "--border-color-split": "#626262",

    "--placeholder-color": fade("--white-color", 60),
    "--disabled-color": "#919191",
    "--disabled-bg": fade("--white-color", 12),
    "--disabled-input-color": fade("--white-color", 38),
    "--disabled-input-bg": fade("--white-color", 12),
    "--radio-dot-disabled-color": fade("--white-color", 20),
    "--shadow-color": "#222222",
    "--shadow-color-f6": fade("--shadow-color", 6),
    "--shadow-color-f7": fade("--shadow-color", 7),
    "--shadow-color-lighter": "#626262",
    "--skeleton-color": "#484848",
    "--skeleton-to-color": "#353535",

    "--table-bg-color": "#484848",
    "--table-row-bg-color": "#727272",
    "--table-gradebook-row-hovered-bg-color" :  fade("--white-color", 4),
    "--table-gradebook-row-active-bg-color" : fade("--white-color", 4),

    "--is-exclude-bg-color": "#333333",

    // Case Carrier/Tags - Manage colors
    "--cl-hx-Df"       : "#424242", 
    "--cl-hx-G"         : "#29364D",
    "--cl-hx-R"         : "#d93131",
    "--cl-hx-O"         : "#F2994A",
    "--cl-hx-Y"         : "#edb612",
    "--cl-hx-LG"         : "#49a800",
    "--cl-hx-GR"         : "#219653",
});

export function useTheme(data:{[key:string]: string}, element?:RefObject<ElementCSSInlineStyle>) {
    useEffect(() => {
        const theme = new CssVarTheme(defaultTheme);
        theme.updateData(data);
        theme.setProperty(element?.current ?? document.documentElement);
    }, [data, element]);
}

export function useModuleColor(mainColor: string, secondaryColor: string, element:RefObject<ElementCSSInlineStyle>) {
    useEffect(() => {
        const ele = element?.current;
        if (ele == null) return;
        const theme = new CssVarTheme({
            "--module-main-color": "#FFFFFF",
            "--module-secondary-color": "#FFFFFF",
            "--activity-main-color": "#A7BEEC",
            "--activity-secondary-color": "#D0E0FF",
            "--module-main-color-f8": fade("--module-main-color", 8),
            "--module-main-color-f10": fade("--module-main-color", 10),
            "--activity-main-color-f10": fade("--activity-main-color", 10),
        });
        theme.updateData({
            "--module-main-color": mainColor,
            "--module-secondary-color": secondaryColor,
        });
        theme.setProperty(ele);
    }, [mainColor, secondaryColor, element]);
}

export const lightIdThemes = { light:lightTheme, red:redTheme, blue:blueTheme, green:greenTheme, orange:orangeTheme };
export const darkIdThemes = {  dark:darkTheme, gray:grayTheme, };

export type ThemeModeType = keyof typeof lightIdThemes | keyof typeof darkIdThemes;
export const allThemes: { [key in ThemeModeType]: { [key: string]: string } } = {...lightIdThemes, ...darkIdThemes};

export function getThemeProp(themeId: string, propName: string){
    if(allThemes.hasOwnProperty(themeId)){
        let theme = allThemes[themeId];
        if(theme.hasOwnProperty(propName)) return theme[propName];
        theme = defaultTheme;
        if(theme.hasOwnProperty(propName)) return theme[propName];
    }
    return "";
}