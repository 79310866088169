import { DefaultId, DbIdentity, NumberDate, DocId } from "./types";

import { aFetch } from "../services/api/fetch";
import { action, makeObservable, observable } from "mobx";
import { CaseloadModule } from "./CaseloadModule";
import { CaseloadTag } from "./CaseloadTag";
import { CaseloadResourceType } from "./Caseload";
import { CaseloadDiscussionShareType } from "./CaseloadDiscussion";
import { PageDoc } from "./PageDoc";

export class CaseloadActivityItem {
    activityId   ?: DbIdentity = undefined ;
    attachmentId ?: DbIdentity = undefined ;
    discussionId ?: DbIdentity = undefined ;
    caseloadId    : DbIdentity = DefaultId ;
    title         : string     = ""        ;
    moduleId      : string     = ""        ;
    dateCreated  ?: NumberDate = undefined ;
    dateUpdated  ?: NumberDate = undefined ;
    startTime    ?: NumberDate = undefined ;
    endTime      ?: NumberDate = undefined ;
    actDocId     ?: DocId      = undefined ;
    module?   : CaseloadModule = undefined ;
    type      : DbIdentity     = CaseloadResourceType.Activity;
    attachmentUrl          : string = ""       ;
    attachmentContentType ?: string = undefined;
    isShowAsImage         ?: boolean           ;
    constructor(data?: any) {
        if (data != null) {
            Object.assign(this, data);
        }
        
        makeObservable(this, {
            discussionId : observable,
            attachmentId : observable,
            activityId   : observable,
            caseloadId   : observable,
            title        : observable,
            startTime    : observable,
            endTime      : observable,
            moduleId     : observable,
            attachmentUrl         : observable,
            attachmentContentType : observable,
            isShowAsImage         : observable,
        });
    }
}

export class CaseloadActivity {
    activityId      : DbIdentity = DefaultId;
    createdBy      ?: DbIdentity = undefined;
    caseloadId      : DbIdentity = DefaultId;
    selectedTagIds  : DbIdentity[] = []     ;
    title           : string = ""           ;
    moduleId        : string = ""           ;
    dateCreated    ?: NumberDate = undefined;
    startTime      ?: NumberDate = undefined;
    endTime        ?: NumberDate = undefined;
    isDeleted      ?: boolean    = undefined;
    tags           ?: CaseloadTag[] = undefined ;
    actDocId ?: DocId                                 ;
    type      : DbIdentity     = CaseloadResourceType.Activity;
    module?   : CaseloadModule = undefined              ;
    shareType : CaseloadDiscussionShareType = CaseloadDiscussionShareType.All;

    set_startTime(v ?: NumberDate){this.startTime = v;}
    set_endTime(v ?: NumberDate){this.endTime = v;}
    set_selectedTagIds(v: DbIdentity[]) { this.selectedTagIds = v; }
    set_shareType     (v: CaseloadDiscussionShareType) { this.shareType = v; }

    constructor(data?: any) {
        if (data != null) {
            Object.assign(this, data);

            if (this.tags && this.tags?.length > 0) {
                this.set_shareType(CaseloadDiscussionShareType.SelectedTags);
                this.set_selectedTagIds(this.tags?.map(x=>x.caseloadTagId)!);
            }
        }
        
        makeObservable(this, {
            activityId        : observable        ,
            caseloadId        : observable        ,
            selectedTagIds    : observable.shallow,
            title             : observable        ,
            shareType         : observable        ,
            set_shareType     : action.bound      ,
            set_selectedTagIds: action.bound      ,
            tags              : observable.shallow,
            dateCreated       : observable        ,
            startTime         : observable        ,
            endTime           : observable        ,
            isDeleted         : observable        ,
            moduleId          : observable        ,
            module            : observable        ,
            set_startTime     : action.bound      ,
            set_endTime       : action.bound      ,
        });
    }

    static async fetchPageById({ facultyId, caseloadId, activityId, signal }: { facultyId: DbIdentity, caseloadId: DbIdentity, activityId: DbIdentity, signal?: AbortSignal }){
        const [err, x] = await aFetch<{}>("GET", `/faculty/${facultyId}/caseload/${caseloadId}/page/${activityId}`, undefined, {signal})
        return [err, (err ? undefined : new PageDoc(x))!] as const;
    }
}