import { observable, action, toJS, computed, makeObservable } from "mobx";
import { createError } from "../services/api/AppError";
import { aFetch } from "../services/api/fetch";
import { PermissionEnum } from "./Permission";

import {DbIdentity, DefaultId, isValidIdentity} from "./types";

export class Role {
    roleId: DbIdentity;
    name: string;
    isSystemRole: boolean;
    description: string;
    permissions: string[];

    constructor(data?:{}) {
        makeObservable(this, {
            roleId: observable,
            name: observable,
            isSystemRole: observable,
            description: observable,
            permissions: observable.shallow,
            set_name: action,
            set_description: action,
            set_permissions: action,
            params: computed
        });

        this.roleId      = DefaultId;
        this.name        = "";
        this.description = "";
        this.isSystemRole = false;
        this.permissions = [];

        if (data != null) Object.assign(this, data);
    }

    set_name = (v: string  ) => { this.name        = v };
    set_description = (v: string  ) => { this.description = v };
    set_permissions = (v: string[]) => { this.permissions = v };

    get params() { return ({roleId: String(this.roleId)}) }

    toJS() {
        const v = toJS(this);
        return v;
    }

    clone() {
        return new Role(this.toJS())
    }
}

export interface ICustomRole
{
    name: string;
    id: DbIdentity;
    permission: PermissionEnum;
}

let counter = 100;
export class CustomRole {
    static async getCustomRoles({ districtId, signal }: { districtId: DbIdentity, signal?: AbortSignal }) {
        const [err, xs] = await aFetch<ICustomRole[]>("GET", `/district/${districtId}/roles`, undefined, { signal });
        return [err, xs] as const;
    }

    static async setCustomRoles({ role, signal, districtId }: { districtId: DbIdentity, role: ICustomRole, signal?: AbortSignal }) {
        const isCreateNew = !isValidIdentity(role.id);
        const verb = isCreateNew ? "POST" : "PUT";
        const path = isCreateNew ? `/district/${districtId}/role` : `role/${role.id}`;
        const [err, data] = await aFetch<ICustomRole>(verb, path, ({ name: role.name, permission: role.permission  }), { signal  });

        return [err, data] as const;
    }

    static async getUserCustomRoles({ signal, userId }: { userId: DbIdentity,signal?: AbortSignal }) {
        const [err, xs] = await aFetch<ICustomRole[]>("GET", `/user/${userId}/roles`, undefined, { signal });
        return [err, xs] as const;
    }

}

