import { makeObservable, observable, toJS } from "mobx";
import { EGoogleDocViewType } from "../types";

export enum EEmbedAppType {
    GoogleDrive = "Google.Drive",
    OneDrive    = "Microsoft.Drive",
}

interface IEmbedItem {
    contentType  : string,
    embedApp     : string,
    embedName?: string,
    embedId      : string,
    resourceKey ?: string,
    lastEditedUtc: number,
    link         : string,
    type         : EGoogleDocViewType,
}

export class EmbedItem implements IEmbedItem {
    embedId      : string = "";
    contentType  : string = "";
    embedApp     : string = "";
    embedName ?: string = "";
    lastEditedUtc: number = 0;
    link         : string = "";
    type         : EGoogleDocViewType = EGoogleDocViewType.Document;

    constructor(data?:{}) {
        makeObservable(this, {
            embedId      : observable,
            contentType  : observable,
            embedApp     : observable,
            embedName    : observable,
            lastEditedUtc: observable,
            link         : observable,
            type         : observable,
        });
        if (data != null) Object.assign(this, data);
    }
    toJS(): IEmbedItem { return toJS(this); }
}