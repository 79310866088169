import { set, get, del, keys, UseStore, createStore } from "idb-keyval";
import { LocalCacheKey, StudentCommentCachePrefix, StudentDocCachePrefix } from "../config";
import { NumberDate } from "../models/types";
import { sessionStorage } from "./localStorage";
import { encryptData, decryptData } from "./cryptoJS";

class IdbKeyValStore {
    private cacheStore ?: UseStore;

    constructor(dbName: string, storeName: string) {
        this.cacheStore = createStore(dbName, storeName);
    }

    async getCache(cacheName: IDBValidKey) {
        return await get(cacheName, this.cacheStore);
    }

    async setCache(cacheName: IDBValidKey, value: any) {
        return await set(cacheName, value, this.cacheStore);
    }

    async deleteCache(cacheName: IDBValidKey) {
        return await del(cacheName, this.cacheStore);
    }

    async keyIncludes(seachCacheName: string) {
        return await (await keys(this.cacheStore)).filter(x => x.toString().includes(seachCacheName)).map(x => {
            const elements = x.toString().split('|');
            return {key: x, cache_time: Number(elements[0] || 0)}
        });
    }
}

export class StudentCacheIdbStore {
    private storeDb : IdbKeyValStore = new IdbKeyValStore('student-cache', 'data');

    constructor() {}

    async clearStudentDocAndCommentCache(dueDate: NumberDate) {
        const docCachekeys = await this.storeDb.keyIncludes(StudentDocCachePrefix);
        const commentCacheKeys = await this.storeDb.keyIncludes(StudentCommentCachePrefix);
        await (docCachekeys.concat(commentCacheKeys)).map(async (x) => x.cache_time <= dueDate && await this.storeDb.deleteCache(x.key));
    }

    async get(cacheNameWithNoTime: string) {
        const cacheKey = sessionStorage.getItem(LocalCacheKey)
        if (!cacheKey) return undefined;

        const items = await this.storeDb.keyIncludes(cacheNameWithNoTime);
        if (items.length <= 0) return undefined;

        const data = await this.storeDb.getCache(items[0]!.key);
        if (data == null) return undefined;

        return decryptData(data, cacheKey);
    }

    async set(cacheNameWithNoTime: string, value: any) {
        const cacheKey = sessionStorage.getItem(LocalCacheKey)
        if (!cacheKey) return false;

        const items = await this.storeDb.keyIncludes(cacheNameWithNoTime);
        items.map(x => this.storeDb.deleteCache(x.key));

        await this.storeDb.setCache(`${Date.now()}|${cacheNameWithNoTime}`, encryptData({data: value, time: Date.now()}, cacheKey));

        return true;
    }

    async del(cacheNameWithNoTime: string) {
        const items = await this.storeDb.keyIncludes(cacheNameWithNoTime);
        items.map(x => this.storeDb.deleteCache(x.key));
    }

    async has(cacheNameWithNoTime: string) {
        const items = await this.storeDb.keyIncludes(cacheNameWithNoTime);
        return items.length > 0;
    }
}

