export enum PermissionEnum {
    None                    = 0,
    CanEditClass            = 1 << 0,
    CanAddStudent           = 1 << 1,
    CanEditSchoolRole       = 1 << 2,
    CanImpersonateFaculty   = 1 << 3,
    CanImpersonateStudent   = 1 << 4,
    CanAccessStudent        = 1 << 5,
    CanDistributeContent    = 1 << 6,
    CanFlagStudent          = 1 << 7,
    CanCreateGroup          = 1 << 8,
    CanSeeGroups            = 1 << 9,
    CanJoinGroups           = 1 << 10,
    CanAddGroupUser         = 1 << 11,
    CanViewStudentWellness  = 1 << 12,
    CanEditStudentWellness  = 1 << 13,
    CanEditWellnessQuestion = 1 << 14,
    CanImpersonateParent    = 1 << 15,
    CanAdminSchools         = 1 << 16,
    CanAdminRoster          = 1 << 17,
    CanAdminFaculty         = 1 << 18,
    CanAdminStudents        = 1 << 19,
    CanAdminParents         = 1 << 20,
    CanAdminAllClasses      = 1 << 21,
    CanStartConference      = 1 << 22,
    CanManageAppIntegrations= 1 << 23,
    CanAdminPrograms        = 1 << 24,
    CanManageCaseCarrier    = 1 << 25,
    CanManageIep            = 1 << 26,
    CanCreateEvent          = 1 << 27,
    CanViewIep              = 1 << 28,
    CanManageShoulderTaps   = 1 << 29,
    HasStudentSelfReflection= 1 << 30,
    HasGoogleClassroom      = 2147483648, //1 << 31,
    CanDoSurveys            = 4294967296, //1 << 32
    CanManageAcademicPlanner= 8589934592, //1 << 33
    CanUpdatePersonalInfo   = 17179869184, //1 << 34
    CanOpenChatBot          = 34359738368, //1 << 35
    CanViewCommunityHub     = 68719476736, //1 << 36
    CanManageTeams          = 137438953472, //1 << 37
    CanSetClassCredits      = 274877906944, //1 << 38
}

export const DistricOnlyPermissionValues = [
    PermissionEnum.CanAdminSchools,
    PermissionEnum.CanAdminRoster,
    PermissionEnum.CanAdminFaculty,
    PermissionEnum.CanAdminStudents,
    PermissionEnum.CanAdminParents,
    PermissionEnum.CanAdminAllClasses,
    PermissionEnum.CanManageAppIntegrations,
    PermissionEnum.CanAdminPrograms,
    PermissionEnum.CanManageShoulderTaps,
    PermissionEnum.CanManageAcademicPlanner,
    PermissionEnum.CanManageTeams
];

export const PermanentPermissionValues = [
    PermissionEnum.CanManageCaseCarrier,
    PermissionEnum.CanManageIep
];

export const StudentPermissionValues = [
    PermissionEnum.CanCreateGroup,
    PermissionEnum.CanSeeGroups,
    PermissionEnum.CanJoinGroups,
    PermissionEnum.CanAddGroupUser,
    PermissionEnum.CanCreateEvent,
    PermissionEnum.CanUpdatePersonalInfo,
    PermissionEnum.CanOpenChatBot,
];

export const TeacherPermissionValues = [
    PermissionEnum.CanEditClass,
    PermissionEnum.CanAddStudent,
    PermissionEnum.CanImpersonateFaculty,
    PermissionEnum.CanImpersonateStudent,
    PermissionEnum.CanImpersonateParent,
    PermissionEnum.CanAccessStudent,
    PermissionEnum.CanFlagStudent,
    PermissionEnum.CanViewStudentWellness   ,
    PermissionEnum.CanEditStudentWellness   ,
    PermissionEnum.CanEditWellnessQuestion,
    ...StudentPermissionValues,
    PermissionEnum.CanStartConference,
    // TES-6437: Simplify IEP logic - Teacher cannot view IEPs
    // we hide this setting from Permission Edit page - this will be controlled by PrimaryRole = Faculty/Student
    // PermissionEnum.CanViewIep
    PermissionEnum.HasStudentSelfReflection,
    PermissionEnum.CanDistributeContent,
    PermissionEnum.HasGoogleClassroom,
    PermissionEnum.CanDoSurveys,
    PermissionEnum.CanViewCommunityHub,
    PermissionEnum.CanSetClassCredits
];

export const NonSchoolPermissions = [
    PermissionEnum.CanImpersonateFaculty,
    PermissionEnum.CanImpersonateStudent,
    PermissionEnum.CanImpersonateParent,
    PermissionEnum.CanCreateGroup,
    PermissionEnum.CanSeeGroups,
    PermissionEnum.CanJoinGroups,
    PermissionEnum.CanAddGroupUser,
    PermissionEnum.CanStartConference,
    PermissionEnum.CanCreateEvent,
    PermissionEnum.CanUpdatePersonalInfo,
    PermissionEnum.CanOpenChatBot,
    PermissionEnum.CanViewCommunityHub
];
