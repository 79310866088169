import type { DocId, UrlString, NumberDate, HtmlString } from "./types";
import { observable, action, makeObservable, toJS } from "mobx";
import { ActItem, EActItemType } from "./ActDoc";
import { BlendOptions } from "./BlendOptions";
import { IBanner } from "./Banner";
import { hasDisplayableContent } from "../utils/html";
import { ActItemContainer } from "./ActDoc/ActItemContainer";

export class BaseDoc extends ActItemContainer implements IBanner {
    id                     : DocId      = "";
    title                  : string     = "";       set_title              (v: string      ) { this.title   = v }
    banner                 : UrlString  = "";       set_banner             (v: UrlString   ) { this.banner  = v }
    bannerBlendOptions    ?: BlendOptions   ;       set_bannerBlendOptions (v: BlendOptions) { this.bannerBlendOptions  = v }
    /** @deprecated we don't use summary anymore, please do not clone this */
    summary                : HtmlString = "";       set_summary            (v: HtmlString  ) { this.summary = v }
    dateUpdated            : NumberDate = 0 ;
    isTinyMCE             ?: boolean;               set_isTinyMCE           (v?:boolean    ) { this.isTinyMCE = v }

    constructor(data ?: BaseDoc) {
        super(data);
        makeObservable(this, {
            id                 : observable,
            title              : observable, set_title              : action.bound,
            banner             : observable, set_banner             : action.bound,
            bannerBlendOptions : observable, set_bannerBlendOptions : action.bound,
            summary            : observable, set_summary            : action.bound,
            dateUpdated        : observable,
            isTinyMCE          : observable,
        }
    );

        if (data != null) {
            const { bannerBlendOptions, banner, items,...pData } = data;
            Object.assign(this, pData);
            this.bannerBlendOptions = new BlendOptions(bannerBlendOptions);
            this.banner = banner??""; //ensure banner not null
        }
        else {
            this.bannerBlendOptions = new BlendOptions();
        }

        // parse summary to items if it is not empty
        if (hasDisplayableContent(this.summary)) {
            this.items.push(new ActItem({type: EActItemType.Text, content: this.summary}));
            this.summary = "";
        }
    }

    toJS(): any {
        return toJS(this);
    }
}
