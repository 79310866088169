import { localStorage, sessionStorage } from "../../utils/localStorage";
import { JwtTokenKey, RememberMeKey, LocalCacheKey } from "../../config";

const copyCommandKey = 'copyTokenFromSessionToLocal'

function copyTokenFromSessionToLocal(){
    if(isPersistent()) return;
    const sToken = sessionStorage.getItem(JwtTokenKey);
    if(sToken)
        localStorage.setItem(JwtTokenKey, sToken);
}

// listen for changes to localStorage
window.addEventListener("storage", function(event) {
    if(!event) { event = window.event; } // ie suq
    if(!event.newValue) return;
    if (event.key === copyCommandKey) {
        copyTokenFromSessionToLocal();
    }
}, false);

window.addEventListener('beforeunload', function (e) {
    if(!isPersistent())
    {
        localStorage.removeItem(JwtTokenKey);
        // to trigger the copy token action on another tabs
        localStorage.setItem(copyCommandKey, 'set');
        localStorage.removeItem(copyCommandKey);
    }
});

function isPersistent() {
    return localStorage.getItem(RememberMeKey) === 'true';
}

export function storeJwtToken(token:string, persistent:boolean|"auto") {
    if (persistent == "auto") persistent = isPersistent();

    localStorage.setItem(JwtTokenKey, token);
    localStorage.setItem(RememberMeKey, persistent ? 'true' : 'false');
    if (!persistent) {
        sessionStorage.setItem(JwtTokenKey, token);
    }
}

export function getJwtToken() {
    copyTokenFromSessionToLocal();
    return (localStorage.getItem(JwtTokenKey) || sessionStorage.getItem(JwtTokenKey));
}

export function clearJwtToken() {
    localStorage.removeItem(JwtTokenKey);
    sessionStorage.removeItem(JwtTokenKey);
}

export function storeLocalCacheKey(key:string) {
    sessionStorage.setItem(LocalCacheKey, key);
}

export function removeLocalCacheKey() {
    sessionStorage.removeItem(LocalCacheKey);
}
