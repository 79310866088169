
import type { DbIdentity } from "./types";
import { IUser, User } from "./User";
import { Student } from "./Student";
import { Faculty } from "./Faculty";
import { School } from "./School";
import { Class, IClassFaculty, StudentGroup } from "./Class";
import { IClassStudent, ClassStudent } from "./ClassStudent";
import { Activity } from "./Activity";
import type { ISchoolStudent } from "./SchoolStudent";
import { IActivityScore, ActivityScore } from "./ActivityScore";
import { Discussion } from "./Discussion";
import { Comment, IUserThreadBlocking } from "./Comment";
import { CommentSeen } from "./CommentSeen";
import { GradingTerm } from "./GradingTerm";
import { UserNotice } from "./UserNotice";
import { CustomLink } from "./CustomLink";
import { CustomLinkPortal } from "./CustomLinkPortal";
import { Role } from "./Role";
import { ClassFaculty } from "./ClassFaculty";
import { Quality } from "./Quality";
import { StudentStaffNote } from "./StudentStaffNote";
import { Parent } from "./Parent";
import { ParentStudent } from "./ParentStudent";
import { Group, PendingGroupMember, GroupMember } from "./Group";
import { StudentFlag } from "./StudentFlag";
import { notNull } from "../utils/list";
import { Event, EventMember, EventSchedule } from "./Event";
import { Conference, ConferenceLog, ConferenceLogRecording, ConferenceModule } from "./Conference";
import { ActivityVisibility } from "./ActivityVisibility";
import { ThreadUnread } from "./ThreadUnread";
import { Folder } from "./Folder";
import { runInAction } from "mobx";
import { AppIntegration, UserAppIntegration } from "./AppIntegration";
import {  Module } from "./Module";
import { ResourceGroup } from "./ResourceGroup";
import { ResourceActivity } from "./ResourceActivity";
import { Caseload } from "./Caseload";
import { CaseloadStudent } from "./CaseloadStudent";
import { GradingScale } from "./GradingScale";
import { ClassInvitation } from "./ClassInvitation";
import { ShoulderTap } from "./ShoulderTap";
import { ShoulderTapOwner } from "./ShoulderTapOwner";
import { ShoulderTapRecipient } from "./ShoulderTapRecipient";
import { ClassGradebookStudent } from "./ClassGradebookStudent";
import { ActivityVideoExtension } from "./Activity/ActivityVideoExtension";
import { SbgActivityScore } from "./SbgActivityScore";

interface IActivity {
    activityId: DbIdentity
}

export interface ISchoolFaculty {
    schoolId : DbIdentity;
    facultyId: DbIdentity;
}

export interface GeneralDto {
    users                  : IUser[],
    roles                  : {}[],
    faculties              : {}[],
    students               : {}[],
    schools                : {}[],
    gradingTerms           : {}[],
    classes                : {}[],
    activities             : IActivity[],
    schoolFaculties        : ISchoolFaculty[],
    schoolStudents         : ISchoolStudent[],
    classStudents          : IClassStudent[],
    classFaculties         : IClassFaculty[],
    activityScores         : IActivityScore[];
    discussions            : {}[];
    comments               : {}[];
    commentSeens           : {}[];
    threadUnreads          : ThreadUnread[];
    activityCountDiscussion: Record<DbIdentity, number>, // activityId -> count
    userNotices            : UserNotice[],
    customLinks            : {}[],
    customLinkPortals      : {}[],
    qualities              : {}[],
    studentStaffNotes      : {}[],
    studentFlags           : {}[],
    parents                : {}[],
    parentStudents         : {}[],
    groups                 : {}[],
    groupMembers           : {}[],
    pendingGroupMembers    : {}[],
    events                 : {}[],
    eventMembers           : {}[],
    eventSchedules         : {}[],
    discussionStudentCount?: number,
    conferences            : {}[],
    conferenceLogs         : {}[],
    conferenceLogRecordings: {}[],
    conferenceModules      : {}[],
    activityVisibilities   : {}[],
    studentGroups          : {}[],
    blockedUsers           : IUserThreadBlocking[],
    folders                : {}[],
    appIntegrations        : {}[],
    userAppIntegrations    : {}[],
    modules                : Module[],
    resourceGroups         : ResourceGroup[],
    resourceActivities     : ResourceActivity[],
    caseloads              : Caseload[],
    caseloadStudents       : CaseloadStudent[],
    gradingScales          : GradingScale[],
    classInvitations       : ClassInvitation[],
    shoulderTaps           : ShoulderTap[],
    shoulderTapOwners      : ShoulderTapOwner[],
    shoulderTapRecipients  : ShoulderTapRecipient[],
    classGradebookStudents : ClassGradebookStudent[],
    activityVideoExtensions: ActivityVideoExtension[],
    totalRecords?           : number,
}

export interface IGeneralViewModel {
    users                  : User[],
    roles                  : Role[],
    faculties              : Faculty[],
    students               : Student[],
    schools                : School[],
    gradingTerms           : GradingTerm[],
    classes                : Class[],
    activities             : Activity[],
    activitiesScores       : ActivityScore[],
    schoolFaculties        : ISchoolFaculty[],
    schoolStudents         : ISchoolStudent[],
    classStudents          : ClassStudent[],
    classFaculties         : ClassFaculty[],
    discussions            : Discussion[],
    comments               : Comment[],
    commentSeens           : CommentSeen[],
    threadUnreads          : ThreadUnread[],
    activityCountDiscussion: Record<DbIdentity, number>, // activityId -> count
    userNotices            : UserNotice[],
    customLinks            : CustomLink[],
    customLinkPortals      : CustomLinkPortal[],
    qualities              : Quality[],
    studentStaffNotes      : StudentStaffNote[],
    studentFlags           : StudentFlag[],
    parents                : Parent[],
    parentStudents         : ParentStudent[],
    groups                 : Group[],
    groupMembers           : GroupMember[],
    pendingGroupMembers    : PendingGroupMember[],
    events                 : Event[],
    eventMembers           : EventMember[],
    eventSchedules         : EventSchedule[],
    discussionStudentCount?: number,
    conferences            : Conference[],
    conferenceLogs         : ConferenceLog[],
    conferenceLogRecordings: ConferenceLogRecording[],
    conferenceModules      : ConferenceModule[],
    activityVisibilities   : ActivityVisibility[],
    studentGroups          : StudentGroup[],
    blockedUsers           : IUserThreadBlocking[],
    folders                : Folder[],
    appIntegrations        : AppIntegration[],
    userAppIntegrations    : UserAppIntegration[],
    modules                : Module[],
    resourceGroups         : ResourceGroup[],
    resourceActivities     : ResourceActivity[],
    caseloads              : Caseload[],
    caseloadStudents       : CaseloadStudent[],
    gradingScales          : GradingScale[],
    classInvitations       : ClassInvitation[],
    shoulderTaps           : ShoulderTap[],
    shoulderTapOwners      : ShoulderTapOwner[],
    shoulderTapRecipients  : ShoulderTapRecipient[],
    classGradebookStudents : ClassGradebookStudent[],
    activityVideoExtensions: ActivityVideoExtension[],
    sisUserIds             : string[],
    totalRecords?           : number,
}

export function parseGeneralViewModel(data:GeneralDto): IGeneralViewModel {
    if (data == null) data = {} as any;

    const users                   = Array.isArray(data.users                   ) ? data.users                   .map( u       => new User                   ( u           )) : [];
    const roles                   = Array.isArray(data.roles                   ) ? data.roles                   .map( u       => new Role                   ( u           )) : [];
    const faculties               = Array.isArray(data.faculties               ) ? data.faculties               .map( s       => new Faculty                ( s           )) : [];
    const students                = Array.isArray(data.students                ) ? data.students                .map( s       => new Student                ( s           )) : [];
    const schools                 = Array.isArray(data.schools                 ) ? data.schools                 .map( c       => new School                 ( c           )) : [];
    const gradingTerms            = Array.isArray(data.gradingTerms            ) ? data.gradingTerms            .map( c       => new GradingTerm            ( c           )) : [];
    const classes                 = Array.isArray(data.classes                 ) ? data.classes                 .map( c       => new Class                  ( c           )) : [];
    const activities              = Array.isArray(data.activities              ) ? data.activities              .map( a       => new Activity               ( a           )) : [];
    const activitiesScores        = Array.isArray(data.activityScores          ) ? data.activityScores          .map( s       => new ActivityScore          ( s           )) : [];
    const discussions             = Array.isArray(data.discussions             ) ? data.discussions             .map( s       => new Discussion             ( s           )) : [];
    const classStudents           = Array.isArray(data.classStudents           ) ? data.classStudents           .map( x       => new ClassStudent           ( x           )) : [];
    const userNotices             = Array.isArray(data.userNotices             ) ? data.userNotices             .map( u       => new UserNotice             ( u           )) : [];
    const customLinks             = Array.isArray(data.customLinks             ) ? data.customLinks             .map( l       => new CustomLink             ( l           )) : [];
    const qualities               = Array.isArray(data.qualities               ) ? data.qualities               .map( l       => new Quality                ( l           )) : [];
    const customLinkPortals       = Array.isArray(data.customLinkPortals       ) ? data.customLinkPortals       .map( l       => new CustomLinkPortal       ( l           )) : [];
    const studentStaffNotes       = Array.isArray(data.studentStaffNotes       ) ? data.studentStaffNotes       .map( l       => new StudentStaffNote       ( l           )) : [];
    const studentFlags            = Array.isArray(data.studentFlags            ) ? data.studentFlags            .map( l       => new StudentFlag            ( l           )) : [];
    const parents                 = Array.isArray(data.parents                 ) ? data.parents                 .map((p: any) => new Parent                 ( p.user ?? p )) : [];
    const parentStudents          = Array.isArray(data.parentStudents          ) ? data.parentStudents          .map((p: any) => new ParentStudent          ( p           )) : [];
    const groups                  = Array.isArray(data.groups                  ) ? data.groups                  .map( g       => new Group                  ( g           )) : [];
    const groupMembers            = Array.isArray(data.groupMembers            ) ? data.groupMembers            .map( m       => new GroupMember            ( m           )) : [];
    const pendingGroupMembers     = Array.isArray(data.pendingGroupMembers     ) ? data.pendingGroupMembers     .map( g       => new PendingGroupMember     ( g           )) : [];
    const events                  = Array.isArray(data.events                  ) ? data.events                  .map( g       => new Event                  ( g           )) : [];
    const eventMembers            = Array.isArray(data.eventMembers            ) ? data.eventMembers            .map( m       => new EventMember            ( m           )) : [];
    const eventSchedules          = Array.isArray(data.eventSchedules          ) ? data.eventSchedules          .map( m       => new EventSchedule          ( m           )) : [];
    const conferences             = Array.isArray(data.conferences             ) ? data.conferences             .map( m       => new Conference             ( m           )) : [];
    const conferenceLogs          = Array.isArray(data.conferenceLogs          ) ? data.conferenceLogs          .map( m       => new ConferenceLog          ( m           )) : [];
    const conferenceLogRecordings = Array.isArray(data.conferenceLogRecordings ) ? data.conferenceLogRecordings .map( m       => new ConferenceLogRecording ( m           )) : [];
    const conferenceModules       = Array.isArray(data.conferenceModules       ) ? data.conferenceModules       .map( m       => new ConferenceModule       ( m           )) : [];
    const studentGroups           = Array.isArray(data.studentGroups           ) ? data.studentGroups           .map( m       => new StudentGroup           ( m           )) : [];
    const folders                 = Array.isArray(data.folders                 ) ? data.folders                 .map( f       => new Folder                 ( f           )) : [];
    const appIntegrations         = Array.isArray(data.appIntegrations         ) ? data.appIntegrations         .map( a       => new AppIntegration         ( a           )) : [];
    const userAppIntegrations     = Array.isArray(data.userAppIntegrations     ) ? data.userAppIntegrations     .map( a       => new UserAppIntegration     ( a           )) : [];
    const modules                 = Array.isArray(data.modules                 ) ? data.modules                 .map( a       => new Module                 ( a           )) : [];
    const resourceGroups          = Array.isArray(data.resourceGroups          ) ? data.resourceGroups          .map( a       => new ResourceGroup          ( a           )) : [];
    const resourceActivities      = Array.isArray(data.resourceActivities      ) ? data.resourceActivities      .map( a       => new ResourceActivity       ( a           )) : [];
    const caseloads               = Array.isArray(data.caseloads               ) ? data.caseloads               .map( a       => new Caseload               ( a           )) : [];
    const caseloadStudents        = Array.isArray(data.caseloadStudents        ) ? data.caseloadStudents        .map( a       => new CaseloadStudent        ( a           )) : [];
    const gradingScales           = Array.isArray(data.gradingScales           ) ? data.gradingScales           .map( a       => new GradingScale           ( a           )) : [];
    const classInvitations        = Array.isArray(data.classInvitations        ) ? data.classInvitations        .map( a       => new ClassInvitation        ( a           )) : [];
    const shoulderTaps            = Array.isArray(data.shoulderTaps            ) ? data.shoulderTaps            .map( a       => new ShoulderTap            ( a           )) : [];
    const shoulderTapOwners       = Array.isArray(data.shoulderTapOwners       ) ? data.shoulderTapOwners       .map( a       => new ShoulderTapOwner       ( a           )) : [];
    const shoulderTapRecipients   = Array.isArray(data.shoulderTapRecipients   ) ? data.shoulderTapRecipients   .map( a       => new ShoulderTapRecipient   ( a           )) : [];
    const classGradebookStudents  = Array.isArray(data.classGradebookStudents  ) ? data.classGradebookStudents  .map( a       => new ClassGradebookStudent  ( a           )) : [];
    const activityVideoExtensions = Array.isArray(data.activityVideoExtensions ) ? data.activityVideoExtensions .map( a       => new ActivityVideoExtension ( a           )) : [];

    const a2ac = new Map(activitiesScores.map(s => [s.activityId, s]));

    for (const s of students) {
        const css = classStudents.filter(cs => cs.studentId == s.studentId);
        if (css.length == 1) {
            const [cs] = css;
            s.cumulativeGrade        = cs!.cumulativeGrade;
            s.cumulativePublishGrade = cs!.cumulativePublishGrade;
        }
        const classIds = css.map(cs => cs.classId);
        s.classes        = classIds;
        s.tClassStudent  = css;
        s.tActivityScore = activities.filter(a => classIds.includes(a.classId))
                            .map(a => a2ac.get(a.activityId)).filter(notNull)
                            ;
    }

    for (const c of customLinks) c.set_portal([...new Set(customLinkPortals.filter(x => x.customLinkId == c.customLinkId).map(y => y.portalId))]);

    const mActivity = new Map(activities.map(a => [a.activityId, a]));
    for (const d of discussions) {
        const a = mActivity.get(d.discussionActivityId);
        if (!a) continue;
        runInAction(() => {
            d.oneRosterId = a.oneRosterId;
            d.aeriesSyncPolicy = a.aeriesSyncPolicy;
            d.aeriesGradeSyncPolicy = a.aeriesGradeSyncPolicy;
        });
    }

    for (const s of schools) {
        const school = data.schoolStudents.find(x => x.schoolId == s.schoolId);
        if (!school) continue;

        s.sortIndex = school.sortIndex;
    }

    return ({
        users                  : users,
        roles                  : roles,
        faculties              : faculties,
        students               : students,
        schools                : schools,
        gradingTerms           : gradingTerms,
        classes                : classes,
        activities             : activities,
        activitiesScores       : activitiesScores,
        schoolFaculties        : data.schoolFaculties ?? [],
        schoolStudents         : data.schoolStudents ?? [],
        classFaculties         : (data.classFaculties ?? []).map(x => new ClassFaculty(x)),
        classStudents          : classStudents,
        comments               : (data.comments ?? []).map(x => new Comment(x)),
        discussions            : discussions,
        commentSeens           : (data.commentSeens ?? []).map(x => new CommentSeen(x)),
        threadUnreads          : (data.threadUnreads ?? []).map(x => new ThreadUnread(x)),
        activityCountDiscussion: (data.activityCountDiscussion ?? {}),
        userNotices            : userNotices ?? [],
        customLinks            : customLinks,
        customLinkPortals      : customLinkPortals,
        qualities              : qualities,
        studentStaffNotes      : studentStaffNotes,
        studentFlags           : studentFlags,
        parents                : parents,
        parentStudents         : parentStudents,
        groups                 : groups,
        pendingGroupMembers    : pendingGroupMembers,
        groupMembers           : groupMembers,
        events                 : events,
        eventMembers           : eventMembers,
        eventSchedules         : eventSchedules,
        discussionStudentCount : data.discussionStudentCount,
        conferences            : conferences,
        conferenceLogs         : conferenceLogs,
        conferenceLogRecordings: conferenceLogRecordings,
        conferenceModules      : conferenceModules,
        activityVisibilities   : (data.activityVisibilities ?? []).map(x => new ActivityVisibility(x)),
        studentGroups          : studentGroups,
        blockedUsers           : data.blockedUsers ?? [],
        folders,
        appIntegrations,
        userAppIntegrations,
        modules,
        resourceGroups,
        resourceActivities,
        caseloads,
        caseloadStudents,
        gradingScales,
        classInvitations,
        shoulderTaps,
        shoulderTapOwners,
        shoulderTapRecipients,
        classGradebookStudents,
        activityVideoExtensions,
        totalRecords           : data.totalRecords,
    });
}