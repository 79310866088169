import { makeObservable, observable } from "mobx";

export class StudentSuccessStatistic {
    credits        : number = 0;
    classCompleted : Number = 0;
    classInProgress: Number = 0;
    ePortfolio     : Number = 0;
    achievement    : Number = 0;

    constructor(data?: {}) {
        if (data != null) Object.assign(this, data);

        makeObservable(this, {
            credits        : observable,
            classCompleted : observable,
            classInProgress: observable,
            ePortfolio     : observable,
            achievement    : observable
        });
    }
}