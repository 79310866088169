import { observable, action, toJS, makeObservable } from "mobx";
import { DbIdentity, DefaultId } from "./types";
import { aFetch } from "../services/api/fetch";
import { IClassGradebook } from "./Class";

export interface IClassGradebookStudent {
    classGradebookId        : DbIdentity;
    classId                 : DbIdentity;
    studentId               : DbIdentity;
    cumulativeGrade        ?: number;
    cumulativePublishGrade ?: number;
    lockedMark             ?: string;
}

export class ClassGradebookStudent {
    constructor(data?: {}) {
        if (data != null) {
            Object.assign(this, data);
        }

        makeObservable(this, {
            classGradebookId        : observable,
            classId                 : observable,
            studentId               : observable,
            cumulativeGrade         : observable,
            cumulativePublishGrade  : observable,
            lockedMark              : observable, set_lockedMark : action,
        });
    }

    classGradebookId       : DbIdentity = DefaultId;
    classId                : DbIdentity = DefaultId;
    studentId              : DbIdentity = DefaultId;
    cumulativeGrade       ?: number     = undefined;
    cumulativePublishGrade?: number     = undefined;
    lockedMark            ?: string     = "";        set_lockedMark(v?: string) { this.lockedMark = v };

    toJS() {
        return toJS(this);
    }

    static async getClassGradebooksAsStudent({studentId, classId, signal} : {studentId: DbIdentity, classId: DbIdentity, signal?: AbortSignal}){
        const [err, data] = await aFetch<{}[]>("GET", `/student/${studentId}/class/${classId}/classGradebooks`, { signal });
        return [err, err ? [] : data.map(x => x as IClassGradebook)] as const;
    }

    static async getClassGradebooksAsParent({ studentId, classId, signal} : { studentId: DbIdentity, classId: DbIdentity, signal?: AbortSignal}){
        const [err, data] = await aFetch<{}[]>("GET", `/parent/student/${studentId}/class/${classId}/classGradebooks`, { signal });
        return [err, err ? [] : data.map(x => x as IClassGradebook)] as const;
    }

    static async fetchClassGradebookStudentsAsStudent({studentId, classId, signal }:{studentId:DbIdentity, classId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<ClassGradebookStudent[]>("GET", `/student/${studentId}/class/${classId}/gradebookStudents`, undefined, { signal });
        return [err, (err ? [] : data.map(x => new ClassGradebookStudent(x)))!] as const;
    }

    static async fetchClassGradebookStudentsAsParent({ studentId, classId, signal }:{ studentId: DbIdentity, classId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<ClassGradebookStudent[]>("GET", `/parent/student/${studentId}/class/${classId}/gradebookStudents`, undefined, { signal });
        return [err, (err ? [] : data.map(x => new ClassGradebookStudent(x)))!] as const;
    }
}
