import { action, computed, makeObservable, observable } from "mobx";
import { MediaSubTypeEnum, MediaTypeEnum } from "../MediaTypeEnum";
import { DbIdentity, HtmlString, UrlString, googleMimeTypeToViewType, isValidIdentity } from "../types";
import { ActOption } from "./ActOption";
import { IPollQuizItem, PollQuizItem } from "./PollQuizItem";
import { BaseDiscussion } from "../BaseDiscussion";
import { IMatchQuizItem, IMatchQuizOption, IMatchQuizRowItem, MatchQuizzItem, MatchQuizzOption, MatchQuizzRowItem } from "./MatchQuizzItem";
import { FillInBlankItem, FillInBlankItemType, IFillInBlankItem } from "./FillInBlankItem";
import { IAnnotationItem } from "../Annotation";
import { HighlightTextType } from "./ActDoc";
import { INumericSliderOption, NumericSliderOption } from "./NumericSliderOption";
import { nanoid } from "nanoid";
import { getFillInBlankElementInHtml } from "../../components/inputs/editorMixerUtils";
import { cleanUpContent, escapeUnprintCharacter, stripHtml } from "../../utils/html";
import { UploadFile } from "antd/lib/upload/interface";
import { audioExts, imageExts, videoExts } from "../../utils/getMediaInfo";
import { message } from "antd";
import { extractContent } from "../../utils/html";
import i18n from "../../i18n";
import { GoogleDocItem } from "../GoogleDrive";
import { EEmbedAppType } from "./EmbedItem";
import { uniq } from "lodash-es";
import { EActItemType, QuestionTypes } from "./EActItemType";
import { isUrl } from "../../utils/url";
import { IStudentActItem } from "../StudentActDoc";
import { ISettingContentItem, getElementId } from "../BaseContentItem/ISettingContentItem";
import { MicrosoftFile } from "../Microsoft/OneDrive";

export interface IActItem  {
    id                  : string;
    type                : EActItemType;
    title               : string;
    content             : HtmlString;
    image               : UrlString;
    startDate           ?: number;
    endDate             ?: number;
    link                : UrlString;
    contentType         : string;
    mediaType           : MediaTypeEnum;
    embedLink           : UrlString;
    embedContentType    : string;
    embedId             : string;
    h5pContentId        : string;
    resourceKey        ?: string;
    embedApp            : string;
    embedName      ?: string;
    embedType           : string;
    embedOwnerAccount   : string;
    embedLastEdited    ?: number;
    collaborativeMode   : boolean;
    isEmbedLink         : boolean;
    pdfLink             : UrlString;
    matchQuizzItems     : IMatchQuizItem[];
    matchQuizzRowItems  : IMatchQuizRowItem[];
    matchQuizzNoMappingAnswers: IMatchQuizOption[];
    numericMin          ?: number;
    numericMax          ?: number;
    isMultipleAnswer    ?: boolean;
    isRequireAllCorrect    ?: boolean;
    allowAnyOrderResponses ?: boolean;
    options             ?: ActOption[];
    pollItems           ?: IPollQuizItem[];
    pollType            ?: ActItemPollType;
    totalVotes          ?: number;
    discussionId        ?: DbIdentity;
    resourceDiscussionId?: DbIdentity;
    discussionModuleId  ?: string;
    discussion          ?: BaseDiscussion;
    discussionItem      ?: IActItem;
    visibleTo           ?: string[];
    pointScore          ?: number;
    isExtraCredit       ?: boolean;
    isTrueFalseItem     ?: boolean;
    isLockChoiceOrder   ?: boolean;
    editorMode          ?: string;
    subType              : MediaSubTypeEnum;

    mediaType2           : MediaTypeEnum;
    image2               : UrlString;
    link2                : UrlString;
    contentType2         : string;

    items               ?: IActItem[];
    fillInBlankItems    ?: IFillInBlankItem[];

    canVoteNum          ?: number;

    numericSliderOptions   : INumericSliderOption[];
    numericSliderInterval ?: number;
    blockVideoSeeking         ?: boolean;
}

export enum ActItemPollType {
    Multiple,
    Dropdown,
    Text
}

export class ActItem implements ISettingContentItem {
    id                  : string                = "";
    type                : EActItemType          = EActItemType.Text;
    title               : string                = "";
    totalVotes         ?: number;
    image               : UrlString             = "";
    content             : HtmlString            = "";
    startDate          ?: number                = undefined;
    endDate            ?: number                = undefined;
    link                : UrlString             = "";
    contentType         : string                = "";
    embedLink           : UrlString             = "";
    sharedLink          : UrlString             = "";
    embedId             : string                = "";
    h5PContentId        : string                = ""; set_h5PContentId(v?: string) { this.h5PContentId = v ?? ""; }
    resourceKey        ?: string;
    embedApp            : string                = "";
    embedName      ?: string                = undefined;
    embedType           : string                = "";
    embedContentType    : string                = "";
    embedLastEdited    ?: number                = undefined;
    embedOwnerAccount   : string                = "";
    /** @description link to previous google assignment doc to continue TES-1637 */
    collaborativeMode   : boolean               = false; // set default is off https://denovu.atlassian.net/browse/TES-4006
    isEmbedLink         : boolean               = false;
    mediaType           : MediaTypeEnum         = MediaTypeEnum.Unknown;
    matchQuizzItems     : MatchQuizzItem[]      = [];
    matchQuizzRowItems  : MatchQuizzRowItem[]   = [];
    matchQuizzNoMappingAnswers: MatchQuizzOption[] = [];
    isValuesUsedOnce   ?: boolean               = false;
    pdfLink             : UrlString             = "";
    numericMin          : number|undefined      = undefined;
    numericMax          : number|undefined      = undefined;
    options            ?: ActOption[]           = [];
    isMultipleAnswer   ?: boolean               = false;
    isRequireAllCorrect?: boolean               = true;
    allowAnyOrderResponses ?: boolean           = false;
    pollItemsAnswer     ?: number[]              = undefined;
    pollTextAnswer      ?: string                = undefined;
    pollType           ?: ActItemPollType;
    pollItems          ?: PollQuizItem[]        = [];
    discussionId       ?: DbIdentity            = undefined;
    resourceDiscussionId ?: DbIdentity            = undefined;
    discussionModuleId ?: string|undefined      = undefined;
    discussion         ?: BaseDiscussion        = undefined;
    discussionItem     ?: ActItem               = undefined;
    visibleTo          ?: string[]              = undefined;
    /** Extend info for visibelTo. Determine whether a class is all/excluded all students. */
    visibleToPerClass  = observable.map<string, boolean>();
    pointScore         ?: number                = undefined;
    isExtraCredit      ?: boolean               = undefined;
    isTrueFalseItem    ?: boolean               = undefined;
    isLockChoiceOrder  ?: boolean               = false;
    actualScore        ?: number                = undefined;
    editorMode         ?: string                = undefined;
    embedFrameHeight   ?: string                = "75vh";
    origLink            : UrlString             = "";
    subType             : MediaSubTypeEnum      = MediaSubTypeEnum.Unknown;
    mediaType2          : MediaTypeEnum         = MediaTypeEnum.Unknown;
    image2              : UrlString             = "";
    link2               : UrlString             = "";
    contentType2        : string                = "";
    origLink2           : UrlString             = "";
    items               : ActItem[]             = [];
    isImportedContent  ?: boolean               = false;
    penaltyPercentage  ?: number                = undefined;
    isPenalty          ?: boolean               = undefined;

    fillInBlankItems   ?: FillInBlankItem[];
    optionsChecked      : ActOption[]           = [];
    annotations         : IAnnotationItem[] = [];

    answerContent          : string           = ""       ;
    highlightTextContent  ?: HtmlString       = undefined;
    highlightTextItems     : string[]         = []       ;
    correctHighlightItems  : string[]         = []       ;
    highlightTextType     ?: HighlightTextType = undefined;
    documentInfo          ?: {link: string, contentType: string, pdfLink: string} = undefined;

    numericSliderOptions   : NumericSliderOption[] = []        ; set_numericSliderOptions  (v  : NumericSliderOption[] ) { this.numericSliderOptions  = v; }
    numericSliderInterval ?: number                = undefined ; set_numericSliderInterval (v ?: number                ) { this.numericSliderInterval = v; }

    canVoteNum            ?: number           = 1;
    pollTextAnswer        ?: string; set_pollTextAnswer(v ?: string) { this.pollTextAnswer = v }
    set_canVoteNum(v ?: number) { this.canVoteNum = v; }

    hasBlockWord            ?: boolean        = false; set_hasBlockWord(v ?: boolean) { this.hasBlockWord = v; }
    blockVideoSeeking       ?: boolean        = false;

    /**
     * @see EActItemType.LtiContentBlock
     * */
    appIntegrationId       ?: DbIdentity; set_appIntegrationId(v ?: DbIdentity) { this.appIntegrationId = v; }

    constructor(data:any) {
        makeObservable(this, {
            id                               : observable.ref,
            type                             : observable,
            title                            : observable,
            image                            : observable,
            content                          : observable,
            startDate                        : observable,
            endDate                          : observable,
            link                             : observable,
            contentType                      : observable,
            embedLink                        : observable,
            sharedLink                       : observable,            
            embedId                          : observable,
            h5PContentId                     : observable, set_h5PContentId: action.bound,
            resourceKey                      : observable,
            embedApp                         : observable,
            embedName                        : observable,
            embedType                        : observable,
            embedContentType                 : observable,
            embedLastEdited                  : observable,
            embedOwnerAccount                : observable,
            collaborativeMode                : observable,
            isEmbedLink                      : observable,
            mediaType                        : observable,
            matchQuizzItems                  : observable,
            matchQuizzRowItems               : observable,
            matchQuizzNoMappingAnswers       : observable,
            pdfLink                          : observable,
            numericMin                       : observable,
            numericMax                       : observable,
            options                          : observable,
            isMultipleAnswer                 : observable,
            isRequireAllCorrect              : observable,
            allowAnyOrderResponses           : observable,
            pollItemsAnswer                  : observable,
            pollTextAnswer                   : observable,
            pollType                         : observable,
            pollItems                        : observable.shallow,
            discussionId                     : observable,
            resourceDiscussionId             : observable,
            discussionModuleId               : observable,
            discussion                       : observable.ref,
            discussionItem                   : observable,
            visibleTo                        : observable,
            visibleToPerClass                : observable.ref,
            visibleSettingClasses            : computed,
            pointScore                       : observable,
            isExtraCredit                    : observable,
            isTrueFalseItem                  : observable,
            isLockChoiceOrder                : observable,
            isValuesUsedOnce                 : observable,
            actualScore                      : observable,
            editorMode                       : observable,
            embedFrameHeight                 : observable,
            subType                          : observable,
            mediaType2                       : observable,
            image2                           : observable,
            link2                            : observable,
            contentType2                     : observable,
            items                            : observable,
            fillInBlankItems                 : observable,
            hasBlockWord                     : observable,
            blockVideoSeeking                  : observable,
            set_hasBlockWord                 : action.bound,
            set_title                        : action.bound,
            set_type                         : action.bound,
            set_subType                      : action.bound,
            set_image                        : action.bound,
            set_content                      : action.bound,
            set_startDate                    : action.bound,
            set_endDate                      : action.bound,
            set_visibleTo                    : action.bound,
            set_pointScore                   : action.bound,
            set_actualScore                  : action.bound,
            set_isExtraCredit                : action.bound,
            set_isTrueFalseItem              : action.bound,
            set_embedFrameHeight             : action.bound,
            set_discussionId                 : action.bound,
            set_resourceDiscussionId         : action.bound,
            set_discussionModuleId           : action.bound,
            set_collaborativeMode            : action.bound,
            set_blockVideoSeeking              : action.bound,
            set_discussion                   : action,
            set_discussionItem               : action,
            set_numericMin                   : action,
            populateMaxValue                 : action,
            set_numericMax                   : action,
            populateMinValue                 : action,
            imageFile                        : observable.ref,
            set_imageFile                    : action.bound,
            imageFile2                       : observable.ref,
            handleImageUpload                : action,
            handleVideoUpload                : action,
            handleUploadError                : action,
            removeMathQuizzItem              : action,
            addMathQuizzRightOption          : action,
            removeMathQuizzRightOption       : action,
            set_link                         : action,
            set_link2                        : action,
            set_embedLink                    : action,
            set_sharedLink                   : action,
            set_mediaType                    : action,
            set_mediaType2                   : action,
            set_pdfLink                      : action,
            mediaFile                        : observable.ref,
            set_mediaFile                    : action,
            pMediaFile                       : observable.ref,
            mediaFile2                       : observable.ref,
            set_mediaFile2                   : action,
            pMediaFile2                      : observable.ref,
            googleFile                       : observable.ref,
            set_googleFile                   : action.bound,
            microsoftFile                    : observable.ref,
            set_microsoftFile                : action.bound,
            set_embedActItem                 : action.bound,
            documentFile                     : observable.ref,
            documentInfo                     : observable.ref,
            set_documentFile                 : action,
            clearEmbedContent                : action.bound,
            clearDocumentFile                : action.bound,
            clearMediaType                   : action,
            clearMediaType2                  : action,
            clearDocumentType                : action,
            set_pollItemsAnswer              : action,
            set_pollTextAnswer               : action,
            set_pollType                     : action.bound,
            canShowContent                   : computed,
            set_isMultipleAnswer             : action.bound,
            set_isRequireAllCorrect          : action.bound,
            set_allowAnyOrderResponses       : action.bound,
            set_LockChoiceOrderAll           : action.bound,
            isAllLocked                      : computed,
            set_multipleChoice_correctAnswers: action,
            isEmptyContent                    : computed,
            isDelimiter                      : computed,
            penaltyPercentage                : observable,
            isPenalty                        : observable,
            optionsChecked                   : observable,
            set_optionsChecked               : action,
            get_optionsChecked               : action,
            answerContent                    : observable,
            set_answerContent                : action.bound,
            annotations                      : observable.shallow,
            set_isPenalty                    : action.bound,
            totalVotes                       : observable,
            set_totalVotes                   : action.bound,
            highlightTextContent     : observable ,
            highlightTextItems       : observable.shallow,
            correctHighlightItems    : observable.shallow,
            set_highlightTextItem    : action.bound,
            set_correctHighlightItem : action.bound,
            set_highlightTextContent : action.bound,
            highlightTextType        : observable  ,
            set_highlightTextType    : action.bound,
            set_isValuesUsedOnce     : action.bound,
            canVoteNum               : observable  ,
            set_canVoteNum           : action.bound,
            numericSliderOptions         : observable.shallow,
            numericSliderInterval        : observable  ,
            addNumericSliderOption       : action.bound,
            removeNumericSliderOption    : action.bound,

            set_numericSliderOptions     : action.bound,
            set_numericSliderInterval    : action.bound,
            isValidNumericSlider         : action.bound,

            appIntegrationId   : observable, set_appIntegrationId: action.bound,

            isNameUnique             : computed,
            isDuplicateChoice        : computed,
            isNoCorrectAnswer        : computed,
            inValidNumericSliderItem : computed,
            refineEditingItem        : action.bound,
            elementId                : computed,
        });

        if (data != null) {
            const {options, matchQuizzItems, matchQuizzRowItems, matchQuizzNoMappingAnswers ,pollItems, discussionItem, fillInBlankItems, annotations, highlightTextAnswer, numericSliderOptions, visibleToPerClass,items, ...pData} = data;
            if (Array.isArray(options)) this.options = options.map(opt => new ActOption(opt));
            if (Array.isArray(matchQuizzItems)) this.matchQuizzItems = matchQuizzItems.map(i => new MatchQuizzItem(i));
            if (Array.isArray(matchQuizzRowItems)) this.matchQuizzRowItems = matchQuizzRowItems.map(i => new MatchQuizzRowItem({
                    leftOptions: i.leftOptions.map(y => new MatchQuizzOption(y)),
                    rightOptions: i.rightOptions.map(y => new MatchQuizzOption(y)),
                    isEmptyQuestion: i.isEmptyQuestion
            }));
            if (Array.isArray(matchQuizzNoMappingAnswers)) this.matchQuizzNoMappingAnswers = matchQuizzNoMappingAnswers.map(i => new MatchQuizzOption(i));
            if (Array.isArray(pollItems)) this.pollItems = pollItems.map(i => new PollQuizItem(i));
            if (Array.isArray(fillInBlankItems)) this.fillInBlankItems = fillInBlankItems.map(i => new FillInBlankItem(i));
            // if (Array.isArray(annotations)) this.annotations = annotations.map(i => new Annotation(i));
            if (Array.isArray(annotations)) this.annotations = annotations;
            if(discussionItem) this.discussionItem = new ActItem(discussionItem);
            if (visibleToPerClass != null) this.visibleToPerClass = observable.map(visibleToPerClass);
            if (Array.isArray(numericSliderOptions)) this.numericSliderOptions = numericSliderOptions.map(o => new NumericSliderOption(o));
            if(Array.isArray(items)) this.items = items.map(o=>new ActItem(o));

            Object.assign(this, pData);
        }
        if (this.content == null) this.content = "";

        const isNewItem: boolean = !this.id;

        if(this.type === EActItemType.ChoiceQuiz) {
            if (this.options == undefined) {
                this.options = [];
            }
            if (this.options.length == 0 && isNewItem) {
                if (this.isTrueFalseItem) {
                    this.options.push(new ActOption({label: 'True'}));
                    this.options.push(new ActOption({label: 'False'}));
                }
                else {
                    this.options.push(new ActOption());
                    this.options.push(new ActOption());
                    this.options.push(new ActOption());
                    this.options.push(new ActOption());
                }
            }
        }
        else if(this.type === EActItemType.PollQuiz) {
            if (this.pollItems == null) {
                this.pollItems = [];
            }
            if (this.pollItems.length < 1 && isNewItem) {
                this.pollItems.push(new PollQuizItem());
                this.pollItems.push(new PollQuizItem());
            }
        }
        else if(this.type === EActItemType.NumericSlider) {
            if (this.numericSliderOptions == null) {
                this.numericSliderOptions = [];
            }
            if (this.numericSliderOptions.length < 1 && isNewItem) {
                this.numericSliderOptions.push(new NumericSliderOption());
            }
        }

        if (isNewItem) { this.id = nanoid(); }

        /// 2020-07-15 huyn: auto populate wip discussion
        if (!!this.discussion && !isValidIdentity(this.discussion.discussionId) && !this.discussion.content)
        {
            this.discussion.content = this.content;
        }
        this.origLink = this.link;

        // set point = 1 by default if the item's type is question
        if (QuestionTypes.has(this.type)) this.pointScore = this.pointScore ?? 1;
    }

    set_title           (v : string          ) { this.title            = v }
    set_totalVotes      (v ?: number          ) { this.totalVotes       = v }
    set_type            (v : EActItemType    ) { this.type             = v }
    set_subType         (v : MediaSubTypeEnum) { this.subType          = v }
    set_image           (v : UrlString       ) { this.image            = v }
    set_highlightTextContent   (v        : HtmlString      ) { this.highlightTextContent = v         }
    set_highlightTextItem      (v        : string[]        ) { this.highlightTextItems   = v       ; }
    set_correctHighlightItem   (v        : string[]        ) { this.correctHighlightItems   = v       ; }
    set_highlightTextType      (v       ?:HighlightTextType) { this.highlightTextType    = v       ; }
    set_content         (v : any             ) {
        let orgContent = this.content;
        if (this.type == EActItemType.Text && v.includes('background-color: var(')){
            String(v).match(/background-color[^;]*\)(;|)/g)?.forEach(s => this.content = String(v).replace(s,''));
        } else this.content = v;

        if (this.type == EActItemType.FillInBlank) {
            const eleArr = getFillInBlankElementInHtml(v);
            const _fillInBlankItems = [];
            for (let ele of eleArr) {
                const data = ele.dataset; // include data-id & data-type
                let currentItem = this.fillInBlankItems?.find(b => b.id == data.id);
                if (currentItem) {
                    if(data.type && currentItem.type != FillInBlankItemType[data.type]) currentItem.type = FillInBlankItemType[data.type];
                } else {
                    currentItem = new FillInBlankItem({ id: data.id , type: data.type ? FillInBlankItemType[data.type] : FillInBlankItemType.TypeIn });
                }
                _fillInBlankItems.push(currentItem);
            }
            this.fillInBlankItems = _fillInBlankItems;
        }
        else if(this.type == EActItemType.HighlightText){
            if(escapeUnprintCharacter(v) != escapeUnprintCharacter(orgContent)){

                // In case loading for creating new a highlight question, set it to the value of original content,
                // In case when reloading the existed highlight question, it will be set to the highlightTextContent.
                // including 'htq-item' meaning question already highlighted.
                var ct = this.highlightTextContent?.includes("htq-item") ? this.highlightTextContent : v;
                this.set_highlightTextContent(ct);
                //this.set_highlightTextType(undefined);
                //this.set_highlightTextItem([]);
            }
        }
    }
    set_startDate       (v?: number          ) { this.startDate        = v }
    set_endDate         (v?: number          ) { this.endDate          = v }
    set_visibleTo       (v?: string[]        ) { this.visibleTo        = v }
    set_pointScore      (v?: number          ) { this.pointScore       = v }
    set_penaltyPercentage(v?: number         ) { this.penaltyPercentage = v }
    set_actualScore     (v?: number          ) { this.actualScore      = v }
    set_isExtraCredit   (v?: boolean         ) { this.isExtraCredit    = v }
    set_isTrueFalseItem (v?: boolean         ) { this.isTrueFalseItem  = v }
    set_embedFrameHeight(v?: string          ) { this.embedFrameHeight = v }
    set_discussionId    (v?:DbIdentity       ) { this.discussionId     = v }
    set_resourceDiscussionId    (v?:DbIdentity       ) { this.resourceDiscussionId     = v }
    set_discussion      (v?:BaseDiscussion   ) { this.discussion       = v }
    set_discussionItem  (v?:ActItem          ) { this.discussionItem   = v }
    set_discussionModuleId (v?:string        ) { this.discussionModuleId = v }
    set_collaborativeMode (v ?:boolean       ) { this.collaborativeMode = v ?? false; }
    set_answerContent   (v : any             ) { this.answerContent    = v;}
    set_blockVideoSeeking (v ?: boolean) { this.blockVideoSeeking = v ?? false }

    isEmpty = (str:any) => {
        return (str==undefined || 0 === str.length);
    }
    set_numericMin = (v:number|undefined)   => { this.numericMin = v; };
    populateMaxValue = () => {
        if(!this.isEmpty(this.numericMin) && (this.isEmpty(this.numericMax) || this.numericMax! < this.numericMin!)) {
            this.numericMax = this.numericMin;
        }
    };
    set_numericMax = (v:number|undefined)   => { this.numericMax = v; };
    populateMinValue = () => {
        if(!this.isEmpty(this.numericMin) && !this.isEmpty(this.numericMax) && this.numericMin! > this.numericMax!){
            this.numericMin = this.numericMax;
        }
    };

    imageFile?:UploadFile = undefined;
    set_imageFile (v:UploadFile|undefined) { this.imageFile = v };
    imageFile2?:UploadFile = undefined;

    handleImageUpload = (
        targetImgElement: HTMLImageElement,
        index: number,
        state: string,
        imageInfo: object,
        remainingFilesCount: number) => {
    };

    handleVideoUpload = (
        targetImgElement: HTMLImageElement,
        index: number,
        state: string,
        imageInfo: object,
        remainingFilesCount: number) => {
    };

    handleUploadError = (
        errorMessage: string,
        result: any) => {
    };

    removeMathQuizzItem = (v:MatchQuizzItem) => {
        this.matchQuizzItems = this.matchQuizzItems.filter(i=>i!=v);
    };

    removeMathQuizzRowItem = (v:MatchQuizzRowItem) => {
        this.matchQuizzRowItems = this.matchQuizzRowItems.filter(i=>i!=v);
    };

    addMathQuizzRightOption = (v:MatchQuizzRowItem) => {
        v.rightOptions.push(new MatchQuizzOption({id: nanoid()}))
    };

    removeMathQuizzRightOption = (rowItem :MatchQuizzRowItem, option :MatchQuizzOption) => {
        rowItem.rightOptions = rowItem.rightOptions.filter(x => x != option);
        if(rowItem.isEmptyQuestion && rowItem.rightOptions.length == 0){
            this.removeMathQuizzRowItem(rowItem);
        }
    };

    addNumericSliderOption = () => this.numericSliderOptions.push(new NumericSliderOption({correctValue: this.numericMin}));
    removeNumericSliderOption = (item: NumericSliderOption) => this.numericSliderOptions = this.numericSliderOptions.filter(o => o.id != item.id);
    isValidNumericSlider = () => {
        return (
            this.numericMin != null && this.numericMax != null && this.numericMin < this.numericMax &&
            this.numericSliderInterval != null && this.numericSliderInterval > 0 && this.numericSliderInterval <= (this.numericMax - this.numericMin)
    )}

    set_link = (v: UrlString    ) => { this.link       = v };
    set_link2 = (v: UrlString    ) => { this.link2      = v };
    set_embedLink = (v: UrlString    ) => { this.embedLink  = v };
    set_sharedLink = (v: UrlString   ) => { this.sharedLink  = v };
    set_mediaType = (v: MediaTypeEnum) => { this.mediaType  = v };
    set_mediaType2 = (v: MediaTypeEnum) => { this.mediaType2 = v };
    set_pdfLink = (v: UrlString    ) => { this.pdfLink    = v };

    mediaFile?:UploadFile = undefined;
    async set_mediaFile(v:UploadFile|undefined) {
        if (v == this.mediaFile) return;
        this.pMediaFile = undefined;
        if (v != null) {
            const file: File = (v.originFileObj || v) as any;
            if (file.type.startsWith("video/") || videoExts.some(regex => regex.test(file?.name ?? ""))) {
                v.url = URL.createObjectURL(file);
                this.mediaType = MediaTypeEnum.VideoFile;
            } else if (file.type.startsWith("image/") || imageExts.some(regex => regex.test(file?.name ?? ""))) {
                v.thumbUrl = v.url = URL.createObjectURL(file);
                this.mediaType = MediaTypeEnum.ImageFile;
            } else if (file.type.startsWith("audio/") || audioExts.some(regex => regex.test(file?.name ?? ""))) {
                v.url = URL.createObjectURL(file);
                this.mediaType = MediaTypeEnum.AudioFile;
            } else {
                message.warning(i18n.t("app.activities.actDoc.unsupportedFile", {fileName: file.name}));
                return;
            }
            this.link = "";
            this.contentType = file.type;
        }
        this.mediaFile = v;
    };
    pMediaFile?: Promise<any> = undefined;

    mediaFile2?:UploadFile = undefined;
    async set_mediaFile2(v:UploadFile|undefined) {
        if (v == this.mediaFile2) return;
        this.pMediaFile2 = undefined;
        if (v != null) {
            const file: File = (v.originFileObj || v) as any;
            if (file.type.startsWith("video/") || videoExts.some(regex => regex.test(file?.name ?? ""))) {
                v.url = URL.createObjectURL(file);
                this.mediaType2 = MediaTypeEnum.VideoFile;
            } else if (file.type.startsWith("image/") || imageExts.some(regex => regex.test(file?.name ?? ""))) {
                v.thumbUrl = v.url = URL.createObjectURL(file);
                this.mediaType2 = MediaTypeEnum.ImageFile;
            } else if (file.type.startsWith("audio/") || audioExts.some(regex => regex.test(file?.name ?? ""))) {
                v.url = URL.createObjectURL(file);
                this.mediaType2 = MediaTypeEnum.AudioFile;
            } else {
                message.warning(i18n.t("app.activities.actDoc.unsupportedFile", { fileName: file.name }));
                return;
            }
            this.link2 = "";
            this.contentType2 = file.type;
        }
        this.mediaFile2 = v;
    };
    /** for Side by side item when click to multimedia */
    pMediaFile2?: Promise<any> = undefined;

    googleFile?:GoogleDocItem = undefined;
    async set_googleFile(v:GoogleDocItem|undefined) {
        if (v) {
            this.set_embedActItem({mimeType: v.mimeType, embedId: v.id, resourceKey: v.resourceKey, embedLastEdited: v.lastEditedUtc, isEmbedLink: v.isEmbedLink, embedName: v.name});
            this.embedLink = v.embedUrl ?? "";
            this.clearDocumentFile();
        }
        this.googleFile = v;
    };

    microsoftFile?: MicrosoftFile = undefined;
    async set_microsoftFile(v?: { id: string, type?: string, embedUrl?: string, sharedUrl?: string, name?: string }) {
        if (!v) return;
        this.embedId   = v.id;
        this.embedApp  = EEmbedAppType.OneDrive;
        this.embedName = v.name;
        this.embedType = v.type ?? this.embedType;
        this.embedLink = v.embedUrl ?? this.embedLink;
        this.sharedLink = v.sharedUrl ?? this.sharedLink;
        this.microsoftFile = new MicrosoftFile(v);
    };

    set_embedActItem(p: { mimeType: string, embedId: string, resourceKey?: string, embedLastEdited?: number, embedOwnerAccount?: string, isEmbedLink: boolean, embedName?: string }) {
        this.embedContentType  = p.mimeType;
        this.contentType       = p.mimeType;
        this.embedId           = p.embedId;
        this.resourceKey       = p.resourceKey;
        this.embedApp          = EEmbedAppType.GoogleDrive;
        this.embedName     = p.embedName;
        this.embedType         = googleMimeTypeToViewType(p.mimeType);
        this.embedLastEdited   = p.embedLastEdited;
        this.embedOwnerAccount = p.embedOwnerAccount ?? "";
        this.isEmbedLink       = p.isEmbedLink;
        this.set_embedLink("");
        this.set_sharedLink("");
    };

    documentFile?:UploadFile = undefined;
    set_documentFile = async (v:UploadFile|undefined) => {
        if (v != null) {
            const file: File = (v.originFileObj || v) as any;
            this.link = "";
            this.pdfLink = "";
            this.contentType = file.type;
            if (file.type == "application/pdf") { this.pdfLink = URL.createObjectURL(file) }
            v.url = URL.createObjectURL(file)
            this.clearEmbedContent();
        }
        this.documentFile = v;
    };
    clearDocumentFile() {
        this.link = "";
        this.pdfLink = "";
        this.contentType = "";
        this.documentFile = undefined;
    }

    clearEmbedContent() {
        this.embedContentType = "";
        this.embedId = "";
        this.embedLink = "";
        this.embedName = undefined;
        this.embedApp = "";
        this.resourceKey = "";
        this.embedType = "";
        this.embedLastEdited = 0;
        this.embedOwnerAccount = "";
        this.isEmbedLink=false;
        this.googleFile = undefined;
        this.microsoftFile = undefined;
    }

    clearMediaType() {
        this.contentType = "";
        this.mediaType = MediaTypeEnum.Unknown;
    }

    clearMediaType2() {
        this.contentType2 = "";
        this.mediaType2 = MediaTypeEnum.Unknown;
    }

    clearDocumentType() {
        this.contentType = "";
    }

    clone() { return this.toJS(); }

    private get _cleanedUpContent(){
        return cleanUpContent(this.content);
    }

    toJS() {
        return ({
            id                 : this.id,
            type               : this.type,
            subType            : this.subType,
            title              : this.title,
            image              : this.image,
            image2             : this.image2,
            content            : this._cleanedUpContent,
            startDate          : this.startDate,
            endDate            : this.endDate,
            link               : this.link,
            link2              : this.link2,
            contentType        : this.contentType,
            contentType2       : this.contentType2,
            mediaType          : this.mediaType,
            mediaType2         : this.mediaType2,
            blockVideoSeeking  : this.blockVideoSeeking,
            pdfLink            : this.pdfLink,
            embedLink          : this.embedLink,
            sharedLink         : this.sharedLink,
            embedContentType   : this.embedContentType,
            embedId            : this.embedId,
            h5PContentId       : this.h5PContentId,
            resourceKey        : this.resourceKey,
            embedApp           : this.embedApp,
            embedName          : this.embedName,
            embedType          : this.embedType,
            embedLastEdited    : this.embedLastEdited,
            embedOwnerAccount  : this.embedOwnerAccount,
            isEmbedLink        : this.isEmbedLink,
            matchQuizzItems    : this.matchQuizzItems.map(i => i.toJS()),
            matchQuizzRowItems : this.matchQuizzRowItems.map(i =>i.toJS()),
            matchQuizzNoMappingAnswers: this.matchQuizzNoMappingAnswers.map(i =>i.toJS()),
            numericMin         : this.numericMin,
            numericMax         : this.numericMax,
            isMultipleAnswer   : this.isMultipleAnswer,
            isRequireAllCorrect: this.isRequireAllCorrect,
            allowAnyOrderResponses: this.allowAnyOrderResponses,
            options            : this.options == null ? undefined: this.options .map(opt => opt.toJS()),
            pollItems          : this.pollItems == null ? undefined: this.pollItems.map(opt => opt.toJS()),
            pollType           : this.pollType,
            canVoteNum         : this.canVoteNum,
            discussionId       : this.discussionId,
            resourceDiscussionId: this.resourceDiscussionId,
            discussionModuleId : this.discussionModuleId,
            collaborativeMode  : this.collaborativeMode,
            discussionItem     : this.discussionItem?.toJS(),
            visibleTo          : this.visibleTo,
            visibleToPerClass  : !!this.visibleToPerClass?.size ? Object.fromEntries(this.visibleToPerClass) : undefined,
            pointScore         : this.pointScore,
            isExtraCredit      : this.isExtraCredit,
            isTrueFalseItem    : this.isTrueFalseItem,
            isLockChoiceOrder  : this.isLockChoiceOrder,
            isValuesUsedOnce   : this.isValuesUsedOnce,
            editorMode         : this.editorMode,
            embedFrameHeight   : this.embedFrameHeight,
            fillInBlankItems   : this.fillInBlankItems == null ? undefined: this.fillInBlankItems.map(opt => (new  FillInBlankItem(opt)).toJS()),
            penaltyPercentage  : this.penaltyPercentage,
            annotations        : this.annotations,
            isPenalty          : this.isPenalty,
            highlightTextContent : this.highlightTextContent,
            highlightTextItems   : this.highlightTextItems  ,
            correctHighlightItems: this.correctHighlightItems,
            highlightTextType    : this.highlightTextType   ,
            numericSliderOptions     : this.numericSliderOptions,
            numericSliderInterval    : this.numericSliderInterval,
            appIntegrationId         : this.appIntegrationId,
        });
    }

    set_pollItemsAnswer(v?: number[]) { this.pollItemsAnswer = v };
    set_pollTextAnswer(v?: string) { this.pollTextAnswer = v };
    set_pollType(v?: ActItemPollType) { this.pollType = v };

    get canShowContent() {
        return this.type != EActItemType.Discussion;
    }
    get visibleSettingClasses() { return [...this.visibleToPerClass?.keys()] ?? [];}

    set_isMultipleAnswer(v:boolean) {
        this.isMultipleAnswer = v;
    }

    set_isValuesUsedOnce(v:boolean) {this.isValuesUsedOnce = v;}

    set_isRequireAllCorrect(v:boolean) {
        this.isRequireAllCorrect = v;

        //isRequireAllCorrect and isPenalty can not true both
        if(v && this.isPenalty)
            this.set_isPenalty(false);
    }

    set_allowAnyOrderResponses(v:boolean) {
        this.allowAnyOrderResponses = v;
    }

    set_isPenalty(v ?: boolean) {
        this.isPenalty = v;

        //isRequireAllCorrect and isPenalty can not true both
        if(v && this.isRequireAllCorrect)
            this.set_isRequireAllCorrect(false);
    }

    set_LockChoiceOrderAll() {
        const allLocked = this.isAllLocked;
        if (this.type === EActItemType.ChoiceQuiz) {
            this.options?.forEach(o => o.set_isLocked(!allLocked));
        }
        if (this.type === EActItemType.MatchQuiz || this.type == EActItemType.MatchQuizN) {
            this.matchQuizzRowItems.forEach(item => item.leftOptions[0]?.set_isLocked(!allLocked));
        }
    }

    get isAllLocked() {
        if (this.type === EActItemType.ChoiceQuiz) {
            return (this.options
                && !this.options.some(x => !x.isLocked)) || false;
        }
        if (this.type === EActItemType.MatchQuiz || this.type === EActItemType.MatchQuizN) {
            return (this.matchQuizzRowItems
                && this.matchQuizzRowItems.every(item => item.leftOptions[0]?.isLocked)) || false;
        }
        return false;
    }

    set_multipleChoice_correctAnswers(isCorrect: boolean, index: number) {
        if (this.options == undefined) this.options = [];
        const nCorrectOption = this.options.filter(o => o.isCorrect).length;

        // At least 1 correct answer
        if (nCorrectOption <= 1 && !isCorrect) return;
        this.options[index]?.set_isCorrect(isCorrect);

        if (this.isTrueFalseItem && isCorrect) {
            this.options.map((o, inx) => {
                if (inx != index) o.set_isCorrect(false);
            });
        }
    }

    is_multipleChoice_deleteAble(index: number): boolean {
        if (this.options == undefined) return false;
        const nCorrectOption = this.options.filter(o => o.isCorrect).length;
        return !(nCorrectOption <= 1 && this.options[index]?.isCorrect);
    }

    is_orderList_deleteAble(index: number): boolean {
        if (this.options == undefined) return false;
        return (this.options.length > 1);
    }

    set_optionsChecked(item: ActOption, v: boolean) {
        for(var i = 0; i < this.optionsChecked.length; i++){
            if(this.optionsChecked[i]!.isEqual(item)) {
                if(!v) this.optionsChecked.splice(i, 1);
                return;
            }
        }
        if(!this.isMultipleAnswer) {
            this.optionsChecked = [];
        }
        this.optionsChecked.push(new ActOption({label:item.label, image:item.image, id:item.id}));
    }

    get_optionsChecked(item: ActOption) {
        return this.optionsChecked.some(x => x.isEqual(item))
    }

    get isNameUnique() {
        if (this.type == EActItemType.FillInBlank) {
            const blankItems = this.fillInBlankItems;
            if (!blankItems || blankItems.length < 1) return false;
            for (let item of blankItems) {
                const options = item.options.filter(x => x.getLabel != "").map(y => y.getLabel)
                if(uniq(options).length != options.length) return true;
            }
        }
        return false;
    }

    get isDuplicateChoice() {
        if (this.type !== EActItemType.ChoiceQuiz) return false;
        const contents = (this.options ?? []).map(x => extractContent(x.label).trim()).filter(x => x != "");
        return uniq(contents).length !== contents.length;
    }

    get isNoCorrectAnswer() {
        if (this.type == EActItemType.ChoiceQuiz) {
            if (this.options?.filter(x => x.getLabel != "" && x.isCorrect).length == 0)
            return true;
        }
        if (this.type == EActItemType.FillInBlank) {
            const blankItems = this.fillInBlankItems;
            if (!blankItems || blankItems.length < 1) return false;
            for (let item of blankItems) {
                if (item.type == FillInBlankItemType.TypeIn   && item.options.filter(x => x.getLabel != ""               ).length == 0) return true;
                if (item.type == FillInBlankItemType.DropDown && item.options.filter(x => x.getLabel != "" && x.isCorrect).length == 0) return true;
            }
        }
        return false;
    }

    get inValidNumericSliderItem() {
        if (!this.isValidNumericSlider()) return true;
        const numericSliderOptions = this.numericSliderOptions;
        if (!numericSliderOptions || numericSliderOptions.length < 1 || numericSliderOptions.some(x => !x.isValid(this))) return true;
        return false;
    }

    refineEditingItem() {
        switch (this.type) {
            case EActItemType.MatchQuiz:
                    this.matchQuizzItems = this.matchQuizzItems.filter(i => i.hasLeftValue || i.hasRightValue);
                break;
            case EActItemType.MatchQuizN:
                    this.matchQuizzRowItems = this.matchQuizzRowItems.filter(i => i.leftOptions.some(x => x.hasValue) || i.rightOptions.some(x => x.hasValue));
                    this.matchQuizzNoMappingAnswers = this.matchQuizzNoMappingAnswers.filter(i => i.hasValue && !this.matchQuizzRowItems.flatMap(x => x.rightOptions).some(y => y.id == i.id));
                break;
            case EActItemType.PollQuiz:
                if (this.pollItems){
                    this.pollItems = this.pollItems.filter((pollItem) => pollItem.getLabel != "" || pollItem.imageFile != null || pollItem.image != "");
                }
                break;
            default:
                break;
        }
    }

    get elementId() { return getElementId(this.id); }
    get isEmptyContent() {
        if (this.type == EActItemType.NumericQuestion || this.type == EActItemType.TextQuiz) return false;
        return (stripHtml(this.content)??"").length < 1
                    && !isUrl(this.link)
                    && !this.documentFile
                    && !this.embedId
                    && !this.h5PContentId
                    && this.matchQuizzItems.length < 1
                    && this.matchQuizzRowItems.length < 1
                    && this.options && this.options.filter(opt => opt.IsValid()).length < 1
                    && !this.discussion
                    && (this.pollItems && this.pollItems.length < 1)
    }

    /** Determine whether the Act Item is a delimiter without content.*/
    get isDelimiter()  {return (this.content.includes("continue-btn") || this.content.includes("spacer"));}

    static filter = {
        /** visible to student need to check in back-end, this function is just using to double check purpose. */
        visibleToStudent : <T extends ActItem>(s: T, studentId: DbIdentity) => s.visibleTo == null || s.visibleTo.length < 1 || s.visibleTo.some(v => Number(v) === studentId),
    }
}

export const isValidPollItem = (item: ActItem|IStudentActItem) => {
    if (item.pollType === ActItemPollType.Text) return true;

    return !!item.pollItems?.length;
}