import { makeObservable, observable } from "mobx";
import { DefaultId } from "./types";
import { aFetch } from "../services/api/fetch";

export enum ClientErrorType{
    Unknown           = 0,
    ConnectionLost    = 1,
    GoogleSignInError = 2,
}
export class ClientErrorLog {
    constructor(data?: {}) {
        if (data != null) {
            Object.assign(this, data);
        }

        makeObservable(this, {
            event       : observable,
            source      : observable,
            error       : observable,
            timeStamp   : observable,
            url         : observable,
            errorType   : observable,
            endpointUrl : observable,
        });
    }

    event        : string = "";
    source      ?: string = "";
    error        : string = "";
    lineno      ?: number = 0;
    colno       ?: number = 0;
    timeStamp    : number = Date.now();
    url          : string = "";
    errorType    : ClientErrorType = DefaultId;
    endpointUrl ?: string = "";

    static async addLog(errorLog: ClientErrorLog) {
        const [err, reportId] = await aFetch<number>("POST", `/qos/client/issue`, errorLog);
        return [err, reportId] as const;
    }
}