
export enum MediaTypeEnum {
    Unsupport = -1,
    Unknown   = 0,
    ImageFile = 1,
    VideoFile = 2,
    AudioFile = 3,
    VideoLink = 4,
    AudioLink = 5,
    ImageLink = 6,
    PfdLink    = 7,
    GoogleLink = 8,
}

export enum MediaSubTypeEnum {
    Unknown     = 0,
    Image       = 1,
    Image_Text  = 2,
    Image_Image = 3,
    Video       = 4,
    Video_Text  = 5,
}