export function isOneDriveUrl(url: string):boolean{
    return (/^https?:\/\/([a-zA-Z\d-]+\.){0,}sharepoint\.com/i.test(url) === true);
}

export function normalizeOneDriveFileEmbed(urlStr: string){
    if(!urlStr || urlStr == "") return urlStr;
    let url = new URL(urlStr);
    // action=embedview is miracle param to make a normal in to embed 😍
    url.searchParams.set("action","embedview");
    return url.toString();
}

export function normalizeOneDriveFileEditor(urlStr: string){
    if(!urlStr || urlStr == "") return urlStr;
    let url = new URL(urlStr);
    // action=default is miracle param to make a normal into editor 😍
    url.searchParams.set("action","default");
    return url.toString();
}