import { observable, computed, toJS, makeObservable, action } from "mobx";

import { DbIdentity, DefaultId, NumberDate } from "./types"
import { User } from "./User";
import { CommentSeen } from "./CommentSeen";
import type { ThreadUnread } from "./ThreadUnread";

import { aFetch } from "../services/api/fetch";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";

import { createError } from "../services/api/AppError";
import i18n from "../i18n";

import { localStorage } from "../utils/localStorage";

import { BuildCacheName_Comment, Gradebook_Sync_Comment_Storage_Name, IsEnableCacheComment } from "../config";
import { ActivityScore } from "./ActivityScore";
import { CommentEditHistory } from "./CommentEditHistory";

import { StudentCacheIdbStore } from "../utils/idbKeyval";
import { uniq } from "lodash-es";
export interface IUserDiscussionComment {
    threadId    : string;
    commentUser : User,
    commentCount: number,
}

export interface ICommentCounter {
    userId      : DbIdentity,
    totalComment: number,
}

export enum CommentVisibility {
    Everyone     = 0,
    ClassFaculty = 1,
}
export interface IDiscussionThreadStats {
    threadId: string,
    rootLevelPosted?: boolean,
    numRepliedToOtherPeople?: number
}


export interface IComment {
    attachments : ICommentAttachment[]
    content: string
    threadId: string
    asAnonymous: boolean,
    createdBy?: DbIdentity
    dateCreated?: NumberDate,
}

export type IPendingComment = { pendingCommentId   : DbIdentity } & IComment

export class Comment {
    commentId   : DbIdentity = DefaultId;
    threadId    : string     = ""       ;
    replyTo    ?: DbIdentity = undefined;
    isSavedEditedComment ?: boolean = false;
    content     : string     = ""       ; set_content(v: string) { this.content = v }
    createdBy  ?: DbIdentity = undefined;
    dateCreated?: NumberDate = undefined;
    dateUpdated?: NumberDate = undefined;
    isGood     ?: boolean    = false    ;
    isDeleted   : boolean    = false    ;
    deletedBy   : string     = ""       ;
    isFaculty   : boolean    = false    ;
    asAnonymous : boolean    = false    ;
    isPending   : boolean    = false    ;

    /**
     * User ID that the comment should be visible to beside the comment creator.
     *
     * When {@link CommentVisibility.Everyone}, comment will be visible to all thread users.
     *
     * When {@link CommentVisibility.ClassFaculty}, comment will be visible to faculties of clas.
     */
    visibility: DbIdentity | CommentVisibility  = CommentVisibility.Everyone;

    attachments : ICommentAttachment[] = [];

    constructor(data: any) {
        makeObservable(this, {
            replyTo    : observable,
            isSavedEditedComment: observable,
            content    : observable, set_content: action.bound,
            createdBy  : observable,
            dateCreated: observable,
            dateUpdated: observable,
            isDeleted  : observable,
            isGood     : observable,
            isFaculty  : observable,
            asAnonymous: observable,
            visibility : observable,
            deletedBy  : observable,
            isPending  : observable,
            params     : computed,
            isAnonymous: computed,
            attachments: observable.shallow
        });

        if (data != null){
            const { attachments, ...pData } = data;
            Object.assign(this, pData);
            if (Array.isArray(attachments)) this.attachments = attachments.map(item => ({...item} as ICommentAttachment));
        }
    }

    get params() { return ({ threadId: this.threadId, commentId: this.commentId }) }
    get isAnonymous() { return this.createdBy == 0 && this.asAnonymous;}
    toJS() { return toJS(this); }

    async save(userId: number) {
        const { threadId, commentId } = this;
        if (!threadId.trim()) return [createError(new Error(i18n.t("api.comment.error.invalidThreadId")), 500), undefined!] as const;

        const [method, url] = commentId < 1
                        ? ["POST", `/thread/${threadId}/comments`] as const
                        : ["PUT", `/thread/${threadId}/comments/${commentId}`] as const;

        interface IPostCommentResponse {
            comment: Comment;
            isPending?: boolean;
            threadStats: IDiscussionThreadStats;
            editHistories?: CommentEditHistory[];
            activityScore?: ActivityScore;
        }

        const [err, data] = await aFetch<IPostCommentResponse>(method, url, this.toJS());

        if (IsEnableCacheComment) {
            const studentCacheDb = new StudentCacheIdbStore();
            const cacheName = BuildCacheName_Comment(userId, threadId);

            if (err || !data) { //whatever the error, we need to cache data for safety.
                await studentCacheDb.set(cacheName, this.toJS());
                return [err ?? createError(new Error(i18n.t("api.error.comment.offline")), 400), undefined!] as const;
            }
            else {
                //remove if "PUT" or "POST" successfully.
                await studentCacheDb.del(cacheName);
            }
        }

        // early return if error
        if (err) return [err, undefined] as const

        // post comment information to gradebook table
        localStorage.setItem(Gradebook_Sync_Comment_Storage_Name, JSON.stringify({threadId}));

        const { comment, threadStats, editHistories, activityScore, isPending } = (data ?? {});
        return [err, err ? undefined : {
            comment: new Comment({...comment, isPending}),
            threadStats,
            editHistories: editHistories ?? [],
            activityScore : activityScore ? new ActivityScore(activityScore): undefined,
        }] as const;
    }

    static async getCommentsOfThread({ threadId, dateCreated, classId, signal }: {threadId: string, dateCreated ?:NumberDate, classId ?:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/thread/${threadId}/comments`, { dateCreated, classId }, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async getEPortfolioCommentsOfThread({ threadId, dateCreated, signal }: {threadId: string, dateCreated?:NumberDate, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/thread/${threadId}/eportfolio-comments`, {dateCreated}, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async getThreadUnreads({ threadIdList, signal }: {threadIdList: string[], signal?: AbortSignal}) {
        const [err, data] = await aFetch<ThreadUnread[]>("GET", `/thread/threadUnreads/${threadIdList.join(',')}`, { signal });
        return [err, (err ? [] : data)!] as const;
    }

    static async getUnreadThreadsOfEportfolio({ eportfolioDocId, signal }: {eportfolioDocId: string, signal?: AbortSignal}) {
        const [err, data] = await aFetch<ThreadUnread[]>("GET", `/thread/threadUnreads/eportfolio/${eportfolioDocId}`, undefined, { signal });
        return [err, (err ? [] : data)!] as const;
    }

    static async getCommentSeenOfThread({ threadId, signal }: {threadId: string, signal?: AbortSignal}) {
        const [err, data] = await aFetch<CommentSeen>("GET", `/thread/${threadId}/commentSeen`, undefined, { signal });
        return [err, (err ? undefined : new CommentSeen(data))!] as const;
    }

    static async getCommentsOfThreadAsParent({ parentId, studentId, threadId, dateCreated, classId, signal }: { parentId: DbIdentity, studentId: DbIdentity, threadId: string, dateCreated?:NumberDate, classId ?: DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `parent/${parentId}/student/${studentId}/thread/${threadId}/comments`, { dateCreated, classId }, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async getDiscussionCommentUsersOfClass({ facultyId, classId, signal }:{facultyId: DbIdentity, classId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<IUserDiscussionComment[]>("GET", `/faculty/${facultyId}/class/${classId}/comments`, undefined, { signal });
        return [err, (err ? [] : data.map(x =>  ({
            threadId: x.threadId,
            commentCount: x.commentCount,
            commentUser: new User(x.commentUser)
        } as IUserDiscussionComment)))] as const;
    }

    static async getThreadIdsOfStudentClassAsParent({ parentId, studentId, classId, signal }: {parentId: DbIdentity, studentId: DbIdentity, classId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<string[]>("GET", `/parent/${parentId}/student/${studentId}/class/${classId}/threadIds`, undefined, { signal });
        return [err, (err ? [] : data)] as const;
    }

    static async setSeen({ threadId, lastSeen }: { threadId: string, lastSeen?: number }) {
        const [err, x] = await aFetch<{}>("POST", `/thread/${threadId}/seen`, lastSeen);
        return [err, (err ? undefined : x)!] as const;
    }

    static async setMarkOrUnmark({ commentId, isMarked }: { commentId: number, isMarked: boolean }) {
        const [err, x] = await aFetch<{}>("PUT", `thread/comment/${commentId}/mark`, isMarked);
        return [err, (err ? undefined : new Comment(x))!] as const;
    }

    static async deleteComment({ threadId, commentId }: { threadId: string,commentId: number }) {
        const [err, { comment, threadStats }] = await aFetch<{ comment: Comment, threadStats: IDiscussionThreadStats}>("DELETE", `thread/${threadId}/comments/${commentId}`);
        return [err, (err ? undefined : { deletedComment: new Comment(comment), threadStats })!] as const;
    }

    static async deleteEPortfolioComment({ threadId, commentId }: { threadId: string, commentId: number }) {
        const [err, { comment, threadStats }] = await aFetch<{ comment: Comment, threadStats: IDiscussionThreadStats}>("DELETE", `thread/${threadId}/eportfolio-comments/${commentId}`);
        return [err, (err ? undefined : { deletedComment: new Comment(comment), threadStats })!] as const;
    }

    static async blockedUser({ threadId, userId }: { threadId: string, userId: number }) {
        const [err, x] = await aFetch<IUserThreadBlocking>("POST", `thread/${threadId}/block/users/${userId}`);
        return [err, (err ? undefined : x)!] as const;
    }

    static async unBlockedUser({ threadId, userId }: { threadId: string,userId: number }) {
        const [err, x] = await aFetch<IUserThreadBlocking>("POST", `thread/${threadId}/block/users/${userId}/unblock`);
        return [err, (err ? undefined : x)!] as const;
    }

    static async undeleteComment({ threadId, commentId }: { threadId: string,commentId: number }) {
        const [err, { comment, threadStats }] = await aFetch<{ comment: Comment, threadStats: IDiscussionThreadStats}>("PUT", `thread/${threadId}/comments/${commentId}/undelete`);
        return [err, (err ? undefined : { unDeletedComment: new Comment(comment), threadStats })!] as const;
    }

    async notifyStudentThatGradeHasComment(threadId: string, studentId: DbIdentity, content: string) {
        const data = {notifyToUserId: studentId, comment: content, activityId: undefined, isFacultyComment: true} as IGradeHasComment;
        const [err] = await aFetch<{}>("POST", `thread/${threadId}/gradeHasComment`, data);
        return [err] as const;
    }

    async notifyFacultyThatGradeHasComment(threadId: string, facultyId: DbIdentity, content: string) {
        const data = {notifyToUserId: facultyId, comment: content, activityId: undefined, isFacultyComment: false} as IGradeHasComment;
        const [err] = await aFetch<{}>("POST", `thread/${threadId}/gradeHasComment`, data);
        return [err] as const;
    }

    async notifyGroupStudentThatGradeHasComment(threadId: string, activityId: DbIdentity, commentId: string) {
        // this action is for Group of Student, it only happens from Faculty side
        // so isFacultyComment always be true and notifyToUserId can be ANY number > 0 to pass server-side validation (server-side won't use this number anyway)
        const data = {notifyToUserId : 1, commentId, activityId: activityId, isFacultyComment: true} ;
        const [err] = await aFetch<{}>("POST", `thread/${threadId}/groupGradeHasComment`, data);
        return [err] as const;
    }

    static async countStudentDiscussionComment(activityId: DbIdentity) {
        const [err, data] = await aFetch<ICommentCounter[]>("GET", `thread/activity/${activityId}/commentCount`);
        return [err, (err ? [] : data)] as const;
    }

    static async fetchNewStudentComments({ facultyId, signal, classIds: cIds }: { facultyId: DbIdentity, signal?: AbortSignal, classIds: DbIdentity[] }) {
        // This API could be output cached, better normalize query string.
        const classIds = uniq(cIds).sort();
        return aFetch<IStudentComment>("GET", `/faculty/${facultyId}/studentComments`, {classIds}, { signal });
    }

    static async fetchStudentCommentsInActivity({facultyId, studentId,activityId, signal}: {facultyId: DbIdentity, studentId: DbIdentity, activityId: DbIdentity, signal?: AbortSignal}){
        const [err, data] = await  aFetch<Comment[]>("GET", `/faculty/${facultyId}/student/${studentId}/activity/${activityId}/studentComments`, undefined, { signal });
        return [err, (err ? [] : data)] as const;
    }
    static sorter = {
        dateCreated: (a: Comment, b: Comment) => (a.dateCreated || -1) - (b.dateCreated || -1),
        dateUpdated: (a: Comment, b: Comment) => (b.dateUpdated || -1) - (a.dateUpdated || -1),
    }
}

export interface IStudentComment {
    activityId      : DbIdentity,
    discussionId    : DbIdentity,
    studentId       : DbIdentity,
    defaultClassId  : DbIdentity,
    conferenceLogId : DbIdentity,
    isActivityGroup : boolean   ,
    commentCount    : number    ,
}

export interface IUserThreadBlocking {
    userId    : DbIdentity,
    threadId  : string,
    createdBy : DbIdentity
}

interface IGradeHasComment {
    notifyToUserId : DbIdentity | undefined,
    comment : string,
    activityId: DbIdentity | undefined,
    isFacultyComment: boolean
}

export interface ICommentAttachment {
    link?: string,
    file?: File & { url: string, name ?: string },
    contentType: string
}
