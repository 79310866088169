import { isObject } from "lodash-es";

interface IStorageData {
    value : string,
    ttl  ?: number
}

function isValidStorageData(value: any) : value is IStorageData {
    return isObject(value) && 'value' in value && 'ttl' in value;
}

const safeLocalStorage = {
    /** Set an item to local storage with time to live in seconds. */
    setItem(key: string, value: string, ttl?: number ) {
        try {
            if (!!ttl) value = JSON.stringify({value, ttl: (Date.now() + (ttl * 1e3))});
            localStorage.setItem(key, value); return undefined;
        } catch(e) {
            return e as Error
        }
    },

    getItem(key: string) : string | null {
        let rawValue : string | null = null;
        try {
            rawValue = localStorage.getItem(key);
            if (rawValue == null) return rawValue;
            const item = JSON.parse(rawValue);

            const hasTTL = isValidStorageData(item);
            if (!hasTTL) return rawValue;

            if (item.ttl && Date.now() > item.ttl) {
                localStorage.removeItem(key);
                return null;
            }

            return item.value;
        } catch(e) {
            console.warn(e);
            return rawValue;
        }
    },

    removeItem(key: string) { try { localStorage.removeItem(key); return undefined; } catch(e) { return e as Error } },
};

const safeSessionStorage = {
    setItem(key: string, value: string) { try { sessionStorage.setItem(key, value); return undefined; } catch(e) { return e as Error } },
    getItem(key: string) { try { const value = sessionStorage.getItem(key); return value; } catch(e) { console.error(e); return null } },
    removeItem(key: string) { try { sessionStorage.removeItem(key); return undefined; } catch(e) { return e as Error } },
};

export { safeLocalStorage as localStorage, safeSessionStorage as sessionStorage };
