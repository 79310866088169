import { observable, makeObservable, computed } from "mobx";
import { DbIdentity, DefaultId } from "./types";
import { aFetch } from "../services/api/fetch";
import { lastFirstName, firstLastName } from "../utils/stringUtils";
import { UserTypeEnum } from "./User";
import { IMemberSearchView } from "../components/SearchMember";

export class GroupMemberSearchView implements IMemberSearchView {
    id              : DbIdentity   = DefaultId;
    firstName       : string       = "";
    middleName      : string       = "";
    lastName        : string       = "";
    firstNameAlias  : string       = "";
    middleNameAlias : string       = "";
    lastNameAlias   : string       = "";
    displayName     : string       = "";
    avatar          : string       = "";
    aeriesAvatar    : string       = "";
    email           : string       = "";
    userType        : UserTypeEnum = UserTypeEnum.Student;
    isOwner         : boolean      = false;

    constructor(data?:any) {
        makeObservable(this, {
            id             : observable,
            firstName      : observable,
            middleName     : observable,
            lastName       : observable,
            firstNameAlias : observable,
            middleNameAlias: observable,
            lastNameAlias  : observable,
            displayName    : observable,
            avatar         : observable,
            aeriesAvatar   : observable,
            email          : observable,
            userType       : observable,
            isOwner        : observable,
            flNameAlias      : computed,
            lfNameAlias      : computed,
            getDisplayName   : computed,
        });

        if (data != null)
        {
            Object.assign(this, data);
        }
    }
    
    get flName      () { return firstLastName(this.firstName, '', this.lastName) }
    get lfName      () { return lastFirstName(this.firstName, '', this.lastName) }
    get flNameAlias() { return this.firstNameAlias?.trim().length > 0 ? firstLastName(this.firstNameAlias, '', this.lastNameAlias || this.lastName) : firstLastName(this.firstName, '', this.lastName) }
    get lfNameAlias() { return this.firstNameAlias?.trim().length > 0 ? lastFirstName(this.firstNameAlias, '', this.lastNameAlias || this.lastName) : lastFirstName(this.firstName, '', this.lastName) }
    get getDisplayName() { return this.displayName?.trim() || this.flNameAlias; }

    static sorter = {
        flNameAlias: <T extends GroupMemberSearchView>(a: T, b: T) => (a.flNameAlias.localeCompare(b.flNameAlias)),
        lfNameAlias: <T extends GroupMemberSearchView>(a: T, b: T) => (a.lfNameAlias.localeCompare(b.lfNameAlias)),
        email      : <T extends GroupMemberSearchView>(a: T, b: T) => (a.email.localeCompare(b.email      )),
    };

    static async search({groupId, searchText, signal}:{groupId:DbIdentity, searchText: string, signal ?: AbortSignal}) {
        const [err, data] = await aFetch<{}[]>("GET", `/group/${groupId}/addMember/search`, {searchText}, { signal });
        return [err, (err ? [] : data.map(s => new GroupMemberSearchView(s)))] as const;
    }
}
