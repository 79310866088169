import { action, computed, makeObservable, observable, toJS } from "mobx";
import { stripHtml } from "../../utils/html";
import { HtmlString } from "../types";
import { nanoid } from "nanoid";

export interface IFillInOption {
    id          : string,
    label       : string,
    isCorrect   : boolean,
    caseSensitive?: boolean,
}
export interface IFillInBlankItem {
    id          : string,
    type        : FillInBlankItemType,
    ruleType    : FillInBlankItemRule,
    options     : IFillInOption[],
    pointScore   ?: number,
    numericMin   ?: number,
    numericMax   ?: number,
    numberAnswer ?: number,
}

export enum FillInBlankItemType {
    TypeIn   = 1,
    DropDown = 2,
    Numeric = 3,
    MathFormula = 4,
}

export enum FillInBlankItemRule {
    ExactMatch = 1,
    Contains   = 2,
    StartWith  = 3,
    EndWith    = 4,
}

export class FillInOption implements IFillInOption {
    id              : string  = ""   ;
    label           : string  = ""   ; set_label         (v : string ) { this.label          = v; }
    isCorrect       : boolean = false; set_isCorrect     (v : boolean) { this.isCorrect      = v; }
    isLatex         : boolean = false; set_isLatex       (v : boolean) { this.isLatex        = v; }
    caseSensitive   : boolean = false; set_caseSensitive (v : boolean) { this.caseSensitive  = v; }
    hasId           : boolean = true ;

    constructor(data?:{}) {
        makeObservable(this, {
            label        : observable  , set_label       : action.bound,
            isCorrect    : observable  , set_isCorrect   : action.bound,
            getLabel     : computed    ,
            IsValid      : action.bound,
            caseSensitive: observable  , set_caseSensitive: action.bound,
            isLatex      : observable  , set_isLatex: action.bound,
        });
        if (data != null) Object.assign(this, data);
        if (!this.id) {
            this.hasId = false;
            this.id = nanoid();
        }
    }

    IsValid() {
        return !!this.label;
    }

    get getLabel(): HtmlString {
        return stripHtml(this.label).trim();
    }

    isEqual(item: FillInOption): boolean{
        return (this.getLabel == item.getLabel);
    }
}
export class FillInBlankItem implements IFillInBlankItem {
    id          : string = "";
    type         : FillInBlankItemType = FillInBlankItemType .TypeIn    ; set_type         (v  : FillInBlankItemType ) { this.type         = v; }
    ruleType     : FillInBlankItemRule = FillInBlankItemRule.ExactMatch ; set_ruleType     (v  : FillInBlankItemRule ) { this.ruleType     = v; }
    options      : FillInOption[]      = []                             ; set_options      (v  : FillInOption[]      ) { this.options      = v; }
    pointScore ? : number              = 0                              ; set_pointScore   (v ?: number              ) { this.pointScore   = v; }
    numericMin ? : number              = undefined                      ; set_numericMin   (v ?: number              ) { this.numericMin   = v; }
    numericMax ? : number              = undefined                      ; set_numericMax   (v ?: number              ) { this.numericMax   = v; }
    numberAnswer?: number              = undefined                      ; set_numberAnswer (v ?: number              ) { this.numberAnswer = v; }
    isLatex      : boolean             = false                          ;

    constructor(data?: any) {
        makeObservable(this, {
            id           : observable,
            type         : observable, set_type         : action.bound,
            ruleType     : observable, set_ruleType     : action.bound,
            options      : observable, set_options      : action.bound,
            pointScore   : observable, set_pointScore   : action.bound,
            numericMin   : observable, set_numericMin   : action.bound,
            numericMax   : observable, set_numericMax   : action.bound,
            numberAnswer : observable, set_numberAnswer : action.bound,
            addOption    : action,
            removeOption : action,
            isValid      : action,
            validOptions : computed,
            isLatex      : observable,
        });

        if (data != null) {
            const { options, ...pData } = data;
            Object.assign(this, pData);
            if (Array.isArray(options)) this.options = options.map(o => new FillInOption(o));
        }
    }

    toJS(): IFillInBlankItem { return toJS(this); }

    isValid(v: string) {
        const _value = stripHtml(v).trim()
        let isValid = false;
        if (this.type == FillInBlankItemType.TypeIn || this.type == FillInBlankItemType.MathFormula) {
            switch (this.ruleType) {
                case FillInBlankItemRule.Contains   : isValid = this.options.findIndex(o => resolveCaseSensitive(_value, o.caseSensitive).includes(resolveCaseSensitive(o.getLabel, o.caseSensitive))) > -1   ; break;
                case FillInBlankItemRule.StartWith  : isValid = this.options.findIndex(o => resolveCaseSensitive(_value, o.caseSensitive).startsWith(resolveCaseSensitive(o.getLabel, o.caseSensitive))) > -1 ; break;
                case FillInBlankItemRule.EndWith    : isValid = this.options.findIndex(o => resolveCaseSensitive(_value, o.caseSensitive).endsWith(resolveCaseSensitive(o.getLabel, o.caseSensitive))) > -1   ; break;
                case FillInBlankItemRule.ExactMatch :
                default                             : isValid = this.options.findIndex(o => resolveCaseSensitive(o.getLabel, o.caseSensitive) == resolveCaseSensitive(_value, o.caseSensitive)) > -1;
            }
        }
        else {
            isValid = this.options.find(o => o.getLabel == _value)?.isCorrect??false;
        }
        return isValid;
        function resolveCaseSensitive(v: string, cs: boolean){
            if(cs) return v;
            return v.toLocaleLowerCase();
        }
    }

    addOption   ()                   { this.options.push(new FillInOption()); }
    removeOption(item: FillInOption) { this.options = this.options.filter(o => o.id != item.id); }

    get validOptions() {
        return this.options.filter(x => this.type == FillInBlankItemType.TypeIn || x.isCorrect);
    }


    isEmpty = (str:any) => {return (str==undefined || 0 === str.length);}
    populateMaxValue = () => {
        if(!this.isEmpty(this.numericMin) && (this.isEmpty(this.numericMax) || this.numericMax! < this.numericMin!)) {
            this.numericMax = this.numericMin;
        }
    };
    populateMinValue = () => {
        if(!this.isEmpty(this.numericMin) && !this.isEmpty(this.numericMax) && this.numericMin! > this.numericMax!){
            this.numericMin = this.numericMax;
        }
    };
}