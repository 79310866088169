import { computed, toJS, makeObservable } from "mobx";

import {DbIdentity, DefaultId } from "./types";

export class SbgConference {
    classId               : DbIdentity       = DefaultId;
    conferenceId          : DbIdentity       = DefaultId;
    classStandardId       : DbIdentity       = DefaultId;

    constructor(data?:any) {
        if (data != null) {
            Object.assign(this, data);
        }
        makeObservable(this, {
            params: computed,
        });
    }

    get params() { return ({classId:String(this.classId), conferenceId:String(this.conferenceId), classStandardId:String(this.classStandardId)}) }

    extends(data:any) {
        Object.assign(this, data);
    }

    toJS() { return toJS(this); }

    clone() {
        return new SbgConference(this.toJS());
    }
}