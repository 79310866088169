import { RegExpPatternEmail } from "../config";
import { extractContentWithLineBreak } from "./html";

export function str2Words(str: string) {
    if (!str) return [];
    str = str.replace(/<p\s*\/?>/gi, ' ');
    const plainText = extractContentWithLineBreak(str);
    return plainText.trim().split(/\s+/).filter(s => !!s);
}

export function str2Sentences(str: string) {
    if (!str) return [];
    str = str.replace(/<p\s*\/?>/gi, ' ');
    const plainText = extractContentWithLineBreak(str);
    return plainText.trim()
            .replace(/\.\.+/g, '')
            .split(/(?:[.?!])\s+/).filter(s => !!s);
}

export function getWords(str: string, length: number, postFix ?: string) {
    return str2Words(str)
        .slice(0, length)
        .join(' ') + postFix;
}

export function generateNewNameIfItDuplicated(newName: string, existingNames ?: string[]): string {
    try {
        let maxCopyNumber: number = 0;
        const pattern: RegExp = /^(.*)\((\d+)\)$/;
        if (!existingNames) return newName;
        for (const existingName of existingNames) {
            if (existingName === newName) {
                maxCopyNumber = Math.max(maxCopyNumber, 1);
            } else if (pattern.test(existingName)) {
                const match: RegExpMatchArray | null = existingName.match(pattern);
                if (match) {
                    const baseName: string = match[1] ?? newName;
                    const copyNumber: number = parseInt(match[2]??"0");
                    if (baseName.trimEnd() === newName) {
                        maxCopyNumber = Math.max(maxCopyNumber, copyNumber + 1);
                    }
                }
            }
        }

        if (maxCopyNumber === 0) {
            return newName;
        } else {
            return `${newName} (${maxCopyNumber})`;
        }
    } catch (ex) {
        console.assert(ex);
        return newName;
    }
}

export function lastFirstName(f: string, m: string, l: string) { return [l, [f, m].filter(Boolean).join(" ")].filter(Boolean).join(", "); }
export function firstLastName(f: string, m: string, l: string) { return [f, m, l].filter(Boolean).join(" "); }
export function isValidEmail(email?: string)  {return !!email && RegExpPatternEmail.test(email.toLowerCase());}
export const trimPx = (value: string): string => value.replace(/px$/, '');
export const addPx = (value: string): string => /^[0-9.]+$/.test(value) ? (value + 'px') : value;

export function isEmpty(value ?: string){
    return !(value?.trim());
}

export function isNotEmpty(value ?: string): value is string{
    return !isEmpty(value);
}

export function censor(text: string, reg: RegExp) {
    return text.replace(reg, function (match) {
        let stars = '';
        for (var i = 0; i < match.length; i++) {
            stars += '*';
        }
        return stars;
    });
}

export class MarkdownUtils {
    private value: string  = "";

    constructor(str?: string) {
        this.value = str ?? "";
    }

    /**
     * remove ```jon and ``` from the string
     * @param str
     * @returns
     */
    public removeCodeBlock() {
        const regex = /^```\s{0,}([jJ][sS][oO][nN])?|```\s{0,}$/gm;
        this.value = this.value.replace(regex, "");
        return this;
    }

    /**
     * Replace all \n with new line <br/>
     * @param str
     * @returns
     */
    public safeNewLine() {
        this.value = this.value.replace(/\\n/g, "<br/>");
        return this;
    }

    /**
     * Get the final result
     * @returns
     */
    public result() {
        return this.value;
    }
}

export function cleanUpUserSearchText(value?: string){
    return value ? ((/,+/g).test(value) ? value.replaceAll(/\W+/g,' ') : value.trim()) : '';
}