import { makeObservable, observable } from "mobx";
import { ActDoc } from "./ActDoc";
import { LessonPlanDoc } from "./LessonPlanDoc";
import { omit } from "lodash-es";
import { DbIdentity } from "../types";
import { createError } from "../../services/api/AppError";
import { aFetch } from "../../services/api/fetch";

export class InstructionActDoc extends ActDoc {
    lessonPlanDoc : LessonPlanDoc = new LessonPlanDoc();

    constructor(data ?: any) {
        super(data);

        makeObservable(this, {
            lessonPlanDoc : observable,
        });

        if (data != null) {
            const { lessonPlanDoc } = omit<InstructionActDoc, keyof ActDoc>(data);
            this.lessonPlanDoc = new LessonPlanDoc(lessonPlanDoc);
        }
    }

    override toJS(): any {
        return ({
            ...super.toJS(),
            lessonPlanDoc : this.lessonPlanDoc.toJS()
        });
    }

    static async saveInstructionDoc({facultyId, activityId, doc}:{facultyId: DbIdentity, activityId: DbIdentity, doc: InstructionActDoc}) {
        if (activityId < 1) return [createError(new Error(`Invalid activityId`), 400), doc] as const;
        const [err, x] = await aFetch<{}>("PUT", `/faculty/${facultyId}/activity/${activityId}/instructionDoc`, doc.toJS())
        return [err, (err ? undefined : new InstructionActDoc(x))!] as const;
    }

    static async fetchInstructionDoc({facultyId, activityId, signal} : {facultyId: DbIdentity, activityId: DbIdentity,signal?: AbortSignal }) {
        const [err, x] = await aFetch<{}>("GET", `/faculty/${facultyId}/activity/${activityId}/instructionDoc`, undefined, { signal })
        return [err, (err ? undefined : new InstructionActDoc(x))!] as const;
    }

    async saveResourceInstructionDoc({ resourceGroupId, activityId } : {resourceGroupId:DbIdentity, activityId:DbIdentity}) {
        const [err, x] = await aFetch<{}>("PUT", `/resourceGroup/${resourceGroupId}/activity/${activityId}/instructionDoc`, this.toJS())
        return [err, (err ? undefined : new InstructionActDoc(x))!] as const;
    }
}