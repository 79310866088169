import { DbIdentity, DefaultId } from "./types"

export enum ShoulderTapEntityType {
    District = 1,
    School   = 2,
    Class    = 3,
    Student  = 4,
}

export class ShoulderTapRecipient {
    shoulderTapRecipientId  : DbIdentity = DefaultId;
    entityType              : ShoulderTapEntityType = DefaultId;
    entityId                : DbIdentity = DefaultId;

    constructor(data ?: {}) {
        if (data) Object.assign(this, data);
    }
}