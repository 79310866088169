import { observable, action, toJS, makeObservable, computed } from "mobx";

import { DbIdentity, DefaultId, NumberDate } from "./types";
import { aFetch } from "../services/api/fetch";
import { ClassGradebookStudent, IClassGradebookStudent } from "./ClassGradebookStudent";

export interface IClassStudent {
    classId                 : DbIdentity;
    studentId               : DbIdentity;
    sortIndex               : number;
    cumulativeGrade        ?: number;
    cumulativePublishGrade ?: number;
    gradingFloor           ?: number;
    lockedMark             ?: string;
    lastAccessed           ?: NumberDate;
}

export enum StudentType {
    Unknown = 0,
    Normal  = 1,
    Audit   = 2,
}

export class ClassStudent {
    constructor(data?: {}) {
        if (data != null) {
            Object.assign(this, data);
        }

        makeObservable(this, {
            sortIndex             : observable,
            cumulativeGrade       : observable,
            cumulativePublishGrade: observable,
            gradingFloor          : observable, set_gradingFloor: action.bound,
            lockedMark            : observable, set_lockedMark  : action,
            lastAccessed          : observable,
            oneRosterId           : observable,
            studentType           : observable, set_studentType: action.bound,
            startDate             : observable,
            endDate               : observable,
            isInactive            : observable,
            showOnStudentDashboard: observable,
            showOnTeacherDashboard: observable,
            isActive              : computed  ,
            isAllActive           : computed  ,
        });
    }

    classId                : DbIdentity = DefaultId;
    studentId              : DbIdentity = DefaultId;
    sortIndex              : number     = 10000;
    cumulativeGrade       ?: number     = undefined;
    cumulativePublishGrade?: number     = undefined;
    gradingFloor          ?: number     = undefined; set_gradingFloor(v?: number) { this.gradingFloor = v };
    lockedMark            ?: string     = "";        set_lockedMark(v?: string) { this.lockedMark = v };
    lastAccessed          ?: NumberDate = undefined;
    oneRosterId           ?: string     = undefined;

    studentType            = StudentType.Unknown; set_studentType(v: StudentType) { this.studentType = v };
    startDate            ?: NumberDate       = undefined;
    endDate              ?: NumberDate       = undefined;
    /** @deprecated */
    isInactive            : boolean     = false;

    showOnStudentDashboard: boolean     = true;
    showOnTeacherDashboard: boolean     = true;

    get isActive() { return this.studentType == StudentType.Normal && (this.endDate == null || Date.now() < this.endDate); }

    get isAllActive() { return (this.endDate == null || Date.now() < this.endDate); }

    toJS() {
        return toJS(this);
    }

    gradingFloorScore(maxScore : number) {
        if (!this.gradingFloor) return undefined;
        return this.gradingFloor * maxScore;
    }

    static async saveAsFaculty(cs: ClassStudent, { facultyId }: { facultyId: DbIdentity }) {
        const [err] = await aFetch("PUT", `/faculty/${facultyId}/class/${cs.classId}/student/${cs.studentId}/classStudent`, cs.toJS());
        return err;
    }

    static async reactiveStudent({ facultyId, classId, studentId }: { facultyId: DbIdentity, classId: DbIdentity, studentId: DbIdentity }) {
        const [err, x] = await aFetch<{}>("PUT", `/faculty/${facultyId}/class/${classId}/student/${studentId}/reactivate`);
        return [err, (err ? undefined: new ClassStudent(x))] as const;
    }

    static async deleteStudentAsFaculty({ facultyId, classId, studentId }: { facultyId: DbIdentity, classId: DbIdentity, studentId: DbIdentity }) {
        const [err, x] = await aFetch<{}>("DELETE", `/faculty/${facultyId}/class/${classId}/student/${studentId}/classStudent`);
        return [err, (err ? undefined : new ClassStudent(x))] as const;
    }

    static async updateGradingFloor({ facultyId, classId, studentId, gradingFloor }: {facultyId: DbIdentity, classId: DbIdentity, studentId:DbIdentity, gradingFloor ?: number}) {
        const [err, x] = await aFetch<{}>("PUT", `/faculty/${facultyId}/class/${classId}/student/${studentId}/gradingFloor`, {GradingFloor : gradingFloor});
        return [err, (err ? undefined : new ClassStudent(x))] as const;
    }
    static async updateClassGradingFloor({ facultyId, classId, gradingFloor }: {facultyId: DbIdentity, classId: DbIdentity, gradingFloor ?: number}) {
        const [err, xs] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/class/${classId}/gradingFloor`, { GradingFloor: gradingFloor });
        return [err, (err ? [] : xs.map(x => new ClassStudent(x)))] as const;
    }

    static async reCalculateClassGrades({ classId, gradebookId }: { classId: DbIdentity, gradebookId?: DbIdentity }) {
        const [err, xs] = await aFetch<{classStudents:{}[], gradebookStudents:IClassGradebookStudent[]}>("PUT", `/class/${classId}/reCalculateClassGrades/${gradebookId ?? 0}`);
        const cs = (err ? [] : xs.classStudents.map(x => new ClassStudent(x)));
        const gs = (err ? [] : xs.gradebookStudents.map(x => new ClassGradebookStudent(x)));
        return [err, cs, gs] as const;
    }
}
