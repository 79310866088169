import { Media_Image_ThumbnailPostfix, isLocalhost } from "../config";

export function qs2URLSearchParams(qs:{}) {
    const p = new URLSearchParams();
    write2URLSearchParams(qs, p);
    return p;
}
function write2URLSearchParams(qs:{}, p:URLSearchParams) {
    for (const [key, value] of Object.entries(qs)) {
        if (value === undefined){
            if (Array.from(p.keys()).includes(key)) p.delete(key);
            continue;
        }
        if (Array.isArray(value)) for (const v of value) p.append(key, v);
        else p.set(key, String(value));
    }
}

export function qs(url:URL|string, qs:{}) {
    try {
        const u = new URL(typeof url === "string" ? url : url.toJSON());
        write2URLSearchParams(qs, u.searchParams);
        return u;
    } catch (error) {
        console.error(error);
        return url.toString();
    }
}

const isUrlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
// less validate for localhost
const isLocalhostUrlPattern = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._\-+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
export const isYoutubeUrlPattern = /((?:www|m)\.)?((?:youtube\.com|youtu.be))/;
export const isVimeoUrlPattern = /(?:www\.|player\.)?vimeo.com/;
export const isEmbedableCanvaUrlPattern = /http(s)?:\/\/www\.canva\.com\/design\/([a-zA-Z0-9/-\w&.\-]+)\/(watch|view)/;
export const isGmailImagePattern = /<img[^>]*src="http[s]?:\/\/mail.google.com\/[^"]+"[^>]*>/i

export function isUrl(url:string) {
    if (isLocalhost) return isLocalhostUrlPattern.test(url);
    return isUrlPattern.test(url);
}

// using for uploaded images in our system
export function imageUrlToThumbnail(url:string) {
    let fileExtension = "";
    let fileName = url.substr(url.lastIndexOf('/') + 1);
    const extendIndex = fileName.lastIndexOf('.');
    let urlWithoutExt = url;
    if (extendIndex > 0) {
        fileExtension = fileName.substring(extendIndex, fileName.length);
        urlWithoutExt = url.substr(0, url.lastIndexOf('/') + 1) + fileName.substr(0, extendIndex);
    }
    return `${urlWithoutExt}${Media_Image_ThumbnailPostfix}${fileExtension}`;
}

export function updateQueryStringParameter(uri: string | undefined, key: string, value: string) {
    if(!uri) return uri;
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
}

export function getProtocol (url: string): string {
    const protocolMatches = url.match(/^(https?:\/\/|www\.)(.+)$/i);
    if (protocolMatches && protocolMatches.length > 1) {
        return protocolMatches[1] === 'www.' ? 'https://' : protocolMatches[1]!;
    } else {
        return 'https://';
    }
};

export function getUrl(regex: RegExp, inputUrl: string, outputPattern: string): string {
    const protocol = getProtocol(inputUrl);

    const match = regex.exec(inputUrl);
    let newUrl = protocol + outputPattern;
    if (match) {
        for (let i = 0; i < match.length; i++) {
        newUrl = newUrl.replace('$' + i, () => match[i] ? match[i]! : '');
        }
    }

    return newUrl.replace(/\?$/, '');
};

export function isRelativeUrl(url: string){
    try {
        return new URL(document.baseURI).origin === new URL(url, document.baseURI)?.origin;
    } catch (e) {
        if (e instanceof TypeError) return false;
        throw e;
    }
}