export async function historyBack(fallback?: () => void) {
  if (typeof navigation != "undefined") {
    if (navigation.canGoBack) { navigation.back(); return true };
    fallback?.();
    return false;
  }

  if (history.length < 2) {
    fallback?.();
    return false;
  }

  const url = window.location.href;
  history.back();
  await sleep(300);
  const isGone = (window.location.href != url);
  if (!isGone && fallback != null) fallback();
  return isGone;
}

export function historyReplace(url: string|URL, options?: { state: any }) {
  history.replaceState(options?.state, '', url);
}

export function locationReplace(url: string|URL, options?: { state: any }) {
  if (typeof navigation != "undefined") {
    navigation.navigate(url.toString(), { ...options, history: "replace" });
    return
  }
  location.replace(url.toString());
}

function sleep(ms:number) { return new Promise(resolve => setTimeout(resolve, ms)) }
