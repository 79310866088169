export enum ClassPermission {
    None                                = 0,
    CanInviteTeacher                    = 1 << 0,
    /**
    * @deprecated Replaced by CanEditCourseMaterials
    */
    CanEditPages                        = 1 << 1,
    /**
    * @deprecated Replaced by CanEditCourseMaterials
    */
    CanEditAssignments                  = 1 << 2,
    /**
    * @deprecated Replaced by CanEditCourseMaterials
    */
    CanEditAssessments                  = 1 << 3,
    CanEditGrades                       = 1 << 4,
    /**
    * @deprecated Replaced by CanEditCourseMaterials
    */
    CanEditDiscussions                  = 1 << 5,
    CanSeeGrades                        = 1 << 6,
    CanDisplayNameAsCoTeacherToStudents = 1 << 7,
    CanManageRoster                     = 1 << 8,
    CanManageClassSettings              = 1 << 9,
    CanCreateStudents                   = 1 << 10,
    CanEditAnnouncements                = 1 << 11,
    CanEditCourseMaterials              = 1 << 12,
    CanManageGroups                     = 1 << 13,
    CanDisplayCustomLabel               = 1 << 14,
    Full                                = 0xffffffff, // this equivalent to UInt32.MaxValue in C#
}

export const AllPermissions = [
    ClassPermission.CanInviteTeacher  ,
    ClassPermission.CanEditGrades     ,
    ClassPermission.CanSeeGrades,
    ClassPermission.CanDisplayNameAsCoTeacherToStudents,
    ClassPermission.CanManageRoster   ,
    ClassPermission.CanManageClassSettings,
    ClassPermission.CanCreateStudents ,
    ClassPermission.CanEditAnnouncements,
    ClassPermission.CanEditCourseMaterials,
    ClassPermission.CanManageGroups,
    ClassPermission.CanDisplayCustomLabel
];

export const ViewPermissions = [
    ClassPermission.CanSeeGrades,
];

export const EditPermissions = [
    ClassPermission.CanEditAnnouncements,
    ClassPermission.CanEditCourseMaterials,
    ClassPermission.CanEditGrades       ,
];

export const ManagePermissions = [
    ClassPermission.CanManageRoster   ,
    ClassPermission.CanManageGroups   ,
    ClassPermission.CanManageClassSettings,
    ClassPermission.CanCreateStudents ,
    ClassPermission.CanInviteTeacher  ,
];