import { observable,  makeObservable } from "mobx";
import { DbIdentity, DefaultId } from "./types"

export class ActivityDistribution {
    activityDistributionId : DbIdentity = DefaultId;
    sourceClassId          : DbIdentity = DefaultId;
    sourceActivityId       : DbIdentity = DefaultId;
    targetClassId          : DbIdentity = DefaultId;
    targetActivityId       : DbIdentity = DefaultId;
    numOpened              : number     = 0        ;
    numStarted             : number     = 0        ;
    numSubmitted           : number     = 0        ;

    constructor(data?: any) {
        makeObservable(this, {
            activityDistributionId: observable,
            sourceClassId         : observable,
            sourceActivityId      : observable,
            targetClassId         : observable,
            targetActivityId      : observable,
            numOpened             : observable,
            numStarted            : observable,
            numSubmitted          : observable,
        });

        if (data != null) {
            Object.assign(this, data);

        }
    }
}