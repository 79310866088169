import { observable, makeObservable, action, computed } from "mobx";
import { extractContentWithLineBreak } from "../utils/html";
import { ICommentAttachment } from "./Comment";
import { contentType2MediaType, getMediaInfo } from "../utils/getMediaInfo";
import { MediaTypeEnum } from "./MediaTypeEnum";

const charLimit = 35;
export class ThreadUnread {
    threadId    : string = "";
    unread      : number = 0; set_unread(v: number) { this.unread = v}
    commentCount: number = 0; set_commentCount(v: number) { this.commentCount = v}
    lastComment?: string = undefined; set_lastComment(v?: string) { this.lastComment = v}
    lastCommentAttachments : ICommentAttachment[] = []; set_lastCommentAttachments(v: ICommentAttachment[]) { this.lastCommentAttachments = v}

    constructor(data?:{}) {
        makeObservable(this, {
            unread      : observable, set_unread      : action.bound,
            commentCount: observable, set_commentCount: action.bound,
            lastComment : observable, set_lastComment : action.bound,
            lastCommentAttachments : observable.shallow, set_lastCommentAttachments : action.bound,
        });

        if (data != null){
            const { lastCommentAttachments, ...pData } = data;
            Object.assign(this, pData);
            if (Array.isArray(lastCommentAttachments)) this.lastCommentAttachments = lastCommentAttachments.map(item => ({...item} as ICommentAttachment));
        }
    }

    async lastContent() {
        let content = await replaceMediaTag2String(this.lastComment);
        content = extractContentWithLineBreak(content);

        const attachContent = await this.attachments2Text(this.lastCommentAttachments);

        let lastContent = [content, attachContent].join(" ");
        if (!!lastContent) {
            lastContent = lastContent.trim();
            return lastContent.length > charLimit ? `${lastContent.slice(0, charLimit)}...` : lastContent;
        }

        return null;
    }

    async attachments2Text(atts: ICommentAttachment[]) {
        if (atts.length < 1) return "";

        const arrString = [];
        for(let i = 0; i < atts.length; i++) {
            const a = atts[i];
            if (!a) continue;

            const text = await attachment2Text(a);
            if (!!text) arrString.push(text);
        }

        return Array.from(new Set(arrString)).join(", ");
    }

    static empty = new ThreadUnread();
}


function replaceMediaTag2String(html: string | undefined) {
    if (!html) return "";

    const mediaTags = [
        {regx: /<img[^>]*src="([^"]*)"[^>]*>/gi, replaceTo: "[Image]"},
        {regx: /<img[^>]*src="([^"]*)"[^>]*>(.*?)<\/img>/gi, replaceTo: "[Image]"},

        {regx: /<video[^>]*src="([^"]*)"[^>]*>/gi, replaceTo: "[Video]"},
        {regx: /<video[^>]*>(.*?)<\/video>/gi, replaceTo: "[Video]"},
        {regx: /<video[^>]*>(\n.*?)<\/video>/gi, replaceTo: "[Video]"},

        {regx: /<audio[^>]*src="([^"]*)"[^>]*>/gi, replaceTo: "[Audio]"},
        {regx: /<audio[^>]*>(.*?)<\/audio>/gi, replaceTo: "[Audio]"},
        {regx: /<audio[^>]*>(\n.*?)<\/audio>/gi, replaceTo: "[Audio]"},

        {regx: /<iframe[^>]*src="([^"]*)"[^>]*>/gi, replaceTo: "[Link]"},
        {regx: /<figure[^>]*src="([^"]*)"[^>]*>/gi, replaceTo: "[Link]"},
        {regx: /<a[^>]*href="([^"]*)"[^>]*>(.*?)<\/a>/gi, replaceTo: "[Link]"},
    ];

    mediaTags.forEach(x => {
        html = html?.replace(x.regx, (match, src) => {
            return x.replaceTo;
        });
    })

    return html;
}

async function attachment2Text(att: ICommentAttachment) {
    let type = contentType2MediaType(att.contentType);
    if (type == MediaTypeEnum.Unknown || type == MediaTypeEnum.Unsupport)
        type = (await getMediaInfo(att.link ?? '')).mediaType;

    switch (type) {
        case MediaTypeEnum.ImageFile:
        case MediaTypeEnum.ImageLink:
            return "[Image]";

        case MediaTypeEnum.VideoFile:
        case MediaTypeEnum.VideoLink:
            return "[Video]";

        case MediaTypeEnum.AudioFile:
        case MediaTypeEnum.AudioLink:
            return "[Audio]";

        case MediaTypeEnum.PfdLink:
        case MediaTypeEnum.GoogleLink:
            return "[File]";
    }

    return "";
}