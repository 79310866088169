import { observable, action, makeObservable, computed, toJS } from "mobx";

import { DbIdentity, DefaultId } from "./types";

import { aFetch } from "../services/api/fetch";
import { numberSorter } from "../utils/list";

export enum QualityType {
    Default = "",
    Boolean = "Boolean",
};
export class Matrix {
    id          : DbIdentity = DefaultId;
    matrixName                          = ""   ; set_matrixName        (v  : string         ) { this.matrixName        = v;          };
    description                         = ""   ; set_description       (v  : string         ) { this.description       = v           };
    teacherAssessed   : boolean         = false; set_teacherAssessed   (v ?: boolean        ) { this.teacherAssessed   = v ?? false; }
    committeeAssessed : boolean         = false; set_committeeAssessed (v ?: boolean        ) { this.committeeAssessed = v ?? false; }
    communityHub      : boolean         = false; set_communityHub      (v ?: boolean        ) { this.communityHub      = v ?? false; }
    requireStudentSelfReflections : boolean = false; set_requireStudentSelfReflections (v ?: boolean ) { this.requireStudentSelfReflections = v ?? false; }
    qualities         : QualityMatrix[] = []   ; set_qualities         (v ?: QualityMatrix[]) { this.qualities         = v ?? [];    }
    originQualities   : QualityMatrix[] = []   ; set_originQualities   (v ?: QualityMatrix[]) { this.originQualities   = v ?? [];    }

    constructor(data?:any) {
        makeObservable(this, {
            id                : observable,
            matrixName        : observable        , set_matrixName        : action.bound,
            description       : observable        , set_description       : action.bound,
            teacherAssessed   : observable        , set_teacherAssessed   : action.bound,
            committeeAssessed : observable        , set_committeeAssessed : action.bound,
            communityHub      : observable        , set_communityHub      : action.bound,
            requireStudentSelfReflections : observable, set_requireStudentSelfReflections : action.bound,
            qualities         : observable.shallow, set_qualities         : action.bound,
            originQualities   : observable.shallow, set_originQualities   : action.bound,
            hasChanged        : computed,

            addQuality        : action.bound,
            removeQuality     : action.bound,
            onDragEnd     : action.bound,
        });

        if (data != null) {
            const {qualities, ...pData} = data;
            Object.assign(this, pData);
            if (Array.isArray(qualities)) this.qualities = qualities.map(x => new QualityMatrix(x));
        }
    }

    toJS() {
        return toJS(this);
    }

    addQuality = () => {
        const max = Math.max(Math.max(...this.qualities.map(x => x.id)) + 1, 1) + this.id; //qualityId = Matrix.Id + (Max(qualityId) + 1)
        const id = Number.isFinite(max) ? max : 1; 
        const newQuality = new QualityMatrix({id});
        if (this.qualities.length > 0){
            newQuality.levels = this.qualities[0]?.levels.map((x, index) => new QualityMatrixLevel({id: index + 1, name: x.name}))!;
        } else {
            newQuality.levels = [new QualityMatrixLevel({id:1})];
        }
        this.qualities = [...this.qualities, newQuality];
    };

    removeQuality(item: QualityMatrix) {
        this.qualities = this.qualities.filter(c => c !== item);
    }

    onDragEnd = (startIndex:number, endIndex:number) => {
        const xs = this.qualities;
        const [x] = xs.splice(startIndex, 1);
        if(x){
            xs.splice(endIndex, 0, x);
            this.qualities = [...xs];
        }
    };

    get hasChanged(){
        return JSON.stringify(this.qualities) != JSON.stringify(this.originQualities);
    }

    static async fetchAsUser(ps: { signal?: AbortSignal }) {
        const [err, xs] = await aFetch<{}[]>("GET", `/district/qualityMatrix`, undefined, ps);
        return [err, err ? [] : xs.map(x => new Matrix(x))] as const;
    }

    static async fetchAsAdmin(ps: { signal?: AbortSignal }) {
        const [err, xs] = await aFetch<{}[]>("GET", `/admin/qualityMatrix`, undefined, ps);
        return [err, err ? [] : xs.map(x => new Matrix(x))] as const;
    }

    static async save(items:Matrix[]) {
        const [err, xs] = await aFetch<{}[]>("POST", `/admin/qualityMatrix`, items.map(x => x.toJS()));
        return [err, err ? [] : xs.map(x => new Matrix(x))] as const;
    }
}
export class QualityMatrix {
    id          : DbIdentity = DefaultId;
    name        = "";
    description = "";
    icon        = "";
    color       = "";
    levels      : QualityMatrixLevel[] = [];
    type        : QualityType = QualityType.Default;
    sentenceStarters: string = "";

    nameIsRequired = false;

    set_name             (v: string     ) { this.name             = v; this.nameIsRequired = false; };
    set_description      (v: string     ) { this.description      = v                               };
    set_icon             (v: string     ) { this.icon             = v                               };
    set_color            (v: string     ) { this.color            = v                               };
    set_nameIsRequired   (v: boolean    ) { this.nameIsRequired   = v                               };
    set_type             (v: QualityType) { this.type             = v;                              };
    set_sentenceStarters (v: string     ) { this.sentenceStarters = v;                              };

    constructor(data?:any) {
        makeObservable(this, {
            name                : observable        ,
            description         : observable        ,
            icon                : observable        ,
            color               : observable        ,
            levels              : observable.shallow,
            nameIsRequired      : observable        ,
            type                : observable        ,
            set_description     : action.bound      ,
            set_icon            : action.bound      ,
            set_name            : action.bound      ,
            set_color           : action.bound      ,
            set_type            : action.bound      ,
            set_nameIsRequired  : action            ,
            sentenceStarters    : observable        ,
            set_sentenceStarters: action.bound      ,
            sentenceStarterArr  : computed          ,
            isMultipleSelect    : computed          ,
            addScale            : action.bound      ,
            removeScale         : action.bound      ,
        });

        if (data != null) {
            const {levels, ...pData} = data;
            Object.assign(this, pData);
            if (Array.isArray(levels)) this.levels = levels.map(x => new QualityMatrixLevel(x));
            if(!this.type) this.type = QualityType.Default;
        }
    }

    get isMultipleSelect  () { return this.type == QualityType.Boolean; }
    get sentenceStarterArr() { return this.toSentenceList(this.sentenceStarters); }

    toSentenceList(data ?: string) : string[] {
        const separateRegex =  /[,;|\n]/g;
        const dataList = data?.split(separateRegex).map(x => x?.trim()).filter(Boolean);
        return dataList??[];
    }

    toJS() {
        return ({
            id         : this.id,
            name       : this.name,
            description: this.description,
            icon       : this.icon,
            color      : this.color,
            levels     : this.levels.map(l => l.toJS()),
            sentenceStarters: this.sentenceStarters
        });
    }

    extends(data:any) {
        Object.assign(this, data);
    }

    addScale() {
        const id = Math.max(Math.max(...this.levels.map(x => x.id)) + 1, 1);
        this.levels.push(new QualityMatrixLevel({id: id, name:"", description:"", color: "", icon: ""}));
    }

    removeScale(scale: QualityMatrixLevel) {
        this.levels = this.levels.filter(i => i !== scale);
    }

    static async fetchAsAdmin(ps: { signal?: AbortSignal }) {
        const [err, xs] = await aFetch<{}[]>("GET", `/admin/qualityMatrix`, undefined, ps);
        return [err, err ? [] : xs.map(x => new QualityMatrix(x))] as const;
    }

    static async save(items:QualityMatrix[]) {
        const [err, xs] = await aFetch<{}[]>("POST", `/admin/qualityMatrix`, items.map(x => x.toJS()));
        return [err, err ? [] : xs.map(x => new QualityMatrix(x))] as const;
    }
}
export class QualityMatrixLevel {
    id          : DbIdentity = DefaultId;
    name        = "";
    description = "";
    color       = "";
    orderIndex ?: number;
    nameIsRequired = false;

    set_name       (v : string) { this.name       = v; this.nameIsRequired = false }
    set_color      (v : string) { this.color      = v }
    set_description(v : string) { this.description= v }
    set_nameIsRequired(v: boolean) { this.nameIsRequired = v };

    constructor(data?:{}) {
        makeObservable(this, {
            name           : observable,
            description    : observable,
            color          : observable,
            orderIndex     : observable,
            nameIsRequired : observable,
            set_name       : action.bound,
            set_color      : action.bound,
            set_description: action.bound,
            set_nameIsRequired: action
        });

        if (data != null) Object.assign(this, data);
    }

    toJS() {
        return ({
            id          : this.id,
            name        : this.name,
            description : this.description,
            color       : this.color,
            orderIndex  : this.orderIndex,
        });
    }
}

export const sortLevelId = numberSorter<number>(s => s);