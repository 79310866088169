import { makeObservable, observable } from "mobx";
import { aFetch } from "../services/api/fetch";
import { Activity, ActivityType } from "./Activity";
import { ExportTracker } from "./ExportTracker";
import type { DbIdentity } from "./types";
import { Folder } from "./Folder";
export interface IModule {
    moduleId        : string  ;
    moduleParentId ?: string  ;
    moduleIndex     : number  ;
    color          ?: string  ;
    secondaryColor ?: string  ;
    type           ?: ActivityType;
    isAddModuleItemToTop ?: boolean;
}

/** @deprecated */
export interface IModuleItem {
    moduleId  : string ;
    module   ?: IModule;
}

export class Module implements IModule {
    moduleId        : string = "";
    moduleIndex     : number = 0;
    moduleParentId ?: string = undefined;
    color          ?: string;
    secondaryColor ?: string;
    isAddModuleItemToTop :boolean = false;

    constructor(data?:any) {
        makeObservable(this, {
            moduleId      : observable,
            moduleIndex   : observable,
            moduleParentId: observable,
            color         : observable,
            secondaryColor: observable,
            isAddModuleItemToTop: observable,
        });

        if (data != null) this.extends(data);
    }

    extends(data: any) {
        Object.assign(this, data);
        if (!this.moduleParentId && !data.moduleParentId) this.moduleParentId = undefined;
    }

    static async fetchModulesAsFaculty({facultyId, classId, signal}:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<Module[]>("GET", `/faculty/${facultyId}/class/${classId}/modules`, undefined, { signal });
        return [err, err ? [] : xs?.map(x => new Module(x))] as const;
    }

    static async fetchModulesAsStudent({studentId, classId, signal}:{studentId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<IModule[]>("GET", `/student/${studentId}/class/${classId}/modules`, undefined, { signal });
        return [err, err ? [] : xs?.map(x => new Module(x))] as const;
    }

    static async fetchModulesAsParent({parentId, studentId, classId, signal}:{parentId: DbIdentity, studentId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<IModule[]>("GET", `parent/${parentId}/student/${studentId}/class/${classId}/modules`, undefined, { signal });
        return [err, err ? [] : xs?.map(x => new Module(x))] as const;
    }

    static async fetchModulesForPublicClass({publicLinkId, signal}:{publicLinkId: string, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<IModule[]>("GET", `public/publicClass/${publicLinkId}/modules`, undefined, { signal });
        return [err, err ? [] : xs?.map(x => new Module(x))] as const;
    }

    static async cancelImport(facultyId: DbIdentity, entityId: DbIdentity) {
        const [err, data] = await aFetch<boolean>("GET", `faculty/${facultyId}/importModuleFile/${entityId}/cancelImport`);
        return [err, data] as const;
    }

    static async setColor({facultyId, classId, moduleId, color, secondaryColor}: {facultyId:DbIdentity, classId:DbIdentity, moduleId:string, color: string, secondaryColor: string}) {
        const [err, data] = await aFetch<{}>("PUT", `/faculty/${facultyId}/class/${classId}/module/${moduleId}/setColor`, { "color": color, "secondaryColor": secondaryColor });
        return [err, (err || !data) ? undefined : new Module(data)] as const;
    }

    static async getExportTrackerId(classId: DbIdentity) {
        const [err, trackerId] = await aFetch<DbIdentity>("GET", `export/class/${classId}`);
        return [err, trackerId] as const;
    }

    static async getExportProgress(trackerId: DbIdentity) {
        const [err, tracker] = await aFetch<ExportTracker>("GET", `export/progress/${trackerId}`);
        return [err, tracker] as const;
    }

    static async setLockOrUnlock({facultyId, classId, moduleId, sectionClassIds}:{facultyId:DbIdentity, classId:DbIdentity, moduleId:string, sectionClassIds ?: DbIdentity[]}) {
        const [err, { folders, activities}] = await aFetch<{folders: Folder[], activities: Activity[]}>("PUT", `/faculty/${facultyId}/class/${classId}/module/${moduleId}/lockOrUnlock`, { "sectionClassIds" : sectionClassIds });
        return [err, err ? [] : folders?.map(x => new Folder(x)), err ? [] : activities?.map(x => new Activity(x))] as const;
    }
}
