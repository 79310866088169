import { makeObservable, observable, toJS } from "mobx";

import type { DbIdentity } from "./types";
import type { IModule } from "./Module";

import { aFetch } from "../services/api/fetch";

export class ResourceModule implements IModule {
    moduleId        : string = "";
    moduleIndex     : number = 0;
    moduleParentId ?: string = undefined;
    color          ?: string;
    secondaryColor ?: string;

    constructor(data?:any) {
        makeObservable(this, {
            moduleId      : observable,
            moduleIndex   : observable,
            moduleParentId: observable,
            color         : observable,
            secondaryColor: observable,
        });

        if (data != null) this.extends(data);
    }

    extends(data: any) {
        Object.assign(this, data);
        if (!this.moduleParentId && !data.moduleParentId) this.moduleParentId = undefined;
    }

    toJS() { return toJS(this) }

    static async fetchModulesAsFaculty({facultyId, resourceGroupId, signal}:{facultyId:DbIdentity, resourceGroupId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<ResourceModule[]>("GET", `/faculty/${facultyId}/resourceGroup/${resourceGroupId}/modules`, undefined, { signal });
        return [err, err ? [] : xs.map(x => new ResourceModule(x))] as const;
    }
}
