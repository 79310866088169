import { observable, action, makeObservable } from "mobx";

import { DbIdentity, DefaultId } from "./types"
import { aFetch } from "../services/api/fetch";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import type { UploadFile } from "antd/lib/upload/interface";
import { uploadMedia } from "../services/api/upload";
import { FaviconDomainUrl } from "../config"
import { ISortable } from "../utils/changeSortIndex";

export class CustomLink implements ISortable {
    districtId    : DbIdentity = DefaultId;
    customLinkId  : DbIdentity = DefaultId;
    customLinkName: string = "";
    linkUrl       : string = "";
    iconUrl       : string = "";
    sortIndex     : number = 10000;
    portals       : number[] = [];

    constructor(data?:any) {
        makeObservable(this, {
            districtId        : observable,
            customLinkId      : observable,
            customLinkName    : observable, set_customLinkName: action.bound,
            linkUrl           : observable, set_linkUrl       : action.bound,
            iconUrl           : observable,
            sortIndex         : observable,
            portals           : observable.shallow,


            set_iconUrl       : action,
            set_sortIndex     : action,
            set_portal        : action,
            iconFile          : observable.ref,
            set_iconFile      : action.bound,
            clear_icon        : action
        });

        if (data != null)
            Object.assign(this, data);
    }

    set_customLinkName(v: string    ) { this.customLinkName = v;}
    set_linkUrl(v: string) {
        if (this.linkUrl != v){
            let url = FaviconDomainUrl + v;
            this.set_iconUrl(url);
        }
        this.linkUrl               = v
    };
    set_iconUrl(v: string) { this.iconUrl = v };
    set_sortIndex(v: number) { this.sortIndex = v };
    set_portal(v: number[]) { this.portals = v };

    toJS() {
        return ({
            districtId              :   this.districtId,
            customLinkId            :   this.customLinkId,
            customLinkName          :   this.customLinkName,
            linkUrl                 :   this.linkUrl,
            iconUrl                 :   this.iconUrl,
            sortIndex               :   this.sortIndex,
            portals                 :   this.portals
        });
    }

    extends(data:any) {
        Object.assign(this, data);
    }

    iconFile?: UploadFile = undefined;
    set_iconFile(v?:UploadFile|undefined) { this.iconFile = v }
    clear_icon() { this.iconUrl = ""; this.iconFile = undefined; }

    static async fetchAsAdmin({ customLinkId, signal }: {customLinkId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/customLink/${customLinkId}`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(data);

        return [err, (vm?.customLinks[0])!] as const;
    }

    async update() {
        if (this.customLinkId < 1)
        {
            const [err, x] = await aFetch<{}>("POST", `/customLink`, this.toJS());
            return [err, (err ? undefined : new CustomLink(x))!] as const;
        }
        const [err, x] = await aFetch<{}>("PUT", `/customLink/${this.customLinkId}`, this.toJS());
        return [err, (err ? undefined : new CustomLink(x))!] as const;
    }

    async save() {
        const pUploadIcon = (async () => {
            if (this.iconFile == null) return;
            const f = this.iconFile.originFileObj || (this.iconFile as any as File);
            const [err, url] = await uploadMedia(f);
            if (!err) {
                this.set_iconFile(undefined);
                this.set_iconUrl(url);
            }
            return err;
        })();

        const [uploadLogoErr, uploadIconErr] = await Promise.all([pUploadIcon]);
        const uploadErr = (uploadLogoErr || uploadIconErr);
        if (uploadErr != null) return [uploadErr, this] as const;

        const [err, s] = await this.update();
        return [err, new CustomLink(s)] as const
    }

    static async fetchItems() {
        const [err, data] = await aFetch<GeneralDto>("GET",`/CustomLink/Portal`);
        const vm = err ? undefined : parseGeneralViewModel(data);
        const cs = (vm == null ? [] : vm.customLinks.map(x => new CustomLink(x)));
        return [err, cs] as const;
    }

    static async batchUpdate(xs: CustomLink[]) {
        const [err, data] = await aFetch<GeneralDto>("PUT", `/CustomLink/BatchUpdate`, xs.map(x => x.toJS()));
        const vm = err ? undefined : parseGeneralViewModel(data);
        const cs = (vm == null ? [] : vm.customLinks.map(x => new CustomLink(x)));
        return [err, cs] as const;
    }

    static async delete(customLinkId: DbIdentity) {
        const [err, x] = await aFetch<{}[]>("DELETE", `/customLink/${customLinkId}`);
        return [err, (err ? undefined : new CustomLink(x))!] as const;
    }

    static sorter = {
        sortIndex: <T extends CustomLink>(a?: T, b?: T) => ((a?.sortIndex ?? Number.MAX_SAFE_INTEGER) - (b.sortIndex ?? Number.MAX_SAFE_INTEGER)),
        customLinkName: (a?: CustomLink, b?: CustomLink) => ((a ? a.customLinkName: "").localeCompare(b ? b.customLinkName : "")),
    }
}
