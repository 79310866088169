import { action, computed, makeObservable, observable } from "mobx";

import {DbIdentity, DefaultId} from "./types";
import { BaseDiscussion } from "./BaseDiscussion";
import { ResourceActivity } from "./ResourceActivity";

import { aFetch } from "../services/api/fetch";
import { createError } from "../services/api/AppError";
import { ResourceModule } from "./ResourceModule";

export class ResourceDiscussion extends BaseDiscussion {
    activityId      : DbIdentity = DefaultId;
    resourceGroupId : DbIdentity = DefaultId;
    parentFolderId? : DbIdentity = undefined;
    isTinyMCE      ?: boolean; set_isTinyMCE (v ?:boolean){this.isTinyMCE = v}

    constructor(data?:any) {
        super(data);

        makeObservable(this, {
            params: computed,
            isTinyMCE: observable,
            set_isTinyMCE: action.bound
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }

    override clone() { return new ResourceDiscussion(this.toJS()) }

    get params() {
        return ({
            resourceGroupId: String(this.resourceGroupId),
            discussionId   : String(this.discussionId   ),
            activityId     : String(this.activityId     ),
        });
    }

    async save(data?: {}) {
        data = { ...data, ...this.toJS() };
        if (this.resourceGroupId < 1) return [createError(new Error("Invalid resourceGroupId"), 400), undefined!] as const;

        if (this.discussionId == DefaultId) {
            const [err, x] = await aFetch<{}>("POST", `/resourceGroup/${this.resourceGroupId}/discussion`, data);
            const vm = err ? undefined : parseGeneralViewModel(x);
            return [err, vm!] as const;
        }

        const [err, x] = await aFetch<{}>("PUT", `/resourceGroup/${this.resourceGroupId}/discussion/${this.discussionId}`, data);
        const vm = err ? undefined : parseGeneralViewModel(x);
        return [err, vm!] as const;
    }

    static async fecthDiscussionsByResourceGroup({ resourceGroupId, signal }: { resourceGroupId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}>("GET", `/resourceGroup/${resourceGroupId}/discussion`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(data);
        return [err, vm!] as const;
    }

    async delete() {
        const [err, x] = await aFetch<{}>("DELETE", `/resourceGroup/${this.resourceGroupId}/discussion/${this.discussionId}`);
        const vm = err ? undefined : new ResourceDiscussion(x);
        return [err, vm!] as const;
    }

    static async unDeleted(resourceGroupId: DbIdentity,discussionActivityIds: DbIdentity[]) {
        const [err, x] = await aFetch<{}>("PUT", `/resourceGroup/${resourceGroupId}/discussion/undeleted`, discussionActivityIds);
        const vm = err ? undefined : new ResourceDiscussion(x);
        return [err, vm!] as const;
    }
}

interface IActivityDiscussion {
    activities     : ResourceActivity  [],
    discussions    : ResourceDiscussion[],
    resourceModules: ResourceModule    [],
}

function parseGeneralViewModel(data:any): IActivityDiscussion {
    const activities      = Array.isArray(data.resourceActivities ) ? data.resourceActivities .map((a: any) => new ResourceActivity  (a)) : [];
    const discussions     = Array.isArray(data.resourceDiscussions) ? data.resourceDiscussions.map((a: any) => new ResourceDiscussion(a)) : [];
    const resourceModules = Array.isArray(data.resourceModules    ) ? data.resourceModules    .map((a: any) => new ResourceModule    (a)) : [];

    return ({
        activities : activities,
        discussions: discussions,
        resourceModules,
    });
}
